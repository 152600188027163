export default class PowerOrderStatusType {
  static UNTREATED = 1;
  static READY_FOR_TRANSFER = 2;
  static TRANSFER_COMPLETE = 3;
  static TRANSFER_ERROR = 4;
  static COLLECTIVE_MEASURING = 5;
  static DUPLICATE = 6;
  static REGRET = 7;
  static REJECTED = 8;
  static DELETED = 9;
  static AWAITING_ANSWER = 10;
  static ON_HOLD = 11;
  static ON_HOLD_2 = 12;
  static UNTREATED_DUPLICATE = 13;

  constructor(){   
    this.id = null;
    this.statusCode=null;
    this.statusNameEn=null;
    this.statusNameNo=null;
  }

  static createUntreated(){
    const entity = new PowerOrderStatusType();
    entity.id = PowerOrderStatusType.UNTREATED;
    entity.statusCode = "UNTREATED";
    entity.statusNameEn = "Untreated";
    entity.statusNameNo="Ubehandlet";

    return entity;
  }
}
