import { FETCH_BROKER_OFFICE_ITEM_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_ITEM_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_ITEM_ERROR } from "../actions/types";

import { FETCH_BROKER_OFFICE_RESULTS_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_RESULTS_ERROR } from "../actions/types";

import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_ERROR } from "../actions/types";
import { UPDATE_BROKER_OFFICE_POWER_PRODUCTS } from "../actions/types";

import { FETCH_BROKER_OFFICE_DEALS_PRODUCTS_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_DEALS_PRODUCTS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_DEALS_PRODUCTS_ERROR } from "../actions/types";
import { UPDATE_BROKER_OFFICE_DEALS_PRODUCTS } from "../actions/types";

import { FETCH_BROKER_OFFICE_SEND_CONFIG_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_SEND_CONFIG_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_SEND_CONFIG_ERROR } from "../actions/types";
import { UPDATE_BROKER_OFFICE_SEND_CONFIG } from "../actions/types";

import { FETCH_BROKER_OFFICE_SETTINGS_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_SETTINGS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_SETTINGS_ERROR } from "../actions/types";
import { UPDATE_BROKER_OFFICE_SETTINGS } from "../actions/types";

import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_START } from "../actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_ERROR } from "../actions/types";
import { UPDATE_BROKER_OFFICE_ACCOUNTING_SETTINGS } from "../actions/types";

import { FETCH_BROKER_OFFICE_BROKERS_START } from "actions/types";
import { FETCH_BROKER_OFFICE_BROKERS_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_BROKERS_ERROR } from "actions/types";


import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_START } from "actions/types";
import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_ERROR } from "actions/types";
import { UPDATE_BROKER_OFFICE_PRODUCT_VALUE } from "actions/types";
import { DELETE_BROKER_OFFICE_PRODUCT_VALUE } from "actions/types";


import { FETCH_BROKER_OFFICE_ACCOUNTING_INFO_START } from "actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_INFO_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_INFO_ERROR } from "actions/types";
import { SET_BROKER_OFFICE_ACCOUNTING_INFO_FILTERS } from "actions/types";

import { DISCARD_BROKER_OFFICE_ACCOUNTING_INFO } from "actions/types";

import { SWITCH_BROKER_OFFICE_ITEM_TAB } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";

import {SET_BROKER_OFFICE_BROKER_OFFICE_FILTER} from "../actions/types";
import {SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER} from "../actions/types";

import {ADD_NEW_BROKER_OFFICE} from "../actions/types";
import {UPDATE_BROKER_OFFICE} from "../actions/types";

import {ADD_NEW_BROKER} from "actions/types.js";
import {UPDATE_BROKER} from "actions/types.js";


const listingDataTemplate = {
  loading: false,
  error: null,
  entities: null,
  brokerOfficeFilter: null,
  companyChainFilter: null  
}

const workingDataItemTemplate = {
  loading: false,
  error: null,
  entity: null,
  currentTab: 1
};


const powerProductTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const dealsProductTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const sendConfigTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const settingsTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const accountingSettingsTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const brokersTabDataItemTemplate = {
  loading: false, 
  error: null,
  brokers: null
};

const productValueTabDataItemTemplate = {
  loading: false, 
  error: null,
  products: null
};

const accountingInfoTabDataItemTemplate = {
  loading: false, 
  error: null,

  filters: {
    fromAccountingDate: null,
    toAccountingDate: null
  },  

  results: null,
  currentPage: null,
  totalPages: null,
  totalResults: null,
  balance: null
};

const createInitialState = () => ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: { },

  powerProductTabData: { },

  dealsProductTabData: { },

  sendConfigTabData: { },

  settingsTabData: { },

  accountingSettingsTabData: { },

  brokersTabData: { },

  accountingInfoTabData: {},

  productValueTabData: {}
});


export default function(state = createInitialState(), action) {
  let { workingData, powerProductTabData, dealsProductTabData, sendConfigTabData, settingsTabData, accountingSettingsTabData, brokersTabData, accountingInfoTabData, productValueTabData } = state;
  const currentlistingData = state.listingData;

  switch (action.type) {
    case FETCH_BROKER_OFFICE_ITEM_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_BROKER_OFFICE_ITEM_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_BROKER_OFFICE_ITEM_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }


    //-------
    case ADD_NEW_BROKER_OFFICE: {
      console.log("Broker office action", action.type, action);

      const {entity} = action.payload;
      const id = entity.id;

      workingData[id] = {
        ...workingDataItemTemplate,
        entity
      };

      return {
        ...state,
        workingData
      };   
    }

    case UPDATE_BROKER_OFFICE: {
      console.log("Broker office action", action.type, action);

      const {id, entity} = action.payload;

      workingData[id] = {
        ...workingDataItemTemplate,
        entity
      };

      return {
        ...state,
        workingData
      };   
    }

    // ------

    case ADD_NEW_BROKER: {
      console.log("Broker office action", action.type, action);

      const {brokerOfficeId, broker} = action.payload;

      const dataItem = brokersTabData[brokerOfficeId];
      
      dataItem.brokers.push(broker);
      
      brokersTabData[brokerOfficeId] = {
        ...dataItem
      };

      return {
        ...state,
        workingData
      };    
    }

    case UPDATE_BROKER: {
      console.log("Broker office action", action.type, action);

      const {brokerOfficeId, brokerId, broker} = action.payload;

      const dataItem = brokersTabData[brokerOfficeId];
      
      dataItem.brokers.map( (item,index) => {
        if (item.id==brokerId) {
          dataItem.brokers[index] = broker;
        }
      });
      
      brokersTabData[brokerOfficeId] = {
        ...dataItem
      };

      return {
        ...state,
        workingData
      };   
    }

    //-------


    case SWITCH_BROKER_OFFICE_ITEM_TAB: {
      console.log("Broker office action", action.type, action);

      const {brokerOfficeId, index} = action.payload;

      const workingDataItem = workingData[brokerOfficeId];
      workingDataItem.currentTab = index;
      workingData[brokerOfficeId] = {...workingDataItem};

      return {
        ...state,
        workingData
      };       
    }


case FETCH_BROKER_OFFICE_RESULTS_START: {
      console.log("Broker office action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,        
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_BROKER_OFFICE_RESULTS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,        
        entities: action.payload.data
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_BROKER_OFFICE_RESULTS_ERROR: {
      console.log("Broker office action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }

    case SET_BROKER_OFFICE_BROKER_OFFICE_FILTER: {
      console.log("Broker office action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: action.payload
      };

      return {
        ...state,
        listingData
      };
    }    

    case SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER: {
      console.log("Broker office action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        companyChainFilter: action.payload
      };

      return {
        ...state,
        listingData
      };
    }    


    //-------

    case FETCH_BROKER_OFFICE_POWER_PRODUCTS_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      powerProductTabData[id] = {
        ...powerProductTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        powerProductTabData
      };
    }

    case FETCH_BROKER_OFFICE_POWER_PRODUCTS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      powerProductTabData[id] = {
        ...powerProductTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        powerProductTabData
      };
    }

    case FETCH_BROKER_OFFICE_POWER_PRODUCTS_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      powerProductTabData[id] = {
        ...powerProductTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        powerProductTabData
      };
    }

    case UPDATE_BROKER_OFFICE_POWER_PRODUCTS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      powerProductTabData[id] = {
        ...powerProductTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    //-------

    case FETCH_BROKER_OFFICE_DEALS_PRODUCTS_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      dealsProductTabData[id] = {
        ...dealsProductTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        dealsProductTabData
      };
    }

    case FETCH_BROKER_OFFICE_DEALS_PRODUCTS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      dealsProductTabData[id] = {
        ...dealsProductTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        dealsProductTabData
      };
    }

    case FETCH_BROKER_OFFICE_DEALS_PRODUCTS_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      dealsProductTabData[id] = {
        ...dealsProductTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        dealsProductTabData
      };
    }

    case UPDATE_BROKER_OFFICE_DEALS_PRODUCTS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      dealsProductTabData[id] = {
        ...dealsProductTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    //------- SEND CONFIG ----





    case FETCH_BROKER_OFFICE_SEND_CONFIG_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      sendConfigTabData[id] = {
        ...sendConfigTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        sendConfigTabData
      };
    }

    case FETCH_BROKER_OFFICE_SEND_CONFIG_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      sendConfigTabData[id] = {
        ...sendConfigTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        sendConfigTabData
      };
    }

    case FETCH_BROKER_OFFICE_SEND_CONFIG_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      sendConfigTabData[id] = {
        ...sendConfigTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        sendConfigTabData
      };
    }

    case UPDATE_BROKER_OFFICE_SEND_CONFIG: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      sendConfigTabData[id] = {
        ...sendConfigTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    //------- SETTINGS ----


    case FETCH_BROKER_OFFICE_SETTINGS_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      settingsTabData[id] = {
        ...settingsTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        settingsTabData
      };
    }

    case FETCH_BROKER_OFFICE_SETTINGS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      settingsTabData[id] = {
        ...settingsTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        settingsTabData
      };
    }

    case FETCH_BROKER_OFFICE_SETTINGS_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      settingsTabData[id] = {
        ...settingsTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        settingsTabData
      };
    }

    case UPDATE_BROKER_OFFICE_SETTINGS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      settingsTabData[id] = {
        ...settingsTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    //------- ACCOUNTING SETTINGS ----


    case FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case UPDATE_BROKER_OFFICE_ACCOUNTING_SETTINGS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    //-------

    case FETCH_BROKER_OFFICE_BROKERS_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      brokersTabData[id] = {
        ...brokersTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        brokersTabData
      };
    }

    case FETCH_BROKER_OFFICE_BROKERS_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      brokersTabData[id] = {
        ...brokersTabDataItemTemplate,
        brokers: action.payload.data
      };

      return {
        ...state,
        brokersTabData
      };
    }

    case FETCH_BROKER_OFFICE_BROKERS_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      brokersTabData[id] = {
        ...brokersTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        brokersTabData
      };
    }    

    //-------

    case FETCH_BROKER_OFFICE_PRODUCT_VALUES_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case FETCH_BROKER_OFFICE_PRODUCT_VALUES_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        products: action.payload.data
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case FETCH_BROKER_OFFICE_PRODUCT_VALUES_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case UPDATE_BROKER_OFFICE_PRODUCT_VALUE: {
      console.log("Broker office action", action.type, action);

      let brokerOfficeId = action.payload.brokerOfficeId;
      let productId = action.payload.productId;
      let productValueEntity = action.payload.productValueEntity;

      const productValueTabDataItem = productValueTabData[brokerOfficeId];
      productValueTabDataItem.products.map(item=>{
        if (item.id==productId) {
          item.productValues = [productValueEntity];  
        }
      });

      productValueTabData[brokerOfficeId] = {...productValueTabDataItem};

      return {
        ...state,
        productValueTabData
      };      
    }

    case DELETE_BROKER_OFFICE_PRODUCT_VALUE: {

      console.log("Broker office action", action.type, action);

      let brokerOfficeId = action.payload.brokerOfficeId;
      let productId = action.payload.productId;

      const productValueTabDataItem = productValueTabData[brokerOfficeId];
      productValueTabDataItem.products.map(item=>{
        if (item.id==productId) {
          item.productValues = [];  
        }
      });

      productValueTabData[brokerOfficeId] = {...productValueTabDataItem};

      return {
        ...state,
        productValueTabData
      };      
    }    

    // ---

    case FETCH_BROKER_OFFICE_ACCOUNTING_INFO_START: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;

      let filters = accountingInfoTabDataItemTemplate.filters;

      if (accountingInfoTabData[id] !== undefined) {
        filters = accountingInfoTabData[id].filters;
      }

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters,
        loading: true
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }

    case FETCH_BROKER_OFFICE_ACCOUNTING_INFO_SUCCESS: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,

        filters: accountingInfoTabDataItem.filters,

        results: action.payload.data.results,
        currentPage: action.payload.data.currentPage,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults,        
        balance: action.payload.data.balance,
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }

    case FETCH_BROKER_OFFICE_ACCOUNTING_INFO_ERROR: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters: accountingInfoTabDataItem.filters,
        error: action.payload
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }


    case SET_BROKER_OFFICE_ACCOUNTING_INFO_FILTERS: {
      console.log("Broker office action", action.type, action);


      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      const filters = {
        ...accountingInfoTabDataItem.filters,
        ...action.payload.filters
      };

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters
      };

      return {
        ...state,
        accountingInfoTabData
      }
    } 

    case DISCARD_BROKER_OFFICE_ACCOUNTING_INFO: {
      console.log("Broker office action", action.type, action);

      let id = action.payload.id;

      delete accountingInfoTabData[id];

      return {
        ...state,
        accountingInfoTabData
      };
    }

    //-------    

    case APP_LOGOUT_SUCCESS: {
      console.log("Broker office action", action.type, action);

      return createInitialState();
    }    

    default:
      return state;
  }
}
