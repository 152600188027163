import React from "react";

import { Link } from "react-router-dom";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific styles
import "app_assets/scss/Brokers.scss";

// app specific components
import { formatDate } from "utils.js";

import CompanyChain from "classes/CompanyChain";

export default function BrokerOfficeDetails({ brokerOffice, classes, setEditMode }) {

  const {companyChain} = brokerOffice;


  return (
    <Card className="Broker-Office-Details">
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}><big>{brokerOffice.name}</big></h4>
      </CardHeader>

      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>Meglerkjede</td>
              <td>
                <Link to={"/broker_chain/" + companyChain.id}>{companyChain.name}</Link>
              </td>
            </tr>
            <tr>
              <td>Juridisknavn</td>
              <td>
                {brokerOffice.name === brokerOffice.legalName ? (
                  <em>{brokerOffice.legalName}</em>
                ) : (
                  brokerOffice.legalName
                )}
              </td>
            </tr>
            <tr>
              <td>Visningsnavn</td>
              <td>{brokerOffice.displayName}</td>
            </tr>
            <tr>
              <td>Ekstern ID</td>
              <td>{brokerOffice.externalId}</td>
            </tr>
            <tr>
              <td>Organisasjonnummer</td>
              <td>{brokerOffice.orgNum}</td>
            </tr>
            <tr>
              <td>Epost</td>
              <td>{brokerOffice.email}</td>
            </tr>

            <tr>
              <td>Telefon</td>
              <td>{brokerOffice.phone}</td>
            </tr>

            <tr>
              <td>Adresse</td>
              <td>{brokerOffice.address}</td>
            </tr>

            <tr>
              <td>Postnummer</td>
              <td>{brokerOffice.zipCode}</td>
            </tr>
            <tr>
              <td>Poststed</td>
              <td>{brokerOffice.city}</td>
            </tr>
            <tr>
              <td>URL</td>
              <td>{brokerOffice.url}</td>
            </tr>

            <tr>
              <td>Opprettet dato</td>
              <td>{formatDate(brokerOffice.created)}</td>
            </tr>

            <tr>
              <td>Endret dato</td>
              <td>{formatDate(brokerOffice.modified)}</td>
            </tr>

            <tr>
              <td>Aktiv</td>
              <td>{brokerOffice.active ? "Ja" : "Nei"}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "center" }}>
        {companyChain.source===CompanyChain.INTERNAL_SOURCE &&
        <Button color="primary" onClick={(e) => setEditMode(true)}>
          Redigere
        </Button>}
      </CardFooter>
    </Card>
  );
}
