import React from "react";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";

// theme resources
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const useStyles = makeStyles(styles);

export default function CustomDialog({ modal, setModal, ok, title, message }) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={modal}
      scroll="paper"
      keepMounted={false}
      onClose={() => setModal(false)}>
      <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          color="transparent"
          onClick={() => setModal(false)}>
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        {message}
      </DialogContent>
      <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
        <Button onClick={() => setModal(false)}>Nei</Button>
        <Button
          onClick={() => {
            setModal(false);
            ok();
          }}
          color="primary">
          Ja
        </Button>
      </DialogActions>
    </Dialog>
  );
}
