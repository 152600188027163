/* AUTHENTICATION ACTIONS */

export const APP_LOGIN_START = 'app_login_start';
export const APP_LOGIN_SUCCESS = 'app_login_success';
export const APP_LOGIN_ERROR = 'app_login_error';


export const APP_LOGOUT_START = 'app_logout_start';
export const APP_LOGOUT_SUCCESS = 'app_logout_success';
export const APP_LOGOUT_ERROR = 'app_logout_error';

//export const APP_LOGOUT = 'app_logout';

export const FETCH_AUTH_INFO_START = 'fetch_auth_info_start';
export const FETCH_AUTH_INFO_SUCCESS = 'fetch_auth_info_success';
export const FETCH_AUTH_INFO_ERROR = 'fetch_auth_info_error';


/* UI NOTIFICATION ACTIONS */

// create a new notification
export const PUSH_NOTIFICATION = 'show_notification';

// close  a notification
export const CLOSE_NOTIFICATION = 'close_notification';


/* TAB ACTIONS */


// append a new tab
export const PUSH_TAB = 'push_tab';

// close a tab
export const CLOSE_TAB = 'close_tab';


/* EMAIL PROCESSING ACTIONS */
export const DRAG_AND_DROP_ATTACHMENT = 'drag_and_drop_attachment';
export const DELETE_NEW_ORDER_FROM_EMAIL = 'delete_new_order_from_email';
export const ADD_NEW_ORDER_TO_EMAIL = 'add_new_order_to_email';

/* EXPERIMENTAL ACTIONS */



/* APP SETTINGS ACTIONS */

export const FETCH_APP_SETTINGS_START = 'fetch_app_settings_start';
export const FETCH_APP_SETTINGS_SUCCESS = 'fetch_app_settings_success';
export const FETCH_APP_SETTINGS_ERROR = 'fetch_app_settings_error';


/* ESTATE ACTIONS */
export const FETCH_ESTATE_ITEM_START = 'fetch_estate_item_start';
export const FETCH_ESTATE_ITEM_SUCCESS = 'fetch_estate_item_success';
export const FETCH_ESTATE_ITEM_ERROR = 'fetch_estate_item_error';

export const FETCH_ESTATE_RESULTS_START = 'fetch_estate_results_start';
export const FETCH_ESTATE_RESULTS_SUCCESS = 'fetch_estate_results_success';
export const FETCH_ESTATE_RESULTS_ERROR = 'fetch_estate_results_error';

export const SET_ESTATE_BROKER_OFFICE_FILTER = 'set_estate_broker_office_filter';
export const SET_ESTATE_COMPANY_CHAIN_FILTER = 'set_estate_company_chain_filter';
export const SET_ESTATE_TEXT_FILTER = 'set_estate_text_filter';
export const SET_ESTATE_FROM_DATE_FILTER = 'set_estate_from_date_filter';
export const SET_ESTATE_TO_DATE_FILTER = 'set_estate_to_date_filter';

export const DISCARD_ESTATE_ITEM = 'discard_estate_item';


/* POWER COMPANY ACTIONS*/
export const FETCH_POWER_COMPANY_ITEM_START = 'fetch_power_company_item_start';
export const FETCH_POWER_COMPANY_ITEM_SUCCESS = 'fetch_power_company_item_success';
export const FETCH_POWER_COMPANY_ITEM_ERROR = 'fetch_power_company_item_error';

export const FETCH_POWER_COMPANY_RESULTS_START = 'fetch_power_company_results_start';
export const FETCH_POWER_COMPANY_RESULTS_SUCCESS = 'fetch_power_company_results_success';
export const FETCH_POWER_COMPANY_RESULTS_ERROR = 'fetch_power_company_results_error';

export const FETCH_POWER_COMPANY_CPO_START = 'fetch_power_company_cpo_start';
export const FETCH_POWER_COMPANY_CPO_SUCCESS = 'fetch_power_company_cpo_success';
export const FETCH_POWER_COMPANY_CPO_ERROR = 'fetch_power_company_cpo_error';

export const UPDATE_POWER_PRODUCT_CPO = 'update_power_product_cpo';

export const FETCH_POWER_COMPANY_TERMS_START = 'fetch_power_company_terms_start';
export const FETCH_POWER_COMPANY_TERMS_SUCCESS = 'fetch_power_company_terms_success';
export const FETCH_POWER_COMPANY_TERMS_ERROR = 'fetch_power_company_terms_error';

export const UPDATE_POWER_COMPANY_TERMS = 'update_power_company_terms';

export const SWITCH_POWER_COMPANY_ITEM_TAB = 'switch_power_company_item_tab';

/*POWER PRODUCT ACTIONS*/
export const UPDATE_POWER_PRODUCT = 'UPDATE_POWER_PRODUCT';

/* BROKER CHAIN ACTIONS */
export const FETCH_BROKER_CHAIN_ITEM_START = 'fetch_broker_chain_item_start';
export const FETCH_BROKER_CHAIN_ITEM_SUCCESS = 'fetch_broker_chain_item_success';
export const FETCH_BROKER_CHAIN_ITEM_ERROR = 'fetch_broker_chain_item_error';

export const FETCH_BROKER_CHAIN_RESULTS_START = 'fetch_broker_chain_results_start';
export const FETCH_BROKER_CHAIN_RESULTS_SUCCESS = 'fetch_broker_chain_results_success';
export const FETCH_BROKER_CHAIN_RESULTS_ERROR = 'fetch_broker_chain_results_error';

export const SWITCH_BROKER_CHAIN_ITEM_TAB = 'switch_broker_chain_item_tab';

export const FETCH_COMPANY_CHAIN_OFFICES_START = 'fetch_company_chain_offices_start';
export const FETCH_COMPANY_CHAIN_OFFICES_SUCCESS = 'fetch_company_chain_offices_success';
export const FETCH_COMPANY_CHAIN_OFFICES_ERROR = 'fetch_company_chain_offices_error';

export const FETCH_COMPANY_CHAIN_PRODUCT_VALUES_START = 'fetch_company_chain_product_values_start';
export const FETCH_COMPANY_CHAIN_PRODUCT_VALUES_SUCCESS = 'fetch_company_chain_product_values_success';
export const FETCH_COMPANY_CHAIN_PRODUCT_VALUES_ERROR = 'fetch_company_chain_product_values_error';

export const UPDATE_COMPANY_CHAIN_PRODUCT_VALUE = 'update_company_chain_product_value';
export const DELETE_COMPANY_CHAIN_PRODUCT_VALUE = 'delete_company_chain_product_value';

export const FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_START = 'fetch_company_chain_accounting_info_start';
export const FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_SUCCESS = 'fetch_company_chain_accounting_info_success';
export const FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_ERROR = 'fetch_company_chain_accounting_info_error';

export const SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS = 'set_company_chain_accounting_info_filters';
export const DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO = 'discard_company_chain_accounting_info';

export const FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_START = 'fetch_company_chain_accounting_settings_start';
export const FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_SUCCESS = 'fetch_company_chain_accounting_settings_success';
export const FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_ERROR = 'fetch_company_chain_accounting_settings_error';

export const UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS = 'update_company_chain_accounting_settings';


/* BROKER OFFICE ACTIONS */
export const FETCH_BROKER_OFFICE_ITEM_START = 'fetch_broker_office_item_start';
export const FETCH_BROKER_OFFICE_ITEM_SUCCESS = 'fetch_broker_office_item_success';
export const FETCH_BROKER_OFFICE_ITEM_ERROR = 'fetch_broker_office_item_error';

export const FETCH_BROKER_OFFICE_RESULTS_START = 'fetch_broker_office_results_start';
export const FETCH_BROKER_OFFICE_RESULTS_SUCCESS = 'fetch_broker_office_results_success';
export const FETCH_BROKER_OFFICE_RESULTS_ERROR = 'fetch_broker_office_results_error';

export const SWITCH_BROKER_OFFICE_ITEM_TAB = 'switch_broker_office_item_tab';

export const SET_BROKER_OFFICE_BROKER_OFFICE_FILTER = 'set_broker_office_broker_office_filter';
export const SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER = 'set_broker_office_company_chain_filter';


export const FETCH_BROKER_OFFICE_POWER_PRODUCTS_START = 'fetch_broker_office_power_products_start';
export const FETCH_BROKER_OFFICE_POWER_PRODUCTS_SUCCESS = 'fetch_broker_office_power_products_success';
export const FETCH_BROKER_OFFICE_POWER_PRODUCTS_ERROR = 'fetch_broker_office_power_products_error';
export const UPDATE_BROKER_OFFICE_POWER_PRODUCTS = 'update_broker_office_power_products';


export const FETCH_BROKER_OFFICE_DEALS_PRODUCTS_START = 'fetch_broker_office_deals_products_start';
export const FETCH_BROKER_OFFICE_DEALS_PRODUCTS_SUCCESS = 'fetch_broker_office_deals_products_success';
export const FETCH_BROKER_OFFICE_DEALS_PRODUCTS_ERROR = 'fetch_broker_office_deals_products_error';
export const UPDATE_BROKER_OFFICE_DEALS_PRODUCTS = 'update_broker_office_deals_products';


export const FETCH_BROKER_OFFICE_SEND_CONFIG_START = 'fetch_broker_office_send_config_start';
export const FETCH_BROKER_OFFICE_SEND_CONFIG_SUCCESS = 'fetch_broker_office_send_config_success';
export const FETCH_BROKER_OFFICE_SEND_CONFIG_ERROR = 'fetch_broker_office_send_config_error';
export const UPDATE_BROKER_OFFICE_SEND_CONFIG = 'update_broker_office_send_config';


export const FETCH_BROKER_OFFICE_SETTINGS_START = 'fetch_broker_office_settings_start';
export const FETCH_BROKER_OFFICE_SETTINGS_SUCCESS = 'fetch_broker_office_settings_success';
export const FETCH_BROKER_OFFICE_SETTINGS_ERROR = 'fetch_broker_office_settings_error';
export const UPDATE_BROKER_OFFICE_SETTINGS = 'update_broker_office_settings';

export const FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_START = 'fetch_broker_office_accounting_settings_start';
export const FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_SUCCESS = 'fetch_broker_office_accounting_settings_success';
export const FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_ERROR = 'fetch_broker_office_accounting_settings_error';
export const UPDATE_BROKER_OFFICE_ACCOUNTING_SETTINGS = 'update_broker_office_accounting_settings';


export const FETCH_BROKER_OFFICE_BROKERS_START = 'fetch_broker_office_brokers_start';
export const FETCH_BROKER_OFFICE_BROKERS_SUCCESS = 'fetch_broker_office_brokers_success';
export const FETCH_BROKER_OFFICE_BROKERS_ERROR = 'fetch_broker_office_brokers_error';

export const ADD_NEW_BROKER_OFFICE = 'add_new_broker_office';
export const UPDATE_BROKER_OFFICE = 'update_broker_office';

export const FETCH_BROKER_OFFICE_PRODUCT_VALUES_START = 'fetch_broker_office_product_values_start';
export const FETCH_BROKER_OFFICE_PRODUCT_VALUES_SUCCESS = 'fetch_broker_office_product_values_success';
export const FETCH_BROKER_OFFICE_PRODUCT_VALUES_ERROR = 'fetch_broker_office_product_values_error';

export const UPDATE_BROKER_OFFICE_PRODUCT_VALUE = 'update_broker_office_product_value';
export const DELETE_BROKER_OFFICE_PRODUCT_VALUE = 'delete_broker_office_product_value';

export const FETCH_BROKER_OFFICE_ACCOUNTING_INFO_START = 'fetch_broker_office_accounting_info_start';
export const FETCH_BROKER_OFFICE_ACCOUNTING_INFO_SUCCESS = 'fetch_broker_office_accounting_info_success';
export const FETCH_BROKER_OFFICE_ACCOUNTING_INFO_ERROR = 'fetch_broker_office_accounting_info_error';

export const SET_BROKER_OFFICE_ACCOUNTING_INFO_FILTERS = 'set_broker_office_accounting_info_filters';

export const DISCARD_BROKER_OFFICE_ACCOUNTING_INFO = 'discard_broker_office_accounting_info';

/* BROKER ACTIONS */
export const FETCH_BROKER_RESULTS_START = 'fetch_broker_results_start';
export const FETCH_BROKER_RESULTS_SUCCESS = 'fetch_broker_results_success';
export const FETCH_BROKER_RESULTS_ERROR = 'fetch_broker_results_error';

export const ADD_NEW_BROKER = 'add_new_broker';
export const UPDATE_BROKER = 'update_broker';


/* POWER ORDERS */
export const FETCH_POWER_ORDER_ITEM_START = 'fetch_power_order_item_start';
export const FETCH_POWER_ORDER_ITEM_SUCCESS = 'fetch_power_order_item_success';
export const FETCH_POWER_ORDER_ITEM_ERROR = 'fetch_power_order_item_error';
export const DISCARD_POWER_ORDER_ITEM = 'discard_power_order_item';

export const FETCH_POWER_ORDER_RESULTS_START = 'fetch_power_order_results_start';
export const FETCH_POWER_ORDER_RESULTS_SUCCESS = 'fetch_power_order_results_success';
export const FETCH_POWER_ORDER_RESULTS_ERROR = 'fetch_power_order_results_error';
export const DISCARD_POWER_ORDER_RESULTS = 'discard_power_order_results';
export const RELOAD_POWER_ORDER_RESULTS = 'reload_power_order_results';
export const SET_POWER_ORDER_FILTERS = 'set_power_order_filters';
export const SET_POWER_ORDER_RESPONSIBLE_USER_MODE = 'set_power_order_responsible_user_mode';

export const ADD_NEW_POWER_ORDER = 'add_new_power_order';
export const ADD_NEW_POWER_ORDER_COMMENT = 'add_new_power_order_comment';

export const UPDATE_POWER_ORDER_TASK = 'update_power_order_task';
export const UPDATE_POWER_ORDER_BUYER = 'update_power_order_buyer';
export const UPDATE_POWER_ORDER_SELLER = 'update_power_order_seller';
export const UPDATE_POWER_ORDER_PRODUCT = 'update_power_order_product';
export const UPDATE_POWER_ORDER_BROKER = 'update_power_order_broker';
export const UPDATE_POWER_ORDER_ESTATE = 'update_power_order_estate';
export const UPDATE_POWER_ORDER_COMMENTS = 'update_power_order_comments';


export const UPDATE_POWER_ORDER_METER = 'update_power_order_meter';
export const UPDATE_POWER_ORDER_LOCKS = 'update_power_order_locks';
export const UPDATE_POWER_ORDER_ITEM_LOCK = 'update_power_order_item_lock';
export const ADD_POWER_ORDER_METER = 'add_power_order_meter';
export const DELETE_POWER_ORDER_METER = 'delete_power_order_meter';

export const SET_DUPLICATE_SEARCH_RESULTS = 'set_duplicate_search_results';
export const SET_DUPLICATE_CHECK_COMPLETED = 'set_duplicate_check_completed';

export const SET_POWER_ORDER_ESTATE_CONFIG_MODE = 'set_power_order_estate_config_mode';

export const REPLACE_TEMPORARY_ORDER_ID = 'replace_temporary_order_id';
export const SET_SELECTED_ORDER_IDS = 'set_selected_order_ids';

/* EMAIL ACTIONS */
export const FETCH_EMAIL_ITEM_START = 'fetch_email_item_start';
export const FETCH_EMAIL_ITEM_SUCCESS = 'fetch_email_item_success';
export const FETCH_EMAIL_ITEM_ERROR = 'fetch_email_item_error';
export const DISCARD_EMAIL_ITEM = 'discard_email_item';

export const FETCH_EMAIL_RESULTS_START = 'fetch_email_results_start';
export const FETCH_EMAIL_RESULTS_SUCCESS = 'fetch_email_results_success';
export const FETCH_EMAIL_RESULTS_ERROR = 'fetch_email_results_error';
export const DISCARD_EMAIL_RESULTS = 'discard_email_results';
export const RELOAD_EMAIL_RESULTS = 'reload_email_results';

export const SET_EMAIL_FILTERS = 'set_email_filters';
export const SET_EMAIL_RESPONSIBLE_USER_MODE = 'set_email_responsible_user_mode';

export const UPDATE_EMAIL_LOCKS = 'update_email_locks';
export const UPDATE_EMAIL_ITEM_LOCK = 'update_email_item_lock';

export const SET_SELECTED_EMAIL_IDS = 'set_selected_email_ids';


/* OTHER PRODUCT ACTIONS */

export const FETCH_OTHER_PRODUCT_RESULTS_START = 'fetch_other_product_results_start';
export const FETCH_OTHER_PRODUCT_RESULTS_SUCCESS = 'fetch_other_product_results_success';
export const FETCH_OTHER_PRODUCT_RESULTS_ERROR = 'fetch_other_product_results_error';

/* USER INTERFACE */
export const SAVE_VERTICAL_SCROLL = 'save_vertical_scroll';
