import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Checkbox from "@material-ui/core/Checkbox";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

// theme components
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app resources
import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";

import {isTemporaryOrderId, isOrderFromEmail} from "functions/powerOrderFunctions";

import { discardEmailItem } from "actions/emailActions";
import { reloadEmailResults } from "actions/emailActions";
import { CLOSE_NOTIFICATION, PUSH_NOTIFICATION } from "actions/types";
import { createRandomNotificationId } from "utils.js";


const useStyles = makeStyles(styles);


export default function PowerOrderSection({powerOrder, saveOrder, setSendEmailMode, duplicateCheckRequired}){

  let emailTo = null;

  if (powerOrder.powerOrderProduct !=null) {
    let powerCompany = powerOrder.powerOrderProduct.powerCompany;
    let powerProduct = powerOrder.powerOrderProduct.powerProduct;

    emailTo = powerCompany.emailTo;

    if (powerProduct.emailTo !== null) {
      emailTo = powerProduct.emailTo;
    }
  }

  const classes = useStyles();

  const dispatch = useDispatch();


  let sendByEmailMenuItem = null;

  if (emailTo!=null && emailTo!="") {
    if (duplicateCheckRequired)
      sendByEmailMenuItem = (<React.Fragment>
                              <span style={{color:"#cccccc"}}>Send epost til<br /><em>{emailTo}</em></span>
                              <br />
                              <span style={{color:"red"}}><Warning />Duplikatkontroll</span>
                            </React.Fragment>);
    else
      sendByEmailMenuItem = <span onClick={e => setSendEmailMode(true)}>Send epost til<br /><em>{emailTo}</em></span>;
  } else {
    sendByEmailMenuItem = <em  style={{color:"#cccccc"}}>Kan ikke send epost</em>;
  }


  const dropdownList = [
    <div data-status={PowerOrderStatusType.UNTREATED}>Ubehandlet</div>,
    { divider: true },
    <div data-status={PowerOrderStatusType.TRANSFER_COMPLETE}  style={{textAlign:"center",color:"red",fontStyle:"italic",fontWeight:"400"}}>Sendt OK</div>,
    { divider: true },
    <div data-status={PowerOrderStatusType.COLLECTIVE_MEASURING} style={{color:"#df0e00"}}>Fellesmålt</div>,
    <div data-status={PowerOrderStatusType.DUPLICATE} style={{color:"#df0e00"}}>Duplikat</div>,
    <div data-status={PowerOrderStatusType.REGRET} style={{color:"#df0e00"}}>Angret lev.</div>,
    <div data-status={PowerOrderStatusType.REJECTED} style={{color:"#df0e00"}}>Avvist lev.</div>,
    <div data-status={PowerOrderStatusType.DELETED} style={{color:"#df0e00"}}>Forkastet</div>,
    { divider: true },
    <div data-status={PowerOrderStatusType.AWAITING_ANSWER} style={{color:"darkturquoise"}}>Avventer svar</div>,
    <div data-status={PowerOrderStatusType.ON_HOLD} style={{color:"darkturquoise"}}>På vent</div>,
    <div data-status={PowerOrderStatusType.ON_HOLD_2} style={{color:"darkturquoise"}}>På vent 2</div>,
    { divider: true },

    sendByEmailMenuItem,

    { divider: true },
    <div data-status={PowerOrderStatusType.READY_FOR_TRANSFER} style={{color:"limegreen",fontSize:"16px"}}>Send</div>
  ];

  const emailWorkingDataItem = useSelector(state => {

    if (isTemporaryOrderId(powerOrder.id) && isOrderFromEmail(powerOrder)) {
       const emailId = powerOrder.powerOrderEmail.emailId;
       return state.emails.workingData[emailId] || null;
    }

    return null;
  });
            
  
  if (emailWorkingDataItem) {

    const autoCloseCondition1 = emailWorkingDataItem.connectedOrderIds.length==1;
    const autoCloseCondition2 = emailWorkingDataItem.unsortedAttachments.length==0;

    if (autoCloseCondition1 && autoCloseCondition2) {
       const sendAndCloseMenuItem = <div data-status={PowerOrderStatusType.READY_FOR_TRANSFER} data-auto-close-email={true} style={{color:"limegreen",fontSize:"16px"}}>Send og lukk papirskjema</div>;
       dropdownList.push(sendAndCloseMenuItem);
    }
  }


  const triggerEmailDone = (emailId) => {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + emailId + "/inspected";
    console.log(`PATCH ${url}`);

    axios
      .patch(url,{value: true})
      .then( response => {

        console.log("AJAX RESPONSE", response);

        dispatch(reloadEmailResults());

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: <span>Email <em><strong>{emailWorkingDataItem.entity.subject}</strong></em> is processed</span>
          }
        });

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 3000);        

        dispatch(discardEmailItem(emailId));
      })
      .catch(error => {

        console.log(error);
      })
  };  

  return (
    <React.Fragment>

      <div className="Section-Header">
        <div className="Section-Title">Ordre</div>

        <div className="Section-Order-Status">
          {isTemporaryOrderId(powerOrder.id)==true && <em>Ikke lagret</em>}
          {isTemporaryOrderId(powerOrder.id)==false && powerOrder.powerOrderStatusType.statusNameNo}
        </div>
      </div>

      <GridContainer>

        <GridItem md={6}>
          
        </GridItem>

        <GridItem md={6} style={{ textAlign: "right", marginTop:"20px"}}>
          
          <CustomDropdown
          onClick={
            element=>{ 
              const newStatus = element.props["data-status"];
              console.log("New status=",newStatus);

              if (newStatus===undefined) {
                return;
              }

              const autoCloseEmail = element.props["data-auto-close-email"] || false;

              let saveOrderHandler = null;
              if (autoCloseEmail) {
                saveOrderHandler = () => {
                  triggerEmailDone(powerOrder.powerOrderEmail.emailId);
                }
              }

              saveOrder(newStatus, saveOrderHandler);
            }
            

          }
            dropup
            buttonText="Lagre som"
            hoverColor="info"
            dropPlacement="top-end"
            buttonProps={{
              round: true,
              fullWidth: false,
              style: { marginBottom: "0" },
              color: "info",
              name: "open_save_menu"
            }}
            dropdownHeader="Lagre som"
            dropdownList={dropdownList}
          />

        </GridItem>
      </GridContainer>
    </React.Fragment>
    )
}