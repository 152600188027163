export default class CompanyChainTransaction {

  static PAID_STATUS='paid';

  constructor() {
    this.id = null;
    this.companyChainId = null;
    this.transactionType = null;
    this.transactionStatus = null;
    this.transactionCategory = null;
    this.orderId = null;
    this.amount = null;
    this.accountingDate = null;
    this.subject = null;
    this.description = null;
    this.createdBy = null;
    this.updatedBy = null;
    this.createdAt = null;
    this.updatedAt = null;

    this.attachments = null;
  }
}