import React from "react";

// @material-ui/icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';


// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";

export default function BrokerInfoSection(props) {

  const {estate} = props;

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Megler</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      <table className="Property-Info">
        <tbody>
          <tr>
            <td>Meglerkjede:</td>
            <td>{estate.companyChain.name}</td>
          </tr>
          <tr>
            <td>Meglerkontor:</td>
            <td>{estate.brokerOffice.name}</td>
          </tr>
          <tr>
            <td>Megler(e):</td>
            <td>
              {estate.brokers.map((broker,index)=><div key={index}>

                  {broker.responsibleBroker==true ? <strong>{broker.name} <em>({broker.role})</em></strong>: <span>{broker.name}</span>}
                
                  {broker.email && broker.email.length>0 && (
                      <React.Fragment>
                        <MailOutlineIcon style={{verticalAlign:"bottom",color:"#cccccc",fontSize:"16pt",marginLeft:"5pt",marginRight:"5pt"}} />

                        <span style={{fontWeight:"100"}}>{broker.email}</span>
                      </React.Fragment>
                    )}


                  {broker.mobile && broker.mobile.length>0 && (
                    <React.Fragment>
                      <PhoneIphoneIcon style={{verticalAlign:"bottom",color:"#cccccc",fontSize:"16pt",marginLeft:"5pt",marginRight:"5pt"}} />

                      <span style={{fontWeight:"100"}}>{broker.mobile}</span>
                    </React.Fragment>
                    )}




               </div>)}
              
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}
