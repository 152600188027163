import React, {useState} from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";

// theme components
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app components
import {formatDateTime} from "utils.js";

const useStyles = makeStyles(styles);

export default function AccountingDashboardPage() {
    
  const DMY = "DD.MM.YYYY";
  const MONTH_NAME_FORMAT = "MMMM/YYYY";

  const classes = useStyles();


let startDate1 = moment().startOf("month");
let endDate1 = startDate1.clone().endOf("month");
let startDate2 = startDate1.clone().subtract(1,"months");
let endDate2 = startDate2.clone().endOf("month");
let startDate3 = startDate2.clone().subtract(1,"months");
let endDate3 = startDate3.clone().endOf("month");


  const [currentInterval, setCurrentInterval] = useState({"from":startDate1.format(DMY),"to": endDate1.format(DMY)});
  

  const fromDateChangeHandler = value => {
    if (typeof value == "object") {
      const dmyString = moment(value.toDate()).format("DD.MM.YYYY");
      const newInterval = {from: dmyString,to:currentInterval.to};
      setCurrentInterval(newInterval);
    }
  }

  const toDateChangeHandler = value => {
    if (typeof value == "object") {
      const dmyString = moment(value.toDate()).format("DD.MM.YYYY");
      const newInterval = {from: currentInterval.from, to:dmyString};
      setCurrentInterval(newInterval);
    }
  }  



  return (
    <div className="Standard-Page-Content">

        <GridContainer>

          <GridItem>
            <Link to="/accounting">
              <Button>Økonomi</Button>
            </Link>

            <Link to="/accounting/dashboard">
              <Button color="primary">Dashboard</Button>
            </Link>
          </GridItem>

        </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardBody>

             <div style={{backgroundColor:"#eee",padding:"1em",marginBottom:"2em",fontSize:"large"}}>
              add help text here
             </div>


             <FormControl style={{marginLeft:"0px",marginTop:"10px",display:"inline-block",width:"200px"}}>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={moment(currentInterval.from,DMY)} onChange={fromDateChangeHandler} inputProps={{ placeholder: "Fra",readOnly: true }} />
              </FormControl>

             <FormControl style={{marginLeft:"20px",marginTop:"10px",display:"inline-block",width:"200px"}}>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={moment(currentInterval.to,DMY)} onChange={toDateChangeHandler} inputProps={{ placeholder: "Til",readOnly: true }} />
              </FormControl>

              <FormControl style={{marginLeft:"50px",display:"inline-block"}}>
                <Button color={ (currentInterval.from==startDate3.format(DMY) && currentInterval.to==endDate3.format(DMY)) ? "primary" : null} 
                  onClick={e=>{setCurrentInterval({"from":startDate3.format(DMY),"to":endDate3.format(DMY)})}}>{startDate3.format(MONTH_NAME_FORMAT)}</Button>
                <Button color={ (currentInterval.from==startDate2.format(DMY) && currentInterval.to==endDate2.format(DMY)) ? "primary" : null} 
                  onClick={e=>{setCurrentInterval({"from":startDate2.format(DMY),"to":endDate2.format(DMY)})}}>{startDate2.format(MONTH_NAME_FORMAT)}</Button>
                <Button color={ (currentInterval.from==startDate1.format(DMY) && currentInterval.to==endDate1.format(DMY)) ? "danger" : null} 
                  onClick={e=>{setCurrentInterval({"from":startDate1.format(DMY),"to":endDate1.format(DMY)})}}>{startDate1.format(MONTH_NAME_FORMAT)}</Button>
              </FormControl>

              <h3>Fra <strong>{currentInterval.from}</strong> til <strong>{currentInterval.to}</strong></h3>



            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
