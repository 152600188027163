import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import SmsMessageLog from "classes/SmsMessageLog";


const useStyles = makeStyles(styles);


const MyCustomCheckbox = (props) =>{

  const classes = useStyles();

  const {label, name, mobile, contactType, onChange, checked} = props;

  return (
    <div className={classes.checkboxAndRadio}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            tabIndex={-1}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
            inputProps={{"data-name":name,"data-mobile":mobile, "data-contact-type": contactType}}
          />
        }
        classes={{
          label: classes.label,
          root: classes.labelRoot,
        }}
        label={label}
      />
    </div>

    );
}

const SmsRecipientWindow = (props) => {

  const {popperId, popperOpen, anchorEl, setAnchorEl, estate, send, defaultSmsRecipientList, hideSellers, hideBuyers, protocolName} = props;

  const [smsRecipientList, setSmsRecipientList] = useState(defaultSmsRecipientList);

  const onSmsRecipientCheckboxChange = e=> {
    
    const isChecked = e.target.checked;
    const name = e.target.dataset.name;
    const mobile = e.target.dataset.mobile;
    const contact_type = e.target.dataset.contactType;

    console.log("Checkbox click!", isChecked, name, mobile);

    // first remove the phone from the list
    let newList = smsRecipientList.filter(item=>{
      if (mobile == item.mobile && name==item.name) {
        return false;
      }
      return true;
    });

    console.log("Updated list after filter",newList);

    // ...and add it if the checkbox is ON
    if (isChecked) {
      newList.push({name,mobile,contact_type} );  
    }
    
    setSmsRecipientList(newList);
  }
  
  const isAddedToSmsRecipientList = (name, mobile) => {
    let result = false;

    smsRecipientList.map((item) => {
      if (item.name == name && item.mobile == mobile) {
        result = true;
      }
    });

    return result;
  };

  return (
            <Popper id={popperId} open={popperOpen} anchorEl={anchorEl}>
              <ClickAwayListener onClickAway={e=>setAnchorEl(null)}>
                <div>
                  <Card className="Sms-Recipients-Popup" style={{width:"400px",minHeight:"200px",textAlign:"left"}}>

                    <h5>Send {protocolName} SMS til:</h5>

                    {estate.brokers.map((broker,index)=>(
                      
                       <MyCustomCheckbox checked={isAddedToSmsRecipientList(broker.name, broker.mobile)} onChange={onSmsRecipientCheckboxChange} key={`m-${index}`} name={broker.name} mobile={broker.mobile} contactType={SmsMessageLog.BROKER_CONTACT_TYPE}
                       label={<React.Fragment>Megler: <strong>{broker.name}</strong> ({broker.mobile})</React.Fragment>} />
                    ))}


                    {hideBuyers!=true && estate.buyers.map((buyer,index)=>(
                       <MyCustomCheckbox checked={isAddedToSmsRecipientList(`${buyer.firstName} ${buyer.lastName}`, buyer.mobile)} onChange={onSmsRecipientCheckboxChange}  key={`b-${index}`} name={`${buyer.firstName} ${buyer.lastName}`} mobile={buyer.mobile} contactType={SmsMessageLog.BUYER_CONTACT_TYPE}
                       label={<React.Fragment>Kjøper {index + 1}: <strong>{buyer.firstName} {buyer.lastName}</strong> ({buyer.mobile})</React.Fragment>} />
                    ))}

                    {hideSellers!=true && estate.sellers.map((seller,index)=>(
                       <MyCustomCheckbox checked={isAddedToSmsRecipientList(`${seller.firstName} ${seller.lastName}`, seller.mobile)} onChange={onSmsRecipientCheckboxChange}  key={`b-${index}`} name={`${seller.firstName} ${seller.lastName}`} mobile={seller.mobile} contactType={SmsMessageLog.SELLER_CONTACT_TYPE}
                       label={<React.Fragment>Selger {index + 1}: <strong>{seller.firstName} {seller.lastName}</strong> ({seller.mobile})</React.Fragment>} />
                    ))}

                    <GridContainer>
                      <GridItem md={6}>
                        <Button size="sm" fullWidth={true} onClick={e=>setAnchorEl(null)}>Lukk</Button>
                      </GridItem>

                      <GridItem md={6}>
                        { smsRecipientList.length==0 && <Button color="danger" size="sm" disabled fullWidth={true}>Send</Button> }
                        { smsRecipientList.length>0 && <Button color="danger" size="sm" fullWidth={true} onClick={e=>send(smsRecipientList)}>Send ({smsRecipientList.length})</Button> }
                      </GridItem>
                    </GridContainer>

                  </Card>
                </div>
              </ClickAwayListener>
            </Popper>  
    );
}

export default SmsRecipientWindow;