import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Broker from "classes/Broker";

import { isTemporaryId } from "utils.js";


import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function BrokerDetailsForm({ broker, close, externalChain, editSettings, editBroker }) {
  const classes = useStyles();

  const brokerSettings = broker.settings;
  const readOnlyBroker = broker.source!==Broker.INTERNAL_SOURCE;

  return (
    <Card className="Broker-Details">
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <InfoIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <big>{broker.name}</big>
        </h4>
      </CardHeader>

      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>Tittel</td>
              <td>{broker.title}</td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>{broker.mobile}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{broker.phone}</td>
            </tr>
            <tr>
              <td>Epost</td>
              <td>{broker.email}</td>
            </tr>
            <tr>
              <td>Aktiv</td>
              <td>{broker.active ? "Ja" : "Nei"}</td>
            </tr>
            <tr>
              <td>Portal role</td>
              <td>{brokerSettings?.portalRole}</td>
            </tr>
            <tr>
              <td>Portal permissions</td>
              <td>{brokerSettings?.portalPermissions}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Lukk</Button>

        <div>
          <Button onClick={editSettings} color="primary" disabled={isTemporaryId(broker.id)}>Instillinger</Button>
          <Button onClick={editBroker} disabled={readOnlyBroker} color="primary">Redigere</Button>
        </div>
      </CardFooter>
    </Card>
  );
}
