import React from "react";

import { Link } from "react-router-dom";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";

export default function OrderInfoSection({powerOrderEstateList}) {
  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Ordre</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      <table className="Property-Info">
        <tbody>

          {powerOrderEstateList.map((element,index)=>(
            <tr key={index}>
              <td>Ordre ID</td>
              <td>
                <Link to={"/order/"+element.orderId}>#{element.orderId}</Link>
              </td>
            </tr>            
          ))}        

        </tbody>
      </table>
    </React.Fragment>
  );
}
