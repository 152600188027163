import React from "react";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";

// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Success from "components/Typography/Success.js";

import {formatDateTime} from "utils.js";

const DuplicateSearchResults = ({ history, duplicateSearchResults, duplicateCheckCompleted, completeCheckHandler }) => {

  const rows = duplicateSearchResults.map((item,index) => (
    <React.Fragment key={item.id}>
      <GridContainer>
        <GridItem md={1}>
          <span style={{fontSize:"12pt",color:"white",paddingLeft:"5px",paddingRight:"5px",background:"#f55a4e"}}>{index+1}</span>
        </GridItem>
        <GridItem md={8}>
          <div className="Duplicate-Check-Result-Item">

            <div>
              Anleggsaddresse: {item.task && <strong>{item.task.streetAddress} {item.task.postCode} {item.task.postLocation}</strong>}
            </div>

            <div>
              Kjøper: {item.buyer && <strong>{item.buyer.firstName} {item.buyer.lastName} {item.buyer.companyName}</strong>}
              &nbsp;&mdash;&nbsp;
              Selger: {item.seller && <strong>{item.seller.firstName} {item.seller.lastName} {item.seller.companyName}</strong>}
            </div>

            <div>
              Målernummer: {item.powerMeters.map( (powerMeterItem,index)=>( <span className="Power-Meter" key={index}>{powerMeterItem.meterNumber}</span>  ))}
              &nbsp;&mdash;&nbsp;
              Opprettet dato: <strong>{formatDateTime(item.createdAt)}</strong>
            </div>

          </div>
        </GridItem>
        <GridItem md={3} style={{textAlign:"right"}}>
          <Button
            color="danger"
            size="sm"
            onClick={(e) => {
              history.push("/order/" + item.id);
            }}>
            Åpne
          </Button>     
        </GridItem>
      </GridContainer>
      <hr />
    </React.Fragment>
  ));

  return (
    <React.Fragment>
      <SnackbarContent message={<h5>Duplikatkontroll</h5>} icon={AddAlert} color="danger" />

      <div  style={{border:"2px solid #f55a4e",padding:"5px",marginBottom:"20px"}}>

        {rows}

        {duplicateCheckCompleted==false &&
        <div style={{textAlign:"right"}}>
          <Button name="confirm_duplicate_check" color="danger" onClick={completeCheckHandler}>Ferdig</Button>
        </div>}

        {duplicateCheckCompleted==true &&
        <div style={{textAlign:"right"}}>
          <Success>
            Duplicate check completed <Check style={{verticalAlign:"middle"}} />
          </Success>
        </div>        
        }
      </div>

    </React.Fragment>
  );
};

export default DuplicateSearchResults;
