import React, {useRef, useState} from "react";
import { useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "debounce";

// core components
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";

// app specific resources
import { createRandomNotificationId } from "utils.js";
import { UPDATE_POWER_ORDER_PRODUCT } from "actions/types";
import { CLOSE_NOTIFICATION, PUSH_NOTIFICATION } from "actions/types";


import PowerOrderProduct from "classes/PowerOrder/PowerOrderProduct";

function getSuggestionValue(suggestion) {
  return suggestion.powerCompany.name;
}

function renderSuggestion(suggestion) {
  return <span><strong>{suggestion.powerProduct.name}</strong> ({suggestion.powerCompany.name})</span>;
}

const ProductSection = (props) => {


  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useDispatch();

  const {orderId} = props;
  let {powerOrderProduct} = props;

  let powerProduct = null;
  let powerCompany = null;
  let emailToPreview = null;

  if (powerOrderProduct !== null){
    powerProduct = powerOrderProduct.powerProduct;
    powerCompany = powerOrderProduct.powerCompany;

    if (powerCompany.apiService=="email") {
      emailToPreview = powerCompany.emailTo;
    }

    if (powerProduct.apiService !== null) {
      if (powerProduct.apiService=="email") {
        emailToPreview = powerProduct.emailTo;
      } else {
        emailToPreview = null;
      } 
    }
  }

  const updatePowerOrderProduct = (orderId, powerOrderProduct, showNotification=false) => {
    dispatch({
      type: UPDATE_POWER_ORDER_PRODUCT,
      payload: {
        orderId,
        powerOrderProduct
      }
    });

    if (showNotification) {
      const notificationId = createRandomNotificationId();

      dispatch({
        type: PUSH_NOTIFICATION,
        payload: {
          id: notificationId,
          message: "Product information deleted"
        }
      });    

      setTimeout(() => {
        dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
      }, 750);       
    }
   
  };  

  const placeholder = <div>&mdash;</div>




  const loadSuggestions = value => {
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_companies/autocomplete?q=" + value;

    console.log("ajax query:" + url);

    axios
      .get(url)
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsLoading(false);

        let results = [];

        let powerCompanies = response.data;
        powerCompanies.forEach(powerCompany=>{
          powerCompany.products.forEach(powerProduct=>{

            results.push({
              powerCompany,
              powerProduct
            });
          });
        });

        setSuggestions(results);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debouncedLoadSuggestions = useRef(debounce((value)=>{loadSuggestions(value)}, 100)).current;

  const onChange = (event, { newValue }) => {
    setSearchText(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex }) => {
    console.log("Suggestion selected: " + suggestionValue, suggestions[suggestionIndex]);


    const selectedObject = suggestions[suggestionIndex];
    powerOrderProduct = new PowerOrderProduct(orderId);   

    powerOrderProduct.productId = selectedObject.powerProduct.id;
    powerOrderProduct.powerCompany = selectedObject.powerCompany;
    powerOrderProduct.powerProduct = selectedObject.powerProduct;

    updatePowerOrderProduct(orderId, powerOrderProduct);

    setSearchText("");


  };

  const inputProps = {
    placeholder: "",
    value: searchText,
    onChange,
    tabIndex: 1000
  };


  // TODO - show loading progress
  //const status = isLoading ? "Loading..." : "Type to load suggestions";


  return (
    <React.Fragment>
      <div className="Section-Header No-Margin">
        <div className="Section-Title Invalid">Leverandør / Produkt</div>
      </div>

      <div className="Reference-Height-Section-Content">

        <div className="Autosuggest-Block">
          <Autosuggest
            onSuggestionSelected={onSuggestionSelected}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            highlightFirstSuggestion={true}
            inputProps={inputProps}
            renderInputComponent={(autoInputProps)=>{
              return (<TextField
                      InputProps={{
                        inputProps: {...autoInputProps},
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />)
            }}
          />
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                <div className="Field-Preview-Label">Strømleverandør:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerCompany ? powerCompany.name : placeholder}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label">Produkt:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerProduct ? powerProduct.name: placeholder}</div>                
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label"></div>
              </td>            
              <td>
                <div className="Field-Preview-Text" style={{fontWeight:"100",fontStyle:"italic"}}>
                  {emailToPreview && "Epost til "+ emailToPreview}
                </div>
              </td>
            </tr>            
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default ProductSection;
