import React, {useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app specific components
import { createRandomTabId, isTemporaryId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import BrokerOfficeEditForm from "./components/BrokerOfficeEditForm";
import BrokerOfficeDetails from "./components/BrokerOfficeDetails";
import BrokerOfficePowerProductTab from "./components/BrokerOfficePowerProductTab";
import BrokerOfficeDealsProductTab from "./components/BrokerOfficeDealsProductTab";
import BrokerOfficeSmsScheduleTab from "./components/BrokerOfficeSmsScheduleTab";
import BrokerOfficeSendConfigTab from "./components/BrokerOfficeSendConfigTab";
import BrokerOfficeSettingsTab from "./components/BrokerOfficeSettingsTab";
import BrokerOfficeAccountingSettingsTab from "./components/BrokerOfficeAccountingSettingsTab";
import BrokerOfficeBrokersTab from "./components/BrokerOfficeBrokersTab";
import BrokerOfficeAccountingTab from "./components/BrokerOfficeAccountingTab";
import BrokerOfficeAccountingInfoTab from "./components/BrokerOfficeAccountingInfoTab";

import { FETCH_BROKER_OFFICE_ITEM_START } from "actions/types";
import { FETCH_BROKER_OFFICE_ITEM_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_ITEM_ERROR } from "actions/types";

import { SWITCH_BROKER_OFFICE_ITEM_TAB } from "actions/types";




const useStyles = makeStyles(styles);

export default function BrokerOfficeEditPage(props) {

  const id = props.computedMatch.params.id;
  const temporaryId = isTemporaryId(id);

  const {history} = props;

  //useScrollIntoView("Standard-Page-Content");


  const [editMode, setEditMode] = useState(temporaryId ? true : false);


  const classes = useStyles();

  const dispatch = useDispatch();


  const workingDataItem = useSelector(state => {
    return state.brokerOffices.workingData[id] || null;
  });

  console.log("Broker office loading data:", workingDataItem);


  if (workingDataItem && workingDataItem.entity!=null) {

    let title;
    if (temporaryId) {
      title = "Nytt meglerkontor";
    } else {
      title = workingDataItem.entity.name;
    }

    dispatch({
      type: PUSH_TAB,
      payload: {
        id: createRandomTabId(),
        title,
        to: "/broker_office/"+id
      }
    });    
  }

  const switchTab = (brokerOfficeId,index) => {
    dispatch({
      type: SWITCH_BROKER_OFFICE_ITEM_TAB,
      payload: { brokerOfficeId, index }
    });
  }

  const fetchItem = id => {
    dispatch({
      type: FETCH_BROKER_OFFICE_ITEM_START,
      payload: { id }
    });


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id;

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_ITEM_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch(error => {

        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_ITEM_ERROR,
          payload: { id, error }
        });
      })
  };


 

  if (workingDataItem == null) {
    fetchItem(id);
  }

  const brokerOffice = workingDataItem!=null ? workingDataItem.entity : null;
  const currentTab = workingDataItem!=null ? workingDataItem.currentTab : null;


  const appSettings = useSelector(state => state.app.settings);
  const accountingTabEnabled = appSettings.accountingMenuEnabled;


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {workingDataItem.error.error.message}</h1>;

  const renderSuccess = () => (
    <div className="Standard-Page-Content">
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          {editMode===true && <BrokerOfficeEditForm brokerOffice={brokerOffice} classes={classes} setEditMode={setEditMode} history={history} />}
          {editMode===false && <BrokerOfficeDetails brokerOffice={brokerOffice} classes={classes} setEditMode={setEditMode} />}
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <div style={{marginTop:"23px"}}>
            <Button disabled={temporaryId} size="sm" color={currentTab==1 ? "rose" : "white"} onClick={e=>switchTab(id,1)}>Meglere</Button>
            <Button disabled={temporaryId} size="sm" color={(currentTab==21 || currentTab==22) ? "rose" : "white"} onClick={e=>switchTab(id,21)}>Produkter</Button>
            <Button disabled={temporaryId} size="sm" color={currentTab==3 ? "rose" : "white"} onClick={e=>switchTab(id,3)}>SMS / Epost</Button>
            <Button disabled={temporaryId} size="sm" color={currentTab==4 ? "rose" : "white"} onClick={e=>switchTab(id,4)}>Send config</Button>
            <Button disabled={temporaryId} size="sm" color={ (currentTab>=51 && currentTab<=54) ? "rose" : "white"} onClick={e=>switchTab(id,accountingTabEnabled ? 51:52)}>Økonomi</Button>
            <Button disabled={temporaryId} size="sm" color={currentTab==6 ? "rose" : "white"} onClick={e=>switchTab(id,6)}>Andre instillinger</Button>
          </div>

          {(currentTab==21 || currentTab==22)  && 
            (
              <div>
                 <Button size="sm" color={currentTab==21 ? "rose" : null} onClick={e=>switchTab(id,21)}>Strøm</Button>
                 <Button size="sm" color={currentTab==22 ? "rose" : null} onClick={e=>switchTab(id,22)}>Deals</Button>
              </div>
            )
          }    

          {currentTab>=51 && currentTab<=53  && 
            (
              <div>
                 {accountingTabEnabled && <Button size="sm" color={currentTab==51 ? "rose" : null} onClick={e=>switchTab(id,51)}>Oversikt</Button>}
                 <Button size="sm" color={currentTab==52 ? "rose" : null} onClick={e=>switchTab(id,52)}>Produktverdi</Button>
                 <Button size="sm" color={currentTab==53 ? "rose" : null} onClick={e=>switchTab(id,53)}>Instillinger</Button>
              </div>
            )
          }          

          {currentTab==1 && temporaryId==false && <BrokerOfficeBrokersTab brokerOffice={workingDataItem.entity} />}
          {currentTab==21 && <BrokerOfficePowerProductTab brokerOffice={workingDataItem.entity} />}
          {currentTab==22 && <BrokerOfficeDealsProductTab brokerOffice={workingDataItem.entity} />}
          {currentTab==3 && <BrokerOfficeSmsScheduleTab brokerOffice={workingDataItem.entity} classes={classes} />}
          {currentTab==4 && <BrokerOfficeSendConfigTab brokerOffice={workingDataItem.entity} classes={classes} />}
          
          {currentTab==51 && <BrokerOfficeAccountingInfoTab brokerOffice={workingDataItem.entity} /> }
          {currentTab==52 && <BrokerOfficeAccountingTab brokerOffice={workingDataItem.entity} /> }
          {currentTab==53 && <BrokerOfficeAccountingSettingsTab brokerOffice={workingDataItem.entity} classes={classes} /> }
         
          {currentTab==6 && <BrokerOfficeSettingsTab brokerOffice={workingDataItem.entity} classes={classes} />}
        </GridItem>
      </GridContainer>      
    </div>
    );

  if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {
    return renderSuccess();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );
}
