import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


// core components
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific resources
import { convertNullToString as _ } from "utils.js";
import { UPDATE_POWER_ORDER_TASK } from "actions/types";
import { UPDATE_POWER_ORDER_BUYER } from "actions/types";
import PowerOrderTask from "classes/PowerOrder/PowerOrderTask";

import PowerOrderBuyer from "classes/PowerOrder/PowerOrderBuyer";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";


const TaskInfoSection = props => {
  const dispatch = useDispatch();
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const { orderId } = props;
  let { task, buyer } = props;

  if (task == null) {
    task = new PowerOrderTask(orderId);
  }

  // the buyer is needed here to mirror the address in billing fields

  if (buyer == null) {
    buyer = new PowerOrderBuyer(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);
  }  

  const updatePowerOrderTask = updatedTask => {
    //console.log("Redux update task", updatedTask);

    dispatch({
      type: UPDATE_POWER_ORDER_TASK,
      payload: updatedTask
    });
  };

  const updatePowerOrderBuyer = updatedBuyer => {
    dispatch({
      type: UPDATE_POWER_ORDER_BUYER,
      payload: updatedBuyer
    });
  };  

  const loadLocation = (newTask, newBuyer) => {
    const {postCode} = newTask;

    if (postCode.length != 4) {
      return;
    }

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/location/search?post_code=" + postCode;

    console.log("ajax query:" + url);
    
    setIsLocationLoading(true);

    axios
      .get(url)
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsLocationLoading(false);

        let results = response.data;

        if (results.length==1) {
          let result = results[0];
          updatePowerOrderTask({ ...newTask, postLocation: result.location });
          updatePowerOrderBuyer({ ...newBuyer, billingPostLocation: result.location });
        }

        
      })
      .catch(error => {
        console.log(error);
      });    
  }

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "task_street_address":
        updatePowerOrderTask({ ...task, streetAddress: value });
        updatePowerOrderBuyer({ ...buyer, billingStreetAddress: value });
        break;
      case "task_post_code":
        let newTask = { ...task, postCode: value, postLocation: "" };
        updatePowerOrderTask(newTask);

        let newBuyer = {...buyer, billingPostCode: value, billingPostLocation: ""};
        updatePowerOrderBuyer(newBuyer);

        loadLocation(newTask, newBuyer);
        break;
      case "task_post_location":
        updatePowerOrderTask({ ...task, postLocation: value });
        updatePowerOrderBuyer({ ...buyer, billingPostLocation: value });
        break;

      case "task_floor":
        updatePowerOrderTask({ ...task, floor: value });
        break;
      case "task_apartment_number":
        updatePowerOrderTask({ ...task, apartmentNumber: value });
        break;        

      default:
        break;
    }
  };

  const commonInputProps = { inputProps: { tabIndex: "3000", onChange: onTextFieldChange } };
  const locationPlaceholder = isLocationLoading ? "Loading..." : "";

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title Invalid">Info om anlegget</div>
        <div className="Section-Control"></div>
      </div>

      <div className="Reference-Height-Section-Content">
        <div className="Input-Line">
          <div className="Field-Label">Anleggsadresse</div>
          <div className="Large-Field-Control">
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{ ...commonInputProps, name: "task_street_address", value: _(task.streetAddress) }}
            />
          </div>
        </div>

        <div className="Input-Line">
          <div className="Field-Label">Postnummer</div>
          <div className="Small-Field-Control">
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{ ...commonInputProps, name: "task_post_code", value: _(task.postCode) }}
            />
          </div>
          <div className="Field-Label">Poststed</div>
          <div className="Medium-Field-Control">
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{ ...commonInputProps, placeholder: locationPlaceholder, name: "task_post_location", value: _(task.postLocation) }}
            />
          </div>
        </div>


        <div className="Input-Line">
          <div className="Field-Label">Etasje</div>
          <div className="Small-Field-Control">
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{ ...commonInputProps, name: "task_floor", value: _(task.floor) }}
            />
          </div>
          <div className="Field-Label">Leilighetsnummer</div>
          <div className="Medium-Field-Control">
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{ ...commonInputProps, name: "task_apartment_number", value: _(task.apartmentNumber) }}
            />
          </div>
        </div>        
      </div>
    </React.Fragment>
  );
};

export default TaskInfoSection;
