import React, {useState} from "react";

import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

// material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { SET_POWER_ORDER_RESPONSIBLE_USER_MODE } from "actions/types";

// theme components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import {setSelectedOrderIds} from "actions/powerOrderActions";

import { UPDATE_POWER_ORDER_LOCKS } from "actions/types";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

export default function ResponsibleUserForm() {
	const dispatch = useDispatch();

	const classes = useStyles();

  // -1 = no selection, 0 = no user(reset) >0 = any user id
  const [selectedUserId, setSelectedUserId] = useState(-1);

  const selectedOrderIds = useSelector(state => state.powerOrders.listingData.selectedOrderIds);

  const { customerSupportUsers } = useSelector(state => state.app.settings);

  let selectedDisplayName = "";

  customerSupportUsers.forEach(item=>{
    if (item.id==selectedUserId) selectedDisplayName=item.displayName;
  });

  const handleOk = (e) => {
    console.log("selected orders:",selectedOrderIds);
    console.log("selected userid:",selectedUserId);

    const userId = selectedUserId===0 ? null : selectedUserId;

    let data = [];
    selectedOrderIds.forEach((orderId) => data.push({ userId, orderId }));


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders/locks";

    axios
      .put(url, data)
      .then((response) => {
        console.log("AJAX RESPONSE", response);
        dispatch({ type: UPDATE_POWER_ORDER_LOCKS, payload: { locks: response.data } });
        dispatch(setSelectedOrderIds([]));
      })
      .catch((error) => {
        console.log("[An error has occured", error);
      }); 
  }

	const handleClose = (e) => {
		dispatch({
			type: SET_POWER_ORDER_RESPONSIBLE_USER_MODE,
			payload: false,
		});

    dispatch(setSelectedOrderIds([]));

		e.preventDefault();
	};
	

	const handleOnChange = (e) => {
    const value = e.target.value;
    setSelectedUserId(value);
  };

	return (
		<GridContainer className="Responsible-User-Form">
			<GridItem md={2}>
				<FormControl fullWidth className={classes.selectFormControl}>
					<InputLabel htmlFor="simple-select" className={classes.selectLabel}>
						Velg ansvarlig
					</InputLabel>
					<Select
						MenuProps={{
							className: classes.selectMenu,
						}}
						classes={{
							select: classes.select,
						}}
						value={selectedUserId}
						onChange={handleOnChange}
						inputProps={{
							name: "responsibleUserSelect",
							id: "responsible-user-select",
						}}>

            <MenuItem
              key={-1}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}

              disabled>
              Velg ansvarlig
            </MenuItem>

						<MenuItem
							key={0}
							classes={{
								root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
							}}

              value={0}>
							<em>Ingen</em>
						</MenuItem>

						{customerSupportUsers.map((item, index) => (
							<MenuItem
								key={index}
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected,
								}}
								value={item.id}>
								<span>{item.displayName}</span>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</GridItem>

			<GridItem md={10}>
				<Button color="primary" disabled={selectedOrderIds.length===0 || selectedUserId===-1} onClick={handleOk}>OK</Button>{" "}
				<a onClick={handleClose}>
					Lukk
				</a>
			</GridItem>
			<GridItem md={12}>
				<div style={{background:"#effcfd",padding:"2pt",display:"inline-block"}}>
          {(selectedOrderIds.length==0 || selectedUserId==-1) && "Select orders and then select the user and press OK. Select first order and than press SHIFT and click another to select multiple orders."}
          {selectedOrderIds.length>0 && selectedUserId>0 && 
            <React.Fragment>
            Lagre <strong>{selectedDisplayName}</strong> som ansvarlig bruker for <strong>{selectedOrderIds.length}</strong> skjemaer
            </React.Fragment>}

          {selectedOrderIds.length>0 && selectedUserId===0 && 
            <React.Fragment>
            Slett ansvarlig bruker for <strong>{selectedOrderIds.length}</strong> skjemaer
            </React.Fragment>}
        </div>
			</GridItem>
		</GridContainer>
	);
}
