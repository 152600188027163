import React from 'react';
import {useDispatch} from "react-redux";
import axios from "axios";

import {APP_LOGOUT_SUCCESS} from "actions/types";


export default function LogoutPage(props){


  const {history} = props;

  const dispatch = useDispatch();


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/auth/logout";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        
        dispatch({
          type: APP_LOGOUT_SUCCESS,
          payload: { }
        });
        

        console.log("Logout successful, redirect to auth");

        history.push("/auth");


      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        /*
        dispatch({
          type: APP_LOGOUT_ERROR,
          payload: { logoutError }
        });
        */
      })  

  return <div>Logout in progress...</div>;
}