import React, {useRef, useState} from "react";
import { useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "debounce";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// icons
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Check from "@material-ui/icons/Check";

// core components
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

// theme components
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app specific resources
import { createRandomNotificationId } from "utils.js";
import { UPDATE_POWER_ORDER_BROKER } from "actions/types";
import { CLOSE_NOTIFICATION, PUSH_NOTIFICATION } from "actions/types";

import PowerOrderBroker from "classes/PowerOrder/PowerOrderBroker";

const useStyles = makeStyles(styles);

// component specific
const BROKER_OFFICE_RESULT_TYPE = 'broker_office';
const BROKER_RESULT_TYPE = 'broker';


function getSuggestionValue(suggestion) {
  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    return suggestion.brokerOffice.name;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    return suggestion.broker.name + " (" + suggestion.brokerOffice.name + ")";  
  }
}

function renderSuggestion(suggestion) {

  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    let className = suggestion.brokerOffice.active==false ? "inactive" : "";
    return <div className={className}><strong>{suggestion.brokerOffice.name}</strong></div>;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    let brokerClassName = suggestion.broker.active==false ? "inactive" : "";
    let officeClassName = suggestion.brokerOffice.active==false ? "inactive" : "";
   return (
    <div>
      <strong className={brokerClassName}>{suggestion.broker.name}</strong>
      <span className={officeClassName}> ({suggestion.brokerOffice.name})</span>
    </div>);  
  }
}

const BrokerSection = (props) => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);  

  const {orderId} = props;
  let {powerOrderBroker} = props;

  let companyChain = null;
  let brokerOffice = null;
  let broker = null;

  if (powerOrderBroker !== null){
    companyChain = powerOrderBroker.companyChain;
    brokerOffice = powerOrderBroker.brokerOffice;
    broker = powerOrderBroker.broker;
  }

  const updatePowerOrderBroker = (orderId, powerOrderBroker, showNotification=false) => {
    dispatch({
      type: UPDATE_POWER_ORDER_BROKER,
      payload: {
        orderId,
        powerOrderBroker
      }
    });

    if (showNotification){
      const notificationId = createRandomNotificationId();

      dispatch({
        type: PUSH_NOTIFICATION,
        payload: {
          id: notificationId,
          message: "Broker information deleted"
        }
      });    

      setTimeout(() => {
        dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
      }, 750);         
    }
  
  };



  const placeholder = <div>&mdash;</div>



  const loadSuggestions = value => {
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_offices/autocomplete";

    const urlParams = {q: value};

    console.log("ajax query:" + url, urlParams);

    axios
      .get(url, {params: urlParams})
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsLoading(false);

        const supportedResultTypes = [BROKER_OFFICE_RESULT_TYPE, BROKER_RESULT_TYPE];
        setSuggestions(response.data.filter(suggestion=>supportedResultTypes.includes(suggestion.resultType)));
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debouncedLoadSuggestions = useRef(debounce((value)=>{loadSuggestions(value)}, 250)).current;

  const onChange = (event, { newValue }) => {
    setSearchText(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex}) => {
    
    console.log("Suggestion selected: " + suggestionValue, suggestions[suggestionIndex]);

    setSearchText("");
    
    const selectedObject = suggestions[suggestionIndex];
    const brokerOfficeId = selectedObject.brokerOffice.id;
    const brokerId = selectedObject.broker!==null ? selectedObject.broker.id : null;

    powerOrderBroker = new PowerOrderBroker(orderId, brokerOfficeId, brokerId, false);   

    
    // populate aggregate fields
    powerOrderBroker.companyChain = selectedObject.companyChain;
    powerOrderBroker.brokerOffice = selectedObject.brokerOffice;

    if (selectedObject.resultType==BROKER_RESULT_TYPE){
      //powerOrderBroker.brokerId = selectedObject.broker.id;
      powerOrderBroker.broker = selectedObject.broker;    
    }

    console.log("New PowerOrderBroker entity",powerOrderBroker);
    updatePowerOrderBroker(orderId, powerOrderBroker);
  };

  const inputProps = {
    placeholder: "",
    //style: {},
    value: searchText,
    onChange,
    tabIndex: 4000
  };
  

  // TODO - show loading progress
  //const status = isLoading ? "Loading..." : "Type to load suggestions";


  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title Invalid">Megler</div>
      </div>

      <div className="Reference-Height-Section-Content">

        <div className="Autosuggest-Block">
          <Autosuggest
            onSuggestionSelected={onSuggestionSelected}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            renderInputComponent={(autoInputProps)=>{
              return (<TextField
                      InputProps={{
                        inputProps: {...autoInputProps},
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />)
            }}            
          />
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                <div className="Field-Preview-Label">Meglerkjede:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerOrderBroker ? companyChain.name: placeholder}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label">Meglerkontor:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerOrderBroker ? brokerOffice.name: placeholder}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label">Megler:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerOrderBroker && powerOrderBroker.broker ? broker.name: placeholder}
                  {powerOrderBroker && powerOrderBroker.broker ? <React.Fragment> <MailOutlineIcon style={{verticalAlign:"bottom",color:"#cccccc",fontSize:"16pt",marginLeft:"5pt",marginRight:"5pt"}} /> <span style={{fontWeight:"100"}}>{broker.email}</span></React.Fragment> : null}


                  { (powerOrderBroker && (powerOrderBroker.broker==null)) && 


                    <div className={classes.checkboxAndRadio}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={powerOrderBroker.neiTakk}
                            onChange={e=>{

                              powerOrderBroker.neiTakk=e.target.checked;
                              updatePowerOrderBroker(orderId, powerOrderBroker);

                            }}
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="Nei takk"
                      />
                    </div>}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default BrokerSection;
