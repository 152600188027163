import React from "react";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific components
import { formatDate } from "utils.js";

import CompanyChain from "classes/CompanyChain";

// app specific styles
import "app_assets/scss/Brokers.scss";

export default function BrokerChainDetails({ brokerChain, classes, setEditMode }) {
  return (
    <Card className="Company-Chain-Details">
      <CardHeader icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <big>{brokerChain.name}</big>
        </h4>
      </CardHeader>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>Navn</td>
              <td>{brokerChain.name}</td>
            </tr>          
            <tr>
              <td>Orgnummer</td>
              <td>{brokerChain.orgNum ? brokerChain.orgNum : <span>&mdash;</span>}</td>
            </tr>
            <tr>
              <td>Telefon</td>
              <td>{brokerChain.telefon ? brokerChain.telefon : <span>&mdash;</span>}</td>
            </tr>
            <tr>
              <td>Konfigurasjonsnavn</td>
              <td>{brokerChain.configName}</td>
            </tr>
            <tr>
              <td>
                Fagsystem
              </td>
              <td>{brokerChain.source}</td>
            </tr>
            <tr>
              <td>Opprettet dato</td>
              <td>{formatDate(brokerChain.created)}</td>
            </tr>
            <tr>
              <td>Endret dato</td>
              <td>{formatDate(brokerChain.modified)}</td>
            </tr>
            <tr>
              <td>API dato</td>
              <td>{formatDate(brokerChain.apiDate)}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "center" }}>
        {brokerChain.source==CompanyChain.INTERNAL_SOURCE &&
        <Button color="primary" onClick={(e) => setEditMode(true)}>
          Redigere
        </Button>}
      </CardFooter>
    </Card>
  );
}
