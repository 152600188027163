import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// theme components
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app specific hooks
import { useScrollIntoView } from "hooks.js";

// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import { FETCH_BROKER_CHAIN_ITEM_START } from "actions/types";
import { FETCH_BROKER_CHAIN_ITEM_SUCCESS } from "actions/types";
import { FETCH_BROKER_CHAIN_ITEM_ERROR } from "actions/types";
import { SWITCH_BROKER_CHAIN_ITEM_TAB } from "actions/types";

import BrokerChainForm from "./components/BrokerChainEditForm.js";
import BrokerChainDetails from "./components/BrokerChainDetails.js";
import CompanyChainProtocolTab from "./components/CompanyChainProtocolTab";
import CompanyChainOfficeTab from "./components/CompanyChainOfficeTab";
import CompanyChainAccountingTab from "./components/CompanyChainAccountingTab";
import CompanyChainAccountingInfoTab from "./components/CompanyChainAccountingInfoTab";
import CompanyChainAccountingSettingsTab from "./components/CompanyChainAccountingSettingsTab";

import Protocol from "classes/Protocol";


const useStyles = makeStyles(styles);

export default function(props) {

  useScrollIntoView();

  const classes = useStyles();

  const id = props.computedMatch.params.id;

  const {history} = props; 


  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);


  const workingDataItem = useSelector(state => {
    return state.brokerChains.workingData[id] || null;
  });


  console.log("Broker chain loading data:", workingDataItem);

  
  if (workingDataItem && workingDataItem.entity!=null) {

    dispatch({
      type: PUSH_TAB,
      payload: {
        id: createRandomTabId(),
        title: workingDataItem.entity.name,
        to: "/broker_chain/"+id
      }
    });    
  }

  const switchTab = (companyChainId,index) => {
    dispatch({
      type: SWITCH_BROKER_CHAIN_ITEM_TAB,
      payload: { companyChainId, index }
    });
  }


  const fetchItem = id => {
    dispatch({
      type: FETCH_BROKER_CHAIN_ITEM_START,
      payload: { id }
    });


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id;

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_CHAIN_ITEM_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch(error => {

        console.log(error);

        dispatch({
          type: FETCH_BROKER_CHAIN_ITEM_ERROR,
          payload: { id, error }
        });
      })
  };

  if (workingDataItem == null) {
    fetchItem(id);
  }

  const brokerChain = workingDataItem!=null ? workingDataItem.entity : null;
  const currentTab = workingDataItem!=null ? workingDataItem.currentTab : null;

  const appSettings = useSelector(state => state.app.settings);
  const accountingTabEnabled = appSettings.accountingMenuEnabled;

  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {workingDataItem.error.error.message}</h1>;

  const renderSuccess = () => (
    <div className="Standard-Page-Content brokerChainEditPage">
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          {editMode===true && <BrokerChainForm brokerChain={brokerChain} classes={classes} setEditMode={setEditMode} />}
          {editMode===false && <BrokerChainDetails brokerChain={brokerChain} classes={classes}  setEditMode={setEditMode} />}
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <div className="brokerChainEditPage-tabs">
            <Button className="brokerChainEditPage-tabs-tab" size="sm" color={currentTab==1 ? "rose" : "white"} onClick={e=>switchTab(id,1)}>Meglerkontorer</Button>
            <Button className="brokerChainEditPage-tabs-tab" size="sm" color={(currentTab>=20 && currentTab<=25) ? "rose" : "white"} onClick={e=>switchTab(id,20)}>Oppdrags- og eiendomstyper</Button>
            {/*<Button className="brokerChainEditPage-tabs-tab" size="sm" disabled color={currentTab==3 ? "rose" : "white"} onClick={e=>switchTab(id,3)}>SMS</Button>*/}
            <Button className="brokerChainEditPage-tabs-tab" size="sm" color={ (currentTab>=41 && currentTab<=43) ? "rose" : "white"} onClick={e=>switchTab(id,accountingTabEnabled ? 41:42)}>Økonomi</Button>
            {/*<Button className="brokerChainEditPage-tabs-tab" size="sm" disabled color={currentTab==5 ? "rose" : "white"} onClick={e=>switchTab(id,5)}>Andre instillinger</Button>*/}
          </div>

          {currentTab>=41 && currentTab<=43  &&
            (
              <div className="brokerChainEditPage-tabs">
                 {accountingTabEnabled && <Button className="brokerChainEditPage-tabs-tab" size="sm" color={currentTab==41 ? "rose" : null} onClick={e=>switchTab(id,41)}>Balanse</Button>}
                 <Button className="brokerChainEditPage-tabs-tab" size="sm" color={currentTab==42 ? "rose" : null} onClick={e=>switchTab(id,42)}>Produktverdi</Button>
                 <Button className="brokerChainEditPage-tabs-tab" size="sm" color={currentTab==43 ? "rose" : null} onClick={e=>switchTab(id,43)}>Instillinger</Button>
              </div>
            )
          }

          {currentTab==1 && <CompanyChainOfficeTab companyChain={brokerChain} history={history} />}

          {currentTab==20 && <CompanyChainProtocolTab companyChain={workingDataItem.entity} />}

          {currentTab==3 && "SMS"}

          {currentTab==41 && <CompanyChainAccountingInfoTab companyChain={workingDataItem.entity} /> }
          {currentTab==42 && <CompanyChainAccountingTab companyChain={workingDataItem.entity} /> }
          {currentTab==43 && <CompanyChainAccountingSettingsTab companyChain={workingDataItem.entity} /> }
          

          {currentTab==5 && "Andre instillinger"}
        </GridItem>
      </GridContainer>      
    </div>
    );

  if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {
    return renderSuccess();
  }


  return (
    <React.Fragment>
      <h1 className="header header-error">Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );
}
