import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import CompanyChain from "classes/CompanyChain";

import {validateStatus} from "functions/brokerOfficeFunctions";

// app specific components
import { isTemporaryId, createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";
import {HTTP_OK, HTTP_BAD_REQUEST} from "constants.js";
import ValidationErrors from "./ValidationErrors";

import {UPDATE_BROKER} from "actions/types.js";


import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";


const useStyles = makeStyles(styles);

export default function BrokerEditForm({broker, companyChain, back}) {

  const dispatch = useDispatch();

  const classes = useStyles();


  const temporaryId = isTemporaryId(broker.id);

  const externalChain = companyChain.source !== CompanyChain.INTERNAL_SOURCE ? true : false;

  const updateBroker = (brokerId, entity) => {
    dispatch({
      type: UPDATE_BROKER,
      payload: {
        brokerOfficeId: broker.brokerOffice,
        brokerId: brokerId,
        broker: entity
      }
    });
  } 
  
  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    broker[name] = value;
    updateBroker(broker.id, broker);
  };

  const onActiveStatusChange = e => {
    const value = e.target.checked;
    broker.active = value;
    updateBroker(broker.id, broker);
  }


  
  const dispatchInfoNotification = (message) => {
  
  const notificationId = createRandomNotificationId();

  dispatch(infoNotification(message, notificationId));       

  setTimeout(() => {
    dispatch(closeNotification(notificationId));
  }, 1500);       

}

const dispatchErrorNotification = (message, autoClose=true) => {

  const notificationId = createRandomNotificationId();

  dispatch(errorNotification(message,notificationId));            

  if (autoClose){
    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);          
  }
}



  const save = (entity) => {
    
    console.log("Save data:", entity);

    let url;
    let axiosHttpMethod;
    let preSaveId = entity.id;

    if (temporaryId) {
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + entity.brokerOffice + "/brokers";
      axiosHttpMethod = axios.post;
      
      console.log(`POST ${url}`);

    } else {
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker/" + entity.id;
      axiosHttpMethod = axios.patch;

      console.log(`PATCH ${url}`);
    }
    
    axiosHttpMethod(url, entity, {validateStatus})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        if (response.status==HTTP_OK) {

          updateBroker(preSaveId, response.data);

          dispatchInfoNotification("Broker successfully saved");
          back();

        } else if (response.status==HTTP_BAD_REQUEST) {

          const validationErrors =  response.data.validationErrors;
          dispatchErrorNotification(<ValidationErrors validationErrors={validationErrors} />, false);
        }

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })        

  }


  return (
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <EditIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {temporaryId==false && <span>Edit broker: <big>{broker.name}</big></span>}
                {temporaryId==true && <span>New broker: <big>{broker.name}</big></span>}
              </h4>

              {temporaryId==true && externalChain && <SnackbarContent message={<h5>You are creating a broker in an broker chain imported from an external source(<em>{companyChain.source}</em>)</h5>} icon={AddAlert} color="danger" />}

            </CardHeader>
  
              <CardBody>
              <form>
                <CustomInput
                  labelText="Navn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "name",value:_(broker.name), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Tittel"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "title",value:_(broker.title), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Mobile"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "mobile",value:_(broker.mobile), onChange: onTextFieldChange}}
                />                

                <CustomInput
                  labelText="Phone"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "phone",value:_(broker.phone), onChange: onTextFieldChange}}
                />                

                <CustomInput
                  labelText="Epost"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "email",value:_(broker.email), onChange: onTextFieldChange}}
                />


                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={broker.active}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        onChange={onActiveStatusChange}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Aktiv"
                  />
                </div>
                
              </form>
            </CardBody>



            <CardFooter  style={{ justifyContent: "space-between" }}>
              <Button onClick={back}>
                Avbtryt
              </Button>            
              <Button color="danger" onClick={e=>save(broker)}>
                Lagre
              </Button>
            </CardFooter>

          </Card>
    )
}