import React, {useState, useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import axios from "axios";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// app specific hooks
import { useScrollIntoView } from "hooks.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";



// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";

import PowerCompanyForm from "./components/PowerCompanyForm";
import PowerCompanyDetails from "./components/PowerCompanyDetails";
import PowerCompanyProductTab from "./components/PowerCompanyProductTab";
import PowerCompanyTermsTab from "./components/PowerCompanyTermsTab";
import PowerCompanyAccountingTab from "./components/PowerCompanyAccountingTab";


import { FETCH_POWER_COMPANY_ITEM_START } from "actions/types";
import { FETCH_POWER_COMPANY_ITEM_SUCCESS } from "actions/types";
import { FETCH_POWER_COMPANY_ITEM_ERROR } from "actions/types";

import { SWITCH_POWER_COMPANY_ITEM_TAB } from "actions/types";

const useStyles = makeStyles(styles);

export default function PowerCompanyEditPage(props) {

  useScrollIntoView();

  // eslint-disable-next-line no-unused-vars
  const [editProductMode, setEditProductMode] = useState(false);

  useEffect(()=>{
    
    if (editProductMode) {
      document.getElementById("edit_product_mode").scrollIntoView({block: "end", inline: "nearest",behaviour:"smooth"});
    }

  });

  const classes = useStyles();

  const id = props.computedMatch.params.id;


  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const workingDataItem = useSelector(state => {
    return state.powerCompanies.workingData[id] || null;
  });

  console.log("Power company loading data:", workingDataItem);


  if (workingDataItem && workingDataItem.entity!=null) {

    dispatch({
      type: PUSH_TAB,
      payload: {
        id: createRandomTabId(),
        title: workingDataItem.entity.name,
        to: "/power_company/"+id
      }
    });    
  }

  const switchTab = (powerCompanyId,index) => {
    dispatch({
      type: SWITCH_POWER_COMPANY_ITEM_TAB,
      payload: { powerCompanyId, index }
    });
  }

  const fetchItem = id => {
    dispatch({
      type: FETCH_POWER_COMPANY_ITEM_START,
      payload: { id }
    });


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_company/" + id;

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_POWER_COMPANY_ITEM_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch(error => {

        console.log(error);

        dispatch({
          type: FETCH_POWER_COMPANY_ITEM_ERROR,
          payload: { id, error }
        });
      })
  };





  if (workingDataItem == null) {
    fetchItem(id);
  }

  const powerCompany = workingDataItem!=null ? workingDataItem.entity : null;
  const currentTab = workingDataItem!=null ? workingDataItem.currentTab: null;

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {workingDataItem.error.error.message}</h1>;

  const renderSuccess = () => (
    <div className="Standard-Page-Content">
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          {editMode===true && <PowerCompanyForm powerCompany={powerCompany} classes={classes} setEditMode={setEditMode} />}
          {editMode===false && <PowerCompanyDetails powerCompany={powerCompany} classes={classes}  setEditMode={setEditMode} />}
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <div style={{marginTop:"23px"}}>
            <Button size="sm" color={currentTab==1 ? "rose" : "white"} onClick={e=>switchTab(id,1)}>Produkter</Button>
            <Button size="sm" color={currentTab==2 ? "rose" : "white"} onClick={e=>switchTab(id,2)}>Avtalevilkår</Button>
            <Button size="sm" color={currentTab==3 ? "rose" : "white"} onClick={e=>switchTab(id,3)}>Økonomi</Button>
          </div>

          {currentTab==1 && <PowerCompanyProductTab powerCompany={powerCompany} products={powerCompany.products} classes={classes} /> }
          {currentTab==2 && <PowerCompanyTermsTab powerCompany={powerCompany} /> }
          {currentTab==3 && <PowerCompanyAccountingTab powerCompany={powerCompany} /> }
        </GridItem>
      </GridContainer>      
    </div>
    );

if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {
    return renderSuccess();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );
}
