import React from "react";

import Button from "components/CustomButtons/Button.js";

import downloadIcon from "app_assets/img/download-icon.png";

export default function SinglePageFilePreview(props) {
  const { onClose, attachment } = props;

  const downloadUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/"+ attachment.id + "/download";

  return (
    <React.Fragment>
      <div className="Full-Page-Overlay" onClick={onClose}></div>
      <div className="Generic-File-Preview Popup-Z-Index">
        <div className="Info-Grid-Area">

          <h3>{attachment.filename}</h3>

          <h5>File size: {attachment.formattedFilesize}</h5>

        </div>

        <div className="Buttons-Grid-Area">
          <a href={downloadUrl} target="_blank">
            <Button color="danger" className="Icon-Button">
              <img src={downloadIcon} />
            </Button>
          </a>

          <Button color="primary" className="Text-Button" onClick={onClose}>
            Lukk
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}
