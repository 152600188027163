import React from "react";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";

export default function CustomerSection(props) {
  const { estate } = props;

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Kjøper(e), Selger(e)</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      <div className="Customer-List-Container">
        <table className="Customer-List-Table">
          {estate.buyers.map((buyer, index) => (
            <tbody key={index}>
              <tr>
                <td  colSpan="4" className="data">
                  <strong>{buyer.firstName} {buyer.lastName} (Kjøper {index + 1})</strong>
                </td>
              </tr>
              <tr>
                <td className="label">Fødselsdato</td>
                <td className="data">{buyer.birthDate}</td>

                <td className="label">Personnummer</td>
                <td className="data">{buyer.socialSecurityNumber}</td>
              </tr>
              <tr>
                <td className="label">Telefon</td>
                <td className="data">{buyer.mobile}</td>

                <td className="label">Epost</td>
                <td className="data">{buyer.email}</td>
              </tr>
              <tr>
                <td className="label">Adresse</td>
                <td colSpan="3" className="data">
                  {buyer.address && [buyer.address,buyer.city,buyer.zipCode].join(", ")}
                </td>
              </tr>              
            </tbody>
          ))}
        </table>

        <table className="Customer-List-Table">
          {estate.sellers.map((seller, index) => (
            <tbody key={index}>
              <tr>
                <td colSpan="4" className="data">
                  <strong>{seller.firstName} {seller.lastName} (Selger {index + 1})</strong>
                </td>
              </tr>
              <tr>
                <td className="label">Fødselsdato</td>
                <td className="data">{seller.birthDate}</td>

                <td className="label">Personnummer</td>
                <td className="data">{seller.socialSecurityNumber}</td>
              </tr>
              <tr>
                <td className="label">Telefon</td>
                <td className="data">{seller.mobile}</td>

                <td className="label">Epost</td>
                <td className="data">{seller.email}</td>
              </tr>
              <tr>
                <td className="label">Adresse</td>
                <td colSpan="3" className="data">
                  {seller.address && [seller.address,seller.city,seller.zipCode].join(", ")}
                </td>
              </tr>              
            </tbody>
          ))}
        </table>        
      </div>
    </React.Fragment>
  );
}
