import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// app specific resources
import {UPDATE_POWER_ORDER_BUYER, UPDATE_POWER_ORDER_METER, UPDATE_POWER_ORDER_TASK} from "actions/types";
import PowerOrderTask from "classes/PowerOrder/PowerOrderTask";

import PowerOrderBuyer from "classes/PowerOrder/PowerOrderBuyer";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";
import Button from "../../../components/CustomButtons/Button";
import EmailHeaderPanel from "../../emails/components/EmailHeaderPanel";

import {isTemporaryOrderId} from "functions/powerOrderFunctions";



const AttachmentInfoSection = props => {

  const applyExtractedTaskInfo = (field, value) => {
    task[field] = value;
    updatePowerOrderTask(task);
  }

    const applyExtractedMeterInfo = (field, value) => {
      console.log(meter);
        meter[field] = value;
        updatePowerOrderMeter(meter);
    }

    const applyExtractedBuyerInfo = (field, value) => {
        buyer[field] = value;
        updatePowerOrderBuyer(buyer);

        console.log("Buyer", buyer);
    }

  const dispatch = useDispatch();
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const { orderId } = props;
  let { task, buyer, powerOrder, meter } = props;

  const [powerOrderOcr, setPowerOrderOcr] = useState("");

  if (powerOrderOcr == "" && !isTemporaryOrderId(powerOrder.id)) {
    const ocrUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order_ocr/" + powerOrder.id;
    console.log(`GET ${ocrUrl}`);

    axios
        .get(ocrUrl)
        .then(response => {
          console.log("AJAX RESPONSE", response);
          setPowerOrderOcr(response.data);
        })
        .catch(error => {

          console.log(error);
          // dispatch(fetchPowerOrderItemError(id, error));
        })
  }

  if (task == null) {
    task = new PowerOrderTask(orderId);
  }

  // the buyer is needed here to mirror the address in billing fields

  if (buyer == null) {
    buyer = new PowerOrderBuyer(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);
  }

  const updatePowerOrderTask = updatedTask => {
    //console.log("Redux update task", updatedTask);

    dispatch({
      type: UPDATE_POWER_ORDER_TASK,
      payload: updatedTask
    });
  };

    const updatePowerOrderMeter = updatedMeter => {
        //console.log("Redux update task", updatedMeter);
        var index = 0;
        dispatch({
            type: UPDATE_POWER_ORDER_METER,
            payload: {
                orderId,
                index,
                powerMeter: updatedMeter
            }
        });
    };
    const updatePowerOrderBuyer = updatedBuyer => {
        dispatch({
            type: UPDATE_POWER_ORDER_BUYER,
            payload: updatedBuyer
        });
    };

    const commonInputProps = { inputProps: { tabIndex: "3000" } };
  const locationPlaceholder = isLocationLoading ? "Loading..." : "";
  return (
      <React.Fragment>

        <h3>Automatically extracted information: Anlegget</h3>
          <table>
              <tbody>
              <tr>
                  <td>
                      Anleggsadresse: {powerOrderOcr.streetAddress}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedTaskInfo('streetAddress', powerOrderOcr.streetAddress)}>
                          Use for task
                      </a>
                  </td>
              </tr>
              <tr>
                  <td>
                      Leilighetsnummer: {powerOrderOcr.leilighetsnummer}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedTaskInfo('apartmentNumber', powerOrderOcr.leilighetsnummer)}>
                          Use for task
                      </a>
                  </td>
              </tr>
              </tbody>
            </table>
          <h3>Automatically extracted information: Kjøper</h3>
          <table>
              <tbody>
              <tr>
                  <td>
                      Anleggsadresse: {powerOrderOcr.streetAddress}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('billingStreetAddress', powerOrderOcr.streetAddress)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>

              <tr>
                  <td>
                      Fornavn: {powerOrderOcr.firstName}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('firstName', powerOrderOcr.firstName)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>
              <tr>
                  <td>
                      Etternavn: {powerOrderOcr.lastName}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('lastName', powerOrderOcr.lastName)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>

              <tr>
                  <td>
                      Fødselsdato: {powerOrderOcr.fodselsdato}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('birthDate', powerOrderOcr.fodselsdato)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>
              <tr>
                  <td>
                      phone: {powerOrderOcr.phone}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('phone', powerOrderOcr.phone)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>
              <tr>
                  <td>
                      E-post: {powerOrderOcr.email}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedBuyerInfo('email', powerOrderOcr.email)}>
                          Use for buyer
                      </a>
                  </td>
              </tr>

              </tbody>
          </table>
          <h3>Automatically extracted information: Måler</h3>
          <table>
              <tbody>

              <tr>
                  <td>
                      Målernummer: {powerOrderOcr.meterNumber}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedMeterInfo('meterNumber', powerOrderOcr.meterNumber)}>
                          Use for meter 1
                      </a>

                  </td>
              </tr>
              <tr>
                  <td>
                      Måler reading value: {powerOrderOcr.readingValue}
                  </td>
                  <td>
                      <a onClick={e => applyExtractedMeterInfo('readingValue', powerOrderOcr.readingValue)}>
                          Use for meter 1
                      </a>

                  </td>
              </tr>
              </tbody>
          </table>

        <br />
        <br />
        <br />
        <br />
        <br />

      </React.Fragment>
  );
};

export default AttachmentInfoSection;
