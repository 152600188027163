import { FETCH_EMAIL_RESULTS_START } from "actions/types";
import { FETCH_EMAIL_RESULTS_SUCCESS } from "actions/types";
import { FETCH_EMAIL_RESULTS_ERROR } from "actions/types";
import { DISCARD_EMAIL_RESULTS } from "actions/types";
import { RELOAD_EMAIL_RESULTS } from "actions/types";

import { FETCH_EMAIL_ITEM_START } from "actions/types";
import { FETCH_EMAIL_ITEM_SUCCESS } from "actions/types";
import { FETCH_EMAIL_ITEM_ERROR } from "actions/types";
import { DISCARD_EMAIL_ITEM } from "actions/types";

import {UPDATE_EMAIL_ITEM_LOCK} from "actions/types";

import { SET_SELECTED_EMAIL_IDS } from "actions/types";


export const fetchEmailResultsStart = () => {
  const action = {
    type: FETCH_EMAIL_RESULTS_START,
    payload: {  }
  };
  return action;
}

export const fetchEmailResultsSuccess = (data) => {
  const action = {
    type: FETCH_EMAIL_RESULTS_SUCCESS,
    payload: { data }
  };
  return action;
}

export const fetchEmailResultsError = (error) => {
  const action = {
    type: FETCH_EMAIL_RESULTS_ERROR,
    payload: { error }
  };
  return action;
}

export const discardEmailResults = () => {
  const action = {
    type: DISCARD_EMAIL_RESULTS,
    payload: {  }
  };
  return action;
}

export const reloadEmailResults = () => {
  const action = {
    type: RELOAD_EMAIL_RESULTS,
    payload: {}
  };

  return action;
};


export const fetchEmailItemStart = (id) => {
  const action = {
    type: FETCH_EMAIL_ITEM_START,
    payload: { id }
  };
  return action;
};

export const fetchEmailItemSuccess = (id, entity) => {
  const action = {
    type: FETCH_EMAIL_ITEM_SUCCESS,
    payload: { id, entity }
  };
  return action;
};

export const fetchEmailItemError = (id, error) => {
  const action = {
    type: FETCH_EMAIL_ITEM_ERROR,
    payload: { id, error }
  };
  return action;
};

export const discardEmailItem = (id) => {
  const action = {
    type: DISCARD_EMAIL_ITEM,
    payload: { id }
  };
  return action;
};

export const updateEmailItemLock = (id, lock) => {
  const action = {
    type: UPDATE_EMAIL_ITEM_LOCK,
    payload: {id, lock}
  }
  return action;
}

export const setSelectedEmailIds = (ids) => {
  return {
    type: SET_SELECTED_EMAIL_IDS,
    payload: ids
  }
}