import React from "react";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific components
import { formatDate } from "utils.js";

// app specific styles
import "app_assets/scss/PowerCompanies.scss";

export default function PowerCompanyDetails({ powerCompany, classes, setEditMode }) {
  return (
    <Card className="Power-Company-Details">
      <CardHeader icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <big>{powerCompany.name}</big>
        </h4>
      </CardHeader>
      <CardBody>
        <table>
          <tbody>
            <tr>
              <td>Navn</td>
              <td>{powerCompany.name}</td>
            </tr>
            <tr>
              <td>Email to</td>
              <td>{powerCompany.emailTo}</td>
            </tr>
            <tr>
              <td>Email cc</td>
              <td>{powerCompany.emailCc}</td>
            </tr>
            <tr>
              <td>API type</td>
              <td>{powerCompany.apiService}</td>
            </tr>
            <tr>
              <td>Aktiv</td>
              <td>{powerCompany.active ? "Ja" : "Nei"}</td>
            </tr>
            <tr>
              <td>Opprettet dato</td>
              <td>{formatDate(powerCompany.createdAt)}</td>
            </tr>
            <tr>
              <td>Endret dato</td>
              <td>{formatDate(powerCompany.updatedAt)}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "center" }}>
        <Button color="danger" onClick={(e) => setEditMode(true)}>
          Redigere
        </Button>
      </CardFooter>
    </Card>
  );
}
