import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";

// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";

import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_START } from "actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_SUCCESS } from "actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_ERROR } from "actions/types";

import { SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS } from "actions/types";


// app specific components
import {buildPaginationData} from "functions/commonFunctions";
import SearchTag from "app_components/SearchTag";
import CompanyChainAccountingInfoTable from "./CompanyChainAccountingInfoTable";

// app specific styling
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";

import AdjustmentTransactionDialog from "./accounting/AdjustmentTransactionDialog";

const useStyles = makeStyles(regularTablesStyle);


export default function CompanyChainAccountingInfoTab({companyChain}) {

  const classes = useStyles();

  const id = companyChain.id;

  const dispatch = useDispatch();

  const [newAdjustmentMode, setNewAdjustmentMode] = useState(false);
  const [editAdjustmentItem, setEditAdjustmentItem] = useState(null);

  const accountingInfoTabDataItem = useSelector((state) => {
    return state.brokerChains.accountingInfoTabData[id] || null;
  });

  console.log("Company chain accounting info tab data item:", accountingInfoTabDataItem);

  const { transactionCategories } = useSelector((state) => state.app.settings);

  const fetchAccountingInfo = (page) => {
    dispatch({
      type: FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_START,
      payload: { id: companyChain.id }
    });

    let urlParams = {};

    urlParams["page"] = page;   

    if (accountingInfoTabDataItem !== null) {
      if (accountingInfoTabDataItem.filters.fromAccountingDate) {
        urlParams["from_accounting_date"] = moment(accountingInfoTabDataItem.filters.fromAccountingDate).format()
      }

      if (accountingInfoTabDataItem.filters.toAccountingDate) {
        urlParams["to_accounting_date"] = moment(accountingInfoTabDataItem.filters.toAccountingDate).format()
      }         
    }

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id + "/transactions";

    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_SUCCESS,
          payload: { id: companyChain.id, data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_ERROR,
          payload: { id: companyChain.id, error }
        });
      })
  };  



  const fromAccountingDateChangeHandler = value =>{
    if (typeof value == "object") {
      dispatch({
        type: SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS,
        payload: {
          id,
          filters: {
            fromAccountingDate: value.toDate()
          }          
        }

      });
    }
  };
  const toAccountingDateChangeHandler = value =>{
    if (typeof value == "object") {
      dispatch({
        type: SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS,
        payload: {
          id,
          filters: {
            toAccountingDate: value.toDate()
          }          
        }

      });
    }
  };

  const resetFromAccountingDateFilter = () => {
      dispatch({
        type: SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS,
        payload: {
           id,
          filters: {
            fromAccountingDate: null
          }         
        }
      });
  }

  const resetToAccountingDateFilter = () => {
      dispatch({
        type: SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS,
        payload: {
          id,
          filters: {
            toAccountingDate: null
          }          
        }
      });
  }

  let dateFilterActive;

  if (accountingInfoTabDataItem==null) {
    fetchAccountingInfo(1);

    dateFilterActive = false;

  } else {
    const isLoading = accountingInfoTabDataItem.loading;
    const hasData = accountingInfoTabDataItem.results == null ? false: true;
    const hasError = accountingInfoTabDataItem.error==null ? false: true;

    if (isLoading==false && hasData==false && hasError==false) {
      if (accountingInfoTabDataItem.currentPage==null)
        fetchAccountingInfo(1);
      else
        fetchAccountingInfo(accountingInfoTabDataItem.currentPage);
    }      

    dateFilterActive = accountingInfoTabDataItem.filters.fromAccountingDate!==null || accountingInfoTabDataItem.filters.toAccountingDate!=null;
  }


  const editAdjustment = (item) => {
    console.log("Edit adjustment", item);
    setEditAdjustmentItem(item);
  }

 
  const renderDefault = () => <h1 style={{ marginTop: "200px" }}>...</h1>;

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {accountingInfoTabDataItem.error.error.message}</h1>;

  const renderSuccess = () => (

    
    <React.Fragment>

      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Transaksjoner {accountingInfoTabDataItem.results!==null && <em> ({accountingInfoTabDataItem.results.length} av {accountingInfoTabDataItem.totalResults} resultater)</em>}</h4>
        </CardHeader>

        <CardBody>

          <GridContainer>

            <GridItem md={9}>
              {dateFilterActive==false && <h3>Total balanse: <strong>{accountingInfoTabDataItem.balance}</strong></h3>}
              {dateFilterActive==true && 
                <div>
                  <h3>Balanse: <strong>{accountingInfoTabDataItem.balance}</strong></h3>

                  {accountingInfoTabDataItem.filters.fromAccountingDate && <span> fra <strong>{moment(accountingInfoTabDataItem.filters.fromAccountingDate).format("DD MMMM YYYY")}</strong></span>}
                  {accountingInfoTabDataItem.filters.toAccountingDate && <span> til <strong>{moment(accountingInfoTabDataItem.filters.toAccountingDate).format("DD MMMM YYYY")}</strong></span>}
              </div>}

            </GridItem>

            <GridItem md={3} style={{textAlign:"right"}}>
              <div style={{marginTop:"15pt"}}>
                <Button size="sm" color="primary" onClick={e=>setNewAdjustmentMode(true)}>Justering</Button>
              </div>
            </GridItem>



            <GridItem md={3}>
              <FormControl style={{marginBottom:"5px"}} fullWidth>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={accountingInfoTabDataItem.filters.fromAccountingDate} onChange={fromAccountingDateChangeHandler} inputProps={{ placeholder: "Fra",readOnly: true }} />
              </FormControl>

              {accountingInfoTabDataItem.filters.fromAccountingDate && (
                <SearchTag title={"Fra dato"} removeTag={resetFromAccountingDateFilter} />
                )}
            </GridItem>

            <GridItem md={3}>
              <FormControl style={{marginBottom:"5px"}} fullWidth>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={accountingInfoTabDataItem.filters.toAccountingDate} onChange={toAccountingDateChangeHandler} inputProps={{ placeholder: "Til",readOnly: true }} />
              </FormControl>

              {accountingInfoTabDataItem.filters.toAccountingDate && (
                <SearchTag title={"Til dato"} removeTag={resetToAccountingDateFilter} />
                )}                    
            </GridItem>

            <GridItem md={6}></GridItem>

            <GridItem md={12}>
              <div style={{ textAlign: "center" }}>
                <Pagination pages={buildPaginationData(accountingInfoTabDataItem.currentPage, accountingInfoTabDataItem.totalPages, fetchAccountingInfo)} />
                <div style={{ color: "#777" }}>
                  Side {accountingInfoTabDataItem.currentPage} av {accountingInfoTabDataItem.totalPages}
                </div>
              </div>
            </GridItem>


          </GridContainer>
  
          <CompanyChainAccountingInfoTable companyChain={companyChain} results={accountingInfoTabDataItem.results} editAdjustment={editAdjustment} />

        </CardBody>
      </Card>

      {newAdjustmentMode==true && <AdjustmentTransactionDialog close={e=>setNewAdjustmentMode(false)} transactionCategories={transactionCategories} transaction={null} companyChain={companyChain} />}
      {editAdjustmentItem!=null && <AdjustmentTransactionDialog close={e=>setEditAdjustmentItem(null)} transactionCategories={transactionCategories} transaction={editAdjustmentItem} companyChain={companyChain} />}

    </React.Fragment>


    );

    if (accountingInfoTabDataItem==null) {
      return renderDefault();
    }
    else if (accountingInfoTabDataItem.results !== null) {
      return renderSuccess();
    } else if (accountingInfoTabDataItem.loading == true) {
      return renderLoading();
    } else if (accountingInfoTabDataItem.error !== null) {
      return renderError();    
    } else if (accountingInfoTabDataItem.entitites == null) {
      return renderDefault();
    }

    return (
      <React.Fragment>
        <h1>Error: invalid state {JSON.stringify(accountingInfoTabDataItem)}</h1>
      </React.Fragment>
    );

}