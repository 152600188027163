import React from "react";
import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

// theme assets
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// app resources
import { UPDATE_POWER_ORDER_SELLER } from "actions/types";
import { expandDateInput, convertNullToString as _ } from "utils.js";
import PowerOrderSeller from "classes/PowerOrder/PowerOrderSeller";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";

const useStyles = makeStyles(styles);

const SellerSection = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { orderId } = props;
  let { seller } = props;

  if (seller == null) {
    seller = new PowerOrderSeller(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);
  }

  const isCompanySeller = seller.customerType == PowerOrderCustomer.COMPANY_CUSTOMER_TYPE;

  const setIsCompanySeller = value => {
    if (value == true) {
      seller.customerType = PowerOrderCustomer.COMPANY_CUSTOMER_TYPE;
    } else {
      seller.customerType = PowerOrderCustomer.PERSON_CUSTOMER_TYPE;
    }

    updatePowerOrderSeller(seller);
  };

  const updatePowerOrderSeller = updatedSeller => {
    dispatch({
      type: UPDATE_POWER_ORDER_SELLER,
      payload: updatedSeller
    });
  };

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    //console.log("Change field", e.target.name, e.target.value);

    switch (name) {
      case "seller_last_name":
        seller.lastName = value;
        updatePowerOrderSeller(seller);
        break;
      case "seller_first_name":
        seller.firstName = value;
        updatePowerOrderSeller(seller);
        break;
      case "seller_birth_date":
        seller.birthDate = value;
        updatePowerOrderSeller(seller);
        break;
      case "seller_phone":
        seller.phone = value.trim();
        updatePowerOrderSeller(seller);
        break;
      case "seller_company_name":
        seller.companyName = value;
        updatePowerOrderSeller(seller);
        break;
      case "seller_company_number":
        seller.companyNumber = value;
        updatePowerOrderSeller(seller);
        break;
      case "seller_email":
        seller.email = value.trim();
        updatePowerOrderSeller(seller);
        break;
      default:
        break;
    }
  };

  const onTextFieldBlur = e => {
    
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "seller_birth_date": 
        seller.birthDate = expandDateInput(value);
        updatePowerOrderSeller(seller);
        break;
      default:
        break;
    }

  };

  const commonInputProps = { inputProps: { tabIndex: "6000", onBlur: onTextFieldBlur, onChange: onTextFieldChange } };

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Selger (flytter ut)</div>
        <div className="Section-Control-Label">person</div>
        <div className="Section-Control-Element">
          <div className="Switch-Container">
            <Switch
              checked={isCompanySeller}
              tabIndex={-1}
              onChange={event => setIsCompanySeller(event.target.checked)}
              value="checked"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          </div>
        </div>
      </div>

      {isCompanySeller == false && (
        <React.Fragment>
          <div className="Input-Line">
            <div className="Field-Label">Etternavn</div>
            <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_last_name", value: _(seller.lastName) }} />
            </div>
            <div className="Field-Label">Fornavn</div>
            <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_first_name", value: _(seller.firstName) }} />
            </div>
          </div>

          <div className="Input-Line">
            <div className="Field-Label">Fødselsdato</div>
            <div className="Medium-Field-Control">
              <CustomInput 
                formControlProps={{ fullWidth: true }} 
                inputProps={{ ...commonInputProps, name: "seller_birth_date", value: _(seller.birthDate) }}
              />

            </div>
            <div className="Field-Label">Telefonnummer</div>
            <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_phone", value: _(seller.phone) }} />
            </div>
          </div>
        </React.Fragment>
      )}

      {isCompanySeller == true && (
        <React.Fragment>
          <div className="Input-Line">
            <div className="Field-Label">Organisasjonsnavn</div>
            <div className="Large-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_company_name", value: _(seller.companyName) }} />
            </div>
            <div className="Field-Label">Orgnummer</div>
            <div className="Small-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_company_number", value: _(seller.companyNumber) }} />
            </div>
          </div>

          <div className="Input-Line" style={{ width: "50%" }}>
            <div className="Field-Label">Telefonnummer</div>
            <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_phone", value: _(seller.phone) }} />
            </div>
          </div>
        </React.Fragment>
      )}

      <div className="Input-Line">
        <div className="Field-Label">Epost</div>
        <div className="Large-Field-Control">
          <CustomInput formControlProps={{ fullWidth: true }} inputProps={{ ...commonInputProps, name: "seller_email", value: _(seller.email) }} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SellerSection;
