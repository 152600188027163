import { FETCH_ESTATE_ITEM_START } from "../actions/types";
import { FETCH_ESTATE_ITEM_SUCCESS } from "../actions/types";
import { FETCH_ESTATE_ITEM_ERROR } from "../actions/types";

import { FETCH_ESTATE_RESULTS_START } from "../actions/types";
import { FETCH_ESTATE_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_ESTATE_RESULTS_ERROR } from "../actions/types";

import { SET_ESTATE_BROKER_OFFICE_FILTER } from "../actions/types";
import { SET_ESTATE_COMPANY_CHAIN_FILTER } from "../actions/types";
import { SET_ESTATE_TEXT_FILTER } from "../actions/types";
import { SET_ESTATE_FROM_DATE_FILTER } from "../actions/types";
import { SET_ESTATE_TO_DATE_FILTER } from "../actions/types";

import { DISCARD_ESTATE_ITEM } from "../actions/types";



import {APP_LOGOUT_SUCCESS} from "../actions/types";

const listingDataTemplate = {
  loading: false,
  error: null,

  results: null,
  currentPage: null,
  totalPages: null,
  totalResults: null,

  brokerOfficeFilter: null,
  companyChainFilter: null,
  textFilter: null,
  fromDateFilter: null,
  toDateFilter: null
}

const itemLoadingTemplate = {
  loading: false,
  error: null,
  entity: null
};



const createInitialState = () => ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: {
    
  }
});


export default function(state = createInitialState(), action) {
  const { workingData } = state;
  const currentlistingData = state.listingData;

  switch (action.type) {
    case FETCH_ESTATE_ITEM_START: {
      console.log("Estate action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_ESTATE_ITEM_SUCCESS: {
      console.log("Estate action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_ESTATE_ITEM_ERROR: {
      console.log("Estate action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }

    case DISCARD_ESTATE_ITEM: {
      console.log("Estate action", action.type, action);
      
      let id = action.payload.id;
      delete workingData[id];

      return {
        ...state,
        workingData
      };      
    }

case FETCH_ESTATE_RESULTS_START: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,
        textFilter: currentlistingData.textFilter,
        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: currentlistingData.toDateFilter,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_ESTATE_RESULTS_SUCCESS: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,
        textFilter: currentlistingData.textFilter,
        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: currentlistingData.toDateFilter,

        results: action.payload.data.results,
        currentPage: action.payload.data.currentPage,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults,
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_ESTATE_RESULTS_ERROR: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }

    case SET_ESTATE_BROKER_OFFICE_FILTER: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        textFilter: currentlistingData.textFilter,
        brokerOfficeFilter: action.payload, //!

        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: currentlistingData.toDateFilter
      };

      return {
        ...state,
        listingData
      };
    }    

    case SET_ESTATE_COMPANY_CHAIN_FILTER: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        textFilter: currentlistingData.textFilter,
        companyChainFilter: action.payload, //!

        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: currentlistingData.toDateFilter
      };

      return {
        ...state,
        listingData
      };
    }    


    case SET_ESTATE_TEXT_FILTER: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,
        textFilter: action.payload, //!

        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: currentlistingData.toDateFilter
      };

      return {
        ...state,
        listingData
      };
    }        


    case SET_ESTATE_FROM_DATE_FILTER: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,
        textFilter: currentlistingData.textFilter,
        fromDateFilter: action.payload, //!
        toDateFilter: currentlistingData.toDateFilter
      };

      return {
        ...state,
        listingData
      };
    }      

    case SET_ESTATE_TO_DATE_FILTER: {
      console.log("Estate action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        brokerOfficeFilter: currentlistingData.brokerOfficeFilter,
        companyChainFilter: currentlistingData.companyChainFilter,
        textFilter: currentlistingData.textFilter,
        fromDateFilter: currentlistingData.fromDateFilter,
        toDateFilter: action.payload, //!
      };

      return {
        ...state,
        listingData
      };
    }  

    case APP_LOGOUT_SUCCESS: {
      console.log("Estate action", action.type, action);

      return createInitialState();
    }    

    default:
      return state;
  }
}
