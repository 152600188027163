import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_START } from "actions/types";
import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_PRODUCT_VALUES_ERROR } from "actions/types";

import BrokerOfficeAccountingTable from "./BrokerOfficeAccountingTable";

export default function BrokerOfficeAccountingTab({brokerOffice}){

  const id = brokerOffice.id;

  const dispatch = useDispatch();

  const productValueTabDataItem = useSelector((state) => {
    return state.brokerOffices.productValueTabData[id] || null;
  });

  console.log("Broker office product value tab data item:", productValueTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_BROKER_OFFICE_PRODUCT_VALUES_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/product_values";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_PRODUCT_VALUES_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_PRODUCT_VALUES_ERROR,
          payload: { id, error }
        });
      });
  };

  if (productValueTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {productValueTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<BrokerOfficeAccountingTable products={productValueTabDataItem.products} brokerOffice={brokerOffice} />);


  if (productValueTabDataItem == null) {
    return renderLoading();
  } else if (productValueTabDataItem.loading == true) {
    return renderLoading();
  } else if (productValueTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}