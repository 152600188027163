import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";


// app resources
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";
import { createRandomNotificationId } from "utils.js";

import { FETCH_BROKER_OFFICE_SEND_CONFIG_START } from "actions/types";
import { FETCH_BROKER_OFFICE_SEND_CONFIG_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_SEND_CONFIG_ERROR } from "actions/types";

import { UPDATE_BROKER_OFFICE_SEND_CONFIG } from "actions/types";

const BrokerOfficeSendConfigTab = ({brokerOffice, classes}) => {


  const id = brokerOffice.id;

  const dispatch = useDispatch();

  const sendConfigTabDataItem = useSelector((state) => {
    return state.brokerOffices.sendConfigTabData[id] || null;
  });

  console.log("Broker office send config tab data item:", sendConfigTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_BROKER_OFFICE_SEND_CONFIG_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/send_config";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_SEND_CONFIG_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_SEND_CONFIG_ERROR,
          payload: { id, error }
        });
      });
  };

  if (sendConfigTabDataItem == null) {
    fetchItem(id);
  }

  let sendConfig = sendConfigTabDataItem != null ? sendConfigTabDataItem.entity: null;

  if (sendConfig == null) {
    sendConfig = {
      brokerOfficeId: id,
  
      fullToSettlement: false,
      fullToMainBroker: false,
      fullToBrokers: false,
      fullToSellers: false,
      fullToBuyers: false,
      fullToCustomerSupport: false,
      fullToFagsystem: false,

      summaryToSettlement: false,
      summaryToMainBroker: false,
      summaryToBrokers: false,
      summaryToSellers: false,
      summaryToBuyers: false,
      summaryToCustomerSupport: false,
      summaryToFagsystem: false,

      settlementEmail: ""      
    }
  }

  const checkboxGroup1 = [
    "fullToSettlement",
    "fullToMainBroker",
    "fullToBrokers",
    "fullToSellers",
    "fullToBuyers",
    "fullToCustomerSupport",
    "fullToFagsystem"
  ];

    const checkboxGroup2 = [
    "summaryToSettlement",
    "summaryToMainBroker",
    "summaryToBrokers",
    "summaryToSellers",
    "summaryToBuyers",
    "summaryToCustomerSupport",
    "summaryToFagsystem"
  ];


  const updateBrokerOfficeSendConfig = (entity) => {

    dispatch({
      type: UPDATE_BROKER_OFFICE_SEND_CONFIG,
      payload: { id, entity }
    });    
  }
  
  const save = () => {


    console.log("Save data", sendConfig);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/send_config";

    console.log(`POST ${url}`);

    axios
      .post(url, sendConfig)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        updateBrokerOfficeSendConfig(response.data);

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Save successful"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 3000);            
      })
      .catch(error => {

        console.log(error);

        // TODO add error notification
      })   

      
  }



  const onCheckboxChange = e => {
    const value = e.target.checked;
    const name = e.target.name;

    console.log("Change field", name, value);

    sendConfig[name] = value;
    updateBrokerOfficeSendConfig(sendConfig);      
  }

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    console.log("Change field", e.target.name, e.target.value);


    switch (name) {
      case "settlementEmail":
        sendConfig.settlementEmail = value;
        updateBrokerOfficeSendConfig(sendConfig);
        break;

      default:
        break;
    }
  }

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {sendConfigTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (
    <Card>
      <GridContainer style={{ marginTop: "25px",paddingLeft:"20px",paddingRight:"20px" }}>
        <GridItem md={4}>
          

          {checkboxGroup1.map(fieldName => (

              <div className={classes.checkboxAndRadio} key={fieldName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{name: fieldName}}
                      onChange={onCheckboxChange}
                      checked={sendConfig[fieldName]}
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={fieldName}
                />
              </div>

            ))}
                    

        </GridItem>

        <GridItem md={4}>


          {checkboxGroup2.map(fieldName => (

              <div className={classes.checkboxAndRadio} key={fieldName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{name: fieldName}}
                      onChange={onCheckboxChange}
                      checked={sendConfig[fieldName]}
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot,
                  }}
                  label={fieldName}
                />
              </div>

            ))}
                                   


        </GridItem>


        <GridItem md={4}>

            <CustomInput
              labelText="Settlement email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{ onChange: onTextFieldChange, value: sendConfig.settlementEmail,  name: "settlementEmail" }}
            />
        </GridItem>

      </GridContainer>

      <GridContainer style={{ paddingLeft:"20px",paddingRight:"20px" }}>
        <GridItem xs={6} sm={6} md={6}>

        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <div style={{ marginTop: "25px", textAlign: "right" }}>
            <Button color="rose" onClick={e=>save()}>Lagre</Button>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );


  if (sendConfigTabDataItem == null) {
    return renderLoading();
  } else if (sendConfigTabDataItem.loading == true) {
    return renderLoading();
  } else if (sendConfigTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }    
}

export default BrokerOfficeSendConfigTab;