import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";


import {formatDate} from "utils.js";


import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);

export default function BrokerOfficeListTable(props) {
  const classes = useStyles();
  const { history, tableHead, tableData } = props;

  const tableCellClasses = classes.tableCell;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [classes.tableHeadFontSize]: true
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: true,
              [classes.tableStripedRow]: key % 2 === 0
            });

            return (
              <TableRow
                key={key}
                hover={true}
                className={classes.tableRow + " " + tableRowClasses}
                onClick={e => history.push("/broker_office/" + item.id)}>
                <TableCell className={tableCellClasses}>{item.name}</TableCell>
                <TableCell className={tableCellClasses}>{item.companyChainName}</TableCell>
                <TableCell className={tableCellClasses}>{item.city}</TableCell>
                <TableCell className={tableCellClasses}>{item.active ? "aktiv" : "inaktiv"}</TableCell>
                <TableCell className={tableCellClasses}>{formatDate(item.created)}</TableCell>
                <TableCell className={tableCellClasses}>{formatDate(item.modified)}</TableCell>
                <TableCell className={tableCellClasses}>

                  {item.productCount>0 && <span style={{color:"red",margin:"5px",fontWeight:"400"}}>P<sup>{item.productCount}</sup></span>}
                  
                  {item.hasSendConfig && item.sendConfigScore>0 && <span style={{color:"red",margin:"5px",fontWeight:"400"}}>SC{item.hasSettlementEmail && <sup>SE</sup>}</span>}
                  {item.hasSendConfig && item.sendConfigScore==0 && <span style={{color:"gray",margin:"5px",fontWeight:"400"}}>SC{item.hasSettlementEmail && <sup>SE</sup>}</span>}
                  
                  {item.hasSettings && item.brokerNotificationSetting==false && <span style={{color:"gray",textDecoration:"line-through",margin:"5px",fontWeight:"100"}}>@-&gt;B</span>}


                </TableCell>                
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

BrokerOfficeListTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array
};
