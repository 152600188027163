import { combineReducers } from 'redux';
import authReducer from './authReducer';
import appReducer from './appReducer';
import notificationReducer from './notificationReducer';
import tabReducer from './tabReducer';
import emailReducer from './emailReducer';
import powerOrderReducer from './powerOrderReducer';
import estateReducer from './estateReducer';
import powerCompanyReducer from './powerCompanyReducer';
import brokerChainReducer from './brokerChainReducer';
import brokerOfficeReducer from './brokerOfficeReducer';
import brokerReducer from './brokerReducer';
import otherProductReducer from './otherProductReducer';
import uiReducer from './uiReducer';


export default combineReducers({
  app: appReducer,
  auth: authReducer,
  notification: notificationReducer,
  tabs:tabReducer,
  emails: emailReducer,
  powerOrders: powerOrderReducer,
  estate: estateReducer,
  powerCompanies: powerCompanyReducer,
  brokerChains: brokerChainReducer,
  brokerOffices: brokerOfficeReducer,
  brokers: brokerReducer,
  otherProducts: otherProductReducer,
  ui: uiReducer
});