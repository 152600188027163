import React, {useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// @material-ui/core components
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";

import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_START } from "actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_SUCCESS } from "actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_ERROR } from "actions/types";
import { UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS } from "actions/types";

// app specific components
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";

import CompanyChainAccountingSettings from "classes/CompanyChainAccountingSettings";


function formatDecimal(str){
  if (str==null){
    return null;
  }

  return Number(str).toFixed(2);
}

export default function CompanyChainAccountingSettingsTab({companyChain}){

  const id = companyChain.id;

  const dispatch = useDispatch();

  const accountingSettingsTabDataItem = useSelector((state) => {
    return state.brokerChains.accountingSettingsTabData[id] || null;
  });

  const ccp = accountingSettingsTabDataItem!==null ? accountingSettingsTabDataItem.entity?.companyChainPercentage: null;

  const [inputValue, setInputValue]=useState(ccp);

  const bcm = accountingSettingsTabDataItem!==null ? accountingSettingsTabDataItem.entity?.bankidChargesMode : null;

  const [dropdownValue, setDropdownValue]=useState(bcm);

  console.log("Company chain accounting settings tab data item:", accountingSettingsTabDataItem);


  const save = () => {

    const dispatchInfoNotification = (message) => {
    
      const notificationId = createRandomNotificationId();

      dispatch(infoNotification(message, notificationId));       

      setTimeout(() => {
        dispatch(closeNotification(notificationId));
      }, 1500);       
    }

    const dispatchErrorNotification = (message) => {

      const notificationId = createRandomNotificationId();

      dispatch(errorNotification(message,notificationId));            

      setTimeout(() => {
        dispatch(closeNotification(notificationId));
      }, 5000);          
      
    }

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id + "/accounting_settings";

    console.log(`POST ${url}`);
   
    axios.post(url, accountingSettingsTabDataItem.entity)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS,
          payload: {
            id,
            entity: response.data
          }
        });

        dispatchInfoNotification("Save successful.");
      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })   
  };

  const fetchItem = (id) => {
    dispatch({
      type: FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id + "/accounting_settings";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_SUCCESS,
          payload: { id, data: response.data }
        });

        // also update edited value
        setInputValue(response.data.companyChainPercentage);
        setDropdownValue(response.data.bankidChargesMode);

      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_ERROR,
          payload: { id, error }
        });
      });
  };

  if (accountingSettingsTabDataItem == null) {
    fetchItem(id);
  }  

  

  const changeHandler = e=>{
    setInputValue(e.target.value);
  }

  const enterHandler = e => {
    if(e.keyCode == 13){
      e.target.blur();
    }        
  }

  const blurHandler = e=> {
    
    let value;

    if (e.target.value=="") {
        value = null;
    }
    else {
      const floatValue = parseFloat(e.target.value);

      if (isNaN(floatValue) || floatValue<0 || floatValue>100) {
        value = null;
      } else {
        value = formatDecimal(floatValue);
      }
    }

    setInputValue(value);

    const entity = accountingSettingsTabDataItem.entity;
    entity.companyChainPercentage = value;

    dispatch({
      type: UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS,
      payload: { id, entity }
    }); 
  }

  const calculateOfficePercentage = () => {
    const {companyChainPercentage} = accountingSettingsTabDataItem.entity;

    if (companyChainPercentage===null || isNaN(companyChainPercentage)) 
      return "";

      return formatDecimal(100 - Number(companyChainPercentage));
  }

  const handleBankidChargesModeChange = e => {

    let value = e.target.value;

    setDropdownValue(value);

    const entity = accountingSettingsTabDataItem.entity;
    entity.bankidChargesMode = value;

    dispatch({
      type: UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS,
      payload: { id, entity }
    }); 
  }

  const entity = accountingSettingsTabDataItem ? accountingSettingsTabDataItem.entity : null;

  console.log("Entity now", entity);


  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {accountingSettingsTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (

    <Card className="companyChainAccountingSettingsTab">
      <CardBody>

        <GridContainer className="companyChainAccountingSettingsTab-container">
          <GridItem md={12}>Standard fordeling</GridItem>
          

          <GridItem md={2}>
            <CustomInput
              labelText="Kjede"
              formControlProps={{
                fullWidth: false
              }}
              inputProps={{ name: "company_chain_percentage", value: _(inputValue), onKeyDown: enterHandler, onChange: changeHandler, onBlur: blurHandler }}
            />
          </GridItem>
          <GridItem md={2}>
            <CustomInput
              labelText="Kontor"
              formControlProps={{
                fullWidth: false
              }}
              inputProps={{ value: calculateOfficePercentage(), disabled: true }}
            />
          </GridItem>

          <GridItem md={8}>
            
            <FormControl>
              <InputLabel>BankID kostnad fordeling:</InputLabel>
              <Select
                className="companyChainAccountingSettingsTab-select"
                value={_(dropdownValue)}
                onChange={handleBankidChargesModeChange}

              >
                <MenuItem value={CompanyChainAccountingSettings.NORMAL_BANKID_CHARGE_MODE}>Standard fordeling</MenuItem>
                <MenuItem value={CompanyChainAccountingSettings.COMPANYCHAIN_BANKID_CHARGE_MODE}>Kun meglerkjeden</MenuItem>
                <MenuItem value={CompanyChainAccountingSettings.BROKEROFFICE_BANKID_CHARGE_MODE}>Kun meglerkontoret</MenuItem>
              </Select>
            </FormControl>  

          </GridItem>



          <GridItem md={12} className="companyChainAccountingSettingsTab-save">
            
            <Button className="companyChainAccountingSettingsTab-save-btn" color="danger" onClick={e=>save()}>
              Lagre
            </Button>  

          </GridItem>

        </GridContainer>

      </CardBody>
    </Card>

    );


  if (accountingSettingsTabDataItem == null) {
    return renderLoading();
  } else if (accountingSettingsTabDataItem.loading == true) {
    return renderLoading();
  } else if (accountingSettingsTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}