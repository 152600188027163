import { PUSH_TAB, CLOSE_TAB } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";


const createInitialState = () => ({
  items: []
});



 
export default function(state = createInitialState(), action) {
  let items = state.items;

  switch (action.type) {
    case PUSH_TAB:

      // don't add the tab if already exists
      for (let i in items){
        if (items[i].to==action.payload.to) {         
          return { ...state, items };
        }
      }

      items.push(action.payload);
      return { ...state, items };

    case CLOSE_TAB:
      let id = action.payload.id;
      let to = action.payload.to;

      if (id !== undefined) {
        items = items.filter(item => id != item.id);  
      } else if (to !== undefined) {
        items = items.filter(item => to != item.to);  
      }
      

      return {
        ...state,
        items
      };

    case APP_LOGOUT_SUCCESS: {
      console.log("Tab reducer action", action.type, action);

      return createInitialState();
    }



    default:
      return state;
  }
}
