import React, { useState } from "react";

// @material-ui/core components
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

const DealsCustomMessage = ({sendCustomSms, enabled}) => {

  const [customSmsPhoneNr, setCustomSmsPhoneNr] = useState("");
  const [customSmsMessage, setCustomSmsMessage] = useState("");  

  const handleCustomSmsPhoneNrChange = e => setCustomSmsPhoneNr(e.target.value);
  const handleCustomSmsMessageChange = e => setCustomSmsMessage(e.target.value);  

  return (
      <div className="Custom-Message-Table">
        <div className="Table-Row">
          <div className="Table-Cell-1">Send til</div>
          <div className="Table-Cell-2">
            
            <CustomInput labelText="Telefonnummer"  formControlProps={{ fullWidth: true }} inputProps={{ value: customSmsPhoneNr, onChange: handleCustomSmsPhoneNrChange, disabled: enabled==false}} />

          </div>
          <div className="Table-Cell-3"></div>
          <div className="Table-Cell-4">
            {enabled==true && <Button disabled={customSmsPhoneNr.length!=8 || customSmsMessage.length<31} color="primary" size="sm" onClick={e=>sendCustomSms(customSmsPhoneNr, customSmsMessage)}>Send</Button>}

            {enabled==false && <Button disabled  size="sm">Send</Button>}
          </div>
        </div>

        <div className="Table-Row">
          <div className="Table-Cell-1"></div>
          <div className="Table-Cell-2">
            <TextareaAutosize style={{ width: "100%" }} value={customSmsMessage} disabled={enabled==false} onChange={handleCustomSmsMessageChange}  rowsMin={3} rowsMax={10} placeholder="Tekst" />
          </div>
        </div>
      </div>
    );
};


export default DealsCustomMessage;