import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";

// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
//import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";

// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app specific hooks
import { useScrollIntoView } from "hooks.js";

// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import OtherProductListTable from "./components/OtherProductListTable";
import { FETCH_OTHER_PRODUCT_RESULTS_START } from "actions/types";
import { FETCH_OTHER_PRODUCT_RESULTS_SUCCESS } from "actions/types";
import { FETCH_OTHER_PRODUCT_RESULTS_ERROR } from "actions/types";

// app specific styling
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";

const useStyles = makeStyles(regularTablesStyle);

export default function OtherProductListPage(props) {

  const { history } = props;


  const PAGE_TITLE = "Andre leverandører";

  useScrollIntoView();

  useEffect(() => {
    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  dispatch({
  type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title: "Andre leverandører",
      to: "/other_products"
    }
  });    


  const listingData = useSelector(state => {
    return state.otherProducts.listingData || null;
  });


  const searchOtherProducts = () => {
    dispatch({
      type: FETCH_OTHER_PRODUCT_RESULTS_START,
      payload: {  }
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/other_products";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_OTHER_PRODUCT_RESULTS_SUCCESS,
          payload: { data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_OTHER_PRODUCT_RESULTS_ERROR,
          payload: { error }
        });
      })
  };


  
  const isLoading = listingData.loading;
  const hasData = listingData.serviceProducts == null ? false: true;
  const hasError = listingData.error==null ? false: true;

  if (isLoading==false && hasData==false && hasError==false) {
    searchOtherProducts();
  }
  

  console.log("listingData",listingData);

  const renderDefault = () => <h1 style={{ marginTop: "200px" }}>...</h1>;

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {listingData.error.error.message}</h1>;

  const renderSuccess = () => (
    <React.Fragment>
      <div className="Standard-Page-Content">
        <GridContainer>
          <GridItem md={12}>
            <Link to="/power_companies">
              <Button size="sm">
                Strømleverandører
              </Button>
            </Link>

            <Link to="/other_products">
              <Button size="sm" color="primary">Andre leverandører</Button>
            </Link>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {PAGE_TITLE}
                </h4>

                
                <Button style={{float:"right"}} color="white" onClick={ e=>{
  
                  dispatch({
                    type: FETCH_OTHER_PRODUCT_RESULTS_SUCCESS,
                    payload: { data: {serviceProducts: null} }
                  });

                }}>
                  <Refresh />
                </Button>


              </CardHeader>



              <CardBody>
                <OtherProductListTable
                  history={history}
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "Category",
                    "Company",
                    "Name",
                    "Description",
                    "Active"
                  ]}
                  tableData={listingData.serviceProducts}
            
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>
    );


  if (listingData.serviceProducts !== null) {
    return renderSuccess();
  } else if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else if (listingData.serviceProducts == null) {
    return renderDefault();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(listingData)}</h1>
    </React.Fragment>
  );

}
