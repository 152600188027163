import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_BROKER_OFFICE_BROKERS_START } from "actions/types";
import { FETCH_BROKER_OFFICE_BROKERS_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_BROKERS_ERROR } from "actions/types";

import BrokerListTable from "./BrokerListTable";

export default function BrokerOfficeBrokersTab({brokerOffice}){

  const {id} = brokerOffice;
  const {companyChain} = brokerOffice;

  const dispatch = useDispatch();

  const brokersTabDataItem = useSelector((state) => {
    return state.brokerOffices.brokersTabData[id] || null;
  });

  console.log("Broker office brokers tab data item:", brokersTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_BROKER_OFFICE_BROKERS_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/brokers";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_BROKERS_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_BROKERS_ERROR,
          payload: { id, error }
        });
      });
  };

  if (brokersTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {brokersTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<BrokerListTable brokers={brokersTabDataItem.brokers} companyChain={companyChain} brokerOffice={brokerOffice}  />);


  if (brokersTabDataItem == null) {
    return renderLoading();
  } else if (brokersTabDataItem.loading == true) {
    return renderLoading();
  } else if (brokersTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}