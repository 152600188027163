export default class Protocol {
  static TAKEOVER_TYPE = "takeover";
  static DEALS_BUYER_TYPE = "dealsBuyer";
  static DEALS_SELLER_TYPE = "dealsSeller";
  static SETTLEMENT_BUYER_TYPE = "settlementBuyer";
  static SETTLEMENT_SELLER_TYPE = "settlementSeller";
  static MOVING_IN_SERVICES_TYPE = 'movingInServices';
  static NEW_CONSTRUCTION_TYPE = 'newConstruction';
  static FORECLOSURE_TYPE = 'foreclosure';
  static COMMERCIAL_TAKEOVER_TYPE = 'takeoverCommercial';
  static MOVING_IN_TYPE = 'movingIn';
  static MOVING_OUT_TYPE = 'movingOut';

  constructor() {
    this.id = null;
    this.estateId = null;
    this.ticketId = null;
    this.password = null;
    this.token = null;
    this.type = null;
    this.active = null;
    this.submitted = null;
    this.summaryCreated = null;
    this.emailSent = null;
    this.submittedDate = null;
    this.created = null;
    this.modified = null;
  }
}
