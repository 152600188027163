import React from "react";

import { Link } from "react-router-dom";

import Close from "@material-ui/icons/Close";

export default function AppTab(props) {
  const { to, title, closeHandler, currentUrl } = props;

  let className = "";

  if (to === currentUrl) {
    className = "current";
  }

  return (
    <Link className={className} to={to} tabIndex={-1}>
      <div className="text">{title}</div>
      <div className="icon" onClick={closeHandler}>
        <Close />
      </div>
    </Link>
  );
}
