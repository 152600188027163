import React from "react";

// app specific components
import CustomerSection from "views/estates/components/CustomerSection";
import EstateDetailsSection from "views/estates/components/EstateDetailsSection";

export default function ProtocolOrderInfoArea(props) {

  const {powerOrderEstate} = props;
  const {estate} = powerOrderEstate;

  return (

    <React.Fragment>

      <div className="View-Estate-Styling">
        <h4>Oppdrag informasjon</h4>
          <a target="_blank"
             href={'https://zapdos.meglerfront.no/api/internal/estate/re-fetch/' + estate.id}>Update fra meglersystemer</a>
        <br />
        <a target="_blank"
           href={'https://zapdos.meglerfront.no/nova/resources/estates/' + estate.id}>Open in Nova</a>
        <CustomerSection estate={estate} />
        <EstateDetailsSection estate={estate} />
      </div>

    </React.Fragment>
  );
}
