

import { FETCH_OTHER_PRODUCT_RESULTS_START } from "../actions/types";
import { FETCH_OTHER_PRODUCT_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_OTHER_PRODUCT_RESULTS_ERROR } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";



const listingDataTemplate = {
  loading: false,
  error: null,
  serviceProducts: null
}


const createInitialState = ()=> ({

  listingData: {
    ...listingDataTemplate
  }
});


export default function(state = createInitialState(), action) {

  switch (action.type) {

    case FETCH_OTHER_PRODUCT_RESULTS_START: {
      console.log("Other products action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_OTHER_PRODUCT_RESULTS_SUCCESS: {
      console.log("Other products action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        serviceProducts: action.payload.data.serviceProducts
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_OTHER_PRODUCT_RESULTS_ERROR: {
      console.log("Other products action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }


    //-------


    case APP_LOGOUT_SUCCESS: {
      console.log("Other products action", action.type, action);

      return createInitialState();
    }
    default:
      return state;
  }
}
