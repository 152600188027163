import {HTTP_OK, HTTP_BAD_REQUEST, HTTP_NOT_AUTHORIZED, HTTP_PRECONDITION_FAILED, HTTP_PRECONDITION_REQUIRED} from "constants.js";
import PowerOrder from "classes/PowerOrder/PowerOrder";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";
import PowerOrderBuyer from "classes/PowerOrder/PowerOrderBuyer";
import PowerOrderSeller from "classes/PowerOrder/PowerOrderSeller";
import PowerOrderMeter from "classes/PowerOrder/PowerOrderMeter";
import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";

export const createTabTitle = (powerOrder) => {
  let title = "Ingen adresse";
  let task = powerOrder.task;

  if (task !== null && task.streetAddress !== "" && task.streetAddress !== null) {
    title = task.streetAddress;

    if (task.postCode !== null && task.postLocation !== null) {
      title += ` - ${task.postCode} ${task.postLocation}`;
    }
  }

  return title;
};


export const validateStatus = (status) => {
   return status == HTTP_OK || status == HTTP_BAD_REQUEST || status==HTTP_NOT_AUTHORIZED || status==HTTP_PRECONDITION_FAILED || status==HTTP_PRECONDITION_REQUIRED;
}

export const buildPaginationData = (currentPage,totalPages,searchFunction)=>{
  
  const data = [];

  const minPage = currentPage-2;
  const maxPage = currentPage+2;

  if (totalPages>1) {
    data.push({text: "⇤", onClick:()=>searchFunction(1)});  
  }

  if (currentPage>1) {
    data.push({text: "←", onClick:()=>searchFunction(currentPage-1)});  
  } else {
    data.push({text: "←", disabled:true,onClick: null});  
  }

  for (let i=1;i<=totalPages;i++){

    if (i>=minPage && i<=maxPage) {
      if (i==currentPage) {
        data.push({text: i, active: true,onClick:()=>searchFunction(i)});
      } else {
        data.push({text: i, onClick:()=>searchFunction(i)});
      }        
    }
  }

  if (currentPage<totalPages) {
    data.push({text: "→", onClick:()=>searchFunction(currentPage+1)});  
  } else {
    data.push({text: "→", disabled:true, onClick: null});  
  }    

  if (totalPages>1) {
    data.push({text: "⇥", onClick:()=>searchFunction(totalPages)});  
  }    

  return data;
}

export const isTemporaryOrderId = id => {
  if (typeof(id)=="string" && id.startsWith("_")){
    return true;
  } 

  return false;
}

export const isOrderFromEmail = powerOrder => {

  if (powerOrder.powerOrderEmail!==null) {
    return true;
  }

  return false;
}

export const createNewPowerOrder = temporaryOrderId => {
  const newPowerOrder = new PowerOrder();
  newPowerOrder.id = temporaryOrderId;
  newPowerOrder.powerOrderStatusType=PowerOrderStatusType.createUntreated();
  newPowerOrder.powerMeters.push(new PowerOrderMeter());

  const customerType = PowerOrderCustomer.PERSON_CUSTOMER_TYPE;
  newPowerOrder.buyer = new PowerOrderBuyer(temporaryOrderId, customerType);
  newPowerOrder.seller = new PowerOrderSeller(temporaryOrderId, customerType);

  return newPowerOrder;
}