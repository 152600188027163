import React from "react";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific components
import { convertNullToString as _ } from "utils.js";

export default function PowerCompanyForm({ powerCompany, classes, setEditMode }) {
  return (
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          Edit power company: <big>{powerCompany.name}</big>
        </h4>
      </CardHeader>
      <CardBody>
        <form>
          <CustomInput
            labelText="Navn"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: _(powerCompany.name) }}
          />

          <CustomInput
            labelText="Email to"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: _(powerCompany.emailTo) }}
          />

          <CustomInput
            labelText="Email cc"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: _(powerCompany.emailCc) }}
          />

          <CustomInput
            labelText="API type"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: _(powerCompany.apiService) }}
          />
        </form>
      </CardBody>

      <CardFooter style={{ justifyContent: "space-between" }}>
        <Button onClick={(e) => setEditMode(false)}>Avbtryt</Button>
        <Button color="danger" disabled={true}>
          Lagre
        </Button>
      </CardFooter>
    </Card>
  );
}
