import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";



// @material-ui/core components
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// theme resources
import Primary from "components/Typography/Primary";

// app resources
import {formatRelativeDateTime, formatDateTime} from "utils.js";
import {setSelectedEmailIds} from "actions/emailActions";

// component resources
import InspectedFilter from "./InspectedFilter.js";
import ResponsibleUserFilter from "./ResponsibleUserFilter.js";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);

export default function EmailListTable(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { loggedUser, history, tableHead, tableData, responsibleUserMode } = props;

  const selectedEmailIds = useSelector(state => {
    return state.emails.listingData.selectedEmailIds;
  });  

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const tableCellClasses = classes.tableCell;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table +' ' + 'Email-List-Table'}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [classes.tableHeadFontSize]: true
                  });

                  /*
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );*/

                if (prop=="Status") {
                  return (
                    <TableCell className={tableCellClasses} key={key}>
                      <InspectedFilter />
                    </TableCell>
                    )
                } else if (prop=="Ansvarlig") {
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <ResponsibleUserFilter />
                        </TableCell>
                      )                    
                  }  else {
                  return <TableCell className={tableCellClasses} key={key}>{prop}</TableCell>
                } 

              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {

            let isSelected = responsibleUserMode && selectedEmailIds.includes(item.id);

            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: true,
              [classes.tableStripedRow]: key % 2 === 0,
              "Selected-Order": isSelected
            });

            return (
              <TableRow
                key={key}
                hover={true}
                className={classes.tableRow + " " + tableRowClasses}
                onClick={e => history.push("/email/" + item.id)}>

                <TableCell className={tableCellClasses + ' ' + 'Email-Id-Cell'} onClick={e=>{

                      if (responsibleUserMode==false) return;

                      const shiftPressed = e.shiftKey;

                      let newSelectedEmailIds = [...selectedEmailIds];

                      if (isSelected){
                          let index = newSelectedEmailIds.indexOf(item.id);
                          if (index > -1) {
                            newSelectedEmailIds.splice(index, 1);
                          }

                          setLastSelectedIndex(null);
                      } else {
                        newSelectedEmailIds.push(item.id);

                        if (shiftPressed==false) {
                          setLastSelectedIndex(key);
                        }

                        if (shiftPressed==true && lastSelectedIndex!==null) {

                          if (key>lastSelectedIndex) {
                            for (let i=lastSelectedIndex+1;i<key;i++) {

                              if (selectedEmailIds.includes(tableData[i].id) === false) {
                                newSelectedEmailIds.push(tableData[i].id);
                              }
                            }
                          }                          
                        }
                      }

                      dispatch(setSelectedEmailIds(newSelectedEmailIds));
                      e.stopPropagation();
                  }}>
                    {responsibleUserMode==true && isSelected==false && <CheckBoxOutlineBlankIcon />}
                    {responsibleUserMode==true && isSelected==true && <Primary><CheckBoxIcon /></Primary>}
                    {responsibleUserMode==false && item.id}
                  </TableCell>
                <TableCell className={tableCellClasses}>

                    {item.isLocked==true && item.lockedByUserId!==loggedUser.id && <div style={{textAlign:"center",fontWeigth:"100",color:"red"}}><strong>{item.lockedByName}</strong> <br /><em>{formatRelativeDateTime(item.lockedAt)}</em></div>}
                    {item.isLocked==true && item.lockedByUserId==loggedUser.id && <div style={{textAlign:"center",fontWeigth:"100",color:"green"}}><strong>{item.lockedByName}</strong> <br /><em>{formatRelativeDateTime(item.lockedAt)}</em></div>}

                </TableCell>
                <TableCell className={tableCellClasses} style={{width:"30%"}}>{item.subject}</TableCell>
                <TableCell className={tableCellClasses}>{item.from}</TableCell>
                <TableCell className={tableCellClasses}>{item.attachmentCount}</TableCell>
                <TableCell className={tableCellClasses}>{formatDateTime(item.receivedAt)}</TableCell>
                <TableCell className={tableCellClasses}>
                  {item.inspected===false && <span className="Email-Not-Inspected">Ubehandlet</span>}
                  {item.inspected===true && <span className="Email-Inspected">Behandlet</span>}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

EmailListTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array
};
