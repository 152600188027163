import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";


// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// core components
import Button from "components/CustomButtons/Button.js";

// app specific styles and graphics
import "app_assets/scss/Emails.scss";
import attachmentsColumnTitleIcon from "app_assets/img/column_title_icon1.png";
import ordersColumnTitleIcon from "app_assets/img/column_title_icon2.png";

// functions
import {createTabTitle} from "functions/emailFunctions";


// common components
import LoadingPage from "views/common/LoadingPage";
import ErrorPage from "views/common/ErrorPage";

// action creators
import {pushTab} from "actions/tabActions";
import { fetchEmailItemStart } from "actions/emailActions";
import { fetchEmailItemSuccess } from "actions/emailActions";
import { fetchEmailItemError } from "actions/emailActions";
import { discardEmailItem } from "actions/emailActions";
import { reloadEmailResults } from "actions/emailActions";
import {replaceTemporaryOrderId} from "actions/powerOrderActions";

// app specific resources
import Constants from "../../constants";
import { createRandomOrderId } from "utils.js";
import { useScrollIntoView } from "hooks.js";
import { createRandomNotificationId } from "utils.js";
import { formatRelativeDateTime } from "utils.js";
import { CLOSE_NOTIFICATION, PUSH_NOTIFICATION } from "actions/types";
import { DRAG_AND_DROP_ATTACHMENT } from "actions/types";
import { DELETE_NEW_ORDER_FROM_EMAIL } from "actions/types";
import { ADD_NEW_ORDER_TO_EMAIL } from "actions/types.js";
import { DROPBOX_DROP_AREA } from "./components/constants.js";
import { EMAIL_ATTACHMENT_AREA } from "./components/constants.js";
import { ORDER_ATTACHMENT_AREA } from "./components/constants.js";
import ProcessingStatusType  from "classes/ProcessingStatusType.js";

// app specific components
import ColumnTitle from "./components/ColumnTitle";
import DropboxArea from "./components/DropboxArea";
import EmailInfoArea from "./components/EmailInfoArea";
import FormTabHeader from "./components/FormTabHeader";
import NewOrderArea from "./components//NewOrderArea";
import EmailAttachmentArea from "./components/EmailAttachmentArea";
import SinglePageFilePreview from "./components/SinglePageFilePreview";
import MultiPageFilePreview from "./components/MultiPageFilePreview";
import GenericFilePreview from "./components/GenericFilePreview";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";

// actions
import {addNewPowerOrder} from "actions/powerOrderActions";
import {updateEmailItemLock} from "actions/emailActions";


// functions
import {createNewPowerOrder} from "functions/powerOrderFunctions";

// other app resources
import PowerOrderEmail from "classes/PowerOrder/PowerOrderEmail";
import PowerOrderEmailAttachment from "classes/PowerOrder/PowerOrderEmailAttachment";
import PowerOrderSourceType from "classes/PowerOrder/PowerOrderSourceType";

import {isTemporaryOrderId} from "functions/powerOrderFunctions";


export default function(props) {

  const PAGE_TITLE = "Epost";


  // --------- HOOKS ---------

  const dispatch = useDispatch();

  useEffect(() => {
    useScrollIntoView();

    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };    
  }, []);

    const [previewAttachment,setPreviewAttachment] = useState(null);

  // --------- PROPS ---------

  const id = props.computedMatch.params.id;
  const { history } = props;

  // --------- SELECTORS ---------


  const workingDataItem = useSelector(state => {
    return state.emails.workingData[id] || null;
  });

  const orderWorkingData = useSelector(state=>{

    return state.powerOrders.workingData;
  });



  // store a reference to every attachment from this email
  // that was added to a new power order

  const connectedOrderIds = (workingDataItem != null) ? workingDataItem.connectedOrderIds : null;

  console.log("connectedOrderIds",connectedOrderIds,orderWorkingData);  

  const orderAttachmentMap = {};
  const newOrdersMap = {};

  if (connectedOrderIds != null) {   

    for (let i in connectedOrderIds) {
      const orderId = connectedOrderIds[i];

      const orderWorkingDataItem = orderWorkingData[orderId];

      if (orderWorkingDataItem!=null && orderWorkingDataItem.entity != null) {

        orderAttachmentMap[orderId] = [];

        orderWorkingDataItem.entity.emailAttachments.forEach(powerOrderAttachment=>{
          orderAttachmentMap[orderId].push(powerOrderAttachment.attachment);
        });

        newOrdersMap[orderId] = orderWorkingDataItem.entity;
      }

    }    
  }

  const loggedUserId = useSelector(state => state.auth.authInfo.loggedUser.id);

  //console.log("Email loading data:", workingDataItem);
  console.log("orderAttachmentMap:", orderAttachmentMap);
  console.log("newOrdersMap:", newOrdersMap);


  // --------- COMPONENT FUNCTIONS ---------


  const fetchItem = id => {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + id + "?set_lock=true";
    console.log(`GET ${url}`);

    dispatch(fetchEmailItemStart(id));

    axios
      .get(url)
      .then( response => {

        console.log("AJAX RESPONSE", response);
        dispatch(fetchEmailItemSuccess(id, response.data));
        dispatch(updateEmailItemLock(id, response.data.lock));
      })
      .catch(error => {

        console.log(error);
        dispatch(fetchEmailItemError(id, error));
      })
  };



  const handleDoneClick = e => {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + id + "/inspected";
    console.log(`PATCH ${url}`);

    axios
      .patch(url,{value: true})
      .then( response => {

        console.log("AJAX RESPONSE", response);

        dispatch(reloadEmailResults());

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: <span>Email <em><strong>{workingDataItem.entity.subject}</strong></em> is processed</span>
          }
        });

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 3000);        

        history.push("/emails");

        dispatch(discardEmailItem(id));
      })
      .catch(error => {

        console.log(error);
      })
  };

  const handleDropboxAreaOnDrop = (dropItem, email) => {
    console.log("dropped attachment",dropItem);
    const { attachment } = dropItem;

    const temporaryOrderId = createRandomOrderId();

    const newPowerOrder = createNewPowerOrder(temporaryOrderId);
    
    newPowerOrder.powerOrderSourceType=PowerOrderSourceType.createEmailSourceType();
    newPowerOrder.source = PowerOrderSourceType.EMAIL;

    const powerOrderEmailAttachment = new PowerOrderEmailAttachment(temporaryOrderId, attachment);
    newPowerOrder.emailAttachments.push(powerOrderEmailAttachment);

    const powerOrderEmail = new PowerOrderEmail(temporaryOrderId, attachment.emailId);
    powerOrderEmail.email = email;
    newPowerOrder.powerOrderEmail = powerOrderEmail;

    console.log("Add temporary order from attachment with id= " + temporaryOrderId);

    dispatch(addNewPowerOrder(newPowerOrder));   

    /*
     * An attachment was dropped in "dropbox" area to create a new order.
     *
    */
    dispatch({
      type: DRAG_AND_DROP_ATTACHMENT,
      payload: {
        dropArea: DROPBOX_DROP_AREA,
        dropType: dropItem.type,
        attachment,
        temporaryOrderId
      }
    });

    return dropItem;
  };

  const handleEmailAreaOnDrop = dropItem => {
    //console.log("move back attachment",dropItem);
    // eslint-disable-next-line no-unused-vars
    const { type, attachment } = dropItem;

    /*
     * An attachment was moved from new orders back to email interactive area
     *
    */
    dispatch({
      type: DRAG_AND_DROP_ATTACHMENT,
      payload: {
        dropArea: EMAIL_ATTACHMENT_AREA,
        attachment
      }
    });

    return dropItem;
  };

  const handleCreateOrder = e => {
    const email  = workingDataItem.entity;

    const temporaryOrderId = createRandomOrderId();

    const newPowerOrder = createNewPowerOrder(temporaryOrderId);
    
    newPowerOrder.powerOrderSourceType=PowerOrderSourceType.createEmailSourceType();
    newPowerOrder.source = PowerOrderSourceType.EMAIL;

    const powerOrderEmail = new PowerOrderEmail(temporaryOrderId, email.id);
    powerOrderEmail.email = email;
    newPowerOrder.powerOrderEmail = powerOrderEmail;

    console.log("Add temporary order without attachment with id= " + temporaryOrderId);

    dispatch(addNewPowerOrder(newPowerOrder));       

    dispatch({
      type: ADD_NEW_ORDER_TO_EMAIL,
      payload: {
        emailId: email.id,
        temporaryOrderId
      }
    });    
  }

  // --------- INIT ---------

  

  if (workingDataItem == null) {
    fetchItem(id);
  }

  if (workingDataItem && workingDataItem.entity!=null) {

    const title = createTabTitle(workingDataItem.entity);

    dispatch(pushTab(title, "/email/"+id, discardEmailItem(id)));  

    // check if any order was just saved
    for (let i in workingDataItem.connectedOrderIds) {
      const connectedOrderId = workingDataItem.connectedOrderIds[i];
      const connectedOrder = newOrdersMap[connectedOrderId];

      if (isTemporaryOrderId(connectedOrderId) && connectedOrderId != connectedOrder.id) {
        
        // replace the temporary id key with the real id after save
        dispatch(replaceTemporaryOrderId(connectedOrderId, connectedOrder.id));
        
        // skip rendering if store update is in progress
        return <LoadingPage />;
      }
    }
  }  

  // TEMPORARY
  if (workingDataItem) {
    console.log("current workingDataItem:",workingDataItem);
  }

  // --------- RENDERING ---------

  const renderLoading = () => <LoadingPage />;

  const renderError = () => <ErrorPage errorMessage={workingDataItem.error.error.message} />;

  const renderNotProcessed = () => <ErrorPage errorMessage="This email is not processed" />;

  const renderSuccess = () => {

    const email = workingDataItem.entity;

    const lock = email.lock;
    const isLocked = lock !==null ? true : false;
    const ownedLock = isLocked && lock.userId===loggedUserId;

    return (
  
      <DndProvider backend={Backend}>
        <div className="Email-Handling-Grid-Layout" key={workingDataItem.entity.id}>
          <div className="Info-Area">
  

            {isLocked && ownedLock==false &&
            <SnackbarContent
              message={
                <span>Email locked by <strong>{lock.lockedByName}</strong>  {formatRelativeDateTime(lock.createdAt)}</span>
              }
              icon={AddAlert}
              color="rose"
            />}   

  
          <div className="Info-Header">
  
            
            <h3>Epost</h3>
            
          </div>        
            <EmailInfoArea email={workingDataItem.entity} />
          </div>
  
  
          <div className="Header-Tabs-Area">
            <FormTabHeader currentEmail={true} connectedOrderIds={workingDataItem.connectedOrderIds} emailId={workingDataItem.entity.id} />
  
          </div>
  
          <div className="Work-Area-1">
            
  
            <ColumnTitle title="Attachments" icon={attachmentsColumnTitleIcon} />
  
            <EmailAttachmentArea setPreviewAttachment={setPreviewAttachment} unsortedAttachments={workingDataItem.unsortedAttachments} onDrop={handleEmailAreaOnDrop} />
  
            <DropboxArea onDrop={dropItem=>handleDropboxAreaOnDrop(dropItem,workingDataItem.entity)} />
          </div>
  
  
  
          <div className="Work-Area-2">
            <div className="Form-Tab-Header"></div>
  
            <ColumnTitle title="Orders created from this email" icon={ordersColumnTitleIcon} handleCreateOrder={handleCreateOrder} />
  
            {workingDataItem.connectedOrderIds.map((temporaryOrderId, index) => (
              <NewOrderArea
                setPreviewAttachment={setPreviewAttachment}
                key={index}
                index={index}
                powerOrder={newOrdersMap[temporaryOrderId]}
                temporaryOrderId={temporaryOrderId}
                onDelete={()=>{
                  dispatch({
                    type: DELETE_NEW_ORDER_FROM_EMAIL,
                    payload: {
                      temporaryOrderId,
                      emailId: workingDataItem.entity.id,
                      attachments: orderAttachmentMap[temporaryOrderId]
                    }
                  });                
                }}
                onDrop={dropItem => {
                  // eslint-disable-next-line no-unused-vars
                  const { type, attachment } = dropItem;
  
                  /*
                   * An attachment was dropped in a new order area.
                   * The attachment was dragged from one of these two areas:
                   *   - the email interactive area
                   *   - any new order area
                   */
                  dispatch({
                    type: DRAG_AND_DROP_ATTACHMENT,
                    payload: {
                      dropArea: ORDER_ATTACHMENT_AREA,
                      attachment,
                      temporaryOrderId
                    }
                  });
  
                  return dropItem;
                }}
                expanded={true}
              />
            ))}
            <div style={{textAlign:"right",marginTop:"100px"}}>
              <Button round color="info" onClick={handleDoneClick}>
                Ferdig
              </Button>
            </div>
          </div>
  
          {previewAttachment!=null && previewAttachment.pageCount==0 && <GenericFilePreview attachment={previewAttachment} onClose={e=>setPreviewAttachment(null)} />}
  
          {previewAttachment!=null && previewAttachment.pageCount==1 && <SinglePageFilePreview attachment={previewAttachment} onClose={e=>setPreviewAttachment(null)} />}
  
          {previewAttachment!=null && previewAttachment.pageCount>1 && <MultiPageFilePreview attachment={previewAttachment} onClose={e=>setPreviewAttachment(null)} />}
  
  
  
  
        </div>
      </DndProvider>
    );
  }

  if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {

    if (workingDataItem.entity.processingStatus==ProcessingStatusType.PROCESSING_COMPLETED) {
      return renderSuccess();  
    } else {
      return renderNotProcessed();
    }
    
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );  
}
