import React from "react";

import Button from "components/CustomButtons/Button.js";

import "app_assets/scss/FormTabHeader.scss";

export default function ColumnTitle({ title, icon, handleCreateOrder }) {
  return (
    <div className="Column-Title">
      <img src={icon} />
      {title}

      {handleCreateOrder &&
      <div style={{float:"right",display:"inline-block",position:"relative",bottom:"5px"}}>
        <Button style={{padding:"5px 10px 5px 10px"}} color="white" size="sm" onClick={handleCreateOrder}>+</Button>
      </div>}
    </div>
  );
}
