import React from 'react';
import {Route, Redirect} from 'react-router-dom';

class PublicRoute extends Route {

	render() {
		let isLogged = false;

		return isLogged ? <Redirect to='/' /> : this.renderComponentWithLayout();
	}

	renderComponentWithLayout(){
		let history=this.props.history;
		let component = this.props.component;
		let layout = this.props.layout;
		let layoutProps = { 
			component,
			history 
		};

		return React.createElement(layout,layoutProps);
	}

}


export default PublicRoute;

