import React from 'react';


// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";

// core components
import Button from "components/CustomButtons/Button.js";

// app assets
import historyLightIcon from "app_assets/img/metro-history-light-icon.png";
import historyDarkIcon from "app_assets/img/metro-history-dark-icon.png";

import emailIcon from "app_assets/img/simple-email-icon.png";
import pdfIcon from "app_assets/img/awesome-file-pdf-icon.png";

// functions
import {isTemporaryOrderId} from "functions/powerOrderFunctions";

export default function CommonOrderInfoArea({powerOrder,refreshHandler,viewOrderHistory,setViewOrderHistory}){
  
  const existingOrder = isTemporaryOrderId(powerOrder.id)==false;

  return (
      <div className="Order-Info-Header">

        <div className="Title">
          <h3>Ordre {powerOrder.powerOrderSourceType && <small>({powerOrder.powerOrderSourceType.sourceNameNo})</small>}</h3>
        </div>

        <div className="Buttons">
          
          {existingOrder && viewOrderHistory==true && <Button color="success" onClick={e=>setViewOrderHistory(false)}><img src={historyLightIcon} /></Button>}
          {existingOrder && viewOrderHistory==false && <Button color="white" onClick={e=>setViewOrderHistory(true)}><img src={historyDarkIcon} /></Button>}


          {false &&
          <Button color="info">
            <img src={emailIcon} />
          </Button>}
          {false && <Button color="danger">
            <img src={pdfIcon} />
          </Button>}

          {existingOrder && 
          <Button color="white" onClick={refreshHandler}>
            <Refresh />
          </Button>}
        </div>
      </div>    
    )

}