import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


// app resources
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";
import { createRandomNotificationId } from "utils.js";

import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_START } from "actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_ERROR } from "actions/types";

import { UPDATE_BROKER_OFFICE_ACCOUNTING_SETTINGS } from "actions/types";

const BrokerOfficeAccountingSettingsTab = ({brokerOffice, classes}) => {


  const id = brokerOffice.id;

  const dispatch = useDispatch();

  const accountingSettingsTabDataItem = useSelector((state) => {
    return state.brokerOffices.accountingSettingsTabData[id] || null;
  });

  console.log("Broker office accounting settings tab data item:", accountingSettingsTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/accounting_settings";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_ACCOUNTING_SETTINGS_ERROR,
          payload: { id, error }
        });
      });
  };

  if (accountingSettingsTabDataItem == null) {
    fetchItem(id);
  }

  let settings = accountingSettingsTabDataItem != null ? accountingSettingsTabDataItem.entity: null;

  if (settings == null) {
    settings = {
      brokerOfficeId: id,
  
      bankidChargesEnabled: true   
    }
  }

  const updateBrokerOfficeAccountingSettings = (entity) => {

    dispatch({
      type: UPDATE_BROKER_OFFICE_ACCOUNTING_SETTINGS,
      payload: { id, entity }
    });    
  }
  
  const save = () => {


    console.log("Save data", settings);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/accounting_settings";

    console.log(`POST ${url}`);

    axios
      .post(url, settings)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        updateBrokerOfficeAccountingSettings(response.data);

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Save successful"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 3000);            
      })
      .catch(error => {

        console.log(error);

        // TODO add error notification
      })   

      
  }



  const onCheckboxChange = e => {
    const value = e.target.checked;
    const name = e.target.name;

    console.log("Change field", name, value);

    settings[name] = value;
    updateBrokerOfficeAccountingSettings(settings);      
  }


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {accountingSettingsTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (
    <Card>
      <GridContainer style={{ marginTop: "25px",paddingLeft:"20px",paddingRight:"20px" }}>
        <GridItem md={4}>
          

          <div className={classes.checkboxAndRadio}>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{name: "bankidChargesEnabled"}}
                  onChange={onCheckboxChange}
                  checked={settings.bankidChargesEnabled}
                  tabIndex={-1}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="BankID kostnad"
            />
          </div>
                    

        </GridItem>

        <GridItem md={4}>

               


        </GridItem>


      

      </GridContainer>

      <GridContainer style={{ paddingLeft:"20px",paddingRight:"20px" }}>
        <GridItem xs={6} sm={6} md={6}>

        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <div style={{ marginTop: "25px", textAlign: "right" }}>
            <Button color="rose" onClick={e=>save()}>Lagre</Button>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );


  if (accountingSettingsTabDataItem == null) {
    return renderLoading();
  } else if (accountingSettingsTabDataItem.loading == true) {
    return renderLoading();
  } else if (accountingSettingsTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }    
}

export default BrokerOfficeAccountingSettingsTab;