import React, {useState} from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";

// theme components
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app components
import {formatDateTime} from "utils.js";

const useStyles = makeStyles(styles);

export default function AccountingPage() {
    
  const [companyChainList, setCompanyChainList] = useState(null);
  const [currentCompanyChainId, setCurrentCompanyChainId] = useState(null);
  const [brokerOfficeList, setBrokerOfficeList] = useState(null);
  const [earningPerOffice, setEarningPerOffice] = useState(false);
  const [balanceMap, setBalanceMap] = useState(null);


  const UNTIL_2020 = {
      "from":"01.01.2015",
      "to": "31.12.2020"
  };

  const YEAR_2021 = {
      "from":"01.01.2021",
      "to": "31.12.2021"
  };

  const YEAR_2022 = {
      "from":"01.01.2022",
      "to": "31.12.2022"
  };

  //let firstDayOfTheYear = moment().startOf('year').format("DD.MM.YYYY");
  let firstDayOfTheYear = "01.01.2022";
  let date45DaysAgo = moment().subtract(45, 'd').format("DD.MM.YYYY");

  const AUTO_INTERVAL = {
      "from":firstDayOfTheYear,
      "to": date45DaysAgo
  };

  const [currentInterval, setCurrentInterval] = useState(AUTO_INTERVAL);

  const classes = useStyles();






  const searchBrokerChains = () => {

      const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chains";

      console.log(`GET ${url}`);

      axios
        .get(url)
        .then( response => {
          // handle success
          console.log("AJAX RESPONSE", response);
          setCompanyChainList(response.data);
          loadBalanceMap();
        })
        .catch(error => {
          console.error("An error has occured during ajax request:",error);
        })


    };



  const searchBrokerOffices = (companyChainFilter) => {

    let urlParams = {};

    urlParams["company_chain_id"] = companyChainFilter;
   

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_offices";

    console.log(`GET ${url}`, urlParams);



    axios
      .get(url, {params: urlParams})
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setBrokerOfficeList(response.data);
        loadBalanceMap(companyChainFilter);

        
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

      })
  };

  const loadBalanceMap = (companyChainFilter=null) => {

    let urlParams = {};

    urlParams["company_chain_id"] = companyChainFilter;
    urlParams["from"] = currentInterval.from;
    urlParams["to"] = currentInterval.to;
   

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/balances";

    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setBalanceMap(response.data);

        
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

      })
  };

  if (companyChainList==null) {
    searchBrokerChains();
  }  


  const buildBrokerOfficeViewUrl = (brokerOfficeId) => {
    return process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/broker_office/" + brokerOfficeId + "/view" + `?from=${currentInterval.from}&to=${currentInterval.to}`;
  }

  const buildBrokerOfficeDownloadUrl = (brokerOfficeId) => {
    return process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/broker_office/" + brokerOfficeId + "/download" + `?from=${currentInterval.from}&to=${currentInterval.to}`;
  }

  const buildBrokerOfficeAttachmentDownloadUrl = (brokerOfficeId,transactionId,attachmentId) => {

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + brokerOfficeId + "/transactions/" + transactionId + "/attachments/" + attachmentId + "/download";

    return url;
  }
  
  const buildBrokerOfficeDetailsUrl = (brokerOfficeId) => {
    return process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/broker_office/" + brokerOfficeId + "/details" + `?from=${currentInterval.from}&to=${currentInterval.to}`;
  }

  const buildCompanyChainViewUrl = (companyChainId) => {
    
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/company_chain/" + companyChainId + "/view";

    url += "?earning_per_office=" + (earningPerOffice ? "yes" : "no");
    url += `&from=${currentInterval.from}&to=${currentInterval.to}`

    return url;
  }

  const buildCompanyChainDownloadUrl = (companyChainId) => {
    
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/company_chain/" + companyChainId + "/download";

    url += "?earning_per_office=" + (earningPerOffice ? "yes" : "no");
    url +=`&from=${currentInterval.from}&to=${currentInterval.to}`

    return url;
  }

  const buildCompanyChainAttachmentDownloadUrl = (companyChainId,transactionId,attachmentId) => {

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChainId + "/transactions/" + transactionId + "/attachments/" + attachmentId + "/download";

    return url;
  }

  const buildCompanyChainDetailsUrl = (companyChainId) => {
    return process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/reports/company_chain/" + companyChainId + "/details" + `?from=${currentInterval.from}&to=${currentInterval.to}`;
  }

  const formatBalance = (value) => {
    if (value=="0") {
      return <span>{value} kr</span>;
    }

    return <span style={{background:"red",color:"white",padding:"2pt 5pt"}}><strong>{value} kr</strong></span>;
  }


  const fromDateChangeHandler = value => {
    if (typeof value == "object") {
      const dmyString = moment(value.toDate()).format("DD.MM.YYYY");
      const newInterval = {from: dmyString,to:currentInterval.to};
      setCurrentInterval(newInterval);
      setCompanyChainList(null);
    }
  }

  const toDateChangeHandler = value => {
    if (typeof value == "object") {
      const dmyString = moment(value.toDate()).format("DD.MM.YYYY");
      const newInterval = {from: currentInterval.from, to:dmyString};
      setCurrentInterval(newInterval);
      setCompanyChainList(null);
    }
  }  

  const addCompanyChainFileTransaction = companyChainId => {
    console.log("addCompanyChainFileTransaction",companyChainId);

    companyChainList.map( (item,index) => {
      if (item.id==companyChainId){
        item._loading = true;
      }
    });

    setCompanyChainList([...companyChainList]);

    let urlParams = {};

    urlParams["earning_per_office"] = earningPerOffice;
   

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/balances/company_chain/" + companyChainId + "/file_transactions";
    url += "?earning_per_office=" + (earningPerOffice ? "yes" : "no");
    url += `&from=${currentInterval.from}&to=${currentInterval.to}`

    console.log(`POST ${url}`);

    axios
      .post(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        const newFileTransaction  = response.data.transaction;
        balanceMap.companyChains[companyChainId].fileRecords.push(newFileTransaction);

        const newAttachment = response.data.attachment;
        const downloadUrl = buildCompanyChainAttachmentDownloadUrl(companyChainId, newAttachment.transactionId,newAttachment.id);
        window.open(downloadUrl,'_blank');

      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

      })
      .finally(()=>{
        companyChainList.map( (item,index) => {
          if (item.id==companyChainId){
            item._loading = false;
          }
        });

        setCompanyChainList([...companyChainList]);
      })

  }


  const addBrokerOfficeFileTransaction = brokerOfficeId => {
    console.log("addBrokerOfficeFileTransaction",brokerOfficeId);

    brokerOfficeList.map( (item,index) => {
      if (item.id==brokerOfficeId){
        item._loading = true;
      }
    });

    setBrokerOfficeList([...brokerOfficeList]);

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/accounting/balances/broker_office/" + brokerOfficeId + "/file_transactions";
    url += `?from=${currentInterval.from}&to=${currentInterval.to}`

    console.log(`POST ${url}`);

    axios
      .post(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        const newFileTransaction  = response.data.transaction;
        balanceMap.brokerOffices[brokerOfficeId].fileRecords.push(newFileTransaction);

        const newAttachment = response.data.attachment;
        const downloadUrl = buildBrokerOfficeAttachmentDownloadUrl(brokerOfficeId, newAttachment.transactionId,newAttachment.id);
        window.open(downloadUrl,'_blank');

      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

      })
      .finally(()=>{
        brokerOfficeList.map( (item,index) => {
          if (item.id==brokerOfficeId){
            item._loading = false;
          }
        });

        setBrokerOfficeList([...brokerOfficeList]);
      })

  }

  return (
    <div className="Standard-Page-Content">

        <GridContainer>

          <GridItem>
            <Link to="/accounting">
              <Button color="primary">Økonomi</Button>
            </Link>

            <Link to="/accounting/dashboard">
              <Button>Dashboard</Button>
            </Link>
          </GridItem>

        </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>

            <CardBody>

             <div style={{backgroundColor:"#eee",padding:"1em",marginBottom:"2em",fontSize:"large"}}>
              <strong>OBS!</strong> The amount visible for each line is <strong>strictly the balance for selected interval</strong>, so it doesn't show 
              any historical negative balance(any time), or any historical positive balance(from the same year). 
              Please use <strong>preview functionality</strong>(simple or pdf) to see the final calculated value that will be used in the generated report.
             </div>


             <FormControl style={{marginLeft:"0px",marginTop:"10px",display:"inline-block",width:"200px"}}>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={moment(currentInterval.from,'DD.MM.YYYY')} onChange={fromDateChangeHandler} inputProps={{ placeholder: "Fra",readOnly: true }} />
              </FormControl>

             <FormControl style={{marginLeft:"20px",marginTop:"10px",display:"inline-block",width:"200px"}}>
                <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={moment(currentInterval.to,'DD.MM.YYYY')} onChange={toDateChangeHandler} inputProps={{ placeholder: "Til",readOnly: true }} />
              </FormControl>

              <FormControl style={{marginLeft:"50px",display:"inline-block"}}>
                <Button color={ (currentInterval.from==UNTIL_2020.from && currentInterval.to==UNTIL_2020.to) ? "primary" : null} onClick={e=>{setCurrentInterval(UNTIL_2020);setCompanyChainList(null)}}>til 2020</Button>
                <Button color={ (currentInterval.from==YEAR_2021.from && currentInterval.to==YEAR_2021.to) ? "primary" : null} onClick={e=>{setCurrentInterval(YEAR_2021);setCompanyChainList(null)}}>2021</Button>
                <Button color={ (currentInterval.from==YEAR_2022.from && currentInterval.to==YEAR_2022.to) ? "primary" : null} onClick={e=>{setCurrentInterval(YEAR_2022);setCompanyChainList(null)}}>2022</Button>
                <Button color={ (currentInterval.from==AUTO_INTERVAL.from && currentInterval.to==AUTO_INTERVAL.to) ? "primary" : null} onClick={e=>{setCurrentInterval(AUTO_INTERVAL);setCompanyChainList(null)}}>AUTO</Button>
              </FormControl>

              <h3>Fra <strong>{currentInterval.from}</strong> til <strong>{currentInterval.to}</strong></h3>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={earningPerOffice}
                      onChange={event => {
                        setEarningPerOffice(event.target.checked);
                      }}
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Opptjent per kontor"
                />
              </div>   
 

              {balanceMap!==null && (
                  <table>
                    <tbody>
                      <tr>
                        <td>Broker office total - only power orders</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalBrokerOfficeBalanceOnlyPowerOrders}</strong></td>
                      </tr>
                      <tr>
                        <td>Broker office total - power orders + positive adjustments</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalBrokerOfficeBalanceAllPositiveTransactions}</strong></td>
                      </tr> 

                      <tr>
                        <td colSpan="2"><hr /></td>
                      </tr> 

                      <tr>
                        <td>Broker chain total - only power orders</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalCompanyChainBalanceOnlyPowerOrders}</strong></td>
                      </tr>
                      <tr>
                        <td>Broker chain total - power orders + positive adjustments</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalCompanyChainBalanceAllPositiveTransactions}</strong></td>
                      </tr>

                      <tr>
                        <td colSpan="2"><hr /></td>
                      </tr> 

                      <tr>
                        <td>TOTAL(BC/BO) - only power orders</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalOnlyPowerOrders}</strong></td>
                      </tr>      
                      <tr>
                        <td>TOTAL(BC/BO)- power orders + positive adjustments</td>
                        <td style={{textAlign:"right"}}><strong>{balanceMap.totalAllPositiveTransactions}</strong></td>
                      </tr>      
                    </tbody>
                  </table>
                )}


              
              {companyChainList==null && <div>Loading...</div>}
              {companyChainList!==null && companyChainList.map( (item,index) => (

                <React.Fragment key={item.id}>
                  <h5><strong>{index+1}.</strong>  <strong onClick={e=>{setBrokerOfficeList(null); setCurrentCompanyChainId(item.id); searchBrokerOffices(item.id);}}>{item.name}</strong>

                    {balanceMap==null && <span style={{padding:"2pt",margin:"5pt"}}>--</span>}
                    {balanceMap!==null && <span style={{padding:"2pt",margin:"5pt"}}>
                      {formatBalance(balanceMap.companyChains[item.id].balance)}
                    </span>}

                    {balanceMap!==null && item._loading!==true && 
                      <React.Fragment>
                        <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildCompanyChainViewUrl(item.id)}>Forhåndsvisning</a> | 
                        <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildCompanyChainDownloadUrl(item.id)}>Forhåndsvisning(PDF)</a> |
                      </React.Fragment>}
                    <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildCompanyChainDetailsUrl(item.id)}>Vis liste</a>

                    {balanceMap!==null && item._loading===true && <span style={{marginLeft:"35pt"}}>Loading...</span>}
                    {balanceMap!==null && item._loading!==true && <a style={{marginLeft:"35pt",color:"red"}} onClick={e=>{e.preventDefault();addCompanyChainFileTransaction(item.id)}} href="#"><strong>!</strong> Legg til historisk transaksjon og last ned PDF</a>}
                    {balanceMap!==null && item._loading!==true && balanceMap.companyChains[item.id].fileRecords.length>0 && <strong style={{marginLeft:"5pt",color:"red"}}>OBS! Sist opprettet {formatDateTime(balanceMap.companyChains[item.id].fileRecords[0].createdAt)})</strong>}
                    
                  </h5>


                  {item.id==currentCompanyChainId && brokerOfficeList==null && <div>Loading broker offices...</div>}
                  {item.id==currentCompanyChainId && brokerOfficeList!=null && brokerOfficeList.map( (boItem, boIndex)=>(

                    <React.Fragment key={boItem.id}>
                      {<h5 style={{marginLeft:"20pt"}}><strong>{""+ (index+1) + "." + (boIndex+1)}</strong> 

                        {boItem.active==true && boItem.name}
                        {boItem.active==false && <em style={{color:"red", textDecoration:"line-through"}}>{boItem.name} (Inaktiv)</em>}

                        {balanceMap.brokerOffices[boItem.id]==undefined && <span style={{padding:"2pt",margin:"5pt"}}>--</span>}
                        {balanceMap.brokerOffices[boItem.id]!==undefined && <span style={{padding:"2pt",margin:"5pt"}}>
                          {formatBalance(balanceMap.brokerOffices[boItem.id].balance)}
                        </span>}

                        {balanceMap.brokerOffices[boItem.id]!==undefined && boItem._loading!==true &&
                          <React.Fragment>
                            <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildBrokerOfficeViewUrl(boItem.id)}>Forhåndsvisning</a> | 
                            <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildBrokerOfficeDownloadUrl(boItem.id)}>Last ned PDF</a> |
                          </React.Fragment>}
                        <a style={{marginLeft:"15pt",marginRight:"15pt"}} target="_blank" href={buildBrokerOfficeDetailsUrl(boItem.id)}>Vis liste</a>

                        {boItem.active==true && balanceMap.brokerOffices[boItem.id]!==undefined && 
                          <React.Fragment>
                           {boItem._loading!==true && <a style={{marginLeft:"35pt",color:"red"}} onClick={e=>{e.preventDefault();addBrokerOfficeFileTransaction(boItem.id)}} href="#"><strong>!</strong> Legg til historisk transaksjon og last ned PDF</a>}
                           {boItem._loading!==true && balanceMap.brokerOffices[boItem.id].fileRecords.length>0 && <strong style={{marginLeft:"5pt",color:"red"}}>OBS! Sist opprettet {formatDateTime(balanceMap.brokerOffices[boItem.id].fileRecords[0].createdAt)})</strong>}
                           {boItem._loading===true && <span style={{marginLeft:"35pt"}}>Loading...</span>}
                          </React.Fragment>}

                      </h5>}
                    </React.Fragment>

                    ) )}
                </React.Fragment>) )}



            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
