import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_POWER_COMPANY_CPO_START } from "actions/types";
import { FETCH_POWER_COMPANY_CPO_SUCCESS } from "actions/types";
import { FETCH_POWER_COMPANY_CPO_ERROR } from "actions/types";

import PowerCompanyAccountingTable from "./PowerCompanyAccountingTable";

export default function PowerCompanyAccountingTab({powerCompany}){

  const id = powerCompany.id;

  const dispatch = useDispatch();

  const accountingTabDataItem = useSelector((state) => {
    return state.powerCompanies.accountingTabData[id] || null;
  });

  console.log("Power company accounting tab data item:", accountingTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_POWER_COMPANY_CPO_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_company/" + id + "/cpo";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_POWER_COMPANY_CPO_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_POWER_COMPANY_CPO_ERROR,
          payload: { id, error }
        });
      });
  };

  if (accountingTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {accountingTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<PowerCompanyAccountingTable products={accountingTabDataItem.products} powerCompany={powerCompany} />);


  if (accountingTabDataItem == null) {
    return renderLoading();
  } else if (accountingTabDataItem.loading == true) {
    return renderLoading();
  } else if (accountingTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}