import randomstring from "randomstring";
import format from 'date-format';
import { format as formatTimeAgo, register as registerTimeAgo } from 'timeago.js';
import nb_NO from 'timeago.js/esm/lang/nb_NO';

export const CUSTOM_ISO8601_DATE_FORMAT = "yyyy-MM-ddThh:mm:ssO";
export const DMY_DATE_FORMAT = "dd.MM.yyyy";

registerTimeAgo('nb_NO', nb_NO);


export function createRandomNotificationId(){
  return randomstring.generate(8);
}

export function createRandomTabId(){
  return randomstring.generate(8);
}

export function createRandomItemId(){
  return randomstring.generate(8);
}

export function createRandomOrderId(){
  return "_"+randomstring.generate(8);
}

export function createTemporaryId(){
  return "_"+randomstring.generate(8);
}

export function isTemporaryId(id){
  if (typeof(id)=="string" && id.startsWith("_")){
    return true;
  } 

  return false;  
}

export function formatDate(dateString, dateFormat='dd.MM.yyyy'){

  if (dateString=="" || dateString==null){
    return "";
  }

  const d = new Date(dateString);

  return format(dateFormat, d);
}


export function formatDateTime(dateString, dateFormat='dd.MM.yyyy hh:mm'){

  if (dateString=="" || dateString==null){
    return "";
  }

  const d = new Date(dateString);

  return format(dateFormat, d);
}

export function formatRelativeDateTime(dateString){
  if (dateString=="" || dateString==null) {
    return "";
  }

  return formatTimeAgo(dateString,'nb_NO');
}


export function expandDateInput(value){
    // already well formatted
    if (value.length==10) {
      return value;
    }
    else if (value.length==6 || value.length==8){
      let day = value.slice(0,2);
      let month = value.slice(2,4);
      let year = value.slice(4,8);
      
      if (value.length==6) {
        if (parseInt(year)<30)
          year = '20' + year;
        else 
          year = '19' + year;
      }
      let filteredValue = `${day}.${month}.${year}`;
      return filteredValue;
    } else {
      return null;
    }
}

export function convertNullToString(v){
  if (v==null) {
    return "";
  }

  return v;
}

export function copyLinkToClipboard(id) {
  /* Get the text field */
  var copyText = document.getElementById(id);

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/

  /* Copy the text inside the text field */
  document.execCommand("copy");
}