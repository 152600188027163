import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";
import Switch from "@material-ui/core/Switch";

// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app specific components
import { createRandomTabId, createRandomOrderId, convertNullToString as _ } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import OrderListTable from "./components/OrderListTable";
import ResponsibleUserForm from "./components/ResponsibleUserForm";
import PageMenu from "./components/PageMenu";
import SearchTag from "app_components/SearchTag";
import ScrollLock from "app_components/ScrollLock";
import PowerOrderSearchData from "classes/PowerOrder/PowerOrderSearchData";

import { FETCH_POWER_ORDER_RESULTS_START } from "actions/types";
import { FETCH_POWER_ORDER_RESULTS_SUCCESS } from "actions/types";
import { FETCH_POWER_ORDER_RESULTS_ERROR } from "actions/types";

import {DISCARD_POWER_ORDER_RESULTS} from "actions/types";

// actions
import {setPowerOrderDateRangeField} from "actions/powerOrderActions";
import {setPowerOrderFromDateFilter} from "actions/powerOrderActions";
import {setPowerOrderToDateFilter} from "actions/powerOrderActions";
import {setPowerOrderTextFilter} from "actions/powerOrderActions";
import {addNewPowerOrder} from "actions/powerOrderActions";

// order functions
import {buildPaginationData, createNewPowerOrder} from "functions/powerOrderFunctions";

// app specific styling
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";


const useStyles = makeStyles(regularTablesStyle);
const useFormStyles = makeStyles(regularFormsStyle);

export default function OrderListPage(props) {
  const { history } = props;

  const PAGE_TITLE = "Ordre";

  useEffect(() => {

    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });

  const classes = useStyles();
  const formClasses = useFormStyles();

  const dispatch = useDispatch();

  const [textFilterInput, setTextFilterInput] = useState("");

  const closeAction = {
    type: DISCARD_POWER_ORDER_RESULTS,
    payload: { }
  }

  dispatch({
  type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title: "Ordre",
      to: "/orders",
      closeAction
    }
  });

  const listingData = useSelector(state => {
    return state.powerOrders.listingData || null;
  });

  const loggedUser = useSelector(state => {
    return state.auth.authInfo.loggedUser || null
  });

  const searchPowerOrders = (page) => {
    dispatch({
      type: FETCH_POWER_ORDER_RESULTS_START,
      payload: {  }
    });

    let urlParams = {};

    urlParams["page"] = page;
    
    if (listingData.filters.status){
      urlParams["status"] = listingData.filters.status;
    } 
    
    if (listingData.filters.source){
      urlParams["source"] = listingData.filters.source;
    } 

    if (listingData.filters.responsibleUser){
      urlParams["responsible_user"] = listingData.filters.responsibleUser;
    } 

    if (listingData.filters.dateRangeField) {
      urlParams["date_range_field"] = listingData.filters.dateRangeField;
    }
    
    if (listingData.filters.fromDate) {
      urlParams["from_date"] = moment(listingData.filters.fromDate).format()
    }

    if (listingData.filters.toDate) {
      urlParams["to_date"] = moment(listingData.filters.toDate).format()
    }   

    if (listingData.filters.textFilter) {
      urlParams["q"] = listingData.filters.textFilter;
    }

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders";

    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_POWER_ORDER_RESULTS_SUCCESS,
          payload: { data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_POWER_ORDER_RESULTS_ERROR,
          payload: { error }
        });
      })
  };


  const fromDateChangeHandler = value => {
    if (typeof value == "object") {
      dispatch(setPowerOrderFromDateFilter(value.toDate()));
    }
  }

  const toDateChangeHandler = value => {
    if (typeof value == "object") {
      dispatch(setPowerOrderToDateFilter(value.toDate()));
    }
  }  

  const newOrderHandler = e => {
    const temporaryOrderId = createRandomOrderId();

    const newPowerOrder = createNewPowerOrder(temporaryOrderId);
    dispatch(addNewPowerOrder(newPowerOrder));

    console.log("Add temporary order with id= " + temporaryOrderId);

    history.push("/order/"+temporaryOrderId); 
  }

  const resetTextFilter = () => {

    console.log("Reset text filter");
     
    setTextFilterInput("");
    dispatch(setPowerOrderTextFilter(null, false));
  }


  const onTextFilterInputBlur = e => {   
    let textFilter = e.target.value;
    textFilter = textFilter.trim();

    if (textFilter!="") {
       dispatch(setPowerOrderTextFilter(textFilter, true));
    }
  }

  const textFilterInputEnterHandler = e => {
    let textFilter = e.target.value;
    textFilter = textFilter.trim();

    if(e.keyCode == 13){
         dispatch(setPowerOrderTextFilter(textFilter, true));
      }
  }    
  

  const isLoading = listingData.loading;
  const hasData = listingData.results == null ? false: true;
  const hasError = listingData.error==null ? false: true;
  const responsibleUserMode=listingData.responsibleUserMode;

  if (isLoading==false && hasData==false && hasError==false) {
    if (listingData.currentPage==null)
      searchPowerOrders(1);
    else
      searchPowerOrders(listingData.currentPage);
  } 

  const renderDefault = () => <h1 className="header header-default">...</h1>;

  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {listingData.error.error.message}</h1>;

  const renderSuccess = () => (
    <React.Fragment>
      <ScrollLock path="/orders" />
      <div className="Standard-Page-Content orderListPage">
        <GridContainer>
          <GridItem md={1}>
            <PageMenu />
          </GridItem>
          <GridItem md={8}>
            <Link to="/orders">
              <Button color="primary">Ordre</Button>
            </Link>

            <Link to="/emails">
              <Button>Epost</Button>
            </Link>
          </GridItem>
          <GridItem md={3} className="orderListPage-addOrder">
            <Button className="orderListPage-addOrder-btn" color="white" size="sm" onClick={newOrderHandler} title="Ny order">+</Button>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{PAGE_TITLE} {listingData.results!==null && <em> ({listingData.results.length} av {listingData.totalResults} resultater)</em>}</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>

                  <GridItem md={2}>

                    <div>
                      <CustomInput
                        labelText="Søk"
                        formControlProps={{
                          fullWidth: false
                        }}
                        inputProps={{onBlur:onTextFilterInputBlur, onKeyDown: textFilterInputEnterHandler, value: _(textFilterInput), onChange:e=>setTextFilterInput(e.target.value) }}
                      />
                    </div>


                    {listingData.filters.textFilter && (
                      <SearchTag title={listingData.filters.textFilter} removeTag={resetTextFilter} />
                    )}   


                  </GridItem>
                  <GridItem md={2}></GridItem>

                  <GridItem md={4}>

                    {listingData.results !== null && (
                      <div className="pagination-wrapper">
                        <div className="pagination-content">
                          Side {listingData.currentPage} av {listingData.totalPages}
                        </div>
                        <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages, searchPowerOrders)} />
                      </div>
                    )}

                  </GridItem>



                  <GridItem md={2}>
                    <FormControl className="dateTime-wrapper" fullWidth>
                      <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={listingData.filters.fromDate} onChange={fromDateChangeHandler} inputProps={{ placeholder: "Fra",readOnly: true }} />
                    </FormControl>

                    {listingData.filters.fromDate && (
                      <SearchTag title={"Fra " + moment(listingData.filters.fromDate).format("DD MMMM YYYY (dddd)")} removeTag={()=>dispatch(setPowerOrderFromDateFilter(null))} />
                      )}

                      { (listingData.filters.fromDate || listingData.filters.toDate) && (<div>
                        
                      {listingData.filters.dateRangeField==PowerOrderSearchData.TRANSFER_DATE_RANGE_FIELD && <span>Datointervall: effektuert dato</span>}
                      {listingData.filters.dateRangeField==PowerOrderSearchData.CREATED_DATE_RANGE_FIELD && <span>Datointervall: opprettet dato</span>}

                      <Switch
                        checked={listingData.filters.dateRangeField==PowerOrderSearchData.CREATED_DATE_RANGE_FIELD}
                        onChange={event => {
                          if (event.target.checked==true) {
                            dispatch(setPowerOrderDateRangeField(PowerOrderSearchData.CREATED_DATE_RANGE_FIELD));
                          } else {
                            dispatch(setPowerOrderDateRangeField(PowerOrderSearchData.TRANSFER_DATE_RANGE_FIELD));
                          }
                        }}
                        value="checked"
                        classes={{
                          switchBase: formClasses.switchBase,
                          checked: formClasses.switchChecked,
                          thumb: formClasses.switchIcon,
                          track: formClasses.switchBar
                        }}
                      />
                      </div>)}
                  </GridItem>

                  <GridItem md={2}>
                    <FormControl className="dateTime-wrapper" fullWidth>
                      <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={listingData.filters.toDate} onChange={toDateChangeHandler} inputProps={{ placeholder: "Til",readOnly: true }} />
                    </FormControl>

                    {listingData.filters.toDate && (
                      <SearchTag title={"Til "+moment(listingData.filters.toDate).format("DD MMMM YYYY (dddd)")} removeTag={()=>dispatch(setPowerOrderToDateFilter(null))} />
                      )}                    
                  </GridItem>

                  {responsibleUserMode && <ResponsibleUserForm />}


                </GridContainer>
              </CardBody>

              <CardBody>
                <OrderListTable
                  loggedUser={loggedUser}
                  history={history}
                  tableHeaderColor="primary"
                  tableHead={[
                    responsibleUserMode ? "#":"ID",
                    "Type",
                    "Ansvarlig",
                    "Meglerkontor",
                    "Leverandør",
                    "Gate",
                    "Sted",
                    "Målernummer",
                    "Kjøper",
                    "Avlest dato",
                    "K.",
                    "Status"
                  ]}
                  tableData={listingData.results}
                  responsibleUserMode={responsibleUserMode}
                />


                {listingData.results !== null && listingData.totalPages>1 && (
                <GridContainer className="pagination-bottomContainer">
                  <GridItem md={4}></GridItem>
                  <GridItem md={4}>                    
                    <div className="pagination-wrapper">
                      <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages, searchPowerOrders)} />
                      <div className="pagination-content">
                        Side {listingData.currentPage} av {listingData.totalPages}
                      </div>                      
                    </div>
                  </GridItem>
                  <GridItem md={4}></GridItem>
                </GridContainer>)}


              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>
    );  

  if (listingData.results !== null) {
    return renderSuccess();
  } else if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else if (listingData.entitites == null) {
    return renderDefault();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(listingData)}</h1>
    </React.Fragment>
  );

}
