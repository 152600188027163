import React from "react";

import Close from "@material-ui/icons/Close";
import "app_assets/scss/SearchTags.scss";


export default function SearchTag(props) {
  const { title, removeTag } = props;

  return (
    <div className="Search-Tag">
      <div className="text">{title}</div>
      <div className="icon" onClick={removeTag}>
        <Close />
      </div>
    </div>
  );
}