import { RELOAD_POWER_ORDER_RESULTS } from "actions/types";
import { FETCH_POWER_ORDER_ITEM_START } from "actions/types";
import { FETCH_POWER_ORDER_ITEM_SUCCESS } from "actions/types";
import { FETCH_POWER_ORDER_ITEM_ERROR } from "actions/types";
import { DISCARD_POWER_ORDER_ITEM } from "actions/types";
import {UPDATE_POWER_ORDER_ITEM_LOCK} from "actions/types";
import {SET_DUPLICATE_CHECK_COMPLETED} from "actions/types";
import {SET_DUPLICATE_SEARCH_RESULTS} from "actions/types";
import {SET_POWER_ORDER_FILTERS} from "actions/types";

import {ADD_NEW_POWER_ORDER} from "actions/types";
import {ADD_NEW_POWER_ORDER_COMMENT} from "actions/types";

import { UPDATE_POWER_ORDER_TASK } from "actions/types";
import { UPDATE_POWER_ORDER_BROKER } from "actions/types";

import { SET_POWER_ORDER_ESTATE_CONFIG_MODE } from "actions/types";
import { UPDATE_POWER_ORDER_BUYER } from "actions/types";
import { UPDATE_POWER_ORDER_SELLER } from "actions/types";
import { REPLACE_TEMPORARY_ORDER_ID } from "actions/types";

import { SET_SELECTED_ORDER_IDS } from "actions/types";




export const reloadPowerOrderResults = () => {
  const action = {
    type: RELOAD_POWER_ORDER_RESULTS,
    payload: {}
  };

  return action;
};

export const discardPowerOrderItem = (id) => {
  const action = {
    type: DISCARD_POWER_ORDER_ITEM,
    payload: { id }
  };
  return action;
};

export const fetchPowerOrderItemStart = (id) => {
  const action = {
    type: FETCH_POWER_ORDER_ITEM_START,
    payload: { id }
  };
  return action;
};

export const fetchPowerOrderItemSuccess = (id, entity) => {
  const action = {
    type: FETCH_POWER_ORDER_ITEM_SUCCESS,
    payload: { id, entity }
  };
  return action;
};

export const fetchPowerOrderItemError = (id, error) => {
  const action = {
    type: FETCH_POWER_ORDER_ITEM_ERROR,
    payload: { id, error }
  };
  return action;
};


export const updatePowerOrderItemLock = (id, lock) => {
  const action = {
    type: UPDATE_POWER_ORDER_ITEM_LOCK,
    payload: {id, lock}
  }
  return action;
}


export const setDuplicateCheckCompleted = (id, duplicateCheckCompleted) => {
  const action = {
    type: SET_DUPLICATE_CHECK_COMPLETED,
    payload: {id, duplicateCheckCompleted}
  }
  return action;
}


export const setDuplicateSearchResults = (id, duplicateSearchResults) => {
  const action = {
    type: SET_DUPLICATE_SEARCH_RESULTS,
    payload: {id, duplicateSearchResults}
  }
  return action;
}

export  const setPowerOrderDateRangeField = dateRangeField => {
  return {
      type: SET_POWER_ORDER_FILTERS,
      payload: {dateRangeField}
    }
}

export  const setPowerOrderFromDateFilter = fromDate => {
  return {
      type: SET_POWER_ORDER_FILTERS,
      payload: {fromDate}
    }
}

export  const setPowerOrderToDateFilter = toDate => {
  return {
      type: SET_POWER_ORDER_FILTERS,
      payload: {toDate}
    }
}

export  const setPowerOrderTextFilter = (textFilter, resetStatus=false) => {

  const payload = { textFilter };

  if (resetStatus) {
    payload["status"] = null;
  }
  
  return {
      type: SET_POWER_ORDER_FILTERS,
      payload
    }
}

export  const addNewPowerOrder = entity => {
  return {
      type: ADD_NEW_POWER_ORDER,
      payload: {entity}
    }
}

export  const addNewPowerOrderComment = entity => {
  return {
      type: ADD_NEW_POWER_ORDER_COMMENT,
      payload: {entity}
    }
}

export const updatePowerOrderTask = task => {
  return {
    type: UPDATE_POWER_ORDER_TASK,
    payload: task
  }
}


export const updatePowerOrderBroker = (orderId,powerOrderBroker) => {
  return {
    type: UPDATE_POWER_ORDER_BROKER,
    payload: {
      orderId,
      powerOrderBroker
    }
  }
}

export const setPowerOrderEstateConfigMode = (orderId, value) => {
  return {
      type: SET_POWER_ORDER_ESTATE_CONFIG_MODE,
      payload: { orderId, value }
  };
}


export const updatePowerOrderBuyer = buyer => {
  return {
    type: UPDATE_POWER_ORDER_BUYER,
    payload: buyer
  };
};

export const updatePowerOrderSeller = seller => {
  return {
    type: UPDATE_POWER_ORDER_SELLER,
    payload: seller
  };
};

export const replaceTemporaryOrderId = (temporaryOrderId, realOrderId) => {
  return {
    type: REPLACE_TEMPORARY_ORDER_ID,
    payload: {temporaryOrderId, realOrderId}
  }
}

export const setSelectedOrderIds = (ids) => {
  return {
    type: SET_SELECTED_ORDER_IDS,
    payload: ids
  }
}