import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_COMPANY_CHAIN_OFFICES_START } from "actions/types";
import { FETCH_COMPANY_CHAIN_OFFICES_SUCCESS } from "actions/types";
import { FETCH_COMPANY_CHAIN_OFFICES_ERROR } from "actions/types";

import CompanyChainOfficeTable from "./CompanyChainOfficeTable";

export default function CompanyChainBrokerOfficeTab({companyChain,history}){

  const id = companyChain.id;

  const dispatch = useDispatch();

  const officeTabDataItem = useSelector((state) => {
    return state.brokerChains.officeTabData[id] || null;
  });

  console.log("Company chain office tab data item:", officeTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_COMPANY_CHAIN_OFFICES_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id + "/broker_offices";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_COMPANY_CHAIN_OFFICES_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_COMPANY_CHAIN_OFFICES_ERROR,
          payload: { id, error }
        });
      });
  };

  if (officeTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {officeTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<CompanyChainOfficeTable brokerOffices={officeTabDataItem.brokerOffices} history={history} companyChain={companyChain} />);


  if (officeTabDataItem == null) {
    return renderLoading();
  } else if (officeTabDataItem.loading == true) {
    return renderLoading();
  } else if (officeTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}