import { FETCH_POWER_COMPANY_ITEM_START } from "../actions/types";
import { FETCH_POWER_COMPANY_ITEM_SUCCESS } from "../actions/types";
import { FETCH_POWER_COMPANY_ITEM_ERROR } from "../actions/types";

import { FETCH_POWER_COMPANY_RESULTS_START } from "../actions/types";
import { FETCH_POWER_COMPANY_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_POWER_COMPANY_RESULTS_ERROR } from "../actions/types";

import { FETCH_POWER_COMPANY_CPO_START } from "../actions/types";
import { FETCH_POWER_COMPANY_CPO_SUCCESS } from "../actions/types";
import { FETCH_POWER_COMPANY_CPO_ERROR } from "../actions/types";

import { UPDATE_POWER_PRODUCT } from "../actions/types";
import { UPDATE_POWER_PRODUCT_CPO } from "../actions/types";

import { FETCH_POWER_COMPANY_TERMS_START } from "../actions/types";
import { FETCH_POWER_COMPANY_TERMS_SUCCESS } from "../actions/types";
import { FETCH_POWER_COMPANY_TERMS_ERROR } from "../actions/types";

import { UPDATE_POWER_COMPANY_TERMS } from "../actions/types";

import { SWITCH_POWER_COMPANY_ITEM_TAB } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";


const listingDataTemplate = {
  loading: false,
  error: null,
  entities: null
}


const workingItemTemplate = {
  loading: false,
  error: null,
  entity: null,
  currentTab: 1
};

const termsTabDataItemTemplate = {
  loading: false, 
  error: null,
  terms: null
};

const accountingTabDataItemTemplate = {
  loading: false, 
  error: null,
  products: null
};


const createInitialState = () => ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: {
    
  },

  termsTabData: {},

  accountingTabData: {}
});


export default function(state = createInitialState(), action) {
  let { workingData, termsTabData, accountingTabData } = state;

  switch (action.type) {
    case FETCH_POWER_COMPANY_ITEM_START: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingItemTemplate,
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_POWER_COMPANY_ITEM_SUCCESS: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_POWER_COMPANY_ITEM_ERROR: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }


    case SWITCH_POWER_COMPANY_ITEM_TAB: {
      console.log("Power company action", action.type, action);

      const {powerCompanyId, index} = action.payload;

      const workingDataItem = workingData[powerCompanyId];
      workingDataItem.currentTab = index;
      workingData[powerCompanyId] = {...workingDataItem};

      return {
        ...state,
        workingData
      };      
    }


case FETCH_POWER_COMPANY_RESULTS_START: {
      console.log("Power company action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_POWER_COMPANY_RESULTS_SUCCESS: {
      console.log("Power company action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        entities: action.payload.data
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_POWER_COMPANY_RESULTS_ERROR: {
      console.log("Power company action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }

    //-------

    case FETCH_POWER_COMPANY_CPO_START: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      accountingTabData[id] = {
        ...accountingTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        accountingTabData
      };
    }

    case FETCH_POWER_COMPANY_CPO_SUCCESS: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      accountingTabData[id] = {
        ...accountingTabDataItemTemplate,
        products: action.payload.data
      };

      return {
        ...state,
        accountingTabData
      };
    }

    case FETCH_POWER_COMPANY_CPO_ERROR: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      accountingTabData[id] = {
        ...accountingTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        accountingTabData
      };
    }

    case UPDATE_POWER_PRODUCT_CPO: {
      console.log("Power company action", action.type, action);

      let powerCompanyId = action.payload.powerCompanyId;
      let productId = action.payload.productId;
      let cpoEntity = action.payload.cpoEntity;

      const accountingTabDataItem = accountingTabData[powerCompanyId];
      accountingTabDataItem.products.map(item=>{
        if (item.id==productId) {
          item.productCpoValues = [cpoEntity];  
        }
      });

      accountingTabData[powerCompanyId] = {...accountingTabDataItem};

      return {
        ...state,
        accountingTabData
      };      
    }

    // -----

    case UPDATE_POWER_PRODUCT: {
      console.log("Power company action", action.type, action);

      let powerCompanyId = action.payload.powerCompanyId;
      let product = action.payload.product;

      const workingDataItem = workingData[powerCompanyId];

      workingDataItem.entity.products.map((item,index)=>{
        if (item.id==product.id){
          workingDataItem.entity.products[index] = product;
        }
      });

      workingData[powerCompanyId] = {...workingDataItem};

      return {
        ...state,
        workingData
      };      
    }

    // -----

    case FETCH_POWER_COMPANY_TERMS_START: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      termsTabData[id] = {
        ...termsTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        termsTabData
      };
    }

    case FETCH_POWER_COMPANY_TERMS_SUCCESS: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      termsTabData[id] = {
        ...termsTabDataItemTemplate,
        terms: action.payload.data
      };

      return {
        ...state,
        termsTabData
      };
    }

    case FETCH_POWER_COMPANY_TERMS_ERROR: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;
      termsTabData[id] = {
        ...termsTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        termsTabData
      };
    }

    case UPDATE_POWER_COMPANY_TERMS: {
      console.log("Power company action", action.type, action);

      let id = action.payload.id;

      const termsTabDataItem = termsTabData[id];

      termsTabDataItem.terms.terms = action.payload.terms;

      termsTabData[id] = {...termsTabDataItem};

      return {
        ...state,
        termsTabData
      };      
    }

    // -----

    case APP_LOGOUT_SUCCESS: {
      console.log("Power company action", action.type, action);

      return createInitialState();
    }

    default:
      return state;
  }
}
