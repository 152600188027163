import React from "react";
import { Link } from "react-router-dom";


import {isTemporaryOrderId} from "functions/powerOrderFunctions";

export default function OrderTabButton({ status, text, orderId, current }) {
  let classes = ["Tab-Button"];

  const temporary = isTemporaryOrderId(orderId);

  if (current) {
    classes.push("Current");  
  }

  if (temporary) {
    classes.push("Status-Unsaved");
  }  else {
    classes.push("Status-Saved");
  } 

  return (
    <Link  to={`/order/${orderId}`} className={classes.join(" ")} tabIndex={-1}>
      {temporary==true && <em>{text}</em>}
      {temporary==false && text}
    </Link>
  );
}
