import { FETCH_BROKER_CHAIN_ITEM_START } from "../actions/types";
import { FETCH_BROKER_CHAIN_ITEM_SUCCESS } from "../actions/types";
import { FETCH_BROKER_CHAIN_ITEM_ERROR } from "../actions/types";

import { FETCH_BROKER_CHAIN_RESULTS_START } from "../actions/types";
import { FETCH_BROKER_CHAIN_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_CHAIN_RESULTS_ERROR } from "../actions/types";

import { FETCH_COMPANY_CHAIN_OFFICES_START } from "../actions/types";
import { FETCH_COMPANY_CHAIN_OFFICES_SUCCESS } from "../actions/types";
import { FETCH_COMPANY_CHAIN_OFFICES_ERROR } from "../actions/types";

import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_START } from "../actions/types";
import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_SUCCESS } from "../actions/types";
import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_ERROR } from "../actions/types";

import { UPDATE_COMPANY_CHAIN_PRODUCT_VALUE } from "../actions/types";
import { DELETE_COMPANY_CHAIN_PRODUCT_VALUE } from "../actions/types";

import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_START } from "../actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_SUCCESS } from "../actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_ERROR } from "../actions/types";

import { UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS } from "../actions/types";

import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_START } from "../actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_SUCCESS } from "../actions/types";
import { FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_ERROR } from "../actions/types";

import { SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS } from "../actions/types";
import { DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO } from "../actions/types";



import { SWITCH_BROKER_CHAIN_ITEM_TAB } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";



const listingDataTemplate = {
  loading: false,
  error: null,
  entities: null
}

const itemLoadingTemplate = {
  loading: false, 
  error: null,
  entity: null,
  currentTab: 1
};

const officeTabDataItemTemplate = {
  loading: false, 
  error: null,
  brokerOffices: null
};

const productValueTabDataItemTemplate = {
  loading: false, 
  error: null,
  products: null
};

const accountingInfoTabDataItemTemplate = {
  loading: false, 
  error: null,

  filters: {
    fromAccountingDate: null,
    toAccountingDate: null
  },  

  results: null,
  currentPage: null,
  totalPages: null,
  totalResults: null,
  balance: null
};

const accountingSettingsTabDataItemTemplate = {
  loading: false, 
  error: null,
  entity: null
};

const createInitialState = ()=> ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: {
    
  },
  protocolTabData: {},
  officeTabData: {},
  productValueTabData: {},
  accountingInfoTabData: {},
  accountingSettingsTabData: {}
});


export default function(state = createInitialState(), action) {
  let { workingData, protocolTabData, officeTabData, productValueTabData, accountingInfoTabData, accountingSettingsTabData } = state;

  switch (action.type) {
    case FETCH_BROKER_CHAIN_ITEM_START: {
      console.log("Broker chain action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_BROKER_CHAIN_ITEM_SUCCESS: {
      console.log("Broker chain action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_BROKER_CHAIN_ITEM_ERROR: {
      console.log("Broker chain action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...itemLoadingTemplate,
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }

    case SWITCH_BROKER_CHAIN_ITEM_TAB: {
      console.log("Broker chain action", action.type, action);

      const {companyChainId, index} = action.payload;

      const workingDataItem = workingData[companyChainId];
      workingDataItem.currentTab = index;
      workingData[companyChainId] = {...workingDataItem};

      return {
        ...state,
        workingData
      };      
    }


    case FETCH_BROKER_CHAIN_RESULTS_START: {
      console.log("Broker chain action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_BROKER_CHAIN_RESULTS_SUCCESS: {
      console.log("Broker chain action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        entities: action.payload.data
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_BROKER_CHAIN_RESULTS_ERROR: {
      console.log("Broker chain action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }



    //-----

    case FETCH_COMPANY_CHAIN_OFFICES_START: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      officeTabData[id] = {
        ...officeTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        officeTabData
      };
    }

    case FETCH_COMPANY_CHAIN_OFFICES_SUCCESS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      officeTabData[id] = {
        ...officeTabDataItemTemplate,
        brokerOffices: action.payload.data
      };

      return {
        ...state,
        officeTabData
      };
    }

    case FETCH_COMPANY_CHAIN_OFFICES_ERROR: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      officeTabData[id] = {
        ...officeTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        officeTabData
      };
    }


    //-----

    case FETCH_COMPANY_CHAIN_PRODUCT_VALUES_START: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case FETCH_COMPANY_CHAIN_PRODUCT_VALUES_SUCCESS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        products: action.payload.data
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case FETCH_COMPANY_CHAIN_PRODUCT_VALUES_ERROR: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      productValueTabData[id] = {
        ...productValueTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        productValueTabData
      };
    }

    case UPDATE_COMPANY_CHAIN_PRODUCT_VALUE: {
      console.log("Company chain action", action.type, action);

      let companyChainId = action.payload.companyChainId;
      let productId = action.payload.productId;
      let productValueEntity = action.payload.productValueEntity;

      const productValueTabDataItem = productValueTabData[companyChainId];
      productValueTabDataItem.products.map(item=>{
        if (item.id==productId) {
          item.productValues = [productValueEntity];  
        }
      });

      productValueTabData[companyChainId] = {...productValueTabDataItem};

      return {
        ...state,
        productValueTabData
      };      
    }

    case DELETE_COMPANY_CHAIN_PRODUCT_VALUE: {
      console.log("Company chain action", action.type, action);

      let companyChainId = action.payload.companyChainId;
      let productId = action.payload.productId;

      const productValueTabDataItem = productValueTabData[companyChainId];
      productValueTabDataItem.products.map(item=>{
        if (item.id==productId) {
          item.productValues = [];  
        }
      });

      productValueTabData[companyChainId] = {...productValueTabDataItem};

      return {
        ...state,
        productValueTabData
      };      
    }

    //-------

    case FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_START: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_SUCCESS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        entity: action.payload.data
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case FETCH_COMPANY_CHAIN_ACCOUNTING_SETTINGS_ERROR: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        accountingSettingsTabData
      };
    }

    case UPDATE_COMPANY_CHAIN_ACCOUNTING_SETTINGS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;
      accountingSettingsTabData[id] = {
        ...accountingSettingsTabDataItemTemplate,
        entity: action.payload.entity
      };

      return {...state}
    }

    // ---

    case FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_START: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;

      let filters = accountingInfoTabDataItemTemplate.filters;

      if (accountingInfoTabData[id] !== undefined) {
        filters = accountingInfoTabData[id].filters;
      }

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters,
        loading: true
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }

    case FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_SUCCESS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,

        filters: accountingInfoTabDataItem.filters,

        results: action.payload.data.results,
        currentPage: action.payload.data.currentPage,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults,        
        balance: action.payload.data.balance,
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }

    case FETCH_COMPANY_CHAIN_ACCOUNTING_INFO_ERROR: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters: accountingInfoTabDataItem.filters,
        error: action.payload
      };

      return {
        ...state,
        accountingInfoTabData
      };
    }


    case SET_COMPANY_CHAIN_ACCOUNTING_INFO_FILTERS: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;

      const accountingInfoTabDataItem = accountingInfoTabData[id];

      const filters = {
        ...accountingInfoTabDataItem.filters,
        ...action.payload.filters
      };

      accountingInfoTabData[id] = {
        ...accountingInfoTabDataItemTemplate,
        filters
      };

      return {
        ...state,
        accountingInfoTabData
      }
    } 

    case DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO: {
      console.log("Company chain action", action.type, action);

      let id = action.payload.id;

      delete accountingInfoTabData[id];

      return {
        ...state,
        accountingInfoTabData
      };
    }

    //-------


    case APP_LOGOUT_SUCCESS: {
      console.log("Broker chain action", action.type, action);

      return createInitialState();
    }
    default:
      return state;
  }
}
