import React from "react";

// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";

const BrokerOfficeSmsScheduleTab = ({brokerOffice, classes}) => {

  const renderDefault = () => (
    <Card>
      <GridContainer style={{ marginTop: "25px",paddingLeft:"20px",paddingRight:"20px" }}>
        <p>
          <strong>
            This functionality has been moved to Nova,
              <br />
              <br />
              <br />
          </strong>
          <a target={'_blank'}
             href={'https://zapdos.meglerfront.no/nova/resources/notification-schedules?notification-schedules_page=1&notification-schedules_search=' + brokerOffice.name}>

            Go to page on Nova admin panel for broker-office</a>
          <br /><br />

          <a target={'_blank'}
             href={'https://zapdos.meglerfront.no/nova/resources/notification-schedules?notification-schedules_page=1&notification-schedules_search=' + brokerOffice.companyChain.name}>

            Go to page on Nova admin panel for company-chain</a>
        </p>

      </GridContainer>
    </Card>
  );


    return renderDefault();
}

export default BrokerOfficeSmsScheduleTab;