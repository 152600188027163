import React from "react";

class AuthLayout extends React.Component {
  render() {
    const { component, ...otherProps } = this.props;
    const element = React.createElement(component, otherProps);

    return <React.Fragment>{element}</React.Fragment>;
  }
}

export default AuthLayout;
