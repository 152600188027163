import React from "react";

// special functionality
import { Magnifier } from "react-image-magnifiers";

// theme components 
import Button from "components/CustomButtons/Button.js";

// app resources
import {PDF, PNG, JPG, JPEG} from "./fileTypes";

import rotateCcwIcon from "app_assets/img/feather-rotate-ccw-icon.png";
import rotateCwIcon from "app_assets/img/feather-rotate-cw-icon.png";
import downloadIcon from "app_assets/img/download-icon.png";

export default function SinglePageFilePreview(props) {
  const { onClose, attachment } = props;

  const largeImage = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/" + attachment.id + "/preview/large/1";

  const viewFileUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/"+ attachment.id;
  const downloadUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/"+ attachment.id + "/download";

  let fileTypeNo  = "";

  if ([PNG, JPG, JPEG].includes(attachment.extension)){
    fileTypeNo = "bilde";
  } else if (attachment.extension==PDF) {
    fileTypeNo = "PDF";
  }

  return (
    <React.Fragment>
      <div className="Full-Page-Overlay" onClick={onClose}></div>
      <div className="Single-Page-File-Preview Popup-Z-Index">


        <div className="Header-Grid-Area">
          <h5>{attachment.filename} <em>({attachment.formattedFilesize})</em></h5>
        </div>

        <div className="Image-Grid-Area">
          <Magnifier imageSrc={largeImage} />
        </div>

        <div className="Buttons-Grid-Area">

          <div style={{display:"inline-block"}}>
            <Button color="danger" className="Icon-Button" disabled>
              <img src={rotateCcwIcon} />
            </Button>
            <Button color="danger" className="Icon-Button" disabled>
              <img src={rotateCwIcon} />
            </Button>
            <a href={downloadUrl} target="_blank"><Button color="danger" className="Icon-Button"><img src={downloadIcon} /></Button></a>
          </div>

          <div style={{display:"inline-block"}}>
            <a href={viewFileUrl} target="_blank"><Button color="info" className="Text-Button">Vis {fileTypeNo}</Button></a>
            <Button color="primary" className="Text-Button" onClick={onClose}>
              Lukk
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
