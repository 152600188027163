import { SAVE_VERTICAL_SCROLL } from "actions/types";

import { APP_LOGOUT_SUCCESS } from "../actions/types";

const createInitialState = () => ({
  verticalScrollMap: {
    "/orders": 0,
    "/emails": 0,
    "/estates": 0,
    "/power_companies": 0,
    "/broker_offices": 0,
    "/broker_chains": 0,
  },
});

export default function (state = createInitialState(), action) {
  let verticalScrollMap = state.verticalScrollMap;

  switch (action.type) {
    case SAVE_VERTICAL_SCROLL:
      let { to, value } = action.payload;

      verticalScrollMap[to] = value;

      return {
        ...state,
        verticalScrollMap,
      };

    case APP_LOGOUT_SUCCESS:
      return createInitialState();
 
    default:
      return state;
  }
}
