import React from "react";

import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";


const useStyles = makeStyles(styles);

export default function OtherProductListTable(props) {


  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { history, tableHead, tableData } = props;


  const formatCategoryName = (category) => {
    const categories = {
      "cleaning":"Flyttevask",
      "financial":"Bank",
      "insurance":"Forsikring",
      "moving":"Flyttetjenester",
      "storage":"Lagring",
      "styling":"Boligstyling og interiør"
    };

    if (categories[category] == undefined) {
      return (<div><em>{category}</em></div>);
    }

    return (<React.Fragment>
              <div><strong>{categories[category]}</strong></div>
              <div><em>{category}</em></div>
            </React.Fragment>);
  }


  const tableCellClasses = classes.tableCell;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [classes.tableHeadFontSize]: true
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: true,
              [classes.tableStripedRow]: key % 2 === 0
            });

            return (
              <TableRow
                key={key}
                hover={true}
                className={classes.tableRow + " " + tableRowClasses}>
                <TableCell className={tableCellClasses}>{item.id}</TableCell>
                <TableCell className={tableCellClasses}>{formatCategoryName(item.category)}</TableCell>
                <TableCell className={tableCellClasses}>{item.company}</TableCell>
                <TableCell className={tableCellClasses}>{item.name}</TableCell>
                <TableCell className={tableCellClasses}>{item.description}</TableCell>
                <TableCell className={tableCellClasses}>{item.active ? "Ja":"Nei"}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

OtherProductListTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array
};
