import React from "react";

import { Link } from "react-router-dom";


// app resources
import {formatDateTime} from "utils.js";

export default function(props) {
  let { email, showLink=false } = props;

  return (
    <div className="Email-Header-Panel">
      {showLink==true && <Link to={"/email/"+email.id}><h4>{email.subject}</h4></Link>}
      {showLink==false && <h4>{email.subject}</h4>}

      <table>
        <tbody>
          <tr>
            <td>fra:</td>
            <td>{email.from}</td>
          </tr>
          <tr>
            <td>til:</td>
            <td>{email.to}</td>
          </tr>
          <tr>
            <td>dato:</td>
            <td>{formatDateTime(email.receivedAt)}</td>
          </tr>
          <tr>
            <td>attachments:</td>
            <td>{email.attachmentCount}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
