import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const regularTablesStyle = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
};

export default regularTablesStyle;