import React,{useState} from 'react';

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import CreateIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import InfoIcon from "@material-ui/icons/Info";
import DateIcon from "@material-ui/icons/CalendarToday";

// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";


// functions
import {formatRelativeDateTime, formatDateTime, convertNullToString as _} from "utils.js";


const ORDER_CREATED = 1;
const ORDER_UPDATED = 2;
const ORDER_TRANSFER_ERROR = 3;
const ORDER_TRANSFER_COMPLETE = 4;
const ORDER_ACCOUNTING_PROCESSING_COMPLETE = 5;


const OrderHistory = ({powerOrder}) => {

  const {events} = powerOrder;

  const [relativeDates,setRelativeDates] = useState(true);

  return (

    <React.Fragment>

          <h5>Order history</h5>

          <SnackbarContent
            message={
              <h5>Opprettet dato: <strong>{formatDateTime(powerOrder.createdAt)}</strong></h5>
            }

            icon={CreateIcon}
            color="info"
          />


          {events.map( event=>{

              let message = null;
              let icon = null;
              let color = null;
              let showMetadata = null;
              // eslint-disable-next-line no-unused-vars
              let metadataInfo = null;

              switch (event.eventTypeId) {
                case ORDER_CREATED:
                  icon = CreateIcon;
                  break;
                case ORDER_UPDATED:
                  icon = CreateIcon;
                  break;
                case ORDER_TRANSFER_ERROR:
                  icon = AddAlert;
                  color = "danger";
                  showMetadata = true;
                  break;
                case ORDER_TRANSFER_COMPLETE:
                  icon = DoneIcon;
                  color = "success";
                  showMetadata = true;
                  break;
                case ORDER_ACCOUNTING_PROCESSING_COMPLETE:
                  icon = DoneIcon;
                  color = "warning";
                  showMetadata = true;
                  break;
                default:
                  icon = InfoIcon;
                  color = "warning";
                  break;
              }

              const dateIcon = <DateIcon style={{verticalAlign:"middle"}} fontSize="small" />;

              const formattedDate = relativeDates ? formatRelativeDateTime(event.createdAt) : formatDateTime(event.createdAt);

              const clickHandler = e=>setRelativeDates(!relativeDates);
              

              if (event.userId==null){
                message = <div onClick={clickHandler}><strong>{event.eventNameNo}</strong> {dateIcon} {formattedDate}</div>
              } else {
                message = <div onClick={clickHandler}><strong>{event.eventNameNo}</strong> av <strong>{event.createdByName}</strong> {dateIcon} {formattedDate}</div>
              }

              if (showMetadata) {
                const parsedMetadata = JSON.parse(event.metadata);

                if (event.eventTypeId==ORDER_TRANSFER_COMPLETE) {
                  if (parsedMetadata) {
                    metadataInfo = parsedMetadata["api_response"];
                  }
                } else if (event.eventTypeId==ORDER_TRANSFER_ERROR) {
                  metadataInfo = event.metadata;
                }
                
              }


              return (
                <React.Fragment key={event.id}>
                  <div style={{marginTop:"20pt",marginBottom:"10px"}}>
                    <SnackbarContent message={message} icon={icon} color={color} />
                    {showMetadata && <textarea style={{ width: "100%",resize:"vertical",border: "none",borderBottom:"1px solid #eee",color:"#aaa" }} rows="4" readOnly value={_(event.metadata)}  />}
                  </div>
                </React.Fragment>
                )
                

            })}

          <hr />
    </React.Fragment>
    )
}

export default OrderHistory;