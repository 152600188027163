export default class Broker {

  static INTERNAL_SOURCE = "internal";
  
  constructor() {
    this.id = null;
    this.brokerOffice = null;
    this.externalId = null;
    this.name = null;
    this.title = null;
    this.mobile = null;
    this.phone = null;
    this.email = null;
    this.active = null;
    this.source = null;
    this.created = null;
    this.modified = null;

    this.settings = null;
  }
}
