import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

import { FETCH_AUTH_INFO_START } from "../actions/types";
import { FETCH_AUTH_INFO_SUCCESS } from "../actions/types";
import { FETCH_AUTH_INFO_ERROR } from "../actions/types";

import { FETCH_APP_SETTINGS_START } from "../actions/types";
import { FETCH_APP_SETTINGS_SUCCESS } from "../actions/types";
import { FETCH_APP_SETTINGS_ERROR } from "../actions/types";


class ProtectedRoute extends Route {
  render() {
    const { dispatch } = this.props;

    //console.log("auth data", this.props.auth);

    const { statusLoading, statusError, authInfo } = this.props.auth;

    const {loading: settingsLoading, error: settingsError, settings: appSettings} = this.props.app;

    const loadAuthStatus = () => {
      dispatch({
        type: FETCH_AUTH_INFO_START,
        payload: {}
      });

      const url = process.env.REACT_APP_API_ENDPOINT_URL + "/auth/status";

      console.log(`GET ${url}`);

      axios
        .get(url)
        .then(response => {
          // handle success
          console.log("AJAX RESPONSE", response);

          dispatch({
            type: FETCH_AUTH_INFO_SUCCESS,
            payload: { authInfo: response.data }
          });
        })
        .catch(statusError => {
          console.log(statusError);

          dispatch({
            type: FETCH_AUTH_INFO_ERROR,
            payload: { statusError }
          });
        });
    }

    const loadAppSettings = () => {
    
      dispatch({
        type: FETCH_APP_SETTINGS_START,
        payload: {}
      });

      const url = process.env.REACT_APP_API_ENDPOINT_URL + "/application/settings";

      console.log(`GET ${url}`);

      axios
        .get(url)
        .then(response => {
          // handle success
          console.log("AJAX RESPONSE for /application/settings", response);

          dispatch({
            type: FETCH_APP_SETTINGS_SUCCESS,
            payload: { settings: response.data }
          });
        })
        .catch(error => {
          console.log(error);

          dispatch({
            type: FETCH_APP_SETTINGS_ERROR,
            payload: { error }
          });
        });
    }

    if (statusError !== null) {
      console.log("Status: Error", statusError);
      return <h3>An error was detected while loading, please reload the application.</h3>;
    } 
    else if (statusLoading == false && authInfo == null) {
      // if authInfo is null, this is the first loading
      console.log("Auth info is null, load data from backend");
      loadAuthStatus();
      return <h1>Verify user...</h1>;
    } 
    else if (statusLoading == true) {
      console.log("Status: Loading auth data...");
      return <h1>Verify user...</h1>;
    } 
    else if (authInfo.isLogged == false) {
      console.log("Status: Not logged, redirecting to auth...");
      // assign the requested url to session storage, so we can redirect the user to that page after login
      sessionStorage.setItem("pre_login_requested_page", this.props.history.location.pathname);
      return <Redirect to="/auth" />;
    } 


    else if (settingsError !== null) {
      console.log("Settings: Error", settingsError);
      return <h3>An error was detected while loading, please reload the application.</h3>;
    } 

    else if (settingsLoading===false && appSettings==null) {
      console.log("Auth status was loaded but settings are not loaded yet");
      loadAppSettings();
      return <h1>Loading settings...</h1>;
    }

    else if (settingsLoading===true) {
      console.log("Settings: Loading app settings...");
      return <h1>Loading settings...</h1>;
    }

    else {
      return this.renderComponentWithLayout();
    }
  }

  renderComponentWithLayout() {
    const { layout, ...otherProps } = this.props;

    otherProps["key"] = otherProps.computedMatch.url;
    console.log("Render layout component with key", otherProps["key"]);

    return React.createElement(layout, otherProps);
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  app: state.app
});

export default connect(mapStateToProps, null)(ProtectedRoute);
