import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


import EmailOrderMessageBuilder from "utils/EmailOrderMessageBuilder";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app resources
import {HTTP_OK, HTTP_PRECONDITION_FAILED} from "constants.js";
import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";
import {isTemporaryOrderId} from "functions/powerOrderFunctions";
import {infoNotification, errorNotification, closeNotification} from "actions/notificationActions";
import {setDuplicateCheckCompleted} from "actions/powerOrderActions";
import {setDuplicateSearchResults} from "actions/powerOrderActions";

const SendEmailWindow = ({ powerOrder, setSendEmailMode, saveOrder, closeOrder, duplicateCheckSkipped }) => {

  const dispatch = useDispatch();

  const [emailText, setEmailText] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [emailTo, setEmailTo] = useState(null);
  const [emailRecipientName, setEmailRecipientName] = useState(null);

  if (duplicateCheckSkipped){
    console.log("No duplicate check was done. Trigger a duplicate check");

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders/duplicate_check";
    console.log("POST " + url);

    const referenceOrderId = isTemporaryOrderId(powerOrder.id) ? null : powerOrder.id;

    const data = {
      referenceOrderId,
      powerMeters: powerOrder.powerMeters
    };

    axios
      .post(url, data)
      .then((response) => {
        console.log("AJAX RESPONSE", response);

        dispatch(setDuplicateCheckCompleted(powerOrder.id, false));
        dispatch(setDuplicateSearchResults(powerOrder.id, response.data.duplicateSearchResults));

        // if no duplicates are found, re-open this email window
        if (response.data.duplicateSearchResults.length==0){
          setSendEmailMode(true);
        }
      })
      .catch((error) => {
        console.log(error);
        // TODO error notification
      });

    setSendEmailMode(false);
    return <React.Fragment />;
  }

  if (emailTo == null) {
    let powerCompany = powerOrder.powerOrderProduct.powerCompany;
    let powerProduct = powerOrder.powerOrderProduct.powerProduct;

    let defaultTo = _(powerCompany.emailTo);

    if (powerProduct.emailTo !== null) {
      defaultTo = _(powerProduct.emailTo);
    }

    let emailCount = defaultTo.split("@").length - 1;

    if (emailCount==1) {
      setEmailTo(`${powerCompany.name} <${defaultTo}>`);
      setEmailRecipientName(powerCompany.name);
    } else if (emailCount>1) {
      setEmailTo(defaultTo);
      setEmailRecipientName("");
    } else {
      setEmailTo("");
      setEmailRecipientName("");
    }
  }

  if (emailSubject == null) {
    let defaultSubject = "";

    if (powerOrder.task != null) {

      const streetAddress = _(powerOrder.task.streetAddress);

      if (isTemporaryOrderId(powerOrder.id)) {
        defaultSubject = `Strømskjema - ${streetAddress}`;
      } else {
        defaultSubject = `Strømskjema - ${streetAddress} - Ref.nr: ${powerOrder.id}`;
      }
    }

    setEmailSubject(defaultSubject);
  }

  if (emailText == null) {
    const builder = new EmailOrderMessageBuilder(powerOrder);
    setEmailText(builder.render());
  }

  const dispatchInfoNotification = (message) => {
    
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));       

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);       

  }

  const dispatchErrorNotification = (message) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            
  }  

  const closeHandler = (e) => {
    setSendEmailMode(false);
  };

  const saveTransferCompleteEvent = (powerOrderId, emailApiResponse) => {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + powerOrderId + "/events";
    console.log("POST " + url);

    const ORDER_TRANSFER_COMPLETE = 4;

    const data = {
      eventTypeId: ORDER_TRANSFER_COMPLETE,
      metadata: {subject: emailSubject, to: emailTo, name: emailRecipientName, emailApiResponse}
    };

    axios
      .post(url, data)
      .then((response) => {
        console.log("AJAX RESPONSE", response);
        setSendEmailMode(false);
        closeOrder();
      })
      .catch((error) => {
        console.log(error);
        dispatchErrorNotification("Cannot save transfer complete event: "+error);
      });
  }

  const sendEmailHandler = (e) => {
    
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders/send_by_email";
    console.log("POST " + url);

    const data = {
      to: emailTo,
      subject: emailSubject,
      text: emailText,
      brokerOfficeId: powerOrder.powerOrderBroker?.brokerOfficeId,
      productId: powerOrder.powerOrderProduct?.productId
    };

    axios
      .post(url, data, {validateStatus: s => (s == HTTP_OK || s==HTTP_PRECONDITION_FAILED) })
      .then((response) => {
        console.log("AJAX RESPONSE", response);

        if (response.status==HTTP_OK){

          const {emailApiResponse} = response.data;
          saveOrder(PowerOrderStatusType.TRANSFER_COMPLETE, (savedPowerOrder)=>{
            dispatchInfoNotification("The email was sent");
            saveTransferCompleteEvent(savedPowerOrder.id,emailApiResponse);
          });

        } else if (response.status==HTTP_PRECONDITION_FAILED){
          
          const configurationErrors = response.data.configurationErrors;
          dispatchErrorNotification(<div>Configuration errors found: {configurationErrors.map(item=><div>* {item}</div>)}</div>, false);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatchErrorNotification("An error was detected while sending the email: "+error);
      });
  };

  return (
    <React.Fragment>
      <div className="Full-Page-Overlay" onClick={closeHandler}></div>

      <div className="Send-Email-Window">
        <div className="Inner-Content">
          <CustomInput
            labelText="Til"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: `${emailTo}` }}
          />

          <CustomInput
            labelText="Emne"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ value: emailSubject, onChange: (e) => setEmailSubject(e.target.value) }}
          />

          <textarea
            style={{ resize: "none", width: "100%", height: "40vh" }}
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}></textarea>

          <div style={{ textAlign: "right" }}>
            <Button color="white" onClick={closeHandler}>
              Avbrytt
            </Button>

            <Button
              disabled={emailTo == "" || emailSubject == "" || emailText == ""}
              color="primary"
              style={{ marginLeft: "10pt" }}
              onClick={sendEmailHandler}>
              SEND
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SendEmailWindow;
