import React,{useState} from "react";

// theme components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

// special functionality
import {Magnifier} from "react-image-magnifiers";
import prettyBytes from "pretty-bytes";

// app specific components
import EmailHeaderPanel from "views/emails/components/EmailHeaderPanel";


const LARGE_VARIANT = 'large';
const HQ_VARIANT = 'hq';

const PowerOrderEmailAttachmentItem = ({attachment, index}) => {

  const [variant,setVariant] = useState(LARGE_VARIANT);
  const [rotation,setRotation] = useState(0);

  const ROTATION_INCREMENT = 90;

  const base = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/" + attachment.id;


  const viewFileUrl = base;
  const downloadUrl = base + "/download";  

  if (attachment.pageCount>0) {

    const pagePreviews = [];

    for (let i=0;i<attachment.pageCount;i++){
      const currentPage = i+1;
      const key = `${attachment.id}_${currentPage}`;

      let imageSrc = `${base}/preview/${variant}/${currentPage}`;

      if (rotation!=0) {
        imageSrc += "?rotate=" + rotation;
      }      

      pagePreviews.push( (
          <div key={key}>
            <h4>Side {currentPage}/{attachment.pageCount}</h4>
              <Magnifier imageSrc={ imageSrc } />
          </div>
        ));
    }

    return (
      <React.Fragment>
        {index>0 && <hr />}
        <GridContainer style={{width:"100%"}}>
          <GridItem md={6}>
            <strong>{attachment.filename}</strong><br /><em>({prettyBytes(attachment.filesize)})</em>
          </GridItem>
          <GridItem md={6} style={{textAlign:"right"}}>
            
            {rotation==0 && <Button color="white" size="sm" className="Text-Button" onClick={e=>setRotation(ROTATION_INCREMENT)}>+90</Button>}
            {rotation!=0 && <Button color="success" size="sm" className="Text-Button" onClick={e=>setRotation( (rotation+ROTATION_INCREMENT) % 360 )}>+90</Button>}

            {variant==LARGE_VARIANT && <Button color="white" size="sm" className="Text-Button" onClick={e=>setVariant(HQ_VARIANT)}>HQ</Button>}
            {variant==HQ_VARIANT && <Button color="success" size="sm" className="Text-Button" onClick={e=>setVariant(LARGE_VARIANT)}>HQ</Button>}

            <a href={viewFileUrl} target="_blank"><Button color="white" size="sm" className="Text-Button">Åpne</Button></a>
            <a href={downloadUrl} target="_blank"><Button color="white" size="sm" className="Icon-Button">Last ned</Button></a>
          </GridItem>            
        </GridContainer>

        {pagePreviews}
      </React.Fragment>
      ); 
  } else {
    return (
        <div>
          {index>0 && <hr />}
        <GridContainer style={{width:"100%"}}>
          <GridItem md={6}>
            <strong>{attachment.filename}</strong><br /><em>({prettyBytes(attachment.filesize)})</em>
          </GridItem>
          <GridItem md={6} style={{textAlign:"right"}}>
            <a href={viewFileUrl} target="_blank"><Button color="white" size="sm" className="Text-Button">Åpne</Button></a>
            <a href={downloadUrl} target="_blank"><Button color="white" size="sm" className="Icon-Button">Last ned</Button></a>
          </GridItem>            
        </GridContainer>
          
          <div>No preview available.</div>
        </div>
        )
  }
}

export default function EmailOrderInfoArea({ powerOrderEmail : {email}, emailAttachments }) {

  return (
    <React.Fragment>
      
      <EmailHeaderPanel email={email} showLink={true} />

      <div className="Email-Body" dangerouslySetInnerHTML={{ __html: email.filteredHtmlContent }} />

      {emailAttachments.map( (item,index)=><PowerOrderEmailAttachmentItem key={index} attachment={item.attachment} index={index} />) }      

    </React.Fragment>
  );
}
