export default class BrokerSettings {
  static BROKER_PORTAL_ROLE = "broker";
  static OFFICE_ADMIN_PORTAL_ROLE = "office_admin";
  static CHAIN_ADMIN_PORTAL_ROLE = "chain_admin";

  constructor() {
    this.id = null;
    this.brokerId = null;
    this.portalRole = null;
    this.portalPermissions = "[]";
    this.createdAt = null;
    this.updatedAt = null;
  }
}
