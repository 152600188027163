import React from "react";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific components
import { formatDate, convertNullToString as _ } from "utils.js";

export default function BrokerChainForm({ brokerChain, classes, setEditMode }) {
  return (
    

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit broker chain: <big>{brokerChain.name}</big>
              </h4>
            </CardHeader>
            <CardBody>
              <form>
                <CustomInput
                  labelText="Navn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: _(brokerChain.name) }}
                />

                <CustomInput
                  labelText="Orgnummer"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: _(brokerChain.orgNum) }}
                />

                <CustomInput
                  labelText="Telefon"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: _(brokerChain.telefon) }}
                />

                <CustomInput
                  labelText="Konfigurasjonsnavn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: _(brokerChain.configName) }}
                />

                <CustomInput
                  labelText="Data source"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: _(brokerChain.source) }}
                />



                <CustomInput
                  labelText="API dato"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{ readOnly: true, value: formatDate(brokerChain.apiDate) }}
                />
              </form>
            </CardBody>

            <CardFooter  style={{ justifyContent: "space-between" }}>
              <Button onClick={e=>setEditMode(false)}>
                Avbtryt
              </Button>            
              <Button color="danger" disabled={true}>
                Lagre
              </Button>
            </CardFooter>
          </Card>
    
  );
}
