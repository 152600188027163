import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific components
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{errorNotification, closeNotification} from "actions/notificationActions";

import {UPDATE_BROKER_OFFICE_PRODUCT_VALUE} from "actions/types";
import {DELETE_BROKER_OFFICE_PRODUCT_VALUE} from "actions/types";


import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);

const ProductValueForm = ({defaultValue, brokerOfficeId, productId, close}) => {

  const [value,setValue] = useState(defaultValue);

  const dispatch = useDispatch();



  const dispatchErrorNotification = (message) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);   
  }

  const saveProductValue = () => {
    console.log("Save product value:", brokerOfficeId, productId, value);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + brokerOfficeId + "/product/" + productId + "/product_value";

    console.log(`POST ${url}`);
    
    axios.post(url, {productValue: value})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: UPDATE_BROKER_OFFICE_PRODUCT_VALUE,
          payload: {
            brokerOfficeId,
            productId,
            productValueEntity: response.data
          }
        });

        //dispatchSuccessNotification("Product value saved");

        close();

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  }


  const deleteProductValue = () => {
    console.log("Delete product value:", brokerOfficeId, productId);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + brokerOfficeId + "/product/" + productId + "/product_value";

    console.log(`DELETE ${url}`);
   
    axios.delete(url)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: DELETE_BROKER_OFFICE_PRODUCT_VALUE,
          payload: {
            brokerOfficeId,
            productId
          }
        });

        //dispatchWarningNotification("Product value deleted");

        close();

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  }


  const inputEnterHandler = e => {

    if(e.keyCode == 13){
         if (value=="")
          deleteProductValue();
         else
          saveProductValue();
    }
  }    


  return (
    <GridContainer>
      <GridItem md={8}>
        <CustomInput
          labelText="Verdi"
          formControlProps={{
            fullWidth: false
          }}
          inputProps={{ name: "product_value", autoFocus: true, defaultValue: _(value), onKeyDown: inputEnterHandler, onChange:e=>{setValue(e.target.value)} }}
        />
      </GridItem>
      <GridItem md={4} style={{ textAlign: "right" }}>
        <Button color="danger" style={{ marginTop: "15pt" }} onClick={e=>{saveProductValue()}}>
          Lagre
        </Button>
      </GridItem>
      <GridItem md={12}>
        <div style={{ fontSize: "8pt", background: "#EFFCFD", padding: "3pt" }}>
          Press ENTER to save and go to the next product.
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default function BrokerOfficeAccountingTable(props) {
  const classes = useStyles();
  const { products, brokerOffice } = props;

  const tableCellClasses = classes.tableCell;

  const [currentItem,setCurrentItem] = useState(null);


  const tableHead = ["Strømleverandør", "Produkt", "Meglerkjede verdi", "Meglerkontor verdi"];

  return (
    <Card>

      <CardBody>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {products.map((item, key) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: key % 2 === 0
                });

                // broker office value - editable
                let displayValue;
                let defaultValue  = null;

                if (item.productValues.length==0) {
                  displayValue = <em>Ingen verdi</em>;
                }
                else if (item.productValues.length==1) {
                  displayValue = item.productValues[0].productValue;
                  defaultValue = item.productValues[0].productValue;
                }
                else if (item.productValues.length>0) {
                  displayValue = <strong>Multiple values</strong>;
                }


                // company chain value - readonly
                let displayCompanyChainValue;

                if (item.companyChainProductValue==null) {
                  displayCompanyChainValue = <em>Ingen verdi</em>;
                } else {
                  displayCompanyChainValue = item.companyChainProductValue.productValue;

                  if (item.productValues.length > 0) {
                     displayCompanyChainValue = <span style={{color:"red",textDecoration:"line-through"}}>{displayCompanyChainValue}</span>;
                  }
                }

                const highlightStyle = item.configuredForWebProtocol==true ? {fontWeight: "400"} : {fontStyle:"italic",color:"#777777"};

                return (
                  <React.Fragment key={item.id}>
                    <TableRow
                      hover={true}
                      className={classes.tableRow + " " + tableRowClasses}
                      onClick={(e) => setCurrentItem(key) }>
                      <TableCell className={tableCellClasses} style={highlightStyle}>{item.powerCompanyName}</TableCell>
                      <TableCell className={tableCellClasses} style={highlightStyle}>{item.name}</TableCell>
                      <TableCell className={tableCellClasses}>{displayCompanyChainValue}</TableCell>
                      <TableCell className={tableCellClasses}>{displayValue}</TableCell>
                    </TableRow>
                    {currentItem==key && 
                    <TableRow>
                      <TableCell colSpan="5" className={tableCellClasses}>
                        
                        <ProductValueForm defaultValue={defaultValue} brokerOfficeId={brokerOffice.id} productId={item.id} close={e=>setCurrentItem(null)} />

                      </TableCell>
                    </TableRow>}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}
