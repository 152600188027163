export const scrollIntoView = () => {
  const className = "Top-Scroll-Anchor";
  let elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
    elements[0].scrollIntoView(true);
  }
};

export const getVerticalScroll = () => {
  let elements = document.getElementsByTagName("html");
  if (elements.length > 0) {
    let vpos = elements[0].scrollTop;
    return vpos;
  }

  return 0;
};
