import React, { useState } from "react";

// material-ui/core resources
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

// theme resources
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// app resources
import Protocol from "classes/Protocol";

const useStyles = makeStyles(styles);

const WebotCustomMessage = ({
  estate,
  sendCustomSms,
  enabled,
  getTakeoverSms,
  getMovingInServicesSms,
  getNewConstructionSms,
  getForeclosureSms,
  getCommercialTakeoverSms,
  getMovingInSms,
  getMovingOutSms
}) => {
  const classes = useStyles();

  const [messageTypeSwitchOn, setMessageTypeSwitchOn] = useState(false);

  const [protocolType, setProtocolType] = useState(null);
  const [customSmsPhoneNr, setCustomSmsPhoneNr] = useState("");
  const [customSmsMessage, setCustomSmsMessage] = useState("");

  const handleCustomSmsPhoneNrChange = (e) => setCustomSmsPhoneNr(e.target.value);
  const handleCustomSmsMessageChange = (e) => setCustomSmsMessage(e.target.value);

  const handleTakeoverSelect = (e) => {
    setProtocolType(Protocol.TAKEOVER_TYPE);
    setCustomSmsMessage(getTakeoverSms());
  };

  const handleMovingInServicesSelect = (e) => {
    setProtocolType(Protocol.MOVING_IN_SERVICES_TYPE);
    setCustomSmsMessage(getMovingInServicesSms());
  };

  const handleNewConstructionSelect = (e) => {
    setProtocolType(Protocol.NEW_CONSTRUCTION_TYPE);
    setCustomSmsMessage(getNewConstructionSms());
  };

  const handleForeclosureSelect = (e) => {
    setProtocolType(Protocol.FORECLOSURE_TYPE);
    setCustomSmsMessage(getForeclosureSms());
  };

  const handleCommercialTakeoverSelect = (e) => {
    setProtocolType(Protocol.COMMERCIAL_TAKEOVER_TYPE);
    setCustomSmsMessage(getCommercialTakeoverSms());
  };

  const handleMovingInSelect = (e) => {
    setProtocolType(Protocol.MOVING_IN_TYPE);
    setCustomSmsMessage(getMovingInSms());
  };

 const handleMovingOutSelect = (e) => {
    setProtocolType(Protocol.MOVING_OUT_TYPE);
    setCustomSmsMessage(getMovingOutSms());
  };

  return (
    <div className="Custom-Message-Table">
      <div className="Table-Row">
        <div className="Table-Cell-1">Send til</div>
        <div className="Table-Cell-2">
          {messageTypeSwitchOn == false && (
            <CustomInput
              labelText="Telefonnummer"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                value: customSmsPhoneNr,
                onChange: handleCustomSmsPhoneNrChange,
                disabled: enabled == false,
              }}
            />
          )}

          {messageTypeSwitchOn == true && (
            <CustomInput labelText="Epost" formControlProps={{ fullWidth: true, disabled: true }} />
          )}
        </div>
        <div className="Table-Cell-3">
          <div className="Inline-Block">
            {false && (
              <Switch
                disabled
                checked={messageTypeSwitchOn}
                onChange={(event) => {
                  setMessageTypeSwitchOn(event.target.checked);
                }}
                value="checked"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            )}
          </div>
        </div>

        <div className="Table-Cell-4">
          {enabled == true && (
            <Button
              disabled={customSmsPhoneNr.length != 8 || customSmsMessage.length < 43 || protocolType == null}
              color="primary"
              size="sm"
              onClick={(e) => sendCustomSms(customSmsPhoneNr, customSmsMessage, protocolType)}>
              Send
            </Button>
          )}

          {enabled == false && (
            <Button disabled size="sm">
              Send
            </Button>
          )}
        </div>
      </div>
      <div className="Table-Row">
        <div className="Table-Cell-1"></div>
        <div className="Table-Cell-2">
          <TextareaAutosize
            style={{ width: "100%" }}
            value={customSmsMessage}
            disabled={enabled == false}
            onChange={handleCustomSmsMessageChange}
            rowsMin={3}
            rowsMax={10}
            placeholder="Tekst(minst 43 tegn)"
          />
        </div>
      </div>
      <div className="Table-Row">
        <div className="Table-Cell-1"></div>
        <div className="Table-Cell-2">
          <div className={classes.checkboxAndRadio}>
            <FormControl component="fieldset">
              <RadioGroup row name="position" defaultValue="">
                {estate.takeoverProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.TAKEOVER_TYPE}
                    control={
                      <Radio
                        onClick={handleTakeoverSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Overtakelsesprotokoll"
                  />
                )}
                {estate.movingInServicesProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.MOVING_IN_SERVICES_TYPE}
                    control={
                      <Radio
                        onClick={handleMovingInServicesSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Digitalt Strømskjema"
                  />
                )}
                {estate.newConstructionProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.NEW_CONSTRUCTION_TYPE}
                    control={
                      <Radio
                        onClick={handleNewConstructionSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Nybygg"
                  />
                )}
                {estate.foreclosureProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.FORECLOSURE_TYPE}
                    control={
                      <Radio
                        onClick={handleForeclosureSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Tvangssalg"
                  />
                )}

                {estate.commercialTakeoverProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.COMMERCIAL_TAKEOVER_TYPE}
                    control={
                      <Radio
                        onClick={handleCommercialTakeoverSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Næring"
                  />
                )}

                {estate.movingInProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.MOVING_IN_TYPE}
                    control={
                      <Radio
                        onClick={handleMovingInSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Innflytting"
                  />
                )}

                {estate.movingOutProtocol !== null && (
                  <FormControlLabel
                    value={Protocol.COMMERCIAL_TAKEOVER_TYPE}
                    control={
                      <Radio
                        onClick={handleMovingOutSelect}
                        icon={<FiberManualRecord className={classes.radioUnchecked} />}
                        checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    label="Utflytting"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebotCustomMessage;
