import React, {useState} from "react";


// material-ui/icons
import Check from "@material-ui/icons/Check";
import AddAlert from "@material-ui/icons/AddAlert";
import CommentIcon from '@material-ui/icons/Comment';
import Warning from "@material-ui/icons/Warning";

// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Success from "components/Typography/Success";

// core components
import Button from "components/CustomButtons/Button.js";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import materialCopyIcon from "app_assets/img/material-copy-icon.png";

import CustomDialog from "app_components/CustomDialog";

import SmsRecipientWindow from "./SmsRecipientWindow";

import { formatDateTime } from "utils.js";


import {copyLinkToClipboard} from "utils.js";





const ProtocolItem = (props) => {

  const {
    commonProps,
    protocolType,
    showConfirmation,
    protocol,
    protocolName,
    buttonColor,
    popperId,
    popperOpen,
    anchorEl,
    setAnchorEl,
    handleOpenSendToClick,
    protocolSupported
  } = props;

  const {
    sendSmsGroup,
    activateProtocol,
    estate,
    createDefaultList
  } = commonProps;


  const [modal,setModal] = useState(false);
  
  const protocolActivated  = (protocol===null) ? false : true;
  const protocolCompleted = protocolActivated && protocol.submitted;


  let protocolUrl = null;

  if (protocolActivated) {
    protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
  }

  const inactiveProtocolMessage = 'Protokollen er deaktivert, men vil bli gjenåpnet ved utsendelse av link. Dersom nødvendig, tøm protokollen ved å åpne linken og trykke på "Avslutt protokoll" etter utsendelse.';

  let protocolActivationMessage;
  if (protocolSupported) protocolActivationMessage = <span>Aktivere <b>{protocolName}</b> ?</span>;
  else protocolActivationMessage = <span><span style={{color:"red"}}><Warning fontSize="large" /></span>  <b>"{protocolName}"</b> er ikke aktivert for dette oppdraget. Vil du sende likevel?</span>;

  return (

      <React.Fragment>

        {showConfirmation && <CustomDialog modal={modal} setModal={setModal} ok={()=>activateProtocol(protocolType)} title={protocolName} message={protocolActivationMessage} />}

        {protocolActivated==true && protocol.active==false && <div style={{marginTop:"20pt"}}></div>}
        {protocolActivated==true && protocol.active==false && <SnackbarContent message={<h5>{inactiveProtocolMessage}</h5>} icon={AddAlert} color="rose" />}

        <div className={"Message-Table" + (protocolSupported ? " ": " unsupported")}>
          {protocolActivated==false && (
              <div className="Table-Row">
                <div className="Table-Cell-1">{protocolName}</div>
                <div className="Table-Cell-2">
                  <div className="No-Protocol-Info">
                    <CommentIcon /> {protocolName} er ikke aktivert
                  </div>
                </div>
                <div className="Table-Cell-3"></div>
                <div className="Table-Cell-4">
                  {showConfirmation==false && <Button color={buttonColor} size="sm" onClick={e=>activateProtocol(protocolType)}>Aktivere</Button>}
                  {showConfirmation==true && <Button color={buttonColor} size="sm" onClick={e=>setModal(true)}>Aktivere</Button>}
                </div>
              </div>
            )}

            {protocolActivated==true && (
              <div className="Table-Row">
                <div className="Table-Cell-1">
                  {protocolName}
                </div>
                <div className="Table-Cell-2">

                    <input className="Protocol-Link" id={`${protocolName}Link`} defaultValue={protocolUrl} readOnly style={{width:"100%"}} />
                    {protocolCompleted==true && <Success><Check fontSize="small" /> {formatDateTime(protocol.submittedDate)}</Success>}  

                </div>
                <div className="Table-Cell-3">

                  <div className="Copy-Link">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          copyLinkToClipboard(`${protocolName}Link`);
                        }}>
                        <img src={materialCopyIcon} />
                      </a>
                  </div>  
                </div>

                <div className="Table-Cell-4">

                  {
                    <React.Fragment>
                      <Button color="primary" size="sm" onClick={handleOpenSendToClick}>Send til...</Button>

                      <SmsRecipientWindow popperId={popperId} popperOpen={popperOpen} anchorEl={anchorEl} estate={estate} setAnchorEl={setAnchorEl} 
                      
                       send={smsRecipientList=>sendSmsGroup(smsRecipientList,protocolType)} defaultSmsRecipientList={createDefaultList()} protocolName={protocolName} />
                    </React.Fragment>

                  }

                </div>
              </div>
            )}
        </div>
      </React.Fragment>  
    );
}

export default ProtocolItem;