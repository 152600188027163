export default class CompanyChain {

  static INTERNAL_SOURCE = "internal";

  constructor() {
    this.id = null;
    this.name = null;
    this.orgNum = null;
    this.phone = null;
    this.configName = null;
    this.source = null;
    this.created = null;
    this.modified = null;
    this.apiDate = null;
  }
}
