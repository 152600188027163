import React from "react";

import OrderTabButton from "./OrderTabButton";
import EmailTabButton from "./EmailTabButton";

export default function FormTabHeader({ emailId, currentOrderId=null, currentEmail=false, connectedOrderIds }) {

  return (
    <div className="Form-Tab-Header">
      <EmailTabButton current={currentEmail} text="Epost" emailId={emailId} />


      {connectedOrderIds.map((id, index) => (
        <OrderTabButton current={currentOrderId!==null && id.toString()===currentOrderId.toString()} key={index} text={`Ordre ${index + 1}`} orderId={id} />
      ))}
    </div>
  );
}
