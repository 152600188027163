// action types
import { DRAG_AND_DROP_ATTACHMENT } from "actions/types";
import { DELETE_NEW_ORDER_FROM_EMAIL } from "actions/types";

import { FETCH_EMAIL_RESULTS_START } from "actions/types";
import { FETCH_EMAIL_RESULTS_SUCCESS } from "actions/types";
import { FETCH_EMAIL_RESULTS_ERROR } from "actions/types";
import { DISCARD_EMAIL_RESULTS } from "actions/types";
import { RELOAD_EMAIL_RESULTS } from "actions/types";

import { FETCH_EMAIL_ITEM_START } from "actions/types";
import { FETCH_EMAIL_ITEM_SUCCESS } from "actions/types";
import { FETCH_EMAIL_ITEM_ERROR } from "actions/types";
import { DISCARD_EMAIL_ITEM } from "actions/types";

import { ADD_NEW_ORDER_TO_EMAIL } from "actions/types";

import { SET_EMAIL_FILTERS } from "actions/types";

import { SET_EMAIL_RESPONSIBLE_USER_MODE} from "actions/types";
import { SET_SELECTED_EMAIL_IDS} from "actions/types";

import { REPLACE_TEMPORARY_ORDER_ID } from "actions/types";

// drop area types
import { DROPBOX_DROP_AREA } from "views/emails/components/constants.js";
import { EMAIL_ATTACHMENT_AREA } from "views/emails/components/constants.js";
import { ORDER_ATTACHMENT_AREA } from "views/emails/components/constants.js";

// drag and drop item types
//import { EMAIL_ATTACHMENT } from "views/emails/components/constants.js";
//import { ORDER_ATTACHMENT } from "views/emails/components/constants.js";

import {APP_LOGOUT_SUCCESS} from "../actions/types";

import {UPDATE_EMAIL_LOCKS} from "actions/types";
import {UPDATE_EMAIL_ITEM_LOCK} from "actions/types";


const listingDataTemplate = {
  loading: false,
  error: null,
  
  results: null,
  currentPage: null,
  totalPages: null,
  totalResults: null,

  filters: {
    inspected: false,
    responsibleUser: null
  },

  responsibleUserMode: false,
  selectedEmailIds: []
}

const createInitialState = () => ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: {
    
  }
});

const createDataItemInitialState = () => ({
  loading: false,
  error: null,
  entity: null,
  unsortedAttachments: [],
  connectedOrderIds: []
});


export default function(state = createInitialState(), action) {

  let { workingData } = state;

  switch (action.type) {

    case FETCH_EMAIL_ITEM_START: {
      console.log("Email action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...createDataItemInitialState(),
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_EMAIL_ITEM_SUCCESS: {
      console.log("Email action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...createDataItemInitialState(),
        entity: action.payload.entity,
        unsortedAttachments: [...action.payload.entity.attachments]
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_EMAIL_ITEM_ERROR: {
      console.log("Email action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...createDataItemInitialState(),
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }

    case DISCARD_EMAIL_ITEM: {
      console.log("Email action", action.type, action);

      let id = action.payload.id;
      delete workingData[id];

      return {
        ...state,
        workingData
      };
    }    

    case FETCH_EMAIL_RESULTS_START: {
      console.log("Email action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_EMAIL_RESULTS_SUCCESS: {
      console.log("Email action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,
        results: action.payload.data.results,
        currentPage: action.payload.data.currentPage,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults            
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_EMAIL_RESULTS_ERROR: {
      console.log("Email action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }

    case DISCARD_EMAIL_RESULTS: {
      console.log("Email action", action.type, action);

      let listingData = {
        ...listingDataTemplate
      };

      return {
        ...state,
        listingData
      };
    }


    case RELOAD_EMAIL_RESULTS: {
      console.log("Email action", action.type, action);

      // when results are reloaded(after an email is marked as inspected)
      // the current page is restored

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        currentPage: state.listingData.currentPage,
        filters: state.listingData.filters
      }

      return {
        ...state,
        listingData
      };      
    }


    case SET_EMAIL_FILTERS: {
      console.log("Email action", action.type, action);

      const filters = {
        ...state.listingData.filters,
        ...action.payload
      }

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters
      };

      return {
        ...state,
        listingData
      };
    }     

    case SET_EMAIL_RESPONSIBLE_USER_MODE: {
      console.log("Email action", action.type, action);

      const responsibleUserMode = action.payload;

      let listingData = {
        ...state.listingData,
        responsibleUserMode
      };

      return {
        ...state,
        listingData
      };
    }

    case SET_SELECTED_EMAIL_IDS: {
      console.log("Email action", action.type, action);

      const selectedEmailIds = action.payload;
      let listingData = state.listingData;

      listingData.selectedEmailIds = selectedEmailIds;

      return {
        ...state,
        listingData
      };   
    }

    case ADD_NEW_ORDER_TO_EMAIL: {
      console.log("Email action", action.type, action);

      const temporaryOrderId = action.payload.temporaryOrderId;
      const emailId = action.payload.emailId;

      const workingDataItem = workingData[emailId];
    
      workingDataItem.connectedOrderIds.push(temporaryOrderId);
      workingData[emailId] = {...workingDataItem};     

      return {
        ...state,
        workingData
      };
    }

    case REPLACE_TEMPORARY_ORDER_ID: {
      console.log("Email action", action.type, action);

      const {temporaryOrderId, realOrderId} = action.payload;

      for (let emailId in workingData) {
        const workingDataItem = workingData[emailId];  

        var index = workingDataItem.connectedOrderIds.indexOf(temporaryOrderId);

        if (index !== -1) {
            workingDataItem.connectedOrderIds[index] = realOrderId;
        }        
      }

      return {
        ...state,
        workingData
      };      
    }


    case UPDATE_EMAIL_LOCKS: {
      console.log("Email action", action.type, action);

      const {locks} = action.payload;

      const lockIndex = {};

      for (let i=0;i<locks.length;i++){
        const lock = locks[i];
        lockIndex[ lock.emailId ] = lock;
      }


      let listingData = state.listingData;

      if (listingData.results !==null){
        for (let i=0;i<listingData.results.length;i++) {
          const email = listingData.results[i];

          if (lockIndex[email.id] !==undefined) {

            const lock = lockIndex[email.id];

            email.isLocked = true;
            email.lockedByUserId = lock.userId;
            email.lockedByName = lock.lockedByName;
            email.lockedAt = lock.createdAt;
          } else {
            email.isLocked = false;
            email.lockedByUserId = null;
            email.lockedByName = null;
            email.lockedAt = null;            
          }
        }  
      }

      return {
        ...state,
        listingData: {...listingData}
      }
    }

    case UPDATE_EMAIL_ITEM_LOCK: {
      console.log("Email action", action.type, action);

      const {id, lock} = action.payload;

      let listingData = state.listingData;

      if (listingData.results !==null){
        for (let i=0;i<listingData.results.length;i++) {
          
          const email = listingData.results[i];

          if (email.id==id){

            if (lock==null){
              email.isLocked = false;
              email.lockedByUserId = null;
              email.lockedByName = null;
              email.lockedAt = null;                      
            } else {
              email.isLocked = true;
              email.lockedByUserId = lock.userId;
              email.lockedByName = lock.lockedByName;
              email.lockedAt = lock.createdAt;              
            }

            break;
          }
        }  
      }

      return {
        ...state,
        listingData: {...listingData}
      }
    }


    case DRAG_AND_DROP_ATTACHMENT:
      return dragAndDropAttachmentReducer(state, action);
    case DELETE_NEW_ORDER_FROM_EMAIL:
      return deleteNewOrderFromEmailReducer(state, action);
    case APP_LOGOUT_SUCCESS: {
      console.log("Email action", action.type, action);

      return createInitialState();
    }    
    default:
      return state;
  }
}


/**
 * Common abbreviations in comments:
 *
 * EAA - email attachment area
 * NOA - new order area
 * DBOX - dropbox area
 * TOID - temporary order id
 *
*/

function dragAndDropAttachmentReducer(state, action) {
  const attachment = action.payload.attachment;
  const temporaryOrderId = action.payload.temporaryOrderId;
  const emailId = attachment.emailId;
  const workingData = state.workingData;
  const workingDataItem = workingData[emailId];

  switch (action.payload.dropArea) {

    /*
     *  save a reference to the new order and remove the attachment from unsorted attachments
     * 
    */
    case DROPBOX_DROP_AREA: {

      console.log(`DROPBOX_DROP_AREA drop`, action);
    
      workingDataItem.connectedOrderIds.push(temporaryOrderId);
      workingDataItem.unsortedAttachments = workingDataItem.unsortedAttachments.filter(item => item.id != attachment.id);
      workingData[emailId] = {...workingDataItem};     

      return {
        ...state,
        workingData
      };
    }

    case EMAIL_ATTACHMENT_AREA: {

      console.log(`[emailReducer] EMAIL_ATTACHMENT_AREA drop`, action);

      workingDataItem.unsortedAttachments.push(attachment);

      return {
        ...state,
        workingData
      };
    }

    case ORDER_ATTACHMENT_AREA: {

      console.log("[emailReducer] ORDER_ATTACHMENT_AREA drop",action);

      workingDataItem.unsortedAttachments = workingDataItem.unsortedAttachments.filter(item => item.id != attachment.id);
      workingData[emailId] = {...workingDataItem};
      
      return {
        ...state,
        workingData
      };
    }

    default:
      break;
  }
}

function deleteNewOrderFromEmailReducer(state, action) {
  const emailId = action.payload.emailId;
  const workingData = state.workingData;
  const workingDataItem = workingData[emailId];  
  const temporaryOrderId = action.payload.temporaryOrderId;
  const attachments = action.payload.attachments;

  console.log("deleteNewOrderReducer", action);

  console.log("restore attachments",attachments);

  // copy all attachments from deleted order back to unsorted attachments
  attachments.forEach( function(element, index) {
    workingDataItem.unsortedAttachments.push(element);
  });

  // remove the temporary order reference from connectedOrderIds
  workingDataItem.connectedOrderIds = workingDataItem.connectedOrderIds.filter(item=>item != temporaryOrderId);
  workingData[emailId] = {...workingDataItem};

  return {
    ...state,
    workingData
  };
}
