export default class PowerOrderTaskInformation {
  constructor(orderId) {
    this.id = null;
    this.orderId = orderId;
    this.streetAddress = null;
    this.postCode = null;
    this.postLocation = null;
    this.floor = null;
    this.apartmentNumber = null;
  }
}
