import { FETCH_AUTH_INFO_START } from "../actions/types";
import { FETCH_AUTH_INFO_SUCCESS } from "../actions/types";
import { FETCH_AUTH_INFO_ERROR } from "../actions/types";

import { APP_LOGIN_START } from "../actions/types";
import { APP_LOGIN_SUCCESS } from "../actions/types";
import { APP_LOGIN_ERROR } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";


const createInitialState = () => ({
  loginLoading: false,
  loginError: null,

  statusLoading: false,
  statusError: null,
  authInfo: null
});

export default function(state = createInitialState(), action) {
  switch (action.type) {
    case FETCH_AUTH_INFO_START: {
      console.log("Auth action", action.type, action);

      return {
        ...createInitialState(),
        statusLoading: true
      };
    }

    case FETCH_AUTH_INFO_SUCCESS: {
      console.log("Auth action", action.type, action);

      const { authInfo } = action.payload;

      return {
        ...createInitialState(),
        authInfo
      };
    }

    case FETCH_AUTH_INFO_ERROR: {
      console.log("Auth action", action.type, action);

      const { statusError } = action.payload;

      return {
        ...createInitialState(),
        statusError
      };
    }

    case APP_LOGIN_START: {
      return {
        ...createInitialState(),
        loginLoading: true
      };
    }

    case APP_LOGIN_SUCCESS: {
      const { authInfo } = action.payload;

      return {
        ...createInitialState(),
        authInfo
      };
    }

    case APP_LOGIN_ERROR: {
      const { loginError } = action.payload;

      return {
        ...createInitialState(),
        loginError
      };
    }

    case APP_LOGOUT_SUCCESS: {
      console.log("Auth action", action.type, action);

      return createInitialState();
    }    

    default:
      return state;
  }
}
