import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";


const createInitialState = () => ({
  items: [
  
  ]
});
 
export default function(state = createInitialState(), action) {
  let items = state.items;

  switch (action.type) {
    case PUSH_NOTIFICATION:
      items.push(action.payload);

      return {
        ...state,
        items
      };
    case CLOSE_NOTIFICATION:
      let id = action.payload.id;

      items = items.filter(item => id != item.id);

      return {
        ...state,
        items
      };

    case APP_LOGOUT_SUCCESS: {
      console.log("Notification action", action.type, action);

      return createInitialState();
    }

    default:
      return state;
  }
}
