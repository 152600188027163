import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import MailOutline from "@material-ui/icons/MailOutline";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// app specific
import ValidationErrorList from "views/fragments/ValidationErrorList";
import { infoNotification, errorNotification, closeNotification } from "actions/notificationActions";
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";
import { HTTP_OK, HTTP_BAD_REQUEST } from "constants.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { UPDATE_POWER_PRODUCT } from "actions/types.js";

const useStyles = makeStyles(styles);

export default function ProductDetails({ product, close }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const updatePowerProduct = product => {
    dispatch({
      type: UPDATE_POWER_PRODUCT,
      payload: {
        powerCompanyId: product.powerCompanyId,
        product
      }
    });    
  }

  const save = (entity) => {
    console.log("Save data:", entity);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_product/" + product.id;

    console.log(`POST ${url}`);

    axios
      .patch(url, entity, {
        validateStatus: (status) => {
          return status == HTTP_OK || status == HTTP_BAD_REQUEST;
        }
      })
      .then((response) => {
        console.log("AJAX RESPONSE", response);

        if (response.status == HTTP_OK) {
          updatePowerProduct(response.data);

          dispatchInfoNotification("Product saved");
          close();
        } else if (response.status == HTTP_BAD_REQUEST) {
          const validationErrors = response.data.validationErrors;
          dispatchErrorNotification(<ValidationErrorList validationErrors={validationErrors} />, false);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      });
  };

  const dispatchInfoNotification = (message) => {
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);
  };

  const dispatchErrorNotification = (message, autoClose = true) => {
    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message, notificationId));

    if (autoClose) {
      setTimeout(() => {
        dispatch(closeNotification(notificationId));
      }, 5000);
    }
  };

  const onDescriptionChange = e => {

    product.description = e.target.value;
    updatePowerProduct(product);
  }

  const onNameChange = e => {

    product.name = e.target.value;
    updatePowerProduct(product);
  }

  return (
    <Card className="Product-Details">
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <MailOutline />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          Edit power product: <big>{product.name}</big>
        </h4>
      </CardHeader>
      <CardBody>
        <form>
          <CustomInput
            labelText="Navn"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ value: _(product.name), onChange: onNameChange }}
          />

          <TextareaAutosize
            style={{ width: "100%", resize: "vertical", border: "1px solid #ddd", padding: "5px" }}
            value={_(product.description)}
            rowsMin={3}
            rowsMax={10}
            onChange={onDescriptionChange}
          />

          <CustomInput
            labelText="Logo"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{ readOnly: true, value: _(product.logoFilename) }}
          />

          <div className={classes.checkboxAndRadio}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  checked={product.active}
                  tabIndex={-1}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                  }}
                  onChange={null}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label="Aktiv"
            />
          </div>
        </form>
      </CardBody>

      <CardFooter style={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Avbrytt</Button>
        <Button color="primary" onClick={(e) => save(product)}>
          Lagre
        </Button>
      </CardFooter>
    </Card>
  );
}
