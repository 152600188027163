import React from "react";

import { useDrop } from "react-dnd";
import {EMAIL_ATTACHMENT} from "./constants.js";

// app specific styles and graphics
import dragContainerBackground from "app_assets/img/drag_container_bg.png";

export default function DropboxArea({ onDrop }) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [EMAIL_ATTACHMENT],
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  let classNames = ["Dropbox-Area"];

  if (isActive) {
    classNames.push("Active");
  } else if (canDrop) {
    classNames.push("CanDrop");
  }

  return (
    <div
      ref={drop}
      className={classNames.join(" ")}
      style={{ backgroundImage: `url("${dragContainerBackground}")` }}></div>
  );
}
