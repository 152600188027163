import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import {UPDATE_BROKER_OFFICE} from "actions/types.js";
import {validateStatus} from "functions/brokerOfficeFunctions";
import {closeTabTo} from "actions/tabActions";


// app specific components
import { isTemporaryId, createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";
import {HTTP_OK, HTTP_BAD_REQUEST} from "constants.js";
import ValidationErrors from "./ValidationErrors";


export default function BrokerOfficeEditForm({brokerOffice,classes, setEditMode, history}) {

  const dispatch = useDispatch();

  const temporaryId = isTemporaryId(brokerOffice.id);

  const updateBrokerOffice = entity => {
    dispatch({
      type: UPDATE_BROKER_OFFICE,
      payload: {
        id: brokerOffice.id,
        entity: brokerOffice
      }
    });
  }

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    brokerOffice[name] = value;
    updateBrokerOffice(brokerOffice);
  };

  const onActiveStatusChange = e => {
    const value = e.target.checked;
    brokerOffice.active = value;
    updateBrokerOffice(brokerOffice);
  }

  const dispatchInfoNotification = (message) => {
  
  const notificationId = createRandomNotificationId();

  dispatch(infoNotification(message, notificationId));       

  setTimeout(() => {
    dispatch(closeNotification(notificationId));
  }, 1500);       

}

const dispatchErrorNotification = (message, autoClose=true) => {

  const notificationId = createRandomNotificationId();

  dispatch(errorNotification(message,notificationId));            

  if (autoClose){
    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);          
  }
}


  const save = (entity) => {
    
    console.log("Save data:", entity);

    let url;
    let axiosHttpMethod;
    let preSaveId = entity.id;

    if (temporaryId) {
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + entity.companyChainId + "/broker_offices";
      axiosHttpMethod = axios.post;
      
      console.log(`POST ${url}`);

    } else {
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + entity.id;
      axiosHttpMethod = axios.patch;

      console.log(`PATCH ${url}`);
    }
    
    axiosHttpMethod(url, entity, {validateStatus})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        if (response.status===HTTP_OK) {

          updateBrokerOffice(response.data);
          dispatchInfoNotification("Broker office successfully saved");

          if (temporaryId) {
            // close the tab after save
            dispatch(closeTabTo("/broker_office/" + preSaveId));

            // ... and go back to order list
            history.push("/broker_office/"+response.data.id);    

            // TODO - discard temp
          } else {
            setEditMode(false);
          }
         

        } else if (response.status===HTTP_BAD_REQUEST) {

          const validationErrors =  response.data.validationErrors;
          dispatchErrorNotification(<ValidationErrors validationErrors={validationErrors} />, false);
        }

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })        

  }


  return (
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit broker office: <big>{brokerOffice.name}</big>
              </h4>
            </CardHeader>
  
              <CardBody>
              <form>
                <CustomInput
                  labelText="Navn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "name",value:_(brokerOffice.name), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Juridisknavn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "legalName",value:_(brokerOffice.legalName), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Visningsnavn"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "displayName",value:_(brokerOffice.displayName), onChange: onTextFieldChange}}
                />                

                <CustomInput
                  labelText="Organisasjonnummer"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "orgNum",value:_(brokerOffice.orgNum), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Epost"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "email",value:_(brokerOffice.email), onChange: onTextFieldChange}}
                />


                <CustomInput
                  labelText="Telefon"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "phone",value:_(brokerOffice.phone), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Adresse"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "address",value:_(brokerOffice.address), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Postnummer"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "zipCode",value:_(brokerOffice.zipCode), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="Poststed"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "city",value:_(brokerOffice.city), onChange: onTextFieldChange}}
                />

                <CustomInput
                  labelText="URL"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{name: "url",value:_(brokerOffice.url), onChange: onTextFieldChange}}
                />


                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={brokerOffice.active}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        onChange={onActiveStatusChange}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Aktiv"
                  />
                </div>
                
              </form>
            </CardBody>



            <CardFooter  style={{ justifyContent: "space-between" }}>
              <Button disabled={temporaryId} onClick={e=>setEditMode(false)}>
                Avbtryt
              </Button>            
              <Button color="danger" onClick={e=>save(brokerOffice)}>
                Lagre
              </Button>
            </CardFooter>

          </Card>
    )
}