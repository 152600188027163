import { FETCH_APP_SETTINGS_START } from "../actions/types";
import { FETCH_APP_SETTINGS_SUCCESS } from "../actions/types";
import { FETCH_APP_SETTINGS_ERROR } from "../actions/types";

import { APP_LOGOUT_SUCCESS } from "../actions/types";


/*
 * Supported settings:
 
   accountingLinkEnabled
   customerSupportUsers
   transactionCategories
*/

const createInitialState = () => ({
  loading: false,
  error: null,
  settings: null,
});

export default function (state = createInitialState(), action) {
  switch (action.type) {
    case FETCH_APP_SETTINGS_START: {
      console.log("Auth action", action.type, action);

      return {
        ...createInitialState(),
        loading: true,
      };
    }

    case FETCH_APP_SETTINGS_SUCCESS: {
      console.log("Auth action", action.type, action);

      const { settings } = action.payload;

      return {
        ...createInitialState(),
        settings,
      };
    }

    case FETCH_APP_SETTINGS_ERROR: {
      console.log("Auth action", action.type, action);

      const { error } = action.payload;

      return {
        ...createInitialState(),
        error,
      };
    }


    case APP_LOGOUT_SUCCESS: {
      console.log("Tab reducer action", action.type, action);

      return createInitialState();
    }

    default:
      return state;
  }
}
