import React, { useState, useEffect } from "react";


import { useDispatch } from "react-redux";
import axios from "axios";

// theme components
import Button from "components/CustomButtons/Button.js";

const PersonalSettlementSection = ({estate}) => {

  const dispatch = useDispatch();

  const [isLoading,setIsLoading] = useState(false);
  const [errorResult,setErrorResult] = useState(null);
  const [apiResult,setApiResult] = useState(null);

  


  if (apiResult===null && errorResult===null && isLoading===false) {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estate/" + estate.id + "/personal_settlement_links";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        console.log("AJAX RESPONSE", response);
        setApiResult(response.data);
      })
      .catch(error => {
        console.log(error);
        setErrorResult(error.response.data);
      }).finally( () => {
        setIsLoading(false);
      });

    setIsLoading(true);
  }

  

  return (
      <React.Fragment>


          {isLoading && "Loading..."}
          {apiResult!==null && (

            <div style={{textAlign:"center"}}>
              <br />
              <br />

              <a target="_blank" href={apiResult.settlementBuyer}><Button color="primary" size="sm">Oppgjørskjema kjøper</Button></a> 

              <br />
              <br />

              <a target="_blank" href={apiResult.settlementSeller}><Button color="primary" size="sm">Oppgjørskjema selger</Button></a> 
            </div>

            )}
          {errorResult !== null && errorResult}



        
      </React.Fragment>

    );
};



export default PersonalSettlementSection;