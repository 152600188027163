import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


// materialui
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// core components
import Card from "components/Card/Card.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app resources
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";
import { createRandomNotificationId } from "utils.js";

import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_START } from "actions/types";
import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_POWER_PRODUCTS_ERROR } from "actions/types";

import { UPDATE_BROKER_OFFICE_POWER_PRODUCTS } from "actions/types";



const BrokerOfficePowerProductTab = ({brokerOffice}) => {


  const id = brokerOffice.id;

  const dispatch = useDispatch();

  const productTabDataItem = useSelector((state) => {
    return state.brokerOffices.powerProductTabData[id] || null;
  });

  console.log("Broker office product tab data item:", productTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_BROKER_OFFICE_POWER_PRODUCTS_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/power_products";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_POWER_PRODUCTS_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_BROKER_OFFICE_POWER_PRODUCTS_ERROR,
          payload: { id, error }
        });
      });
  };

  if (productTabDataItem == null) {
    fetchItem(id);
  }

  const brokerOfficeProducts = productTabDataItem != null ? productTabDataItem.entity: null;

  const getSelectedValues = (elementId) => {
    const selector = "#" + elementId + " option:checked";
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0).map((element) => element.value);
  };

  const addProductHandler = e => {
    const selectedValues = getSelectedValues("available_products");

    selectedValues.forEach((value) => {


      let productAlreadyAdded = false;

      brokerOfficeProducts.brokerOfficeProductList.map(item=>{
        if (value == item.powerProductId) {
          productAlreadyAdded = true;
        }
      });

      if (productAlreadyAdded){
        console.log("productAlreadyAdded: ",value);
        return;
      }

      
      
      const newEntity = {
        powerProductId: value,
        sortKey: null // will get a sortKey automatically on save
      };

      console.log("new entity", newEntity);

      brokerOfficeProducts.brokerOfficeProductList.push(newEntity);
      
    });

    updateBrokerOffficeProducts(brokerOfficeProducts);
  };

  const removeProductHandler = e => {
    const selectedValues = getSelectedValues("enabled_products");

    console.log("remove:",selectedValues);
    const newBrokerOfficeProductList = brokerOfficeProducts.brokerOfficeProductList.filter((item) => {
      return  !selectedValues.includes(''+item.powerProductId);
    });
    console.log(newBrokerOfficeProductList);

    brokerOfficeProducts.brokerOfficeProductList = newBrokerOfficeProductList;

    updateBrokerOffficeProducts(brokerOfficeProducts);  
  }

  const moveUpHandler = e => {
    const selectedValues = getSelectedValues("enabled_products");
    console.log("move up:",selectedValues);

    if (selectedValues.length==0) return;

    const selectedProducts = [];

    
    let firstElementIndex = null;

    for (let i=0; i<brokerOfficeProducts.brokerOfficeProductList.length;i++) {
      const currentItem = brokerOfficeProducts.brokerOfficeProductList[i];

      if (selectedValues.includes(''+currentItem.powerProductId)) {
        firstElementIndex = i;
        break;
      }
    }

    if (firstElementIndex==0){
      console.log("first element is already the first one");
      return;
    }

    console.log("firstElementIndex", firstElementIndex);

    
    const newBrokerOfficeProductList = brokerOfficeProducts.brokerOfficeProductList.filter((item) => {
      if (selectedValues.includes(''+item.powerProductId)) {
        selectedProducts.push(item);
        return false;
      } else {
        return true;
      }
    });    
    
    newBrokerOfficeProductList.splice(firstElementIndex-1,0,...selectedProducts);

    brokerOfficeProducts.brokerOfficeProductList = newBrokerOfficeProductList;

    updateBrokerOffficeProducts(brokerOfficeProducts);    
  }

  const moveDownHandler = e => {
    const selectedValues = getSelectedValues("enabled_products");
    console.log("move down:",selectedValues);

    if (selectedValues.length==0) return;

    const selectedProducts = [];

    let lastElementIndex = null;

    for (let i=0; i<brokerOfficeProducts.brokerOfficeProductList.length;i++) {
      const currentItem = brokerOfficeProducts.brokerOfficeProductList[i];

      if (selectedValues.includes(''+currentItem.powerProductId)) {
        lastElementIndex = i;
      }
    }

    /*
    if (lastElementIndex==0){
      console.log("first element is already the first one");
      return;
    }
    */

    console.log("lastElementIndex", lastElementIndex);

    
    const newBrokerOfficeProductList = brokerOfficeProducts.brokerOfficeProductList.filter((item) => {
      if (selectedValues.includes(''+item.powerProductId)) {
        selectedProducts.push(item);
        return false;
      } else {
        return true;
      }
    });    

    console.log(newBrokerOfficeProductList);
    console.log("typeof lastElementIndex:",typeof(lastElementIndex));

    const insertionPoint = lastElementIndex+1;
    console.log("insertion point:",insertionPoint);
    newBrokerOfficeProductList.splice(insertionPoint,0,...selectedProducts);

    brokerOfficeProducts.brokerOfficeProductList = newBrokerOfficeProductList;

    updateBrokerOffficeProducts(brokerOfficeProducts);  
  }

  const updateBrokerOffficeProducts = (entity) => {

    dispatch({
      type: UPDATE_BROKER_OFFICE_POWER_PRODUCTS,
      payload: { id, entity }
    });    
  }
  
  const save = () => {

    let requestPayload = [];

    brokerOfficeProducts.brokerOfficeProductList.forEach((item,index)=>{
      requestPayload.push({
        powerProductId: item.powerProductId,
        sortKey: index+1
      });
    });

    console.log("Save data", requestPayload);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_office/" + id + "/power_products";

    console.log(`POST ${url}`);

    axios
      .post(url, requestPayload)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        updateBrokerOffficeProducts(response.data);

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Save successful"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 3000);            
      })
      .catch(error => {

        console.log(error);

        // TODO add error notification
      })       
  }


  const getProductById = (powerProductId) => {
    
    for (let i in brokerOfficeProducts.availableProducts){
      const product = brokerOfficeProducts.availableProducts[i];

      if (product.id==powerProductId){
        return product;
      }
    }
  }

  const formatProductName = (product) => {
    const powerCompanyName = product.powerCompanyName.toUpperCase();
    return `${powerCompanyName} - ${product.name}`;
  }

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {productTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (
    <Card>
      <GridContainer style={{ marginTop: "25px",paddingLeft:"20px",paddingRight:"20px" }}>
        <GridItem md={12}>
          
          <GridContainer>
            <GridItem md={5}>
              <p>
                <strong>Available products:</strong>
              </p>

              <select
                id="available_products"
                multiple
                style={{ width: "100%", height: "50vh", border: "2px solid #cccccc" }}>
                {brokerOfficeProducts.availableProducts.map((item) => {

                  if (item.active) {
                    return (<option key={item.id} value={item.id} style={{padding:"2px",fontSize:"13px"}}>{formatProductName(item)}</option>); 
                  } else{
                    return (<option key={item.id} disabled={true} value={item.id} style={{color:"gray",fontStyle:"italic",padding:"2px",fontSize:"13px"}}>{formatProductName(item)}</option>)
                  }

                })}
              </select>
            </GridItem>
            <GridItem md={2} style={{ textAlign: "center", marginTop: "15vh" }}>
              <Button  color="success" onClick={addProductHandler} style={{padding:"5pt"}}>
                <ArrowRightIcon style={{width:"25pt",height:"25pt"}} />
              </Button>
              <br />
              <Button  color="rose" onClick={removeProductHandler} style={{padding:"5pt"}}>
                <ArrowLeftIcon style={{width:"25pt",height:"25pt"}} />
              </Button>
             



            </GridItem>
            <GridItem md={5}>
              <p>
                <strong>Configured products:</strong>
              </p>

              <select id="enabled_products" multiple style={{ width: "100%", height: "50vh", border: "2px solid #4caf50" }}>
                {brokerOfficeProducts.brokerOfficeProductList.map((item, index) => {

                  const product = getProductById(item.powerProductId);

                  return <option key={item.powerProductId} value={item.powerProductId} style={{padding:"2px",fontSize:"13px"}}>{index+1}. {formatProductName(product)}</option>
                })}             
              </select>

              <div style={{textAlign:"center"}}>
                <Button color="white" style={{padding:"5pt"}}><ArrowDropUpIcon style={{width:"25pt",height:"25pt"}} onClick={moveUpHandler}  /></Button>
                <Button color="white" style={{padding:"5pt"}}><ArrowDropDownIcon style={{width:"25pt",height:"25pt"}} onClick={moveDownHandler} /></Button>
              </div>

            </GridItem>
          </GridContainer>
        </GridItem>

      </GridContainer>

      <GridContainer style={{ paddingLeft:"20px",paddingRight:"20px", paddingBottom: "20px" }}>
        <GridItem xs={6} sm={6} md={6}>

        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <div style={{ textAlign: "right" }}>
            <Button color="rose" onClick={e=>save()}>Lagre</Button>
          </div>
        </GridItem>
      </GridContainer>
    </Card>
  );


  if (productTabDataItem == null) {
    return renderLoading();
  } else if (productTabDataItem.loading == true) {
    return renderLoading();
  } else if (productTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }    
}

export default BrokerOfficePowerProductTab;