import React from 'react';

import AuthPage from '../views/authentication/AuthPage';
import LogoutPage from '../views/authentication/LogoutPage';

import OrderListPage from '../views/power_orders/OrderListPage';
import OrderEditPage from '../views/power_orders/OrderEditPage';

import EmailListPage from '../views/emails/EmailListPage';
import EmailEditPage from '../views/emails/EmailEditPage';

import PowerCompanyListPage from '../views/power_companies/PowerCompanyListPage';
import PowerCompanyEditPage from '../views/power_companies/PowerCompanyEditPage';

import EstateListPage from '../views/estates/EstateListPage';
import EstateViewPage from '../views/estates/EstateViewPage';

import StatisticsPage from '../views/statistics/StatisticsPage';
import AccountingPage from '../views/accounting/AccountingPage';
import AccountingDashboardPage from '../views/accounting/AccountingDashboardPage';

import BrokerChainListPage from '../views/broker_chains/BrokerChainListPage';
import BrokerChainEditPage from '../views/broker_chains/BrokerChainEditPage';

import BrokerOfficeListPage from '../views/broker_offices/BrokerOfficeListPage';
import BrokerOfficeEditPage from '../views/broker_offices/BrokerOfficeEditPage';

import OtherProductListPage from '../views/other_products/OtherProductListPage';


import AdminLayout from '../views/layouts/AdminLayout';
import AuthLayout from '../views/layouts/AuthLayout';

import ProtectedRoute from '../app_components/ProtectedRoute';
import PublicRoute from '../app_components/PublicRoute';

import { Switch, Route, Redirect } from "react-router-dom";


export default function AppRoutes(props) {




  const history = props.history;


  return (
          

          <Switch>

            {/* Public routes - only login */}

            <PublicRoute path="/auth" component={AuthPage} layout={AuthLayout} history={history} />
            <Route path="/logout" component={LogoutPage} history={history} />


            {/* Test, temporary routes */}


             
            {/* Protected application routes */}

            <ProtectedRoute  path="/orders" component={OrderListPage} history={history} layout={AdminLayout} />
            <ProtectedRoute  path="/order/:id" component={OrderEditPage} history={history} layout={AdminLayout} />

            
            <ProtectedRoute  path="/emails" component={EmailListPage} history={history} layout={AdminLayout} />
            <ProtectedRoute  path="/email/:id" component={EmailEditPage} history={history} layout={AdminLayout} />

            <ProtectedRoute path="/estates" component={EstateListPage} history={history} layout={AdminLayout} />
            <ProtectedRoute path="/estate/:id" component={EstateViewPage} history={history} layout={AdminLayout} />

          
            <ProtectedRoute path="/power_companies" component={PowerCompanyListPage}  history={history} layout={AdminLayout} />
            <ProtectedRoute path="/power_company/:id" component={PowerCompanyEditPage} history={history} layout={AdminLayout} />
          
            <ProtectedRoute path="/other_products" component={OtherProductListPage}  history={history} layout={AdminLayout} />

            <ProtectedRoute path="/statistics" component={StatisticsPage} history={history} layout={AdminLayout} />
          
            <ProtectedRoute path="/accounting/dashboard" component={AccountingDashboardPage} history={history} layout={AdminLayout}/>
            <ProtectedRoute path="/accounting" component={AccountingPage} history={history} layout={AdminLayout}/>
          
            <ProtectedRoute path="/broker_chains" component={BrokerChainListPage} history={history} layout={AdminLayout} />
            <ProtectedRoute path="/broker_chain/:id" component={BrokerChainEditPage} history={history} layout={AdminLayout} />


            <ProtectedRoute path="/broker_offices" component={BrokerOfficeListPage} history={history} layout={AdminLayout} />
            <ProtectedRoute path="/broker_office/:id" component={BrokerOfficeEditPage} history={history} layout={AdminLayout} />

            <Route exact path="/">
              <Redirect to="/orders" />
            </Route>          



        

            {/* Default not found content */}

            <Route path='*' render={()=><div>Not found!</div>} />
          </Switch>
  );
}





