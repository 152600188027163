import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// @material-ui/icons
import MoneyIcon from "@material-ui/icons/AttachMoney";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific components
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{errorNotification,closeNotification} from "actions/notificationActions";

import {UPDATE_POWER_PRODUCT_CPO} from "actions/types";


import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);

const ProductCpoForm = ({defaultValue, powerCompanyId, productId, close}) => {

  const [value,setValue] = useState(defaultValue);

  const dispatch = useDispatch();


  const saveCpoValue = () => {
    console.log("Save product value:", powerCompanyId, productId, value);


    const dispatchErrorNotification = (message, autoClose=true) => {

      const notificationId = createRandomNotificationId();

      dispatch(errorNotification(message,notificationId));            

      if (autoClose){
        setTimeout(() => {
          dispatch(closeNotification(notificationId));
        }, 5000);          
      }
    }



    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_company/" + powerCompanyId + "/product/" + productId + "/cpo";

    // TODO - use delete http verb if the value is empty string

    console.log(`POST ${url}`);

    let axiosHttpMethod = axios.post;
    
    axiosHttpMethod(url, {cpo: value})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: UPDATE_POWER_PRODUCT_CPO,
          payload: {
            powerCompanyId,
            productId,
            cpoEntity: response.data
          }
        });
        close();

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  }

  const inputEnterHandler = e => {

    if(e.keyCode == 13){
         saveCpoValue();
      }
  }    


  return (
    <GridContainer>
      <GridItem md={8}>
        <CustomInput
          labelText="Verdi"
          formControlProps={{
            fullWidth: false
          }}
          inputProps={{ name: "product_value", autoFocus: true, defaultValue: _(value), onKeyDown: inputEnterHandler, onChange:e=>{setValue(e.target.value)} }}
        />
      </GridItem>
      <GridItem md={4} style={{ textAlign: "right" }}>
        <Button color="danger" style={{ marginTop: "15pt" }} onClick={e=>{saveCpoValue()}}>
          Lagre
        </Button>
      </GridItem>
      <GridItem md={12}>
        <div style={{ fontSize: "8pt", background: "#EFFCFD", padding: "3pt" }}>
          Press ENTER to save.
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default function PowerCompanyAccountingTable(props) {
  const classes = useStyles();
  const { products, powerCompany } = props;

  const tableCellClasses = classes.tableCell;

  const [currentItem,setCurrentItem] = useState(null);


  const tableHead = ["Strømleverandør", "Produkt", "Verdi"];

  return (
    <Card>

      <CardHeader>
          <CardIcon color="rose">
            <MoneyIcon style={{color:"white"}} />
        </CardIcon>
        <h4>
          Økonomi
        </h4>
      </CardHeader>

      <CardBody>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {products.map((item, key) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: key % 2 === 0
                });

                let displayValue;
                let defaultValue  = null;

                if (item.productCpoValues.length==0) {
                  displayValue = <em>Ingen verdi</em>;
                }
                else if (item.productCpoValues.length==1) {
                  displayValue = item.productCpoValues[0].cpo;
                  defaultValue = item.productCpoValues[0].cpo;
                }
                else if (item.productCpoValues.length>0) {
                  displayValue = <strong>Multiple values</strong>;
                }

                return (
                  <React.Fragment key={item.id}>
                    <TableRow
                      hover={true}
                      className={classes.tableRow + " " + tableRowClasses}
                      onClick={(e) => setCurrentItem(key) }>
                      <TableCell className={tableCellClasses}>{powerCompany.name}</TableCell>
                      <TableCell className={tableCellClasses}>{item.name}</TableCell>
                      <TableCell className={tableCellClasses}>{displayValue}</TableCell>
                    </TableRow>
                    {currentItem==key && 
                    <TableRow>
                      <TableCell colSpan="5" className={tableCellClasses}>
                        
                        <ProductCpoForm defaultValue={defaultValue} powerCompanyId={powerCompany.id} productId={item.id} close={e=>setCurrentItem(null)} />

                      </TableCell>
                    </TableRow>}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}
