export default class PowerOrderSourceType {
  static EMAIL = 1;
  static WEB_PROTOCOL = 2;
  static MANUAL = 3;
  static EXTERNAL = 4;

  constructor() {
    this.id = null;
    this.sourceCode = null;
    this.sourceNameEn = null;
    this.sourceNameNo = null;
  }

  static createEmailSourceType() {
    const entity = new PowerOrderSourceType();
    entity.id = PowerOrderSourceType.EMAIL;
    entity.sourceCode = "EMAIL";
    entity.sourceNameEn = "Email";
    entity.sourceNameNo = "Epost";

    return entity;
  }
}
