import React from "react";

// @material-ui/core components
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// app specific components
import { convertNullToString as _ } from "utils.js";

export default function PowerCompanyTermsForm({ powerCompany, terms,  save, termsChangeHandler }) {
  return (
    <Card>
      <CardBody>
        <form>
          <TextareaAutosize style={{ width: "100%",resize:"vertical",border:"1px solid #ddd",padding:"5px" }} value={_(terms.terms)} onChange={termsChangeHandler}  rowsMin={3} rowsMax={10} />
        </form>

        <div style={{ textAlign: "right" }}>
          <Button color="danger" onClick={e=>save(terms)}>Lagre</Button>
        </div>

      </CardBody>
    </Card>
  );
}
