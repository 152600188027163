import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { convertNullToString as _ } from "utils.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);



export default function TransactionCategoryDropdown({transactionCategory, setTransactionCategory, transactionCategories}){

  const classes = useStyles();

  const handleOnChange = e => {

    const value = e.target.value;

    if (value === "") {
      setTransactionCategory(null);
    } else {
      setTransactionCategory(e.target.value);
    }
  };

  const transactionCategoryList = [];
  transactionCategories.map( (parentItem) => {
    transactionCategoryList.push(parentItem);

    parentItem.children.map( (childItem) => {
      childItem._parentItem = parentItem;
      transactionCategoryList.push(childItem);
    });
  });

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Type
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={_(transactionCategory)}
        onChange={handleOnChange}
        inputProps={{
          name: "transactionCategorySelect",
          id: "transaction-category-select",
        }}>
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}>
          Type
        </MenuItem>
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value="">
          Ingen type
        </MenuItem>
        {transactionCategoryList.map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}>
            {item.parentId === null && <span>{item.name}</span>}
            {item.parentId !== null && <span><span style={{color:"#777777",fontStyle:"italic"}}>{item._parentItem.name}</span> &mdash; {item.name}</span>}
          </MenuItem>
        ))}
        ))}
      </Select>
    </FormControl>
  );
}
