import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";

// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
//import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";

// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import BrokerChainListTable from "./components/BrokerChainListTable";
import { FETCH_BROKER_CHAIN_RESULTS_START } from "actions/types";
import { FETCH_BROKER_CHAIN_RESULTS_SUCCESS } from "actions/types";
import { FETCH_BROKER_CHAIN_RESULTS_ERROR } from "actions/types";
import ScrollLock from "app_components/ScrollLock";

// app specific styling
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";

const useStyles = makeStyles(regularTablesStyle);

export default function BrokerChainListPage(props) {

  const { history } = props;


	const PAGE_TITLE = "Meglerkjede";

	useEffect(() => {
		document.title = PAGE_TITLE;

		return function() {
			document.title = Constants.DEFAULT_PAGE_TITLE;
		};
	});

	const classes = useStyles();

  const dispatch = useDispatch();

  dispatch({
  type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title: "Meglerkjede",
      to: "/broker_chains"
    }
  });    


  const listingData = useSelector(state => {
    return state.brokerChains.listingData || null;
  });


  const searchBrokerChain = () => {
    dispatch({
      type: FETCH_BROKER_CHAIN_RESULTS_START,
      payload: {  }
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chains";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_CHAIN_RESULTS_SUCCESS,
          payload: { data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_BROKER_CHAIN_RESULTS_ERROR,
          payload: { error }
        });
      })
  };


  
  const isLoading = listingData.loading;
  const hasData = listingData.entities == null ? false: true;
  const hasError = listingData.error==null ? false: true;

  if (isLoading==false && hasData==false && hasError==false) {
    searchBrokerChain();
  }
  

  console.log("listingData",listingData);

  const renderDefault = () => <h1 style={{ marginTop: "200px" }}>...</h1>;

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {listingData.error.error.message}</h1>;

  const renderSuccess = () => (
    <React.Fragment>
      <ScrollLock path="/broker_chains" />
      <div className="Standard-Page-Content">
        <GridContainer>
          <GridItem md={1}></GridItem>
          <GridItem md={8}>
            <Link to="/broker_chains">
              <Button color="primary" size="sm">
                Meglerkjede
              </Button>
            </Link>

            <Link to="/broker_offices">
              <Button size="sm">Meglerkontorer</Button>
            </Link>
          </GridItem>
          <GridItem md={3}></GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {PAGE_TITLE} ({listingData.entities.length} resultater)
                </h4>

                
                <Button style={{float:"right"}} color="white" onClick={ e=>{
  
                  dispatch({
                    type: FETCH_BROKER_CHAIN_RESULTS_SUCCESS,
                    payload: { data: null }
                  });

                }}>
                  <Refresh />
                </Button>


              </CardHeader>



              <CardBody>
                <BrokerChainListTable
                                    history={history}
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "Kjede",
                    "Fagsystem",
                    "API dato",
                    "Opprettet",
                    "Endret",
                    "Overtakelsesprotokoll",
                    "Digitalt Strømskjema",
                    "Nybygg",
                    "Meglerkontorer"
                  ]}
                  tableData={listingData.entities}
            
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>
    );


  if (listingData.entities !== null) {
    return renderSuccess();
  } else if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else if (listingData.entities == null) {
    return renderDefault();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(listingData)}</h1>
    </React.Fragment>
  );

}
