export default class PowerOrderBroker {
  constructor(orderId, brokerOfficeId, brokerId, neiTakk){
    this.id = null;
    this.orderId = orderId;    
    this.brokerOfficeId = brokerOfficeId;
    this.brokerId = brokerId;
    this.neiTakk = neiTakk;

    // aggregate fields
    this.companyChain = null;
    this.brokerOffice = null;
    this.broker = null;
  }
}