import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// theme components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app specific resources
import { formatDateTime } from "utils.js";
import { createRandomNotificationId} from "utils.js";
import PowerOrderComment from "classes/PowerOrder/PowerOrderComment";
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";


const OrderListComments = (props) => {

  const dispatch = useDispatch();

  const [loadedComments, setLoadedComments] = useState(null);
  const [loadingError, setLoadingError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [newCommentText, setNewCommentText] = useState("");

  const { close, orderId } = props;

  const hasError = loadingError == null ? false : true;
  const hasData = loadedComments == null ? false : true;

  const loadComments = (orderId) => {
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + orderId + "/comments";

    console.log(`GET ${url}`);

    setIsLoading(true);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setLoadedComments(response.data.reverse());
      })
      .catch((error) => {
        console.error("An error has occured during ajax request:", error);

        setLoadingError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const addComment = (content) => {
    const newComment = new PowerOrderComment();
    newComment.orderId = orderId;
    newComment.content = content;

    console.log("Add comment data:", newComment);

    setIsSaving(true);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + orderId + "/comments";

    console.log(`PUT ${url}`);

    axios
      .put(url, newComment)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setNewCommentText("");

        const freshComments = response.data;
        setLoadedComments(freshComments.reverse());

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Comment saved"
          }
        });

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 600);
      })
      .catch((error) => {
        console.log(error);

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "An error occured:"+error,
            color:"danger"
          }
        });

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);
      })
      .finally(() => setIsSaving(false));
  };

  if (isLoading == false) {
    if (hasError==false && hasData == false) {
      loadComments(orderId);
    }
  }

  const MultiLineText = ({ text }) => {
    return text.split("\n").map((line, key) => {
      return (
        <React.Fragment key={key}>
          {line}
          <br />
        </React.Fragment>
      );
    });
  };

  const renderDefault = () => <h1 className="header header-default">...</h1>;

  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">{"Error loading comments:"+loadingError}</h1>;

  const renderSuccess = () => (
    <React.Fragment>
      <GridContainer style={{ width: "100%", paddingTop: "25px", marginBottom: "50px" }}>
        <GridItem md={8}>
          {loadedComments.length > 0 && <div>{loadedComments.length} kommentar(er).</div>}
          {loadedComments.length == 0 && <div>Ingen kommentarer.</div>}

          {loadedComments.map((item) => (
            <div key={item.id} style={{ fontSize: "smaller" }}>
              {formatDateTime(item.createdAt)} - <strong>{item.createdByName}</strong> -{" "}
              <MultiLineText text={item.content} />
            </div>
          ))}
        </GridItem>
        <GridItem md={4}>
          <React.Fragment>
            <textarea
              disabled={isSaving}
              style={{ width: "100%", resize: "vertical", height: "100pt" }}
              name="newComment"
              value={newCommentText}
              onChange={(e) => {
                setNewCommentText(e.target.value);
              }}
            ></textarea>

            <Button disabled={isSaving} color="white" onClick={(e) => close()}>
              Lukk
            </Button>

            <Button
              color="primary"
              disabled={isSaving || (newCommentText.length > 0 ? false : true)}
              onClick={(e) => addComment(newCommentText)}
            >
              Lagre kommentar
            </Button>
          </React.Fragment>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );

  if (isLoading == true) {
    return renderLoading();
  } else if (hasError == true) {
    return renderError();
  } else if (hasData) {
    return renderSuccess();
  } else {
    return renderDefault();
  }
};

export default OrderListComments;
