import React from "react";
import { useDrop } from "react-dnd";

// app specific components
import {ORDER_ATTACHMENT} from "./constants";
import EmailAttachment from "./EmailAttachment";

export default function EmailAttachmentArea(props) {

  const { onDrop, unsortedAttachments, setPreviewAttachment } = props;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ORDER_ATTACHMENT],
    drop: onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  let classNames = ["Email-Attachment-Area"];

  if (isActive) {
    classNames.push("Active");
  } else if (canDrop) {
    classNames.push("CanDrop");
  }

  return (
    <div ref={drop} className={classNames.join(" ")}>
      {unsortedAttachments.map((item,index) => (
        <EmailAttachment key={index} attachment={item} onClick={e=>setPreviewAttachment(item)} />
      ))}
    </div>
  );
}
