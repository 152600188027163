import React from "react";

import { useDispatch } from "react-redux";

// material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

// material-ui/icons
import MoreVert from "@material-ui/icons/MoreVert";

// theme components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import Menu from "@material-ui/core/Menu";

import { SET_POWER_ORDER_RESPONSIBLE_USER_MODE } from "actions/types";

const useStyles = makeStyles(styles);

export default function PageMenu(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResponsibleMode = () => {
    console.log("Enable responsible mode");

    dispatch({
      type: SET_POWER_ORDER_RESPONSIBLE_USER_MODE,
      payload: true,
    });

    setAnchorEl(null);
  };

  return (
    <div>
      <Button justIcon round color="white" onClick={handleClick}>
        <MoreVert className={classes.sidebarMiniIcon} />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleResponsibleMode}>Ansvarlig bruker</MenuItem>
      </Menu>
    </div>
  );
}
