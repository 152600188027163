import React, {useState} from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CardHeader from "components/Card/CardHeader.js";

import CompanyChain from "classes/CompanyChain";

import { formatDate, createTemporaryId, isTemporaryId } from "utils.js";

import BrokerEditForm from "./BrokerEditForm";
import BrokerDetails from "./BrokerDetails";
import BrokerSettingsEditForm from "./BrokerSettingsEditForm";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

import Broker from "classes/Broker";

import {ADD_NEW_BROKER} from "actions/types.js";


const useStyles = makeStyles(styles);

export default function BrokerListTable({ brokers, brokerOffice, companyChain }) {
  
  const VIEW_BROKER_MODE = 1;
  const EDIT_BROKER_MODE = 2;
  const EDIT_SETTINGS_MODE = 3;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentBrokerId,setCurrentBrokerId] = useState(null);
  const [mode, setMode] = useState(null);

  const tableCellClasses = cx({
    [classes.tableCell]: true
  });

  const tableHead = ["ID", "Navn", "Tittel", "Status", "Oppretted dato", "Endret dato"];

  const externalChain = companyChain.source !== CompanyChain.INTERNAL_SOURCE ? true : false;

  const addNewBrokerHandler = e => {
    const temporaryId = createTemporaryId();
    const newBroker = new Broker();

    newBroker.id = temporaryId;
    newBroker.brokerOffice = brokerOffice.id;
    newBroker.active = true;
    newBroker.source= Broker.INTERNAL_SOURCE;

    dispatch({
      type: ADD_NEW_BROKER,
      payload: {
        brokerOfficeId: brokerOffice.id,
        broker: newBroker
      }
    });

    console.log("Add temporary broker with id= " + temporaryId);

    setCurrentBrokerId(temporaryId);
    setMode(EDIT_BROKER_MODE);
  }


  const close = e => {
    setCurrentBrokerId(null);
    setMode(VIEW_BROKER_MODE);
  }  

  const back = e => {
    setMode(VIEW_BROKER_MODE);
  }


  const viewBroker = id => {
    setCurrentBrokerId(id);
    setMode(VIEW_BROKER_MODE);
  }

  const editSettings = id => {
    setCurrentBrokerId(id);
    setMode(EDIT_SETTINGS_MODE);
  }

  const editBroker = id => {
    setCurrentBrokerId(id);
    setMode(EDIT_BROKER_MODE);
  }



  return (
    <Card>
      <CardHeader>
        <div style={{textAlign:"right",margin:"10pt"}}>
          <Button style={{padding:"5px 10px 5px 10px"}} color="white" size="sm" onClick={addNewBrokerHandler}>+</Button>
        </div>
      </CardHeader>
      <CardBody>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {brokers.map((item,key) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: key % 2 === 0
                });

                return (
                  <React.Fragment key={item.id}>
                    <TableRow
                      hover={true}
                      className={classes.tableRow + " " + tableRowClasses}
                      onClick={(e) => viewBroker(item.id) }>
                      <TableCell className={tableCellClasses}>{isTemporaryId(item.id)==false ? item.id: <em>new</em>}</TableCell>
                      <TableCell className={tableCellClasses}>{item.name}</TableCell>
                      <TableCell className={tableCellClasses}>{item.title}</TableCell>
                      <TableCell className={tableCellClasses}>{item.active ? "aktiv" : "inaktiv"}</TableCell>
                      <TableCell className={tableCellClasses}>{formatDate(item.created)}</TableCell>
                      <TableCell className={tableCellClasses}>{formatDate(item.modified)}</TableCell>
                    </TableRow>

                    {currentBrokerId==item.id && 
                    <TableRow>
                      <TableCell colSpan="6" className={tableCellClasses}>
                        {mode==VIEW_BROKER_MODE && <BrokerDetails broker={item} close={close} editBroker={e=>editBroker(item.id)} editSettings={e=>editSettings(item.id)} externalChain={externalChain} />}
                        {mode==EDIT_BROKER_MODE && <BrokerEditForm companyChain={companyChain} broker={item} back={back} />}
                        {mode==EDIT_SETTINGS_MODE && <BrokerSettingsEditForm broker={item} back={back} />}
                      </TableCell>
                    </TableRow>}

                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>{" "}
    </Card>
  );
}
