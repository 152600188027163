import { PUSH_TAB, CLOSE_TAB } from "actions/types";
import { createRandomTabId } from "utils.js";

export const pushTab = (title, to, closeAction = null, closeActions=null) => {
  const action = {
    type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title,
      to,
      closeAction,
      closeActions
    }
  };

  return action;
};

export const closeTabTo = (to) => {
  const action = {
    type: CLOSE_TAB,
    payload: { to }
  };

  return action;
};
