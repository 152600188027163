export default class PowerOrderProduct {
  constructor(orderId, productId) {
    this.id = null;
    this.orderId = orderId;
    this.productId = productId;

    // aggregate fields
    this.powerProduct = null;
    this.powerCompany = null;
  }
}
