export default class BrokerOffice {
  constructor() {
    this.id = null;
    this.companyChainId = null;
    this.externalId = null;
    this.name = null;
    this.legalName = null;
    this.displayName = null;
    this.orgNum = null;
    this.email = null;
    this.phone = null;
    this.address = null;
    this.zipCode = null;
    this.city = null;
    this.url = null;
    this.active = null;
    this.created = null;
    this.modified = null;

    // aggregate field
    this.companyChain = null;
  }
}
