import React from "react";


// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";

import {formatDate} from 'utils.js';

export default function EstateDetailsSection(props) {

  const {estate} = props;

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Oppdrag</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      <table className="Property-Info">
        <tbody>
          <tr>
            <td>Overtagelsesdato</td>
            <td><div>{formatDate(estate.takeoverDate)}
            </div></td>
            <td>Oppdragsnummer</td>
            <td>{estate.taskNumber}</td>
          </tr>
          <tr>
            <td>Task date</td>
            <td>{formatDate(estate.taskDate)}</td>
            <td>Ekstern ID</td>
            <td>{estate.externalId}</td>
          </tr>
          <tr>
            <td>Budaksept</td>
            <td>{formatDate(estate.bidAcceptedDate)}</td>
            <td>Kontraktsmøte</td>
            <td>{formatDate(estate.contractMeetingDate)}</td>
          </tr>
        </tbody>
      </table>

      <hr />

      <table className="Property-Info">
        <tbody>
          <tr>
            <td>Adresse</td>
            <td>{estate.address}</td>
            <td>Oppdragstype</td>
            <td>{estate.taskType}</td>
          </tr>
          <tr>
            <td>Postnummer</td>
            <td>{estate.zipCode}</td>
            <td>Eiertype</td>
            <td>{estate.propertyOwnershipType}</td>
          </tr>
          <tr>
            <td>Poststed</td>
            <td>{estate.city}</td>
            <td>Boligtype</td>
            <td>{estate.propertyType}</td>
          </tr>
          <tr>
            <td>Etasje</td>
            <td>{estate.floor}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>


      <table className="Compact-Property-Info">
        <tbody>
          <tr>
            <td>Knr</td>
            <td>{estate.estateCadastres[0]?.knr}</td>
            <td>Snr</td>
            <td>{estate.estateCadastres[0]?.snr}</td>
            <td>Bnr</td>
            <td>{estate.estateCadastres[0]?.bnr}</td>
            <td>Fnr</td>
            <td>{estate.estateCadastres[0]?.fnr}</td>
            <td>Gnr</td>
            <td>{estate.estateCadastres[0]?.gnr}</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
}
