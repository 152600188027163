import React from "react";


import { useDispatch } from "react-redux";
import axios from "axios";


// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import CommentIcon from '@material-ui/icons/Comment';
import Check from "@material-ui/icons/Check";

// core components
import Button from "components/CustomButtons/Button.js";

// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Success from "components/Typography/Success";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import materialCopyIcon from "app_assets/img/material-copy-icon.png";

import SmsRecipientWindow from "./SmsRecipientWindow";
import DealsCustomMessage from "./DealsCustomMessage";

import {copyLinkToClipboard} from "utils.js";

import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";
import { DISCARD_ESTATE_ITEM } from "actions/types";
import { createRandomNotificationId, formatDateTime } from "utils.js";
import {infoNotification, errorNotification} from "actions/notificationActions";
import Protocol from "classes/Protocol";
import SmsMessageLog from "classes/SmsMessageLog";



const DealsSection = ({estate}) => {

  const dispatch = useDispatch();

  const {dealsBuyerProtocol, dealsSellerProtocol} = estate;

  const dealsBuyerActivated = dealsBuyerProtocol==null ? false : true;
  const dealsSellerActivated = dealsSellerProtocol==null ? false : true;

  const buyerProtocolCompleted = dealsBuyerActivated && dealsBuyerProtocol.submitted;
  const sellerProtocolCompleted = dealsSellerActivated && dealsSellerProtocol.submitted;

  let dealsBuyerUrl, dealsSellerUrl = null;

  if (dealsBuyerActivated) {
    dealsBuyerUrl = `https://mglf.io/e/${dealsBuyerProtocol.ticketId}/${dealsBuyerProtocol.password}`;
  }

  if (dealsSellerActivated) {
    dealsSellerUrl = `https://mglf.io/e/${dealsSellerProtocol.ticketId}/${dealsSellerProtocol.password}`;
  }

  const getDealsBuyerSms = () =>{

    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      "Hei,",
      `Det nærmer seg overlevering av ${estate.address}.`,
      "Trenger du hjelp med flytteprosessen? Vi har gode samarbeidspartnere innen flyttetjenester, flyttevask, og lager.",
      `Les mer her: ${dealsBuyerUrl}`,
      "",
      "Vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }

  const getDealsSellerSms = () =>{

    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      "Hei,",
      `Det nærmer seg overlevering av ${estate.address}.`,
      "Trenger du hjelp med flytteprosessen? Vi har gode samarbeidspartnere innen flyttetjenester, flyttevask, og lager.",
      `Les mer her: ${dealsSellerUrl}`,
      "",
      "Vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }



  const activateProtocol = (type) => {
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/protocol/estate/" + estate.id + "?type=" + type;

    const requestData = {active:true};

    console.log(`POST ${url}`);

    axios
      .post(url, requestData)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);


        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Protocol successfully activated"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);   

        
        dispatch({
          type: DISCARD_ESTATE_ITEM,
          payload: {
            id: estate.id
          }
        });              

      })
      .catch(error => {

        console.log(error);
        dispatch(errorNotification(`Error: ${error.response.data}, response code=${error.response.status}`, createRandomNotificationId()));

      })
  }

  const createDefaultBuyerList = () => {

    const defaultList = [];

    estate.buyers.map((buyer,index)=>{
      defaultList.push({name:`${buyer.firstName} ${buyer.lastName}`,mobile:buyer.mobile,contact_type:SmsMessageLog.BUYER_CONTACT_TYPE});
    });

    return defaultList;
  }

  const createDefaultSellerList = () => {

    const defaultList = [];

    estate.sellers.map((seller,index)=>{
       defaultList.push({name:`${seller.firstName} ${seller.lastName}`,mobile:seller.mobile,contact_type:SmsMessageLog.SELLER_CONTACT_TYPE});
    });

    return defaultList;
  }

  const sendSmsGroup = (smsRecipientList,protocolType) => {

    if (protocolType==Protocol.DEALS_BUYER_TYPE){
      smsRecipientList.map(item=>item.message=getDealsBuyerSms());  
    } else if (protocolType==Protocol.DEALS_SELLER_TYPE){
      smsRecipientList.map(item=>item.message=getDealsSellerSms());  
    }
    
    console.log("Prepare to send messages: ",smsRecipientList);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estate/" + estate.id + "/send_sms?protocol_type=" + protocolType;

    console.log(`POST ${url}`, url);
 
    axios
      .post(url, smsRecipientList)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: DISCARD_ESTATE_ITEM,
          payload: {
            id: estate.id
          }
        });   

        const notificationId = createRandomNotificationId();
        dispatch(infoNotification("SMS messages sent", notificationId));         

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);   

      })
      .catch(error => {

        console.log(error);
        const notificationId = createRandomNotificationId();
        const errorMessage = `An error has occured while sending messages: ${error.response.data}, response code=${error.response.status}`;
        dispatch(errorNotification(errorMessage, notificationId));         
      })
  }

  // popper control
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  let popperOpen1 = Boolean(anchorEl1);
  const popperId1 = popperOpen1 ? 'simple-popper' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  let popperOpen2 = Boolean(anchorEl2);
  const popperId2 = popperOpen2 ? 'simple-popper' : undefined;

  const handleOpenSendDealsBuyer = e => {
    setAnchorEl1(anchorEl1 ? null : e.currentTarget);
  }

  const handleOpenSendDealsSeller = e => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  }

  const inactivePotocolMessage = 'Protokollen er deaktivert, men vil bli gjenåpnet ved utsendelse av link. Dersom nødvendig, tøm protokollen ved å åpne linken og trykke på "Avslutt protokoll" etter utsendelse.';

  return (
      <React.Fragment>

          {false && <div>
            <div>Preview buyer text - temporary for testing</div>
            <textarea readOnly cols="80" rows="10" value={getDealsBuyerSms()} style={{background:"#ccc"}}></textarea>

            <div>Preview seller text - temporary for testing</div>
            <textarea readOnly cols="80" rows="10"  value={getDealsSellerSms()} style={{background:"#ccc"}}></textarea>
          </div>}

          {dealsBuyerActivated==true && dealsBuyerProtocol.active==false && <SnackbarContent message={<h5><strong>Oppgjørsskjema kjøper: </strong>{inactivePotocolMessage}</h5>} icon={AddAlert} color="rose" />}
          {dealsSellerActivated==true && dealsSellerProtocol.active==false && <SnackbarContent message={<h5><strong>Oppgjørsskjema selger: </strong>{inactivePotocolMessage}</h5>} icon={AddAlert} color="rose" />}

          <div className="Message-Table">

            {dealsBuyerActivated==false && (
              <div className="Table-Row">
                <div className="Table-Cell-1">Kjøper</div>
                <div className="Table-Cell-2">
                  <div className="No-Protocol-Info">
                    <CommentIcon /> Deals protokoll(kjøper) er ikke aktivert
                  </div>
                </div>
                <div className="Table-Cell-3"></div>
                <div className="Table-Cell-4">
                  <Button color="danger" size="sm" onClick={e=>activateProtocol(Protocol.DEALS_BUYER_TYPE)}>Aktivere</Button>
                </div>
              </div>
            )}

            {dealsBuyerActivated==true && (
              <div className="Table-Row">
                <div className="Table-Cell-1">Kjøper</div>
                <div className="Table-Cell-2">

                    <input className="Protocol-Link" id="buyerProtocolLink" defaultValue={dealsBuyerUrl} readOnly style={{width:"100%"}} />  
                    {buyerProtocolCompleted==true && <Success><Check /> {formatDateTime(estate.dealsBuyerProtocol.submittedDate)}</Success>}     

                </div>
                <div className="Table-Cell-3">

                  <div className="Copy-Link">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          copyLinkToClipboard("buyerProtocolLink");
                        }}>
                        <img src={materialCopyIcon} />
                      </a>
                  </div>  

                </div>

                

                <div className="Table-Cell-4">
                  <Button color="primary" size="sm" onClick={handleOpenSendDealsBuyer}>Send til...</Button>

                  <SmsRecipientWindow popperId={popperId1} popperOpen={popperOpen1} anchorEl={anchorEl1} estate={estate} setAnchorEl={setAnchorEl1} 
                  send={smsRecipientList=>sendSmsGroup(smsRecipientList,Protocol.DEALS_BUYER_TYPE)} defaultSmsRecipientList={createDefaultBuyerList()} hideSellers={true} protocolName="deals kjøper" />
                </div>
              </div>
            )}


            {dealsSellerActivated==false && (
              <div className="Table-Row">
                <div className="Table-Cell-1">Selger</div>
                <div className="Table-Cell-2">
                  <div className="No-Protocol-Info">
                    <CommentIcon /> Deals protokoll(selger) er ikke aktivert
                  </div>
                </div>
                <div className="Table-Cell-3"></div>
                <div className="Table-Cell-4">
                  <Button color="danger" size="sm" onClick={e=>activateProtocol(Protocol.DEALS_SELLER_TYPE)}>Aktivere</Button>
                </div>
              </div>
            )}


            {dealsSellerActivated==true && (
              <div className="Table-Row">
                <div className="Table-Cell-1">Selger</div>
                <div className="Table-Cell-2">

                    <input className="Protocol-Link" id="sellerProtocolLink" defaultValue={dealsSellerUrl} readOnly style={{width:"100%"}} />  
                    {sellerProtocolCompleted==true && <Success><Check /> {formatDateTime(estate.dealsSellerProtocol.submittedDate)}</Success>}     

                </div>
                <div className="Table-Cell-3">

                  <div className="Copy-Link">
                      <a
                        onClick={e => {
                          e.preventDefault();
                          copyLinkToClipboard("sellerProtocolLink");
                        }}>
                        <img src={materialCopyIcon} />
                      </a>
                  </div>   
                  
                </div>

                
                <div className="Table-Cell-4">
                  <Button color="primary" size="sm" onClick={handleOpenSendDealsSeller}>Send til...</Button>

                  <SmsRecipientWindow popperId={popperId2} popperOpen={popperOpen2} anchorEl={anchorEl2} estate={estate} setAnchorEl={setAnchorEl2} 
                  send={smsRecipientList=>sendSmsGroup(smsRecipientList,Protocol.DEALS_SELLER_TYPE)} defaultSmsRecipientList={createDefaultSellerList()} hideBuyers={true} protocolName="deals selger" />
                </div>
              </div>              
            )}





         

          </div>

          <DealsCustomMessage sendCustomSms={null} enabled={false} />


        
      </React.Fragment>

    );
};



export default DealsSection;