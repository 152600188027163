import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


// core resources
import LinearProgress from '@material-ui/core/LinearProgress';


// theme resources
import Button from "components/CustomButtons/Button.js";

// app specific resources
import { formatDateTime } from "utils.js";

import { createRandomNotificationId} from "utils.js";

import {UPDATE_POWER_ORDER_COMMENTS} from "actions/types";
import PowerOrderComment from "classes/PowerOrder/PowerOrderComment";
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";

// actions
import {addNewPowerOrderComment} from "actions/powerOrderActions";


const UnsavedCommentSection = (props) => {

  const dispatch = useDispatch();

  const [addCommentMode, setAddCommentMode] = useState(false);
  const [newCommentText, setNewCommentText] = useState("");

  const { orderId, commentText } = props;
  let comments = [...props.comments].reverse();

  const hasComments = comments.length > 0;
  const commentCount = comments.length;


  const addComment = (content) => {

    const newComment = new PowerOrderComment();
    newComment.orderId = orderId;
    newComment.content = content;

    dispatch(addNewPowerOrderComment(newComment));   

    setAddCommentMode(false);
    setNewCommentText("");
  };

  const MultiLineText = ({text}) => {
       return text.split("\n").map((line,key) => {
            return <React.Fragment key={key}>{line}<br /></React.Fragment>;
        });
  }

  return (
    <React.Fragment>
      {addCommentMode == false && (
        <Button size="sm" color="white" onClick={(e) => setAddCommentMode(true)}>
          Legg til ny kommentar
        </Button>
      )}

      {addCommentMode == true && (
        <React.Fragment>
          <textarea
            style={{ width: "100%", resize: "vertical", height: "100pt" }}
            name="newComment"
            onChange={(e) => {
              setNewCommentText(e.target.value);
            }}></textarea>
          <div style={{ textAlign: "right" }}>


            <Button color="white" onClick={(e) => setAddCommentMode(false)}>
              Avbryt
            </Button>

            <Button color="primary" disabled={(newCommentText.length>0 ? false : true)} style={{ marginLeft: "10pt" }} onClick={e=>addComment(newCommentText)}>
              Lagre kommentar
            </Button>


          </div>
        </React.Fragment>
      )}


      {hasComments == false && (
        <div className="Field-Preview-Label" style={{ textAlign: "left" }}>
          Ingen kommentarer.
        </div>
      )}
      {hasComments == true && (
        <div className="Section-Header" style={{ background: "none" }}>
          <div className="Section-Title">Kommentarer({commentCount})</div>
        </div>
      )}

      {hasComments == true &&
        comments.map((item, index) => (
          <div key={index} style={{ fontSize: "smaller" }}>
            <span style={{color:"red"}}>Kommentaren er ikke lagret ennå</span> - <MultiLineText text={item.content} />
          </div>
        ))}
    </React.Fragment>
  );
};

export default UnsavedCommentSection;
