import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// theme resources
import Primary from "components/Typography/Primary";

// app resources
import {formatRelativeDateTime, formatDate, convertNullToString as _} from "utils.js";
import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";
import {setSelectedOrderIds} from "actions/powerOrderActions";


// component resources
import StatusFilter from "./StatusFilter.js";
import SourceFilter from "./SourceFilter.js";
import ResponsibleUserFilter from "./ResponsibleUserFilter.js";
import OrderListComments from "./OrderListComments.js";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";



const formatPowerMeters = (firstPowerMeter, powerMeterCount) => {


    if (powerMeterCount==0){
      return <em>Ingen målernummer</em>;
    } else if (powerMeterCount==1){
      return <span>{firstPowerMeter}</span>;
    } else {
      return <span>{firstPowerMeter}<sup>{powerMeterCount}</sup></span>
    }

}



const useStyles = makeStyles(styles);

export default function OrderListTable(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  
  const { loggedUser, history, tableHead, tableData, responsibleUserMode } = props;

  const selectedOrderIds = useSelector(state => {
    return state.powerOrders.listingData.selectedOrderIds;
  });

  const [expandedOrderId, setExpandedOrderId] = useState(null);

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const tableCellClasses = classes.tableCell;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table + ' ' + 'Order-List-Table'}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [classes.tableHeadFontSize]: true
                  });

                  if (prop=="Status") {
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        <StatusFilter />
                      </TableCell>
                      )
                  } else if (prop=="Type") {
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <SourceFilter />
                        </TableCell>
                      )                    
                  }  else if (prop=="Ansvarlig") {
                      return (
                        <TableCell className={tableCellClasses} key={key}>
                          <ResponsibleUserFilter />
                        </TableCell>
                      )                    
                  }  else {
                    return <TableCell className={tableCellClasses} key={key}>{prop}</TableCell>
                  } 
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {

            let isSelected = responsibleUserMode && selectedOrderIds.includes(item.id);

            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: true,
              [classes.tableStripedRow]: key % 2 === 0,
              "Selected-Order": isSelected,
              "Deleted-Order": item.status==PowerOrderStatusType.DELETED //"deleted" status
            });

            let hasComment = item.commentCount > 0;

            return (
              <React.Fragment key={key}>
                <TableRow
                  hover={true}
                  className={classes.tableRow + " " + tableRowClasses}
                  onClick={e => {
                    setExpandedOrderId(null);
                    history.push("/order/" + item.id);                
                  }}>
                  <TableCell className={tableCellClasses + ' ' + 'Order-Id-Cell'} onClick={e=>{

                      if (responsibleUserMode==false) return;

                      const shiftPressed = e.shiftKey;

                      let newSelectedOrderIds = [...selectedOrderIds];

                      if (isSelected){
                          let index = newSelectedOrderIds.indexOf(item.id);
                          if (index > -1) {
                            newSelectedOrderIds.splice(index, 1);
                          }

                          setLastSelectedIndex(null);
                      } else {
                        newSelectedOrderIds.push(item.id);

                        if (shiftPressed==false) {
                          setLastSelectedIndex(key);
                        }

                        if (shiftPressed==true && lastSelectedIndex!==null) {

                          if (key>lastSelectedIndex) {
                            for (let i=lastSelectedIndex+1;i<key;i++) {

                              if (selectedOrderIds.includes(tableData[i].id) === false) {
                                newSelectedOrderIds.push(tableData[i].id);
                              }
                            }
                          }                          
                        }
                      }

                      dispatch(setSelectedOrderIds(newSelectedOrderIds));
                      e.stopPropagation();
                  }}>
                    {responsibleUserMode==true && isSelected==false && <CheckBoxOutlineBlankIcon />}
                    {responsibleUserMode==true && isSelected==true && <Primary><CheckBoxIcon /></Primary>}
                    {responsibleUserMode==false && item.id}
                  </TableCell>
                  <TableCell className={tableCellClasses}>{item.sourceNameNo}</TableCell>
                  
                  <TableCell className={tableCellClasses}>
                    
                    {item.isLocked==true && item.lockedByUserId!==loggedUser.id && <div style={{textAlign:"center",fontWeigth:"100",color:"red"}}><strong>{item.lockedByName}</strong> <br /><em>{formatRelativeDateTime(item.lockedAt)}</em></div>}
                    {item.isLocked==true && item.lockedByUserId==loggedUser.id && <div style={{textAlign:"center",fontWeigth:"100",color:"green"}}><strong>{item.lockedByName}</strong> <br /><em>{formatRelativeDateTime(item.lockedAt)}</em></div>}
                    
                    {item.isLocked==false && <div style={{textAlign:"center"}}>{item.lastUpdatedByName}</div>}

                  </TableCell>

                  <TableCell className={tableCellClasses}>{item.brokerOfficeName}</TableCell>
                  <TableCell className={tableCellClasses}>{item.powerCompanyName}</TableCell>
                  <TableCell className={tableCellClasses}>{item.taskStreetAddress}</TableCell>
                  <TableCell className={tableCellClasses}>
                    <div style={{textAlign:"center"}}>{item.taskPostLocation}<br /><span style={{color:"gray"}}>{item.taskPostCode}</span></div>
                  </TableCell>
                  <TableCell className={tableCellClasses + ' ' + 'Meter-Number-Cell'}>
                    { formatPowerMeters(item.firstPowerMeterNumber, item.powerMeterCount)}
                  </TableCell>
                  <TableCell className={tableCellClasses}>
                  {item.buyerCustomerType==PowerOrderCustomer.PERSON_CUSTOMER_TYPE && `${_(item.buyerFirstName)} ${_(item.buyerLastName)}`}
                  {item.buyerCustomerType==PowerOrderCustomer.COMPANY_CUSTOMER_TYPE && `${_(item.buyerCompanyName)}`}

                  </TableCell>
                  <TableCell className={tableCellClasses}>{formatDate(item.firstPowerMeterReadingDate)}</TableCell>
                  <TableCell className={tableCellClasses + ' ' + 'Comment-Number-Cell'} onClick={e => {

                    e.stopPropagation();

                    if (expandedOrderId == item.id) {
                      setExpandedOrderId(null);
                    } 
                    else {
                      setExpandedOrderId(item.id);
                    }
                  }}>
                  
                    {hasComment===false && <ModeCommentOutlinedIcon fontSize="large"  />}
                    {hasComment===true && <ModeCommentIcon fontSize="large" />}
                    {hasComment===true && <span>{item.commentCount}</span>}


                  
                  </TableCell>
                  <TableCell className={tableCellClasses + ' ' + 'Order-Status-Cell'}>
                    <span className={'Order-Status-' + item.status}>{item.statusNameNo}</span>
                  </TableCell>
                </TableRow>

                {expandedOrderId == item.id && (
                  <tr id={`expanded_panel_${item.id}`}>
                    <td colSpan="12"><OrderListComments orderId={item.id} close={()=>setExpandedOrderId(null)} /></td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}


OrderListTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array
};
