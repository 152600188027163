import PowerOrderCustomer from './PowerOrderCustomer';

export default class PowerOrderBuyer extends PowerOrderCustomer {
  constructor(orderId, customerType){
    super();

    this.id = null;
    this.orderId = orderId;

    this.customerType = customerType;
    this.firstName = null;
    this.lastName = null;
    this.birthDate = null;
    this.phone = null;
    this.email = null;
    this.companyName = null;
    this.companyNumber = null;

    this.billingStreetAddress = null;
    this.billingPostCode = null;
    this.billingPostLocation = null;    
  }
}