import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
//import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { APP_LOGIN_START } from "actions/types";
import { APP_LOGIN_SUCCESS } from "actions/types";
import { APP_LOGIN_ERROR } from "actions/types";

const useStyles = makeStyles(styles);

export default function AuthPage(props) {
  const dispatch = useDispatch();

  const { loginLoading, loginError } = useSelector(state => state.auth);

  const history = props.history;

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  const startLogin = () => {
    console.log("try to authenticate");

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/auth/login";

    dispatch({
      type: APP_LOGIN_START,
      payload: {}
    });

    console.log(`POST ${url}`);

    const bodyFormData = new FormData();
    bodyFormData.set('userName', userName);
    bodyFormData.set('password', password);



    axios
      .post(url, bodyFormData)
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: APP_LOGIN_SUCCESS,
          payload: { authInfo: response.data }
        });


        console.log("Login success, redirect to /");
        if (sessionStorage.getItem("pre_login_requested_page") !== null) {
          history.push(sessionStorage.getItem("pre_login_requested_page"));
        } else {
          history.push("/");
        }

      })
      .catch(loginError => {
        console.log(loginError);

        dispatch({
          type: APP_LOGIN_ERROR,
          payload: { loginError }
        });
      });
  };


  const enterHandler = e => {
    
    if(e.keyCode == 13){
         console.log('Enter pressed', e.target.value);
         startLogin();
      }
      
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimation]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                <h4 className={classes.cardTitle}>Logg inn</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Brukernavn.."
                  id="userName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: userName,
                    onChange: e => setUserName(e.target.value),
                    onKeyDown: enterHandler,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />

                <CustomInput
                  labelText="Passord"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: password,
                    onChange: e => setPassword(e.target.value),
                    onKeyDown: enterHandler,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {loginLoading == false && (
                  <Button color="rose" simple size="lg" block onClick={e=>startLogin()}>
                    Fortsett
                  </Button>
                )}

                {loginLoading == true && (
                  <Button color="rose" disabled simple size="lg" block>
                    Loading...
                  </Button>
                )}
              </CardFooter>

              {loginError != null && (
                <CardFooter>
                  <h3>Login error.</h3>
                </CardFooter>
              )}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
