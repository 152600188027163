import { SET_ESTATE_FROM_DATE_FILTER } from "actions/types";
import { SET_ESTATE_TO_DATE_FILTER } from "actions/types";


export  const setEstateFromDateFilter = value => {
  return {
      type: SET_ESTATE_FROM_DATE_FILTER,
      payload: value
    }
}

export  const setEstateToDateFilter = value => {
  return {
      type: SET_ESTATE_TO_DATE_FILTER,
      payload: value
    }
}