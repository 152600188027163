import React from "react";
import { useDrag } from "react-dnd";

// app specific resources
import {EMAIL_ATTACHMENT} from "./constants";
import {PDF, PNG, JPG, JPEG} from "./fileTypes";

import materialFileIconLarge from "app_assets/img/material-file-icon-large.png";

export default function EmailAttachment(props) {

  const { attachment, onClick } = props;

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: EMAIL_ATTACHMENT, attachment },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  let style = { visibility: isDragging ? "hidden" : "visible" };

  let classNames = ["Attachment-Card"];

  let thumbnailImage = materialFileIconLarge;

  if ([PDF, PNG, JPG, JPEG].includes(attachment.extension)){
    
    if (attachment.pageCount>=1) {
      classNames.push("With-Border");
      thumbnailImage = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/" + attachment.id + "/preview/thumbnail/1";
    }
  }

  return (
    <div ref={dragRef} className={classNames.join(' ')} style={{ ...style }} onClick={onClick}>
   
      <img src={thumbnailImage} />
      {attachment.pageCount>1 && <div className="Page-Count">{attachment.pageCount}</div>} 

      <div className="Title">
        {attachment.filename.length<=10 && attachment.filename}
        {attachment.filename.length>10 && attachment.filename.substr(0,10)+"..."}
      </div>
    </div>
  );
}
