import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import InfoIcon from "@material-ui/icons/Info";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";


const useStyles = makeStyles(styles);

export default function ProductDetails({ product, edit,close }) {
  const classes = useStyles();

  return (
    <Card className="Product-Details">
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <InfoIcon />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>
          <big>{product.name}</big>
        </h4>
      </CardHeader>

      <CardBody>
        <table>
          <tbody>
          <tr>
              <td>Beskrivelse</td>
              <td>{product.description}</td>
            </tr>
          <tr>
              <td>Logo</td>
              <td>{product.logoFilename}</td>
            </tr>
            <tr>
              <td>Aktiv</td>
              <td>{product.active ? "Ja" : "Nei"}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "space-between" }}>
        <Button onClick={close}>Lukk</Button>
        <Button color="primary" onClick={edit}>Redigere</Button>
      </CardFooter>
    </Card>
  );
}
