import React, {useRef, useState} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "debounce";

// material ui
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";


// @material-ui/icons
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";

// core components
import Button from "components/CustomButtons/Button.js";

// app specific assets
import materialResetIcon from "app_assets/img/material-repeat-icon.png";

// theme resources
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

// actions
import {setPowerOrderEstateConfigMode} from "actions/powerOrderActions";

// app specific resources
import {formatDate} from "utils.js";
import { UPDATE_POWER_ORDER_ESTATE } from "actions/types";

import PowerOrderEstate from "classes/PowerOrder/PowerOrderEstate";

// app components
import EstateConfigurator from "./EstateConfigurator";

import {convertNullToString as _} from "utils.js";


const useStyles = makeStyles(styles);

function getSuggestionValue(suggestion) {
  return suggestion.address;
}

function renderSuggestion(suggestion) {

  const buyerNames = suggestion.buyers.map(item=>`${_(item.firstName)} ${_(item.lastName)}`);

  return (
    <div>
      OT: <strong>{formatDate(suggestion.takeoverDate)}</strong>, 
      Oppdrag: <strong>{suggestion.address}, {suggestion.zipCode} {suggestion.city}</strong>
      <br />
      Meglerkontor: <strong>{suggestion.brokerOffice.name}</strong>
      Oppdragsnummer: <strong>{suggestion.taskNumber}</strong>
      <br />
      Kjøpere: <strong>{buyerNames.join(", ")}</strong>

      <hr />
    </div>
  );
}

const EstateSection = (props) => {

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = React.useState(false);
  
  const classes = useStyles();  


  const {orderId, estateConfigMode} = props;
  let {powerOrderEstate} = props;

  let estate = null;

  if (powerOrderEstate !== null){
    estate = powerOrderEstate.estate;
  }

  const updatePowerOrderEstate = (orderId, powerOrderEstate) => {
    dispatch({
      type: UPDATE_POWER_ORDER_ESTATE,
      payload: {
        orderId,
        powerOrderEstate
      }
    });   
  };  

  const placeholder = <div>&mdash;</div>

  const loadSuggestions = value => {
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/estates/autocomplete?q=" + value;

    console.log("ajax query:" + url);

    axios
      .get(url)
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsLoading(false);
        setSuggestions(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debouncedLoadSuggestions = useRef(debounce((value)=>{loadSuggestions(value)}, 250)).current;

  const onChange = (event, { newValue }) => {
    setSearchText(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex }) => {
    console.log("Suggestion selected: " + suggestionValue, suggestions[suggestionIndex]);

    const selectedObject = suggestions[suggestionIndex];

    const estateId = selectedObject.id;
    
    powerOrderEstate = new PowerOrderEstate(orderId,estateId);   

    powerOrderEstate.estateId = estateId;
    powerOrderEstate.estate = selectedObject;

    updatePowerOrderEstate(orderId, powerOrderEstate);

    setSearchText("");

    dispatch(setPowerOrderEstateConfigMode(orderId, true));

  };

  const inputProps = {
    placeholder: "",
    value: searchText,
    onChange,
    tabIndex: 2000
  };

  // TODO - use this va in UI
  // eslint-disable-next-line no-unused-vars
  const status = isLoading ? "Loading..." : "Type to load suggestions";



  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modal}
        scroll="paper"
        keepMounted={false}
        onClose={() => setModal(false)}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Nullstille oppdrag info</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>Er du sikker?</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)}>Nei</Button>
          <Button onClick={() => {setModal(false); updatePowerOrderEstate(orderId, null)}} color="success">
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      <div className="Section-Header No-Margin">
        <div className="Section-Title">Oppdrag</div>
        <div className="Section-Control-Help">{powerOrderEstate !== null && "Nullstille"}</div>
        <div className="Section-Control-Element">

          
        {powerOrderEstate !== null &&
          <div className="Reset-Button" style={{ backgroundImage: `url("${materialResetIcon}")` }} onClick={() => setModal(true)}  />
        }
        
        </div>
      </div>


      <div className="Reference-Height-Section-Content">

        <div className="Autosuggest-Block">
          <Autosuggest
            onSuggestionSelected={onSuggestionSelected}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            renderInputComponent={(autoInputProps)=>{
              return (<TextField
                      InputProps={{
                        inputProps: {...autoInputProps},
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    />)
            }}            
          />
        </div>

        <table>
          <tbody>
            <tr>
              <td>
                <div className="Field-Preview-Label">Oppdragsnummer:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">
                {powerOrderEstate ? (<Link to={"/estate/"+estate.id} tabIndex={-1}>{estate.taskNumber}</Link>): placeholder}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label">Overtagelsesdato:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerOrderEstate ? formatDate(estate.takeoverDate): placeholder}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="Field-Preview-Label">Anleggsadresse:</div>
              </td>
              <td>
                <div className="Field-Preview-Text">{powerOrderEstate ? `${estate.address}, ${estate.city}`: placeholder}</div>
              </td>
            </tr>
          </tbody>
        </table>


        {estateConfigMode && estate!==null && <EstateConfigurator estate={estate} orderId={orderId} />}

      </div>
    </React.Fragment>
  );
};

export default EstateSection;
