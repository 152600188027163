import React from "react";
import { useDispatch } from "react-redux";

import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

import {formatDate} from "utils.js";

import { SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER } from "actions/types";


const useStyles = makeStyles(styles);


const ItemSettings = ({hasProtocolConfig, protocolActive, hasProtocolTaskTypes, hasProtocolPropertyTypes}) => {
  return (
          <React.Fragment>
            {hasProtocolConfig && protocolActive==true && <span style={{color:"green",margin:"5px",fontWeight:"400"}}><Check style={{verticalAlign:"bottom"}} /></span>}
            {hasProtocolConfig && protocolActive==false && <span style={{color:"red",margin:"5px",fontWeight:"400"}}><Clear style={{verticalAlign:"bottom"}} /></span>}

            {false && hasProtocolConfig && <span style={{color:"red",margin:"5px",fontWeight:"400"}}>P</span>}

            {hasProtocolTaskTypes && <span style={{color:"red",margin:"5px",fontWeight:"400"}}>TT</span>}
            {hasProtocolPropertyTypes && <span style={{color:"red",margin:"5px",fontWeight:"400"}}>PT</span>}
          </React.Fragment>
    );
}

export default function BrokerChainListTable(props) {

  const dispatch = useDispatch();

  const classes = useStyles();
  const { history, tableHead, tableData } = props;

  const tableCellClasses = classes.tableCell;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead>
            <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
              {tableHead.map((prop, key) => {
                const tableCellClasses =
                  classes.tableHeadCell +
                  " " +
                  classes.tableCell +
                  " " +
                  cx({
                    [classes.tableHeadFontSize]: true
                  });
                return (
                  <TableCell className={tableCellClasses} key={key}>
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData.map((item, key) => {
            const tableRowClasses = cx({
              [classes.tableRowBody]: true,
              [classes.tableRowHover]: true,
              [classes.tableStripedRow]: key % 2 === 0
            });

            return (
              <TableRow
                key={key}
                hover={true}
                className={classes.tableRow + " " + tableRowClasses}
                onClick={e => history.push("/broker_chain/" + item.id)}>
                <TableCell className={tableCellClasses}>{item.id}</TableCell>
                <TableCell className={tableCellClasses}>{item.name}</TableCell>
                <TableCell className={tableCellClasses}>{item.source}</TableCell>
                <TableCell className={tableCellClasses}>{formatDate(item.apiDate)}</TableCell>
                <TableCell className={tableCellClasses}>{formatDate(item.created)}</TableCell>
                <TableCell className={tableCellClasses}>{formatDate(item.modified)}</TableCell>
                <TableCell className={tableCellClasses}>

                  <ItemSettings
                    hasProtocolConfig={item.hasProtocolConfig1}
                    protocolActive={item.protocolActive1}
                    hasProtocolTaskTypes={item.hasProtocolTaskTypes1}
                    hasProtocolPropertyTypes={item.hasProtocolPropertyTypes1}
                  />

                </TableCell>
                <TableCell className={tableCellClasses}>

                  <ItemSettings
                    hasProtocolConfig={item.hasProtocolConfig2}
                    protocolActive={item.protocolActive2}
                    hasProtocolTaskTypes={item.hasProtocolTaskTypes2}
                    hasProtocolPropertyTypes={item.hasProtocolPropertyTypes2}
                  />

                </TableCell>
                <TableCell className={tableCellClasses}>

                  <ItemSettings
                    hasProtocolConfig={item.hasProtocolConfig3}
                    protocolActive={item.protocolActive3}
                    hasProtocolTaskTypes={item.hasProtocolTaskTypes3}
                    hasProtocolPropertyTypes={item.hasProtocolPropertyTypes3}
                  />         

                </TableCell>
                <TableCell className={tableCellClasses}>
                  <a href="" onClick={
                    e => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push("/broker_offices");

                      dispatch({
                        type: SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER,
                        payload: item
                      });                        
                    }

                  }>Meglerkontorer</a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

BrokerChainListTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array
};
