import { FETCH_BROKER_RESULTS_START } from "../actions/types";
import { FETCH_BROKER_RESULTS_SUCCESS } from "../actions/types";
import { FETCH_BROKER_RESULTS_ERROR } from "../actions/types";

import {APP_LOGOUT_SUCCESS} from "../actions/types";

const searchLoadingTemplate = {
  loading: false,
  error: null,
  data: null
}


const initialState = {

  searchLoadingData: {
    ...searchLoadingTemplate
  },

  itemLoadingData: {
    
  }
};


export default function(state = initialState, action) {
  // eslint-disable-next-line no-unused-vars
  let { itemLoadingData } = state;

  switch (action.type) {
    
    case FETCH_BROKER_RESULTS_START: {
      console.log("Broker action", action.type, action);

      let searchLoadingData = {
        ...searchLoadingTemplate,
        loading: true
      };

      return {
        ...state,
        searchLoadingData
      };
    }

    case FETCH_BROKER_RESULTS_SUCCESS: {
      console.log("Broker action", action.type, action);

      let searchLoadingData = {
        ...searchLoadingTemplate,
        data: action.payload.data
      };

      return {
        ...state,
        searchLoadingData
      };
    }

    case FETCH_BROKER_RESULTS_ERROR: {
      console.log("Broker action", action.type, action);

      let searchLoadingData = {
        ...searchLoadingTemplate,
        error: action.payload
      };

      return {
        ...state,
        searchLoadingData
      };
    }

    case APP_LOGOUT_SUCCESS: {
      console.log("Broker action", action.type, action);

      return initialState;
    }    


    default:
      return state;
  }
}
