export default class PowerOrderAggregate {
  constructor() {
    this.id = null;
    this.source = null;
    this.sourceCode = null;
    this.status = null;
    this.statusCode = null;

    this.receivedAt = null;
    this.createdAt = null;
    this.updatedAt = null;

    this.powerOrderSourceType = null;

    this.powerOrderStatusType = null;

    /** @var PowerOrderProduct */
    this.powerOrderProduct = null;

    /** @var  PowerOrderBroker */
    this.powerOrderBroker = null;

    /** @var  PowerOrderEstate */
    this.powerOrderEstate = null;

    /** @var  PowerOrderEmail */
    this.powerOrderEmail = null;

    /** @var PowerOrderMetadata */
    this.powerOrderMetadata = null;

    /** @var  PowerOrderTask */
    this.task = null;

    /** @var PowerOrderBuyer */
    this.buyer = null;

    /** @var PowerOrderSeller */
    this.seller = null;

    /** @var PowerOrderMeter[] */
    this.powerMeters = [];

    /** @var PowerOrderComment[] */
    this.comments = [];

    /** @var Estate */
    this.estate = null;

    this.lock = null;

    /** @var EmailAttachment[] */
    this.emailAttachments = [];
  }
}
