import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";


// app specific components
import { createRandomNotificationId } from "utils.js";
import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";

import TransactionType from "classes/TransactionType";
import CompanyChainTransaction from "classes/CompanyChainTransaction";


import {DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO} from "actions/types";


import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function AdjustmentTransactionDetails({ transaction, edit, close }) {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [modal, setModal] = React.useState(false);

  const [isLoading,setIsLoading] = useState(false);


  const dispatchInfoNotification = (message) => {
  
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));       

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);       
  }

  const dispatchErrorNotification = (message) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);          
    
  }

  const saveAsPaid = () => {

    const data = {
      transactionStatus: CompanyChainTransaction.PAID_STATUS
    };

    console.log("Save transaction as paid",transaction);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + transaction.companyChainId + "/transactions/" + transaction.id + "/status";
    console.log(`PATCH ${url}`);

    setIsLoading(true);

    axios.patch(url, data)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);

        dispatchInfoNotification("Save successful.");

        dispatch({
          type: DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO,
          payload: {
            id: transaction.companyChainId
          }
        });

        close();        
      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
        setIsLoading(false);
      })
  }

  return (
    <Card>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        className="Popup-Confirmation-Z-Index"
        open={modal}
        scroll="paper"
        keepMounted={false}
        onClose={() => setModal(false)}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Lagret som betalt: <strong>{transaction.subject}</strong></h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>Er du sikker?</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)}>Nei</Button>
          <Button onClick={() => {setModal(false); saveAsPaid(); }} color="danger">
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      <CardHeader color="rose" icon>
        <h4 className={classes.cardIconTitle}>
          View adjustment
        </h4>
      </CardHeader>

      <CardBody>
        <table>
          <tbody>
            <tr>
              <td style={{width:"200px"}}>Amount</td>
              <td>{transaction.amount}</td>
            </tr>
            <tr>
              <td>Accounting date</td>
              <td>{transaction.accountingDate}</td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{transaction.description}</td>
            </tr>
            <tr>
              <td>Files</td>
              <td>{transaction.attachments.map(item=>(
                  <div key={item.id}><a target="_blank" href={`${process.env.REACT_APP_API_ENDPOINT_URL}/company_chain/${transaction.companyChainId}/transactions/${transaction.id}/attachments/${item.id}/view`}>{item.filename}</a></div>
                ))}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>

      <CardFooter style={{ justifyContent: "space-between" }}>

        {TransactionType.getPayableTransactionTypes().includes(transaction.transactionType)  && <Button disabled={isLoading || transaction.transactionStatus==CompanyChainTransaction.PAID_STATUS} onClick={() => setModal(true)} color="danger">Lagret som betalt...</Button>}

        <div style={{textAlign:"right", width:"100%"}}>
          <Button onClick={close}>Avbryt</Button>
          <Button color="primary" onClick={edit}>
            Redigere
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
