import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import moment from "moment";


// material ui
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// theme resources
import styles from "assets/jss/material-dashboard-pro-react/modalStyle.js";

import { HTTP_BAD_REQUEST } from "constants.js";
import ValidationErrors from "app_components/ValidationErrors";

import TransactionType from "classes/TransactionType";
import CompanyChainTransaction from "classes/CompanyChainTransaction";

import {DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO} from "actions/types";

import { convertNullToString as _ } from "utils.js";
import { createRandomNotificationId } from "utils.js";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";

import FileDragAndDrop from "./FileDragAndDrop";
import TransactionCategoryDropdown from "./TransactionCategoryDropdown";

// app specific styles and graphics
import dragContainerBackground from "app_assets/img/drag_container_bg.png";

const useStyles = makeStyles(styles);

export default function AdjustmentTransactionDialog({close, companyChain, transaction, transactionCategories}){

  const dispatch = useDispatch();

  if (transaction==null) {
    transaction = new CompanyChainTransaction();
    transaction.transactionType = TransactionType.ADJUSTMENT_TRANSACTION_TYPE;
    transaction.attachments = [];   


    transaction.accountingDate = moment().format('DD.MM.YYYY');
  }

  
  const isNewTransaction = (transaction.id==null) ? true : false;
  
  const [isLoading,setIsLoading] = useState(false);

  const [modal, setModal] = React.useState(false);

  const classes = useStyles();  

  const [transactionCategory,setTransactionCategory] = useState(transaction.transactionCategory);
  const [amount,setAmount] = useState(transaction.amount);
  const [accountingDate,setAccountingDate] = useState(transaction.accountingDate);
  const [subject,setSubject] = useState(transaction.subject);
  const [description,setDescription] = useState(transaction.description);

  const [newAttachments, setNewAttachments] = useState([]);

  const dispatchInfoNotification = (message) => {
  
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));       

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);       
  }

  const dispatchErrorNotification = (message) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);          
    
  }

  const save = () => {

    transaction.transactionCategory = transactionCategory;
    transaction.amount = amount;
    transaction.accountingDate = accountingDate;
    transaction.subject = subject;
    transaction.description = description;
    transaction.newAttachments = newAttachments;

    console.log("Save transaction",transaction);

    let url, axiosHttpMethod;

    if (isNewTransaction) {
      axiosHttpMethod = axios.post;
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChain.id + "/transactions";
      console.log(`POST ${url}`);
    } else {
      axiosHttpMethod = axios.patch;
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChain.id + "/transactions/" + transaction.id;
      console.log(`PATCH ${url}`);
    }

    setIsLoading(true);

    axiosHttpMethod(url, transaction)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);

        dispatchInfoNotification("Save successful.");

        dispatch({
          type: DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO,
          payload: {
            id: companyChain.id
          }
        });

        close();        
      })
      .catch(error => {

        console.log(error);

        if (error.response && error.response.status == HTTP_BAD_REQUEST) {
          dispatchErrorNotification(<ValidationErrors validationErrors={error.response.data.validationErrors} />, false);          
        } else {
          dispatchErrorNotification("An unexpected error has occured: " + error);
        }
        
        setIsLoading(false);
      })

  }

  const remove = () => {

    console.log("Delete transaction",transaction);

    let url;

    url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChain.id + "/transactions/" + transaction.id;
    console.log(`DELETE ${url}`);

    setIsLoading(true);

    axios.delete(url, transaction)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);

        dispatchInfoNotification("Delete successful.");

        dispatch({
          type: DISCARD_COMPANY_CHAIN_ACCOUNTING_INFO,
          payload: {
            id: companyChain.id
          }
        });

        close();        
      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
        setIsLoading(false);
      })
  }

  const amountBlurHandler = e => {
    setAmount(e.target.value);
  }

  const accountingDateBlurHandler = e => {
    setAccountingDate(e.target.value);
  }

    const subjectBlurHandler = e => {
    setSubject(e.target.value);
  }

  const descriptionBlurHandler = e => {
    setDescription(e.target.value);
  }

  return (
    <React.Fragment>
      <div className="Full-Page-Overlay"></div>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        className="Popup-Confirmation-Z-Index"
        open={modal}
        scroll="paper"
        keepMounted={false}
        onClose={() => setModal(false)}
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Slett justering</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <h5>Er du sikker?</h5>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => setModal(false)}>Nei</Button>
          <Button onClick={() => {setModal(false); remove(); }} color="danger">
            Ja
          </Button>
        </DialogActions>
      </Dialog>

      <div className="New-Adjustment-Dialog Popup-Z-Index">

        <div>
          {isNewTransaction==true && <h5>New adjustment for {companyChain.name}</h5>}
          {isNewTransaction==false && <h5>Edit adjustment for {companyChain.name}</h5>}
        </div>

        <div>


          <GridContainer>
            <GridItem md={12}>
              <CustomInput
                labelText="Subject"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{ defaultValue: _(subject), disabled: isLoading, onBlur: subjectBlurHandler }}
              />
            </GridItem>
            <GridItem md={6}>
              <CustomInput
                labelText="Amount"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{ defaultValue: _(amount), disabled: isLoading, onBlur: amountBlurHandler }}
              />
            </GridItem>
            <GridItem md={6}>
              <CustomInput
                style={{marginLeft:"10px"}}
                labelText="Accounting date"
                formControlProps={{
                  fullWidth: false
                }}
                inputProps={{ defaultValue: _(accountingDate), disabled: isLoading, onBlur: accountingDateBlurHandler }}
              />
            </GridItem>
            <GridItem md={6}></GridItem>
            <GridItem md={6}>
              <TransactionCategoryDropdown transactionCategory={transactionCategory} setTransactionCategory={setTransactionCategory} transactionCategories={transactionCategories} />
            </GridItem>
          </GridContainer>

          <textarea
            disabled={isLoading}
            style={{ width: "100%", resize: "vertical", height: "50pt" }}
            name="description"
            onChange={descriptionBlurHandler} value={_(description)}></textarea>                


          <GridContainer>
            {/*<GridItem md={6}><FileDragAndDrop handleDrop={handleDrop} /></GridItem>*/}
            
            <GridItem md={12} style={{height:"100px",overflow:"auto"}}>
              <div>Attachments ({transaction.attachments.length}):</div>

              {transaction.attachments.map(item=>(
                <div key={item.id}><a target="_blank" style={{color:"green"}} href={`${process.env.REACT_APP_API_ENDPOINT_URL}/company_chain/${transaction.companyChainId}/transactions/${transaction.id}/attachments/${item.id}/view`}>{item.filename}</a></div>
              ))}

              

              {/*<div>New attachments(unsaved) ({newAttachments.length}):</div>*/}
              {/*{newAttachments.map(item=>(
                <div style={{color:"red"}} key={item.id}>{item.filename} ({item.filesize} bytes)</div>
              ))}*/}

            </GridItem>
          </GridContainer>

        </div>

        <div>
          <GridContainer>
            <GridItem md={6}>
              {isNewTransaction==false && 
              <Button className="Text-Button" color="danger" onClick={() => setModal(true)} disabled={isLoading}>
                Slett...
              </Button>}
            </GridItem>
            <GridItem md={6} style={{textAlign: "right"}}>
              
              {isLoading && <CircularProgress size={15} />}

              <Button className="Text-Button" onClick={close} disabled={isLoading}>
                Avbryt
              </Button>

              <Button style={{marginLeft: "10pt"}} color="primary" className="Text-Button" disabled={isLoading} onClick={save}>
                Lagre
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </React.Fragment>
    );
}