import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { debounce } from "debounce";

import {getVerticalScroll} from "functions/uiFunctions.js";
import {saveVerticalScroll} from "actions/uiActions";


export default function ({path}) {

  const dispatch = useDispatch();

  const verticalScroll = useSelector(state => {
    return state.ui.verticalScrollMap[path] || null
  });


  useEffect(() => {
    
    console.debug("window scroll to",verticalScroll);
    window.scrollTo(0, verticalScroll);

    console.debug("init body onscroll listener");

    let elements = document.getElementsByTagName("body");
    if (elements.length > 0) {

      let f = e => {
        let vscroll = getVerticalScroll();

        dispatch(saveVerticalScroll(path, vscroll));
        console.debug("Save vertical scroll for path:", path, vscroll);
      };

      elements[0].onscroll = debounce(f, 250);
    }

    return function() {

      console.debug("remove body onscroll listener");

      let elements = document.getElementsByTagName("body");
      if (elements.length > 0) elements[0].onscroll =  null;
    };

  });

  return null;
}
