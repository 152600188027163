import React from "react";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";
import { formatDateTime } from "utils.js";

export default function EventListSection({ estate, protocolTypes }) {
  const { smsMessageLogList } = estate;

  const reversedMessageLogList = [...smsMessageLogList].reverse().filter(item=>protocolTypes.includes(item.protocolType));

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title">Logg</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      <table className="Event-List-Table">
        <thead>
          <tr>
            <td>Action</td>
            <td>Details</td>
            <td>Protocol type</td>
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {reversedMessageLogList.map((item, index) => (
            <tr key={item.id}>
              <td>SMS sendt</td>
              <td>
                {item.name ? item.name : <em>Ingen navn</em>} - {item.orgReceiver} {item.type && <span>[{item.type}]</span>}
              </td>
              <td>{item.protocolType}</td>
              <td>{formatDateTime(item.sent)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
}
