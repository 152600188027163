import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";

export default class EmailOrderMessageBuilder {
  constructor(powerOrder) {
    this.powerOrder = powerOrder;
  }



  render() {

    const f = value => (value===undefined || value===null) ? "" : value;

    const lines = [];
    const NEWLINE = "\n";

    const powerOrder = this.powerOrder;

    const { powerMeters, powerOrderProduct, task, buyer, seller } = powerOrder;

    lines.push("-----------");
    lines.push("STRØMSKJEMA");
    lines.push("-----------");
    lines.push("");

    lines.push("Leverandør: " + f(powerOrderProduct?.powerCompany.name));
    lines.push("Produkt: " + f(powerOrderProduct?.powerProduct.name));

    lines.push("");
    lines.push("-----------------");
    lines.push("INFO OM ANLEGGET:");
    lines.push("-----------------");
    lines.push("");

    lines.push("Anleggsadresse: " + f(task?.streetAddress));
    lines.push("Postnummer: " + f(task?.postCode));
    lines.push("Poststed: " + f(task?.postLocation));
    lines.push("Etasje: " + f(task?.floor));
    lines.push("Leilighetsnummer: " + f(task?.apartmentNumber));

    lines.push("");
    lines.push("---------------------");
    lines.push("KJØPER (FLYTTER INN):");
    lines.push("---------------------");
    lines.push("");

    if (buyer?.customerType==PowerOrderCustomer.PERSON_CUSTOMER_TYPE) {
      lines.push("Etternavn: " + f(buyer?.lastName));
      lines.push("Fornavn: " + f(buyer?.firstName));
      lines.push("Fødselsdato: " + f(buyer?.birthDate));
    } else if (buyer?.customerType==PowerOrderCustomer.COMPANY_CUSTOMER_TYPE) {
      lines.push("Organisasjonsnavn: " + f(buyer?.companyName));
      lines.push("Orgnummer: " + f(buyer?.companyNumber));
    }

    lines.push("Epost: " + f(buyer?.email));
    lines.push("Telefon: " + f(buyer?.phone));
    lines.push("Fakturaadresse: " + f(buyer?.billingStreetAddress));
    lines.push("Postnummer: " + f(buyer?.billingPostCode));
    lines.push("Poststed: " + f(buyer?.billingPostLocation));

    lines.push("");
    lines.push("--------------------");
    lines.push("SELGER (FLYTTER UT):");
    lines.push("--------------------");
    lines.push("");

    if (seller?.customerType==PowerOrderCustomer.PERSON_CUSTOMER_TYPE) {
      lines.push("Etternavn: " + f(seller?.lastName));
      lines.push("Fornavn: " + f(seller?.firstName));
      lines.push("Fødselsdato: " + f(seller?.birthDate));
    } else if (seller?.customerType==PowerOrderCustomer.COMPANY_CUSTOMER_TYPE) {
      lines.push("Organisasjonsnavn: " + f(seller?.companyName));
      lines.push("Orgnummer: " + f(seller?.companyNumber));
    }

    lines.push("Epost: " + f(seller?.email));
    lines.push("Telefon: " + f(seller?.phone));

    lines.push("");
    lines.push("------------");
    lines.push("STRØMMÅLERE:");
    lines.push("------------");
    lines.push("");

    for (let i=0; i < powerMeters.length; i++) {
      const m = powerMeters[i];
      lines.push(`Måler ${i + 1}:`);
      lines.push("--------");
      lines.push("Målepunkt-ID: " + f(m?.mpid));
      lines.push("Målernummer: " + f(m?.meterNumber));
      lines.push("Måleravlesning: " + f(m?.readingValue));
      lines.push("Avlesningsdato: " + f(m?.readingDate));
      lines.push("");
    }

    lines.push("---");


    lines.push("");
    lines.push("");

    lines.push("Med vennlig hilsen");
    lines.push("Visma Meglerfront");
    lines.push("meglerfront.support@visma.com | 56 09 02 00");
    lines.push("");
    lines.push("Make progress happen");
    lines.push("");
    lines.push("Visma Real Estate AS");
    lines.push("Karenslyst Allé 56, 0277 Oslo | www.visma.no");
    lines.push("");
    lines.push("www.visma.com");
    
    lines.push("");
    lines.push("");

    return lines.join(NEWLINE);
  }
}
