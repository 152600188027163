import React from 'react';
import axios from "axios";
import store from './stores/store';
import { Provider } from 'react-redux';
import AppRoutes from "./routes/AppRoutes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';  

// configuration
import 'moment/locale/nb';

// app resources
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import './app_assets/scss/App.scss';

import BackgroundTasks from "app_components/BackgroundTasks";


const browserHistory = createBrowserHistory();

axios.defaults.withCredentials = true;

function App() {
  return (
    <Provider store={store}>
      <Router history={browserHistory}>
        <div className="App">      
          <BackgroundTasks />
          <AppRoutes  history={browserHistory} />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
