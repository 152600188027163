import React from "react";

const ValidationErrors = ({ validationErrors }) => {
  return (
    <div>
      <h4>Validation errors</h4>
      <ul>
        {validationErrors.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default ValidationErrors;
