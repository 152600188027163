// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { UPDATE_POWER_ORDER_LOCKS } from "actions/types";
import { UPDATE_EMAIL_LOCKS } from "actions/types";

const BackgroundTasks = () => {
  const dispatch = useDispatch();

  const authInfo = useSelector((state) => state.auth.authInfo);

  const openedOrders = useSelector((state) => state.powerOrders.workingData);

  const openedEmails = useSelector((state) => state.emails.workingData);

  
  const POLLING_INTERVAL = 60 * 1000; // poll lock info every minute
  
  useEffect(() => {

    if (process.env.REACT_APP_BUILD_TARGET=="development"){
      console.log("[Background task] Background tasks are disabled in dev");
      return;
    }

    console.log("[Background task] Init background tasks");

    const timer = setInterval(() => {

      if (authInfo == null) {
        console.log("[Background task] User is not logged, skip background jobs");
        return;
      }

      const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders/locks";

      // ------- TASK 1 --------

      console.log(`[Background task] GET ${url}`);

      axios
        .get(url)
        .then((response) => {
          console.log("[Background task] AJAX RESPONSE", response);
          dispatch({ type: UPDATE_POWER_ORDER_LOCKS, payload: { locks: response.data } });
        })
        .catch((error) => {
          console.log("[Background task] an error has occured", error);
        });

      // ------- TASK 2 --------

      console.log(`[Background task] PATCH ${url}`);
      
      const data = [];

      for(const id in openedOrders){
        const powerOrder = openedOrders[id].entity;
        
        if (powerOrder!==null && powerOrder.lock!==null && powerOrder.lock.token!==null){
          data.push({
            orderId: id,
            token: powerOrder.lock.token
          });
        }
      }

      if (data.length==0) {
        console.log("[Background task] No opened orders.");
      } else {
        console.log("[Background task] Update order locks",data);

        axios
          .patch(url,data)
          .then((response) => {
            console.log("[Background task] AJAX RESPONSE", response);
          })
          .catch((error) => {
            console.log("[Background task] an error has occured", error);
          });
      }


      // ------- TASK 3 --------

      const url2 = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/locks";

      console.log(`[Background task] GET ${url2}`);

      axios
        .get(url2)
        .then((response) => {
          console.log("[Background task] AJAX RESPONSE", response);
          dispatch({ type: UPDATE_EMAIL_LOCKS, payload: { locks: response.data } });
        })
        .catch((error) => {
          console.log("[Background task] an error has occured", error);
        });


      // ------- TASK 4 --------

      console.log(`[Background task] PATCH ${url2}`);
      
      const data2 = [];

      for(const id in openedEmails){
        const email = openedEmails[id].entity;
        
        if (email!==null && email.lock!==null && email.lock.token!==null){
          data2.push({
            emailId: id,
            token: email.lock.token
          });
        }
      }

      if (data2.length==0) {
        console.log("[Background task] No opened emails.");
      } else {
        console.log("[Background task] Update email locks",data2);

        axios
          .patch(url2,data2)
          .then((response) => {
            console.log("[Background task] AJAX RESPONSE", response);
          })
          .catch((error) => {
            console.log("[Background task] an error has occured", error);
          });        
      }


    }, POLLING_INTERVAL);

    return () => {
      console.log("Clear timer", timer);
      clearTimeout(timer);
    };
  });

  return null;
};

export default BackgroundTasks;
