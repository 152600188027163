import React from "react";

import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

// app components
import UserLinks from "./UserLinks";
import { CLOSE_TAB } from "actions/types";
import AppTab from "app_components/AppTab";


const formatDisplayName = (loggedUser) => {

  if (loggedUser.displayName!==null && loggedUser.displayName!=="") {
    return loggedUser.displayName;
  }

  return loggedUser.userName;
}

const HeaderMenu = (props) => {
  let { history } = props;

  const currentUrl = props.computedMatch.url;

  const tabState = useSelector(state => state.tabs);
  

  const authInfo = useSelector(state => state.auth.authInfo);
  const appSettings = useSelector(state => state.app.settings);


  const dispatch = useDispatch();

  let currentPath = props.path;

  let firstLinkActive = false;
  if (currentPath === "/orders" || currentPath.startsWith("/order/")) {
    firstLinkActive = true;
  } else if (currentPath === "/emails" || currentPath.startsWith("/email/")) {
    firstLinkActive = true;
  }

  let estateLinkActive = false;
  if (currentPath === "/estates" || currentPath.startsWith("/estate/")) {
    estateLinkActive = true;
  }

  let productOwnerLinkActive = false;
  if (currentPath === "/power_companies" || currentPath.startsWith("/power_company/") || currentPath=="/other_products" ) {
    productOwnerLinkActive = true;
  }

  let brokerChainLinkActive = false;
  if (currentPath === "/broker_chains" || currentPath === "/broker_offices" || currentPath === "/brokers") {
    brokerChainLinkActive = true;
  }

  let accountingLinkActive = false;
  if (currentPath === "/accounting" || currentPath === currentPath.startsWith("/accounting/")) {
    accountingLinkActive = true;
  }

  let accountingLinkEnabled = appSettings.accountingMenuEnabled;

  const fullMenuMode = isWidthUp('sm', props.width);

  const renderNavLinksFull = () => {
    return (
        <div className="navLinks">




          {firstLinkActive === true && (
            <Link className="current" to="/orders" tabIndex={-1}>
              Ordre
            </Link>
          )}

          {firstLinkActive === false && (
            <Link to="/orders" tabIndex={-1}>
              Ordre
            </Link>
          )}
          


          {productOwnerLinkActive === true && (
            <Link className="current" to="/power_companies" tabIndex={-1}>
              Leverandører
            </Link>
          )}

          {productOwnerLinkActive === false && (
            <Link to="/power_companies" tabIndex={-1}>
              Leverandører
            </Link>
          )}


          {accountingLinkEnabled && accountingLinkActive === true && (
            <Link className="current" to="/accounting" tabIndex={-1}>
              Økonomi
            </Link>
          )}

          {accountingLinkEnabled && accountingLinkActive === false && (
            <Link to="/accounting" tabIndex={-1}>
              Økonomi
            </Link>
          )}


          {estateLinkActive === true && (
            <Link className="current" to="/estates" tabIndex={-1}>
              Webot
            </Link>
          )}

          {estateLinkActive === false && (
            <Link to="/estates" tabIndex={-1}>
              Webot
            </Link>
          )}



          {false &&
          <NavLink activeClassName="current" to="/statistics" tabIndex={-1}>
            Statistikk
          </NavLink>}
          {false &&
          <NavLink activeClassName="current" to="/accounting" tabIndex={-1}>
            Økonomi
          </NavLink>}

          {brokerChainLinkActive === true && (
            <Link className="current" to="/broker_chains" tabIndex={-1}>
              Meglerkjede
            </Link>
          )}
          {brokerChainLinkActive === false && (
            <Link to="/broker_chains" tabIndex={-1}>
              Meglerkjede
            </Link>
          )}
        </div>
      );
  }
  const renderNavLinksCompact = () => {
    return (

        <div className="navLinks">
        
          {estateLinkActive === true && (
            <Link className="current" to="/estates" tabIndex={-1}>
              Webot
            </Link>
          )}

          {estateLinkActive === false && (
            <Link to="/estates" tabIndex={-1}>
              Webot
            </Link>
          )}

          <Link to="/logout" style={{float:"right"}}>Logout {formatDisplayName(authInfo.loggedUser)}</Link>

        </div>
      );
  }

  const renderUserMenuFull = () => {
    return (
        <div className="userMenu">

          <div style={{paddingTop:"18px",paddingRight:"10px",float:"left",color:"white",fontSize:"12px"}}>
            User: <span style={{fontWeight:"400"}}>{formatDisplayName(authInfo.loggedUser)}</span>
          </div>

          <div style={{paddingTop:"18px",paddingRight:"10px",float:"left",color:"white",fontSize:"12px"}}>
            Version: <span style={{fontWeight:"400"}}>{process.env.REACT_APP_GIT_SHA!==undefined ? process.env.REACT_APP_GIT_SHA : "dev"}</span>
          </div>

          <UserLinks />
          
        </div>    
    ); 
  }

  const buildTarget = process.env.REACT_APP_BUILD_TARGET;

  return (
    <React.Fragment>

      <div className="App-Navbar">

        {buildTarget!="production" && <div className={"logo " + "logo-" + buildTarget}>
          <div className="line1">Genie</div>
          <div className="line2">{buildTarget}</div>
        </div>}

        {fullMenuMode && renderNavLinksFull()}
        {fullMenuMode && renderUserMenuFull()}

        {fullMenuMode==false && renderNavLinksCompact()}
      </div>

      <div className="App-Tabs">
        {tabState.items.map((item, index, arr) => {
          const isLastTab = index == arr.length - 1;
          const isOnlyTab = arr.length == 1;

          return (
            <AppTab
              key={item.id}
              title={item.title}
              to={item.to}
              currentUrl={currentUrl}
              closeHandler={e => {
                e.preventDefault();

                const isCurrentUrl = currentUrl === item.to;


                if (isCurrentUrl) {
                  if (isOnlyTab) {
                    history.push("/");
                  } else if (isLastTab) {
                    const previousItem = arr[index - 1];
                    history.push(previousItem.to);
                  } else {
                    const nextItem = arr[index + 1];
                    history.push(nextItem.to);
                  }
                }

                dispatch({
                  type: CLOSE_TAB,
                  payload: {
                    id: item.id
                  }
                });

                if (item.closeAction) {
                  dispatch(item.closeAction);
                }

                if (item.closeActions){
                  for(let i=0;i<item.closeActions.length;i++) {
                    dispatch(item.closeActions[i]);
                  }
                }
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default withWidth()(HeaderMenu);
