import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";


// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// functions
import { formatRelativeDateTime, createRandomNotificationId } from "utils.js";
import {createTabTitle, validateStatus} from "functions/powerOrderFunctions";

// constants
import {HTTP_OK, HTTP_BAD_REQUEST, HTTP_NOT_AUTHORIZED, HTTP_PRECONDITION_FAILED, HTTP_PRECONDITION_REQUIRED} from "constants.js";

// app specific hooks
import { useScrollIntoView } from "hooks.js";

// common components
import LoadingPage from "views/common/LoadingPage";
import ErrorPage from "views/common/ErrorPage";

// page specific components
import CommonOrderInfoArea from "./components/CommonOrderInfoArea.js";
import EmailOrderInfoArea from "./components/EmailOrderInfoArea.js";
import ProtocolOrderInfoArea from "./components/ProtocolOrderInfoArea.js";
import MetadataOrderInfoArea from "./components/MetadataOrderInfoArea.js";
import ProductSection from "./components/ProductSection.js";
import TaskInfoSection from "./components/TaskInfoSection.js";
import BuyerSection from "./components/BuyerSection";
import PowerMeterSection from "./components/PowerMeterSection";
import EstateSection from "./components/EstateSection";
import BrokerSection from "./components/BrokerSection";
import SellerSection from "./components/SellerSection";
import CommentSection from "./components/CommentSection";
import UnsavedCommentSection from "./components/UnsavedCommentSection";
import PowerOrderSection from "./components/PowerOrderSection";
import SendEmailWindow from "./components/SendEmailWindow";
import ValidationErrors from "./components/ValidationErrors";
import DuplicateSearchResults from "./components/DuplicateSearchResults";
import OrderHistory from "./components/OrderHistory";

import FormTabHeader from "views/emails/components/FormTabHeader";

// action creators
import {reloadPowerOrderResults} from "actions/powerOrderActions";
import {closeTabTo, pushTab} from "actions/tabActions";
import {discardPowerOrderItem} from "actions/powerOrderActions";
import {fetchPowerOrderItemStart} from "actions/powerOrderActions";
import {fetchPowerOrderItemSuccess} from "actions/powerOrderActions";
import {fetchPowerOrderItemError} from "actions/powerOrderActions";
import {updatePowerOrderItemLock} from "actions/powerOrderActions";
import {setDuplicateCheckCompleted} from "actions/powerOrderActions";
import {setDuplicateSearchResults} from "actions/powerOrderActions";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";

// functions
import {isTemporaryOrderId, isOrderFromEmail} from "functions/powerOrderFunctions";

// app specific styles
import "app_assets/scss/Orders.scss";
import "app_assets/scss/Autosuggest.scss";
import "app_assets/scss/FormTabHeader.scss";
import AttachmentInfoSection from "./components/AttachmentInfoSection";


export default function(props) {

  // --------- HOOKS ---------
  const dispatch = useDispatch();

  useEffect(()=>{
    useScrollIntoView();

  },[]);

  const [sendEmailMode, setSendEmailMode] = useState(false);
  const [viewOrderHistory, setViewOrderHistory] = useState(false);

  // --------- PROPS ---------

  const id = props.computedMatch.params.id;
  const { history } = props;


  // --------- SELECTORS ---------

  const workingDataItem = useSelector(state => {
    return state.powerOrders.workingData[id] || null;
  });

  let emailId;

  if (workingDataItem!=null) {
    emailId = workingDataItem?.entity?.powerOrderEmail?.emailId;
  }

  const emailWorkingDataItem = useSelector(state => {
    if (emailId==null) {
      return null;
    }
    return state.emails.workingData[emailId] || null;
  });   

  const loggedUserId = useSelector(state => state.auth.authInfo.loggedUser.id);

  //console.log("Power order working data:", workingDataItem);
  //console.log("Email working data:", emailWorkingDataItem);

  // --------- COMPONENT FUNCTIONS ---------

  const fetchItem = id => {

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + id + "?set_lock=true";
    console.log(`GET ${url}`);

    dispatch(fetchPowerOrderItemStart(id));

    axios
      .get(url)
      .then( response => {

        console.log("AJAX RESPONSE", response);
        dispatch(fetchPowerOrderItemSuccess(id, response.data));
        dispatch(updatePowerOrderItemLock(id, response.data.lock));
      })
      .catch(error => {

        console.log(error);
        dispatch(fetchPowerOrderItemError(id, error));
      })
  };

  const dispatchInfoNotification = (message) => {
    
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));       

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);       

  }

  const dispatchErrorNotification = (message, autoClose=true) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    if (autoClose){
      setTimeout(() => {
        dispatch(closeNotification(notificationId));
      }, 5000);          
    }
  }

  /*
   * If the order is created with [+] button or an existing order is saved,
   * return to order list;
   * If the order is created from an email, return to that email 
  */
  const closeTabAndGoBack =() => {

    if (isOrderFromEmail(workingDataItem.entity)) {

      history.push("/email/"+emailId);

    } else {
      // trigger order list refresh
      dispatch(reloadPowerOrderResults());

      // close the tab after save
      dispatch(closeTabTo("/order/" + id));

      // ... and go back to order list
      history.push("/orders");    

      // discard the order data
      dispatch(discardPowerOrderItem(id)); 
    }   
  }

  const saveOrder = (newStatus, successHandler=null) => {

    workingDataItem.entity.status = newStatus;

    console.log("Save data:", workingDataItem.entity);

    let url;
    let axiosHttpMethod;

    if (isTemporaryOrderId(id)) {
      // new, unsaved order
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders";
      axiosHttpMethod = axios.post;
      
      console.log(`POST ${url}`);

    } else {
      // existing order
      url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + id;
      axiosHttpMethod = axios.patch;

      console.log(`PATCH ${url}`);
    }
    
    const {duplicateCheckCompleted} = workingDataItem;

    if (duplicateCheckCompleted) {
      url += "?duplicate_check_completed=true";
    }

    

    axiosHttpMethod(url, workingDataItem.entity, {validateStatus})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        if (response.status==HTTP_OK) {

          workingDataItem.entity = response.data;
          dispatchInfoNotification("Order successfully saved");

          if (successHandler==null) {
            closeTabAndGoBack();
          } else {
            successHandler(response.data);
          }

        } else if (response.status==HTTP_BAD_REQUEST) {

          const validationErrors =  response.data.validationErrors;
          dispatchErrorNotification(<ValidationErrors validationErrors={validationErrors} />, false);

        } else if (response.status==HTTP_NOT_AUTHORIZED) {

          dispatchErrorNotification("The login session has expired. Please reload the application so you can login again");

        } else if (response.status==HTTP_PRECONDITION_FAILED) {
          const configurationErrors = response.data.configurationErrors;
          dispatchErrorNotification(<div>Configuration errors found: {configurationErrors.map(item=><div>* {item}</div>)}</div>, false);

        } else if (response.status==HTTP_PRECONDITION_REQUIRED) {

          dispatch(setDuplicateCheckCompleted(id, false));
          dispatch(setDuplicateSearchResults(id, response.data.duplicateSearchResults));

          dispatchErrorNotification("Duplicate check required", true);
        }

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  };


  // --------- INIT ---------

  if (workingDataItem == null) {

    if (isTemporaryOrderId(id)) {
      history.push("/emails");
    } else {
      fetchItem(id);
    }
  }

  if (workingDataItem && workingDataItem.entity!=null) {

    // do not create tab for orders currently created from an email
    
    if (isOrderFromEmail(workingDataItem.entity) && emailWorkingDataItem!==null) {
      console.log("Skip tab creation for this order");
    } else {
      const title = createTabTitle(workingDataItem.entity);
      const closeAction = discardPowerOrderItem(id);
      dispatch(pushTab(title, "/order/"+id, closeAction));    
    }
  }

  // --------- RENDERING ---------


  const renderLoading = () => <LoadingPage />;

  const renderError = () => <ErrorPage errorMessage={workingDataItem.error.error.message} />;

  const renderSuccess = () => {

    const powerOrder = workingDataItem.entity;
    const {powerOrderEmail, powerOrderEstate, powerOrderMetadata} = powerOrder;

    const hasEmailSource = powerOrderEmail!==null;
    const hasWebOTSource = powerOrderEstate!==null;
    const hasMetadata = powerOrderMetadata!==null;

    const lock = powerOrder.lock;
    const isLocked = lock !==null ? true : false;
    const ownedLock = isLocked && lock.userId===loggedUserId;

    const {duplicateSearchResults, duplicateCheckCompleted} = workingDataItem;

    const duplicateCheckSkipped = duplicateSearchResults==null;
    const duplicateCheckRequired = duplicateSearchResults!==null && duplicateSearchResults.length>0 && duplicateCheckCompleted==false;
    const duplicateCheckVisible = duplicateSearchResults!==null && duplicateSearchResults.length>0;


    console.log("Rendering power order " + powerOrder.id);

    return (
  
      <div className="Order-Handling-Grid-Layout" key={powerOrder.id}>

        {sendEmailMode==true && <SendEmailWindow powerOrder={powerOrder} setSendEmailMode={setSendEmailMode} saveOrder={saveOrder} closeOrder={closeTabAndGoBack} duplicateCheckRequired={duplicateCheckRequired} duplicateCheckSkipped={duplicateCheckSkipped} />}


        <div className="Info-Area">

          {isLocked && ownedLock==false &&
          <SnackbarContent
            message={
              <span>Order locked by <strong>{lock.lockedByName}</strong>  {formatRelativeDateTime(lock.createdAt)}</span>
            }
            icon={AddAlert}
            color="rose"
          />}        

          {duplicateCheckVisible && <DuplicateSearchResults history={history} 
          duplicateSearchResults={duplicateSearchResults} 
          duplicateCheckCompleted={duplicateCheckCompleted}
          completeCheckHandler={e=>dispatch(setDuplicateCheckCompleted(powerOrder.id,true))}
           />}        

          <CommonOrderInfoArea powerOrder={powerOrder} viewOrderHistory={viewOrderHistory} setViewOrderHistory={setViewOrderHistory} refreshHandler={e=>dispatch(discardPowerOrderItem(id))} />

          <div className="Order-Info-Area">
            {viewOrderHistory && <OrderHistory powerOrder={powerOrder}  />}

            {hasEmailSource==false && hasWebOTSource==false && hasMetadata==false && <div>No data available.</div>}

            {hasEmailSource && <AttachmentInfoSection  orderId={powerOrder.id} task={powerOrder.task} buyer={powerOrder.buyer} powerOrder={powerOrder} meter={powerOrder.powerMeters[0]} />}
            {hasEmailSource && <EmailOrderInfoArea powerOrderEmail={powerOrderEmail} emailAttachments={powerOrder.emailAttachments} />}

            {hasWebOTSource && <ProtocolOrderInfoArea powerOrderEstate={powerOrderEstate} />}

            {hasMetadata && <MetadataOrderInfoArea powerOrderMetadata={powerOrderMetadata} />}

          </div>
  
        </div>

        <div className="Vertical-Separator"></div>
  
        {isOrderFromEmail(workingDataItem.entity)==true && emailWorkingDataItem!==null  && (
          <div className="Header-Tabs">
            <FormTabHeader currentOrderId={id} connectedOrderIds={emailWorkingDataItem.connectedOrderIds} emailId={workingDataItem.entity.powerOrderEmail.emailId} />
          </div>
        )}
  
        <div className="Form-Area-1 Edit-Order-Styling">
          <ProductSection orderId={powerOrder.id} powerOrderProduct={powerOrder.powerOrderProduct} />
          <TaskInfoSection orderId={powerOrder.id} task={powerOrder.task} buyer={powerOrder.buyer} />
          <BuyerSection orderId={powerOrder.id} buyer={powerOrder.buyer} />
          <PowerMeterSection orderId={powerOrder.id} powerMeters={powerOrder.powerMeters} powerOrder={powerOrder} />
        </div>
        <div className="Form-Area-2 Edit-Order-Styling">
          <EstateSection orderId={powerOrder.id} estateConfigMode={workingDataItem.estateConfigMode} powerOrderEstate={powerOrder.powerOrderEstate} />
          <BrokerSection orderId={powerOrder.id} powerOrderBroker={powerOrder.powerOrderBroker} />
          <SellerSection orderId={powerOrder.id} seller={powerOrder.seller} />
          <PowerOrderSection powerOrder={powerOrder} saveOrder={saveOrder} setSendEmailMode={setSendEmailMode} duplicateCheckRequired={duplicateCheckRequired} />
          
          {isTemporaryOrderId(powerOrder.id)==false && <CommentSection orderId={powerOrder.id} comments={powerOrder.comments} />}

          {isTemporaryOrderId(powerOrder.id)==true && <UnsavedCommentSection orderId={powerOrder.id} comments={powerOrder.comments} />}
        </div>
      </div>
      )};

  if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {
    return renderSuccess();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );
}
