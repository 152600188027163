import React from "react";

// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export default function CompanyChainProtocolTab({ companyChain, classes, protocolType }) {
  const renderDefault = () => (
    <Card className="companyChainProtocolTab">
      <GridContainer className="companyChainProtocolTab-content">
        <GridItem md={6}>
          {/* Task types*/}
          <GridContainer>
            <GridItem md={5}>
              <p>
                <strong>
                  This functionality has been moved to Nova, <br />
                </strong>
                <a target={'_blank'}
                    href={'https://zapdos.meglerfront.no/nova/resources/companychain-protocols?companychain-protocols_page=1&companychain-protocols_search=' + companyChain.name}>

                  Go to page on Nova admin panel</a>
              </p>


            </GridItem>

          </GridContainer>
        </GridItem>

      </GridContainer>

    </Card>
  );

    return renderDefault();

}
