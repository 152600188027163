import React, {useState} from "react";

import {Link } from 'react-router-dom';

import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// app specific components
import TransactionType from "classes/TransactionType";
import BrokerOfficeTransaction from "classes/BrokerOfficeTransaction";

import { formatDate } from "utils.js";

import AdjustmentTransactionDetails from "./accounting/AdjustmentTransactionDetails";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);


function AdjustmentTitle({transaction}){

  const hasCategoryTitle = (transaction.categoryTitle=="" || transaction.categoryTitle==null) ? false: true;
  const hasSubject = (transaction.subject=="" || transaction.subject==null) ? false : true;
  const hasAttachments = (transaction.attachments.length > 0) ? true : false;

  if (transaction.transactionType==TransactionType.ADJUSTMENT_TRANSACTION_TYPE) {
    return (
      <div>
        {hasCategoryTitle==true && <div><strong>{transaction.categoryTitle}</strong></div>}
        {hasCategoryTitle==false && <div><strong><em>Justering</em></strong></div>}
        {hasSubject && <div>{transaction.subject}</div>}
      </div>);
  }

  return (
    <div>
      {hasSubject==false && <em>No subject available</em>}
      {hasSubject==true && <strong>{transaction.subject}</strong>}
      {hasAttachments==true && <em> - {transaction.attachments.length} attachment(s)</em>}

      {transaction.transactionType==TransactionType.FILE_TRANSACTION_TYPE && <span style={{padding:"3pt",marginLeft:"5pt",background:"#2dbcd5",color:"white"}}>MARKEDSTØTTE</span>}
      {transaction.transactionType==TransactionType.PORTAL_FILE_TRANSACTION_TYPE && <span style={{padding:"3pt",marginLeft:"5pt",background:"#2dbcd5",color:"white"}}>PORTAL</span>}
      {transaction.transactionStatus==BrokerOfficeTransaction.PAID_STATUS && <span style={{padding:"3pt",marginLeft:"5pt",background:"#71c075",color:"white"}}>BETALT</span>}

    </div>
    );
}



export default function BrokerOfficeAccountingInfoTable({brokerOffice, results, editAdjustment}) {
  const classes = useStyles();
  
  const tableCellClasses = classes.tableCell;

  const [currentItem,setCurrentItem] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [itemEditMode, setItemEditMode] = useState(false);

  

  const toggleRow = itemId => {
    if (itemId==currentItem) {
      setItemEditMode(false);
      setCurrentItem(null);      
    } else {
      setItemEditMode(false);
      setCurrentItem(itemId);
    }

  }

  const tableHead = ["Type", "Amount", "Status","Accounting date", <strong>Created date</strong>];

  const editableTransactionTypes = [
    TransactionType.ADJUSTMENT_TRANSACTION_TYPE,
    TransactionType.FILE_TRANSACTION_TYPE,
    TransactionType.PORTAL_FILE_TRANSACTION_TYPE
  ];

  return (       
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {results.map((item, idx) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: idx % 2 === 0
                });

                 return (
                  <React.Fragment key={item.id}>
                    <TableRow
                      hover={true}
                      className={classes.tableRow + " " + tableRowClasses}
                      onClick={e=>toggleRow(item.id)}>
                      <TableCell className={tableCellClasses}>
                      {editableTransactionTypes.includes(item.transactionType) && <AdjustmentTitle transaction={item} />}
                      {item.transactionType==TransactionType.POWER_ORDER_TRANSACTION_TYPE && <span title={item.description}>Strømskjema</span>}
                      {item.transactionType==TransactionType.ALARM_ORDER_TRANSACTION_TYPE && <span title={item.description}>Alarm</span>}
                      {item.transactionType==TransactionType.ALARM_ORDER_2_TRANSACTION_TYPE && <span title={item.description}>Alarm(2)</span>}
                      {item.transactionType==TransactionType.BROADBAND_ORDER_TRANSACTION_TYPE && <span title={item.description}>Bredbånd</span>}
                      {item.transactionType==TransactionType.BROADBAND_ORDER_2_TRANSACTION_TYPE && <span title={item.description}>Bredbånd(2)</span>}
                      {item.transactionType==TransactionType.PROTOCOL_BANKID_TRANSACTION_TYPE && <span title={item.description}>Bruk av BankID autentiseringer i digitalt skjema</span>}
                      {item.transactionType==TransactionType.PROTOCOL_BANKID_2_TRANSACTION_TYPE && <span title={item.description}>Bruk av BankID autentiseringer(2) i digitalt skjema</span>}
                      </TableCell>
                      <TableCell className={tableCellClasses}>{item.amount}</TableCell>
                      <TableCell className={tableCellClasses}><strong>{item.transactionStatus}</strong></TableCell>
                      <TableCell className={tableCellClasses}>{item.accountingDate}</TableCell>
                      <TableCell className={tableCellClasses}>{formatDate(item.createdAt)}</TableCell>
                    </TableRow>

                    {currentItem==item.id && editableTransactionTypes.includes(item.transactionType) &&
                    <TableRow>
                      <TableCell colSpan="5" className={tableCellClasses}>
                        
                        <AdjustmentTransactionDetails transaction={item} edit={()=>editAdjustment(item)} close={()=>{setCurrentItem(null);}} />

                      </TableCell>
                    </TableRow>}


                    {currentItem==item.id && item.transactionType==TransactionType.POWER_ORDER_TRANSACTION_TYPE &&
                    <TableRow>
                      <TableCell colSpan="5" className={tableCellClasses}>
                        
                        
                        <Link to={"/order/"+item.orderId}>Ordre #{item.orderId}</Link>

                        <br />
                        <br />
                        No other details available. (To be added)

                      </TableCell>
                    </TableRow>}
                  </React.Fragment>
                );
              })}


            </TableBody>
          </Table>         
  );
}
