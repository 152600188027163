import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// app specific hooks
import { useScrollIntoView } from "hooks.js";

// theme components
import Button from "components/CustomButtons/Button.js";

// app specific components
import WebotSection from "./components/WebotSection";
import FilesSection from "./components/FilesSection";
import CustomerSection from "./components/CustomerSection";
import EventListSection from "./components/EventListSection";
import EstateDetailsSection from "./components/EstateDetailsSection";
import BrokerInfoSection from "./components/BrokerInfoSection";
import OrderInfoSection from "./components/OrderInfoSection";
import SettlementSection from "./components/SettlementSection";
import PersonalSettlementSection from "./components/PersonalSettlementSection";
import DealsSection from "./components/DealsSection";
import Constants from "../../constants";


// app specific styles and graphics
import "app_assets/scss/Estates.scss";

import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";

import { FETCH_ESTATE_ITEM_START } from "actions/types";
import { FETCH_ESTATE_ITEM_SUCCESS } from "actions/types";
import { FETCH_ESTATE_ITEM_ERROR } from "actions/types";

import { DISCARD_ESTATE_ITEM } from "actions/types";

export default function(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    useScrollIntoView();
  }, []);

  const PAGE_TITLE = "WebOT";

  const SETTLEMENT_PROTOCOL_PERSONAL_SMS_TAG = "genie_settlement_protocol_personal_sms";

  useEffect(() => {
    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });  

  const {history} = props;

  const id = props.computedMatch.params.id;

  const workingDataItem = useSelector(state => {
    return state.estate.workingData[id] || null;
  });

  const [currentTab,setCurrentTab] = useState(1);

  console.log("Estate loading data:", workingDataItem);

  if (workingDataItem && workingDataItem.entity!=null) {

    dispatch({
      type: PUSH_TAB,
      payload: {
        id: createRandomTabId(),
        title: `${workingDataItem.entity.address}-${workingDataItem.entity.taskNumber}`,
        to: "/estate/"+id
      }
    });    
  }


  const fetchEstateItem = id => {
    dispatch({
      type: FETCH_ESTATE_ITEM_START,
      payload: { id }
    });


    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estate/" + id;

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_ESTATE_ITEM_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch(error => {

        console.log(error);

        dispatch({
          type: FETCH_ESTATE_ITEM_ERROR,
          payload: { id, error }
        });
      })
  };


  const reload = (e) => {
    dispatch({
      type: DISCARD_ESTATE_ITEM,
      payload: { id }
    });
  };


  const hasCompanyChainTag = (tag) => {

    let result = false;

    workingDataItem.entity.companyChainConfTags.forEach(item=>{

      if (item.tag==SETTLEMENT_PROTOCOL_PERSONAL_SMS_TAG) result = true;
    });

    return result;
  }


  if (workingDataItem == null) {
    fetchEstateItem(id);
  }

  //        {JSON.stringify(workingDataItem.data)}


  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {workingDataItem.error.error.message}</h1>;

  const renderSuccess = () => (
    <div className="View-Estate-Root-Layout View-Estate-Styling estateViewPage" key={workingDataItem.entity.id}>

      <GridContainer className="estateViewPage-content">

        <GridItem md={9}>
          <h3 className="estateViewPage-content-header">{workingDataItem.entity.address} &mdash; {workingDataItem.entity.city} {workingDataItem.entity.zipCode}</h3>
        </GridItem>

        <GridItem md={3} className="estateViewPage-content-reload">
          <Button  className="estateViewPage-content-reload-btn" color="transparent" size="sm" onClick={reload}><Refresh /></Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <a target="_blank"
             href={'https://zapdos.meglerfront.no/api/internal/estate/re-fetch/' + workingDataItem.entity.id}>Update fra meglersystemer</a>
          <br />
          <a target="_blank"
             href={'https://zapdos.meglerfront.no/nova/resources/estates/' + workingDataItem.entity.id}>Open in Nova</a>
          <CustomerSection estate={workingDataItem.entity} />

          <div className="estateViewPage-content-tabs">
            <Button color={currentTab==1 ? "primary" : null} onClick={e=>setCurrentTab(1)}>Webot</Button>
            <Button color={currentTab==2 ? "primary" : null} onClick={e=>setCurrentTab(2)}>Oppgjørskjema</Button>
            <Button color={currentTab==3 ? "primary" : null} onClick={e=>setCurrentTab(3)}>Deals</Button>
          </div>

          {currentTab==1 && ( //1
              <React.Fragment>
                <WebotSection estate={workingDataItem.entity} history={history} />

                {workingDataItem.entity.takeoverProtocolFileList.length>0 && <FilesSection title="Oppsummering - Overtakelsesprotokoll" files={workingDataItem.entity.takeoverProtocolFileList} />}
                {workingDataItem.entity.movingInServicesProtocolFileList.length>0 && <FilesSection title="Oppsummering - Digitalt Strømskjema" files={workingDataItem.entity.movingInServicesProtocolFileList} />}
                {workingDataItem.entity.newConstructionProtocolFileList.length>0 && <FilesSection title="Oppsummering - Nybygg" files={workingDataItem.entity.newConstructionProtocolFileList} />}
                {workingDataItem.entity.foreclosureProtocolFileList.length>0 && <FilesSection title="Oppsummering - Tvangsalg" files={workingDataItem.entity.foreclosureProtocolFileList} />}
                {workingDataItem.entity.commercialTakeoverProtocolFileList.length>0 && <FilesSection title="Oppsummering - Næring" files={workingDataItem.entity.commercialTakeoverProtocolFileList} />}
                {workingDataItem.entity.movingInProtocolFileList.length>0 && <FilesSection title="Oppsummering - Innflytting" files={workingDataItem.entity.movingInProtocolFileList} />}
                {workingDataItem.entity.movingOutProtocolFileList.length>0 && <FilesSection title="Oppsummering - Utflytting" files={workingDataItem.entity.movingOutProtocolFileList} />}

                <EventListSection estate={workingDataItem.entity} protocolTypes={["takeover","movingInServices","newConstruction","foreclosure","takeoverCommercial", "movingIn", "movingOut"]} />
              </React.Fragment>
          )}

          {currentTab==2 && (

            <React.Fragment>

              {hasCompanyChainTag(SETTLEMENT_PROTOCOL_PERSONAL_SMS_TAG)===false && <SettlementSection estate={workingDataItem.entity} />}
              {hasCompanyChainTag(SETTLEMENT_PROTOCOL_PERSONAL_SMS_TAG)===true && <PersonalSettlementSection estate={workingDataItem.entity} />}

              {workingDataItem.entity.settlementBuyerProtocolFileList.length>0 && <FilesSection title="Kjøper oppgjørskjema" files={workingDataItem.entity.settlementBuyerProtocolFileList} warning />}
              {workingDataItem.entity.settlementSellerProtocolFileList.length>0 && <FilesSection title="Selger oppgjørskjema" files={workingDataItem.entity.settlementSellerProtocolFileList} warning />}

              <EventListSection estate={workingDataItem.entity} protocolTypes={["settlementBuyer","settlementSeller"]} />
            </React.Fragment>

          )}

          {currentTab==3 && (
            <React.Fragment>
              <DealsSection  estate={workingDataItem.entity} />
              <EventListSection estate={workingDataItem.entity} protocolTypes={["dealsBuyer","dealsSeller"]} />
            </React.Fragment>
          )}

        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {workingDataItem.entity.powerOrderEstateList.length > 0 && (
            <OrderInfoSection powerOrderEstateList={workingDataItem.entity.powerOrderEstateList} />
          )}
          <EstateDetailsSection estate={workingDataItem.entity} />
          <BrokerInfoSection estate={workingDataItem.entity} />
        </GridItem>        
      </GridContainer>

    </div>
  );


  if (workingDataItem == null) {
    return renderLoading();
  } else if (workingDataItem.loading == true) {
    return renderLoading();
  } else if (workingDataItem.error != null) {
    return renderError();    
  } else if (workingDataItem.entity !== null) {
    return renderSuccess();
  }


  return (
    <React.Fragment>
      <h1 className="header header-error">Error: invalid state {JSON.stringify(workingDataItem)}</h1>
    </React.Fragment>
  );
}
