import React, {useState} from "react";

// special functionality
import { Magnifier } from "react-image-magnifiers";

// theme components
import Button from "components/CustomButtons/Button.js";

// application assets
import downloadIcon from "app_assets/img/download-icon.png";


export default function SinglePageFilePreview(props) {
  const { onClose, attachment } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const currentPreviewImage = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/" + attachment.id + "/preview/large/" + currentPage;

  const viewFileUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/"+ attachment.id;
  const downloadUrl = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/"+ attachment.id + "/download";



    const pagePreviews = [];

    for (let i=0;i<attachment.pageCount;i++){
      const page = i+1;
      const key = `${attachment.id}_${page}`;

      let thumbnailImage = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages/" + attachment.emailId + "/attachments/" + attachment.id + "/preview/thumbnail/" + page;

      pagePreviews.push( (
            <div key={key} onClick={ e=>setCurrentPage(page) }>
              <img src={thumbnailImage} />
              <div>Side {page}</div>
            </div>
        ));
    }

  return (
    <React.Fragment>
      <div className="Full-Page-Overlay" onClick={onClose}></div>
      <div className="Multi-Page-File-Preview Popup-Z-Index">

        <div className="Header-Grid-Area">
          <h5>{attachment.filename} <em>({attachment.formattedFilesize})</em></h5>
        </div>

        <div className="Buttons-Grid-Area">
          
          <a href={downloadUrl} target="_blank"><Button color="danger" className="Icon-Button"><img src={downloadIcon} /></Button></a>
          
          <Button color="danger" className="Text-Button" disabled>
            Split PDF
          </Button>
          
          <a href={viewFileUrl} target="_blank"><Button color="info" className="Text-Button">Åpne PDF</Button></a>

          <Button color="primary" className="Text-Button" onClick={onClose}>
            Lukk
          </Button>
        </div>
        <div className="Image-Grid-Area">
          <div className="Current-Page-Title">Side {currentPage}/{attachment.pageCount}</div>
          <Magnifier imageSrc={currentPreviewImage} />
        </div>
        <div className="Thumbnails-Grid-Area">

          {pagePreviews}

        </div>
      </div>
    </React.Fragment>
  );
}
