import React, {useState} from 'react';

import { useDispatch } from "react-redux";

// material ui
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AddIcon from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";

// core components
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";


// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// theme resources
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// actions
import {updatePowerOrderTask} from "actions/powerOrderActions";
import {updatePowerOrderBroker} from "actions/powerOrderActions";
import {setPowerOrderEstateConfigMode} from "actions/powerOrderActions";

import {updatePowerOrderBuyer} from "actions/powerOrderActions";
import {updatePowerOrderSeller} from "actions/powerOrderActions";


// app specific resources
import { expandDateInput } from "utils.js";

import PowerOrderTask from "classes/PowerOrder/PowerOrderTask";
import PowerOrderBroker from "classes/PowerOrder/PowerOrderBroker";
import PowerOrderBuyer from "classes/PowerOrder/PowerOrderBuyer";
import PowerOrderSeller from "classes/PowerOrder/PowerOrderSeller";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";

import {convertNullToString as _} from "utils.js";


const useStyles = makeStyles(styles);

const EstateConfigurator = ({estate,orderId}) => {

  const dispatch = useDispatch();

  const [selectedBuyerIdx, setSelectedBuyerIdx] = useState(null);
  const [selectedSellerIdx, setSelectedSellerIdx] = useState(null);
  const [selectedBrokerIdx, setSelectedBrokerIdx] = useState(null);

  const classes = useStyles();

  const buttonHandler = e => {
    const newTask = new PowerOrderTask();
    newTask.orderId = orderId;
    newTask.streetAddress = estate.address;
    newTask.postCode = estate.zipCode;
    newTask.postLocation = estate.city;

    dispatch(updatePowerOrderTask(newTask));

    const brokerOfficeId = estate.brokerOfficeId;
    let brokerId = null;
    let selectedBroker = null;

    estate.brokers.map( (item, index)=>{
      if (index==selectedBrokerIdx) {
        selectedBroker = item;
        brokerId = selectedBroker.id;
      }
    });


    const powerOrderBroker = new PowerOrderBroker(orderId, brokerOfficeId, brokerId, false); 

    powerOrderBroker.companyChain = estate.companyChain;
    powerOrderBroker.brokerOffice = estate.brokerOffice;
    powerOrderBroker.broker = selectedBroker;

    powerOrderBroker.brokerOfficeId = brokerOfficeId;
    powerOrderBroker.brokerId = brokerId;

    dispatch(updatePowerOrderBroker(orderId, powerOrderBroker));    

    dispatch(setPowerOrderEstateConfigMode(orderId, false));

    const buyer = new PowerOrderBuyer(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);
    const seller = new PowerOrderSeller(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);

    buyer.billingStreetAddress = estate.address;
    buyer.billingPostCode = estate.zipCode;
    buyer.billingPostLocation = estate.city;   
    

    // BUYER
    if (selectedBuyerIdx !== null) {
      const selectedEstateBuyer = estate.buyers[selectedBuyerIdx];

      buyer.firstName = selectedEstateBuyer.firstName;
      buyer.lastName = selectedEstateBuyer.lastName;
      buyer.phone = selectedEstateBuyer.mobile;
      buyer.email = selectedEstateBuyer.email;

      buyer.birthDate = null;

      if (selectedEstateBuyer.birthDate != null) {
        buyer.birthDate = expandDateInput(selectedEstateBuyer.birthDate);
      } 
    }

    dispatch(updatePowerOrderBuyer(buyer));

    // SELLER
    if (selectedSellerIdx !== null) {
      const selectedEstateSeller = estate.sellers[selectedSellerIdx];

      seller.firstName = selectedEstateSeller.firstName;
      seller.lastName = selectedEstateSeller.lastName;
      seller.phone = selectedEstateSeller.mobile;
      seller.email = selectedEstateSeller.email;

      seller.birthDate = null;

      if (selectedEstateSeller.birthDate != null) {
        seller.birthDate = expandDateInput(selectedEstateSeller.birthDate);
      }
    }

    dispatch(updatePowerOrderSeller(seller));
  }



  return (
    <React.Fragment>

      <SnackbarContent
        message={
          <h5>Oppdrag: <strong>{estate.address}, {estate.city} {estate.zipCode}</strong></h5>
        }

        icon={AddIcon}
        color="info"
      />      

      {estate.buyers.map( (item,index)=>(


        <div className={classes.checkboxAndRadio} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedBuyerIdx==index}
                size="small"
                onChange={e=>setSelectedBuyerIdx(index)}
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={`Kjøper ${index+1}: ${_(item.lastName)} ${_(item.firstName)}`}
          />
        </div> 
        ))}

      <hr />

      {estate.sellers.map( (item,index)=>(


        <div className={classes.checkboxAndRadio} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSellerIdx==index}
                size="small"
                onChange={e=>setSelectedSellerIdx(index)}
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={`Selger ${index+1}: ${_(item.lastName)} ${_(item.firstName)}`}
          />
        </div> 
        ))}


      <hr />

      {estate.brokers.map( (item,index)=>(


        <div className={classes.checkboxAndRadio} key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedBrokerIdx==index}
                size="small"
                onChange={e=>setSelectedBrokerIdx(index)}
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={item.responsibleBroker ? <strong>{`Megler ${index+1}: ${item.name}`} <em>({item.role})</em></strong> : `Megler ${index+1}: ${item.name}`}
          />
        </div> 
        ))}

      <hr />

      <div className={classes.checkboxAndRadio}>
        <FormControlLabel
          control={
            <Checkbox
              disabled
              checked={true}
              size="small"
              tabIndex={-1}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label={`Meglerkontor: ${estate.brokerOffice.name}`}
        />
      </div> 

      <Button size="sm" onClick={e=>dispatch(setPowerOrderEstateConfigMode(orderId, false))}>Avbrytt</Button>
      <Button color="primary" size="sm" onClick={buttonHandler}>Velg</Button>
      
    </React.Fragment>
    );
}

export default EstateConfigurator;