import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";

export const infoNotification = (message, notificationId) => {
  const action = {
    type: PUSH_NOTIFICATION,
    payload: {
      id: notificationId,
      message
    }
  };

  return action;
};

export const errorNotification = (message, notificationId) => {
  const action = {
    type: PUSH_NOTIFICATION,
    payload: {
      id: notificationId,
      message,
      color: "danger"
    }
  };

  return action;
};

export const warningNotification = (message, notificationId) => {
  const action = {
    type: PUSH_NOTIFICATION,
    payload: {
      id: notificationId,
      message,
      color: "warning"
    }
  };

  return action;
};

export const closeNotification = (notificationId) => {
  const action = { type: CLOSE_NOTIFICATION, payload: { id: notificationId } };

  return action;
};
