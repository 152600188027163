import { SAVE_VERTICAL_SCROLL } from "actions/types";

/*
 *
 * Usage:
 *
 * saveVerticalSCroll("/orders",100)
 */
export const saveVerticalScroll = (to, value) => {
  const action = {
    type: SAVE_VERTICAL_SCROLL,
    payload: { to, value },
  };

  return action;
};
