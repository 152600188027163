import React, {useState} from "react";
import { useDispatch } from "react-redux";

import axios from "axios";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

// theme components
import Button from "components/CustomButtons/Button.js";


// app specific assets
import materialAddIcon from "app_assets/img/material-add-icon.png";

// app specific resources
import { expandDateInput, convertNullToString as _ } from "utils.js";
import { UPDATE_POWER_ORDER_METER, ADD_POWER_ORDER_METER, DELETE_POWER_ORDER_METER } from "actions/types";
import PowerOrderMeter from "classes/PowerOrder/PowerOrderMeter";
import {isTemporaryOrderId} from "functions/powerOrderFunctions";
import {setDuplicateCheckCompleted} from "actions/powerOrderActions";
import {setDuplicateSearchResults} from "actions/powerOrderActions";

const PowerMeterSection = (props) => {

  const dispatch = useDispatch();

  const [loading,setLoading] = useState(false);

  const { orderId, powerOrder  } = props;
  let { powerMeters } = props;

  const hasPowerMeters = powerMeters.length > 0;
  const powerMeterCount = powerMeters.length;

  const addPowerOrderMeter = (orderId) => {
    dispatch({
      type: ADD_POWER_ORDER_METER,
      payload: {
        orderId,
        powerMeter: new PowerOrderMeter(orderId)
      }
    });
  };  

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title Invalid">Strømmåler({powerMeterCount}) {loading && "..."}</div>
        <div className="Section-Control-Help">Legg til ny strømmåler</div>
        <div className="Section-Control-Element"><div className="Reset-Button" style={{ backgroundImage: `url("${materialAddIcon}")` }} onClick={e=>addPowerOrderMeter(orderId)} /></div>
      </div>     

      <div className="Meter-Numbers-Area">

        {hasPowerMeters == false && (
          <div className="Field-Preview-Label" style={{ textAlign: "left" }}>
            Ingen strømmåler(e).
          </div>
        )}      

        {powerMeters.map((item,index)=>{
          return <PowerMeterItem key={`${item.id}#${index}`} powerOrder={powerOrder} index={index} orderId={orderId} powerMeter={item} powerMeterCount={powerMeterCount} setLoading={setLoading} />
        })}
        
      </div>


    </React.Fragment>
  );
};



const PowerMeterItem = (props) => {

  const dispatch = useDispatch();

  let {orderId, powerMeter, powerOrder, index, powerMeterCount, setLoading} = props;

  const updatePowerMeter = updatedPowerMeter => {
    dispatch({
      type: UPDATE_POWER_ORDER_METER,
      payload: {
        orderId,
        index,
        powerMeter: updatedPowerMeter
      }
    });
  };  

  const deletePowerMeter = index => {
    dispatch({
      type: DELETE_POWER_ORDER_METER,
      payload: {
        orderId,
        index
      }
    });    
  }

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    //console.log("Change field", e.target.name, e.target.value);

    switch (name) {
      case "meter_number":
        powerMeter.meterNumber = value;
        updatePowerMeter(powerMeter);
        break;
      case "mpid":
        powerMeter.mpid = value;
        updatePowerMeter(powerMeter);
        break;
      case "reading_date":
        powerMeter.readingDate = value;
        updatePowerMeter(powerMeter);
        break;
      case "reading_value":
        powerMeter.readingValue = value;
        updatePowerMeter(powerMeter);
        break;

      default:
        break;
    }
  };


  const checkForDuplicates = modifiedPowerMeter => {
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_orders/duplicate_check";
    console.log("POST " + url);

    const referenceOrderId = isTemporaryOrderId(orderId) ? null: orderId;

    const data = {
      referenceOrderId,
      powerMeters: [ modifiedPowerMeter ]
    }

    setLoading(true);

    axios
      .post(url, data)
      .then((response) => {
        console.log("AJAX RESPONSE", response);

        dispatch(setDuplicateCheckCompleted(orderId, false));
        dispatch(setDuplicateSearchResults(orderId, response.data.duplicateSearchResults));
      })
      .catch((error) => {
        console.log(error);
        // TODO error notification
      }).finally(()=>{
        setLoading(false);
      });
  }

  const onTextFieldBlur = e => {
    
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "reading_date": 
        powerMeter.readingDate = expandDateInput(value);
        updatePowerMeter(powerMeter);
        break;

      default:
        break;
    }

    checkForDuplicates(powerMeter);
  };


  //const commonInputProps={inputProps: { tabIndex: "7000" }};
  const commonInputProps = { inputProps: { tabIndex: "7000", onBlur: onTextFieldBlur, onChange: onTextFieldChange } };

  return (
      <React.Fragment>
        <div className="Input-Line">
          <div className="Field-Label"><strong>Målernummer {index+1}</strong></div>
          <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{...commonInputProps, name: "meter_number", value: _(powerMeter.meterNumber)}} />
          </div>
          <div className="Field-Label">MPID</div>
          <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{...commonInputProps, name: "mpid", value: _(powerMeter.mpid)}} />
          </div>        
        </div>  

        <div className="Input-Line">

          <div className="Field-Label">Avlesning</div>
          <div className="Medium-Field-Control">
              <CustomInput formControlProps={{ fullWidth: true }} inputProps={{...commonInputProps, name: "reading_value", value: _(powerMeter.readingValue)}} />
          </div>          

          <div className="Field-Label">Avlest dato</div>
          <div className="Medium-Field-Control">
              <CustomInput 
                formControlProps={{ fullWidth: true }} 
                inputProps={{ ...commonInputProps, name: "reading_date", value: _(powerMeter.readingDate) }}
              />
          </div>

          <div className="Field-Label" style={{paddingRight:"0px"}}>
            <Button onClick={e=>deletePowerMeter(index)} color="white" size="sm" style={{color:"red",padding:"0px 5px 0px 5px"}}>&mdash;</Button>
          </div>          
        </div>

        {(powerOrder && powerOrder.powerOrderEstate && powerOrder.powerOrderEstate.estate) ?
            <div className="Input-Line">
              <a target="_blank"
                 href={'https://zapdos.meglerfront.no/powerhub/search?zipCode=' + powerOrder.powerOrderEstate.estate.zipCode + '&meterNumber=' + powerMeter.meterNumber + '&mpid=' + powerMeter.mpid}>Søk
                på Powerhub</a>
            </div> : ''
        }

        {index<(powerMeterCount-1) && <div className="Power-Meter-Separator"></div>}
      </React.Fragment>
    )
}

export default PowerMeterSection;