import React from "react";
import { useDispatch } from "react-redux";


import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import CompanyChain from "classes/CompanyChain";

import { formatDate, createTemporaryId } from "utils.js";

import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

import {ADD_NEW_BROKER_OFFICE} from "actions/types.js";

import BrokerOffice from "classes/BrokerOffice";

const useStyles = makeStyles(styles);

export default function CompanyChainOfficeTable(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history, brokerOffices, companyChain } = props;

  const tableCellClasses = classes.tableCell;

  const tableHead = ["Meglerkontor", "Sted", "Status", "Opprettet dato", "Endret dato"];


  const addNewBrokerOfficeHandler = e => {
    const temporaryId = createTemporaryId();
    const newBrokerOffice = new BrokerOffice();

    newBrokerOffice.id = temporaryId;
    newBrokerOffice.companyChainId = companyChain.id;
    newBrokerOffice.companyChain = companyChain;
    newBrokerOffice.active = true;

    dispatch({
      type: ADD_NEW_BROKER_OFFICE,
      payload: {entity: newBrokerOffice}
    });

    console.log("Add temporary broker office with id= " + temporaryId);

    history.push("/broker_office/"+temporaryId); 
  }

  return (
    <Card className="companyChainOfficeTable">

      <CardHeader>
        {companyChain.source==CompanyChain.INTERNAL_SOURCE &&
        <div className="companyChainOfficeTable-addOffice">
          <Button className="companyChainOfficeTable-addOffice-btn" color="white" size="sm" onClick={addNewBrokerOfficeHandler}>+</Button>
        </div>}
      </CardHeader>

      <CardBody>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {brokerOffices.map((item, key) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: key % 2 === 0
                });

                return (
                  <TableRow
                    key={key}
                    hover={true}
                    className={classes.tableRow + " " + tableRowClasses}
                    onClick={(e) => history.push("/broker_office/" + item.id)}>
                    <TableCell className={tableCellClasses}>{item.name}</TableCell>
                    <TableCell className={tableCellClasses}>{item.city}</TableCell>
                    <TableCell className={tableCellClasses}>{item.active ? "aktiv" : "inaktiv"}</TableCell>
                    <TableCell className={tableCellClasses}>{formatDate(item.created)}</TableCell>
                    <TableCell className={tableCellClasses}>{formatDate(item.modified)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}
