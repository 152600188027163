import React from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// app resources
import {SET_POWER_ORDER_FILTERS} from "actions/types";
import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

const StatusFilter = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentValue = useSelector(state => {
    return state.powerOrders.listingData.filters.status;
  });


  const handleOnChange = (e) => {
    const value = e.target.value;
    //console.log("select ", value);

    dispatch({
    type: SET_POWER_ORDER_FILTERS,
      payload: {
        status: value
      }
    });      
  };

  const RED = "#df0e00";
  const TURQUOISE = "darkturquoise";
  const GREEN = "limegreen";

  const statuses = [
    {
      text: "Ubehandlet",
      value: PowerOrderStatusType.UNTREATED,
      color: "inherit"
    },
    {
      text: "Ubehandlet Duplikater",
      value: PowerOrderStatusType.UNTREATED_DUPLICATE,
      color: "inherit"
    },
    {
      text: "Send til leverandør",
      value: PowerOrderStatusType.READY_FOR_TRANSFER,
      color: GREEN
    },
    {
      text: "Sendt OK",
      value: PowerOrderStatusType.TRANSFER_COMPLETE,
      color: GREEN
    },
    {
      text: "Avventer svar",
      value: PowerOrderStatusType.AWAITING_ANSWER,
      color: TURQUOISE
    },
    {
      text: "På vent",
      value: PowerOrderStatusType.ON_HOLD,
      color: TURQUOISE
    },
    {
      text: "På vent 2",
      value: PowerOrderStatusType.ON_HOLD_2,
      color: TURQUOISE
    },
    {
      text: "Sendingsfeil",
      value: PowerOrderStatusType.TRANSFER_ERROR,
      color: RED
    },
    {
      text: "Fellesmålt",
      value: PowerOrderStatusType.COLLECTIVE_MEASURING,
      color: RED
    },
    {
      text: "Duplikat",
      value: PowerOrderStatusType.DUPLICATE,
      color: RED
    },
    {
      text: "Angret lev.",
      value: PowerOrderStatusType.REGRET,
      color: RED
    },
    {
      text: "Avvist lev.",
      value: PowerOrderStatusType.REJECTED,
      color: RED
    },
    {
      text: "Forkastet",
      value: PowerOrderStatusType.DELETED,
      color: RED
    }
  ];


  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Velg status
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={currentValue}
        onChange={handleOnChange}
        inputProps={{
          name: "statusSelect",
          id: "status-select",
        }}>
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}>
          Velg status
        </MenuItem>

        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={null}>
          Alle
        </MenuItem>

        {statuses.map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.value}>
            <span style={{color: item.color}}>{item.text}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
