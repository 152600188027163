import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import {validateStatus} from "functions/brokerOfficeFunctions";

// app specific components
import {  createRandomNotificationId } from "utils.js";

import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";
import {HTTP_OK, HTTP_BAD_REQUEST} from "constants.js";
import ValidationErrors from "./ValidationErrors";

import {UPDATE_BROKER} from "actions/types.js";
import BrokerSettings from "classes/BrokerSettings";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";


const useStyles = makeStyles(styles);

export default function BrokerSettingsEditForm({broker, back}) {


  const FINANCE_VIEW_PERM = "finance.view";
  const FINANCE_WITHDRAW_PERM = "finance.withdraw";
  const ESTATES_PERM = "estates";

  const TAKEOVER_PROTOCOL_PERM = "protocols.takeover";
  const MOVING_IN_PROTOCOL_PERM = "protocols.movingIn";
  const MOVING_OUT_PROTOCOL_PERM = "protocols.movingOut";

  const dispatch = useDispatch();

  const classes = useStyles();

  if (broker.settings===null) {
    broker.settings = new BrokerSettings();
  }


  let pr = broker.settings?.portalRole;
  if (pr===null) pr="";

  const [portalRole, setPortalRole] = useState(pr);

  console.log("Portal role:",portalRole);


  let pp = JSON.parse(broker.settings.portalPermissions);
  if (pp===null) pp=[];

  const [portalPermissions,setPortalPermissions] = useState(pp);

  console.log("Permissions:",portalPermissions);
 
  const updateBroker = (brokerId, entity) => {
    dispatch({
      type: UPDATE_BROKER,
      payload: {
        brokerOfficeId: broker.brokerOffice,
        brokerId: brokerId,
        broker: entity
      }
    });
  } 
  
  const dispatchInfoNotification = (message) => {
  
  const notificationId = createRandomNotificationId();

  dispatch(infoNotification(message, notificationId));       

  setTimeout(() => {
    dispatch(closeNotification(notificationId));
  }, 1500);       

}

const dispatchErrorNotification = (message, autoClose=true) => {

  const notificationId = createRandomNotificationId();

  dispatch(errorNotification(message,notificationId));            

  if (autoClose){
    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);          
  }
}



  const saveSettings = (entity) => {
    
    console.log("Save data:", entity);

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker/" + entity.id + "/settings";

    const settings = entity.settings;
    settings.portalRole = portalRole!=="" ? portalRole : null;
    settings.portalPermissions = JSON.stringify(portalPermissions);
    

    console.log(`PUT ${url}`);
    
    axios.put(url, settings, {validateStatus})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        if (response.status==HTTP_OK) {

          entity.settings = response.data;

          updateBroker(entity.id, entity);

          dispatchInfoNotification("Broker settings successfully saved");
          back();

        } else if (response.status==HTTP_BAD_REQUEST) {

          const validationErrors =  response.data.validationErrors;
          dispatchErrorNotification(<ValidationErrors validationErrors={validationErrors} />, false);
        }

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })        

  }



  const handlePortalRoleChange = e => {

    setPortalRole(e.target.value);
  }

  const hasPortalPermission = perm => {

    return portalPermissions.includes(perm);
  }

  const setPortalPermission = (perm,value) => {

    const list = portalPermissions.filter(p=>p!=perm);

    if (value==true) {
      setPortalPermissions([...list,perm]);
    } else {
      setPortalPermissions([...list]);
    }
  }


  return (
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <EditIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {<span>Edit broker settings: <big>{broker.name}</big></span>}
              </h4>
            </CardHeader>
  
              <CardBody>

                <FormControl>
                  <InputLabel>Portal role:</InputLabel>
                  <Select
                    style={{width:"200px"}}
                    value={portalRole}
                    onChange={handlePortalRoleChange}
                  >
                    <MenuItem value={""}><em>Ikke aktivert</em></MenuItem>
                    <MenuItem value={BrokerSettings.BROKER_PORTAL_ROLE}>Megler</MenuItem>
                    <MenuItem value={BrokerSettings.OFFICE_ADMIN_PORTAL_ROLE}>Dagligleder</MenuItem>
                    <MenuItem value={BrokerSettings.CHAIN_ADMIN_PORTAL_ROLE}>Kjede admin</MenuItem>
                  </Select>
                </FormControl>


                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasPortalPermission(FINANCE_VIEW_PERM)}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        onChange={e=>setPortalPermission(FINANCE_VIEW_PERM, e.target.checked)}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label={FINANCE_VIEW_PERM}
                  />
                </div>

                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasPortalPermission(FINANCE_WITHDRAW_PERM)}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        onChange={e=>setPortalPermission(FINANCE_WITHDRAW_PERM, e.target.checked)}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label={FINANCE_WITHDRAW_PERM}
                  />
                </div>

                <hr />

                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hasPortalPermission(ESTATES_PERM)}
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        onChange={e=>setPortalPermission(ESTATES_PERM, e.target.checked)}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label={ESTATES_PERM}
                  />
                </div>

              <GridContainer>
                <GridItem md={4}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPortalPermission(TAKEOVER_PROTOCOL_PERM)}
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          onChange={(e) => setPortalPermission(TAKEOVER_PROTOCOL_PERM, e.target.checked)}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={TAKEOVER_PROTOCOL_PERM}
                    />
                  </div>
                </GridItem>
                <GridItem md={4}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPortalPermission(MOVING_IN_PROTOCOL_PERM)}
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          onChange={(e) => setPortalPermission(MOVING_IN_PROTOCOL_PERM, e.target.checked)}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={MOVING_IN_PROTOCOL_PERM + "(rental)"}
                    />
                  </div>
                </GridItem>
                <GridItem md={4}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPortalPermission(MOVING_OUT_PROTOCOL_PERM)}
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                          onChange={(e) => setPortalPermission(MOVING_OUT_PROTOCOL_PERM, e.target.checked)}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={MOVING_OUT_PROTOCOL_PERM + "(rental)"}
                    />
                  </div>
                </GridItem>
              </GridContainer>;

              </CardBody>



            <CardFooter  style={{ justifyContent: "space-between" }}>
              <Button onClick={back}>
                Avbtryt
              </Button>            
              <Button color="danger" onClick={e=>saveSettings(broker)}>
                Lagre
              </Button>
            </CardFooter>

          </Card>
    )
}