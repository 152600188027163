import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Datetime from "react-datetime";


// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";

// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import EmailListTable from "./components/EmailListTable";
import PageMenu from "./components/PageMenu";
import ResponsibleUserForm from "./components/ResponsibleUserForm";
import ScrollLock from "app_components/ScrollLock";

import { fetchEmailResultsStart } from "actions/emailActions";
import { fetchEmailResultsSuccess } from "actions/emailActions";
import { fetchEmailResultsError } from "actions/emailActions";
import { discardEmailResults } from "actions/emailActions";

//functions
import {buildPaginationData} from "functions/commonFunctions";



// app specific styling
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";

const useStyles = makeStyles(regularTablesStyle);

export default function EmailListPage(props) {
  const { history } = props;

  const PAGE_TITLE = "Epost";

  useEffect(() => {
    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  dispatch({
  type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title: "Epost",
      to: "/emails",
      closeAction: discardEmailResults()
    }
  });  


  const listingData = useSelector(state => {
    return state.emails.listingData || null;
  });

  const loggedUser = useSelector(state => {
    return state.auth.authInfo.loggedUser || null
  });  

  const searchEmails = (page) => {
    
    dispatch(fetchEmailResultsStart());

    let urlParams = {};

    urlParams["page"] = page;

    if (listingData.filters.inspected !== null){
      urlParams["inspected"] = listingData.filters.inspected ? "yes" : "no";
    }     
    
    if (listingData.filters.responsibleUser){
      urlParams["responsible_user"] = listingData.filters.responsibleUser;
    } 
       
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/email_messages";
    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {

        console.log("AJAX RESPONSE", response);
        dispatch(fetchEmailResultsSuccess(response.data));
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);
        dispatch(fetchEmailResultsError(error));
      })
  };
 
  const isLoading = listingData.loading;
  const hasData = listingData.results == null ? false: true;
  const hasError = listingData.error==null ? false: true;
  const responsibleUserMode=listingData.responsibleUserMode;

  if (isLoading==false && hasData==false && hasError==false) {

    if (listingData.currentPage==null)
      searchEmails(1);
    else
      searchEmails(listingData.currentPage);
  } 
   

  const renderDefault = () => <h1 style={{ marginTop: "200px" }}>...</h1>;

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {listingData.error.error.message}</h1>;

  const renderSuccess = () => (
    <React.Fragment>
      <ScrollLock path="/emails" />
      <div className="Standard-Page-Content">
        <GridContainer>
          <GridItem md={1}>
            <PageMenu />
          </GridItem>
          <GridItem md={8}>
            <Link to="/orders">
              <Button>Ordre</Button>
            </Link>

            <Link to="/emails">
              <Button color="primary">Epost</Button>
            </Link>
          </GridItem>
          <GridItem md={3}>
            <div style={{ textAlign: "right" }}>
              {false && <div style={{ border: "1px solid gray", width: "100px", height: "20px", display: "inline-block" }}>
                CHART HERE
              </div>}
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{PAGE_TITLE} {listingData.results!==null && <em> ({listingData.results.length} av {listingData.totalResults} resultater)</em>}</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem md={2}>

                    {false && 
                    <FormControl fullWidth>
                      <Datetime timeFormat={false} inputProps={{ placeholder: "Fra" }} />
                    </FormControl>}

                    {false &&
                    <CustomInput
                      labelText="Søk meglerkontor"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{}}
                    />}                


                  </GridItem>

                  <GridItem md={2}>

                    {false && 
                    <FormControl fullWidth>
                      <Datetime timeFormat={false} inputProps={{ placeholder: "Til" }} />
                    </FormControl>
                    }

                  </GridItem>

                  <GridItem md={4}>

                    {listingData.results !== null && (
                      <div style={{ textAlign: "center" }}>
                        <div style={{ color: "#777" }}>
                          Side {listingData.currentPage} av {listingData.totalPages}
                        </div>
                        <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages, searchEmails)} />
                      </div>
                    )}

                  </GridItem>
                  <GridItem md={4} />

                  {responsibleUserMode && <ResponsibleUserForm />}

                </GridContainer>
              </CardBody>

              <CardBody>
                <EmailListTable
                  loggedUser={loggedUser}
                  history={history}
                  tableHeaderColor="primary"
                  tableHead={
                    [
                      responsibleUserMode ? "#":"ID", 
                      "Ansvarlig", 
                      "Subject", 
                      "From", 
                      "Attachments", 
                      "Dato", 
                      "Status"
                    ]
                  }
                  tableData={listingData.results}
                  responsibleUserMode={responsibleUserMode}
                />
              </CardBody>

              {listingData.results!==null && listingData.totalPages>1 && (
                <CardBody>
                  <GridContainer style={{marginTop:"5pt",marginBottom:"10pt"}}>
                    <GridItem md={4}></GridItem>
                    <GridItem md={4}>

                      <div style={{ textAlign: "center" }}>
                        <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages, searchEmails)} />
                        <div style={{ color: "#777" }}>
                          Side {listingData.currentPage} av {listingData.totalPages}
                        </div>                        
                      </div>
                      
                    </GridItem>
                    <GridItem md={4}></GridItem>
                  </GridContainer>
                </CardBody>
                )}              
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>
    );
  

  if (listingData.results !== null) {
    return renderSuccess();
  } else if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else if (listingData.results == null) {
    return renderDefault();
  }


  return (
    <React.Fragment>
      <h1>Error: invalid state {JSON.stringify(listingData)}</h1>
    </React.Fragment>
  );  
}
