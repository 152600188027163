import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";

// theme assets
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// app resources
import { UPDATE_POWER_ORDER_BUYER } from "actions/types";
import { expandDateInput, convertNullToString as _ } from "utils.js";
import PowerOrderBuyer from "classes/PowerOrder/PowerOrderBuyer";
import PowerOrderCustomer from "classes/PowerOrder/PowerOrderCustomer";


const useStyles = makeStyles(styles);

const BuyerSection = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { orderId } = props;
  let { buyer } = props;

  if (buyer == null) {
    buyer = new PowerOrderBuyer(orderId, PowerOrderCustomer.PERSON_CUSTOMER_TYPE);
  }

  const [isBillingLocationLoading, setIsBillingLocationLoading] = useState(false);
  
  const isCompanyBuyer = buyer.customerType == PowerOrderCustomer.COMPANY_CUSTOMER_TYPE;

  const setIsCompanyBuyer = value => {
    if (value == true) {
      buyer.customerType = PowerOrderCustomer.COMPANY_CUSTOMER_TYPE;
    } else {
      buyer.customerType = PowerOrderCustomer.PERSON_CUSTOMER_TYPE;
    }

    updatePowerOrderBuyer(buyer);
  };

  const updatePowerOrderBuyer = updatedBuyer => {
    dispatch({
      type: UPDATE_POWER_ORDER_BUYER,
      payload: updatedBuyer
    });
  };

  const loadBillingLocation = (newBuyer) => {
    const {billingPostCode} = newBuyer;

    if (billingPostCode.length != 4) {
      return;
    }

    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/location/search?post_code=" + billingPostCode;

    console.log("ajax query:" + url);
    
    setIsBillingLocationLoading(true);

    axios
      .get(url)
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsBillingLocationLoading(false);

        let results = response.data;

        if (results.length==1) {
          let result = results[0];
          newBuyer.billingPostLocation = result.location;
          updatePowerOrderBuyer(newBuyer);
        }

        
      })
      .catch(error => {
        console.log(error);
      });    
  }  

  const onTextFieldChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    //console.log("Change field", e.target.name, e.target.value);

    switch (name) {
      case "buyer_last_name":
        buyer.lastName = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_first_name":
        buyer.firstName = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_birth_date":
        buyer.birthDate = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_phone":
        buyer.phone = value.trim();
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_company_name":
        buyer.companyName = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_company_number":
        buyer.companyNumber = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_email":
        buyer.email = value.trim();
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_billing_street_address":
        buyer.billingStreetAddress = value;
        updatePowerOrderBuyer(buyer);
        break;
      case "buyer_billing_post_code":
        buyer.billingPostCode = value;
        updatePowerOrderBuyer(buyer);
        loadBillingLocation(buyer);
        break;
      case "buyer_billing_post_location":
        buyer.billingPostLocation = value;
        updatePowerOrderBuyer(buyer);
        break;

      default:
        break;
    }
  };

  const onTextFieldBlur = e => {
    
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "buyer_birth_date": 
        buyer.birthDate = expandDateInput(value);
        updatePowerOrderBuyer(buyer);
        break;
      default:
        break;
    }

  };

  const commonInputProps = { inputProps: { tabIndex: "5000", onBlur: onTextFieldBlur, onChange: onTextFieldChange } };
  const billingLocationPlaceholder = isBillingLocationLoading ? "Loading..." : "";

  return (
    <React.Fragment>
      <div className="Section-Header">
        <div className="Section-Title Invalid">Kjøper (flytter inn)</div>
        <div className="Section-Control-Label">person</div>
        <div className="Section-Control-Element">
          <div className="Switch-Container">
            <Switch
              tabIndex={-1}
              checked={isCompanyBuyer}
              onChange={event => {
                setIsCompanyBuyer(event.target.checked);
              }}
              value="checked"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          </div>
        </div>
      </div>

      {isCompanyBuyer == false && (
        <React.Fragment>
          <div className="Input-Line">
            <div className="Field-Label">Etternavn</div>
            <div className="Medium-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_last_name", value: _(buyer.lastName) }}
              />
            </div>
            <div className="Field-Label">Fornavn</div>
            <div className="Medium-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_first_name", value: _(buyer.firstName) }}
              />
            </div>
          </div>

          <div className="Input-Line">
            <div className="Field-Label">Fødselsdato</div>
            <div className="Medium-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_birth_date", value: _(buyer.birthDate) }}
              />
            </div>
            <div className="Field-Label">Telefonnummer</div>
            <div className="Medium-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_phone", value: _(buyer.phone) }}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      {isCompanyBuyer == true && (
        <React.Fragment>
          <div className="Input-Line">
            <div className="Field-Label">Organisasjonsnavn</div>
            <div className="Large-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_company_name", value: _(buyer.companyName) }}
              />
            </div>
            <div className="Field-Label">Orgnummer</div>
            <div className="Small-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_company_number", value: _(buyer.companyNumber) }}
              />
            </div>
          </div>

          <div className="Input-Line" style={{ width: "50%" }}>
            <div className="Field-Label">Telefonnummer</div>
            <div className="Medium-Field-Control">
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{ ...commonInputProps, name: "buyer_phone", value: _(buyer.phone) }}
              />
            </div>
          </div>
        </React.Fragment>
      )}

      <div className="Input-Line">
        <div className="Field-Label">Epost</div>
        <div className="Large-Field-Control">
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{ ...commonInputProps, name: "buyer_email", value: _(buyer.email) }}
          />
        </div>
      </div>

      <div className="Input-Line">
        <div className="Field-Label">Fakturaadresse</div>
        <div className="Large-Field-Control">
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              ...commonInputProps,
              name: "buyer_billing_street_address",
              value: _(buyer.billingStreetAddress)
            }}
          />
        </div>
      </div>

      <div className="Input-Line">
        <div className="Field-Label">Postnummer</div>
        <div className="Small-Field-Control">
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{ ...commonInputProps, name: "buyer_billing_post_code", value: _(buyer.billingPostCode) }}
          />
        </div>
        <div className="Field-Label">Poststed</div>
        <div className="Medium-Field-Control">
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              ...commonInputProps,
              placeholder: billingLocationPlaceholder,
              name: "buyer_billing_post_location",
              value: _(buyer.billingPostLocation)
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default BuyerSection;
