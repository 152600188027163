import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_START } from "actions/types";
import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_SUCCESS } from "actions/types";
import { FETCH_COMPANY_CHAIN_PRODUCT_VALUES_ERROR } from "actions/types";

import CompanyChainAccountingTable from "./CompanyChainAccountingTable";

export default function CompanyChainAccountingTab({companyChain}){

  const id = companyChain.id;

  const dispatch = useDispatch();

  const productValueTabDataItem = useSelector((state) => {
    return state.brokerChains.productValueTabData[id] || null;
  });

  console.log("Company chain product tab data item:", productValueTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_COMPANY_CHAIN_PRODUCT_VALUES_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + id + "/product_values";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_COMPANY_CHAIN_PRODUCT_VALUES_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_COMPANY_CHAIN_PRODUCT_VALUES_ERROR,
          payload: { id, error }
        });
      });
  };

  if (productValueTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {productValueTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<CompanyChainAccountingTable products={productValueTabDataItem.products} companyChain={companyChain} />);


  if (productValueTabDataItem == null) {
    return renderLoading();
  } else if (productValueTabDataItem.loading == true) {
    return renderLoading();
  } else if (productValueTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}