import React from "react";

const fieldMap = {
    "externalId": "Ekstern ID",
    "brokerOfficeName": "Meglernkontor",
    "brokerName": "Megler",
    "comment": "Kommentar",
    "powerCompanyName": "Strømleverandør",
    "powerProductName": "Strømprodukt"
};

const getFieldLabel = (name) => {
    if (fieldMap.hasOwnProperty(name)) {
        return fieldMap[name];
    }

    return name;
}


export default function MetadataOrderInfoArea(props) {

    const { powerOrderMetadata } = props;
    const { metadata } = powerOrderMetadata;

    return (

        <React.Fragment>


      <div className="View-Estate-Styling">
        <h4>Ekstra informasjon <em>(metadata)</em></h4>

        {metadata==null && "No information available"}
        {metadata!=null && <table><tbody>
          
          {Object.keys(metadata).map((item,index)=>{
            return (<tr key={index}>
                      <td>{getFieldLabel(item)}</td>
                      <td><strong>{metadata[item]}</strong></td>
                    </tr>);
        })}

        </tbody></table>}
 
      </div>

    </React.Fragment>
    );
}