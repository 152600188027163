import React from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// app resources
import { SET_EMAIL_FILTERS } from "actions/types";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

export default function ResponsibleUserFilter(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentValue = useSelector((state) => {
    return state.emails.listingData.filters.responsibleUser;
  });

  const { customerSupportUsers } = useSelector((state) => state.app.settings);

  const handleOnChange = (e) => {
    const value = e.target.value;

    dispatch({
      type: SET_EMAIL_FILTERS,
      payload: {
        responsibleUser: value,
      },
    });
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Velg ansvarlig
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={currentValue}
        onChange={handleOnChange}
        inputProps={{
          name: "userFilterSelect",
          id: "user-filter-select",
        }}>
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}>
          Velg ansvarlig
        </MenuItem>
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={null}>
          Alle
        </MenuItem>
        {customerSupportUsers.map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}>
            <span>{item.displayName}</span>
          </MenuItem>
        ))}
        ))}
      </Select>
    </FormControl>
  );
}
