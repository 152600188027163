import React from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// app resources
import {SET_EMAIL_FILTERS} from "actions/types";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

const InspectedFilter = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentValue = useSelector(state => {
    return state.emails.listingData.filters.inspected;
  });


  const handleOnChange = (e) => {
    const value = e.target.value;
    //console.log("select ", value);

    dispatch({
    type: SET_EMAIL_FILTERS,
      payload: {
        inspected: value
      }
    });      
  };

  const RED = "#df0e00";
  const GREEN = "limegreen";

  const statuses = [
    {
      text: "Ubehandlet",
      value: false,
      color: RED
    },
    {
      text: "Behandlet",
      value: true,
      color: GREEN
    }
  ];


  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        Velg status
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        value={currentValue}
        onChange={handleOnChange}
        inputProps={{
          name: "statusSelect",
          id: "status-select",
        }}>
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem,
          }}>
          Velg status
        </MenuItem>

        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={null}>
          Alle
        </MenuItem>

        {statuses.map((item, index) => (
          <MenuItem
            key={index}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.value}>
            <span style={{color: item.color}}>{item.text}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default InspectedFilter;
