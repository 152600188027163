import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { debounce } from "debounce";

// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";


// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
//import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";


// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific components
import { createRandomTabId } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import BrokerOfficeListTable from "./components/BrokerOfficeListTable";
import SearchTag from "app_components/SearchTag";
import ScrollLock from "app_components/ScrollLock";


// actions
import { FETCH_BROKER_OFFICE_RESULTS_START } from "actions/types";
import { FETCH_BROKER_OFFICE_RESULTS_SUCCESS } from "actions/types";
import { FETCH_BROKER_OFFICE_RESULTS_ERROR } from "actions/types";
import { SET_BROKER_OFFICE_BROKER_OFFICE_FILTER } from "actions/types";
import { SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER } from "actions/types";


// app specific styling
import "app_assets/scss/Autosuggest.scss";
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";



// component specific
const COMPANY_CHAIN_RESULT_TYPE='company_chain';
const BROKER_OFFICE_RESULT_TYPE = 'broker_office';
const BROKER_RESULT_TYPE = 'broker';

function getSuggestionValue(suggestion) {
  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    return suggestion.brokerOffice.name;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    return suggestion.broker.name + " (" + suggestion.brokerOffice.name + ")";  
  } else if (suggestion.resultType==COMPANY_CHAIN_RESULT_TYPE) {
    return suggestion.companyChain.name;  
  }
}

function renderSuggestion(suggestion) {

  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    let className = suggestion.brokerOffice.active==false ? "inactive" : "";
    return <div className={className}><strong>{suggestion.brokerOffice.name}</strong></div>;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    let brokerClassName = suggestion.broker.active==false ? "inactive" : "";
    let officeClassName = suggestion.brokerOffice.active==false ? "inactive" : "";
   return (
    <div>
      <strong className={brokerClassName}>{suggestion.broker.name}</strong>
      <span className={officeClassName}> ({suggestion.brokerOffice.name})</span>
    </div>);  
  } else if (suggestion.resultType==COMPANY_CHAIN_RESULT_TYPE) {
   return (
    <div>
      <strong style={{fontSize:"larger"}}>{suggestion.companyChain.name}</strong>
    </div>);  
  }
}





const useStyles = makeStyles(regularTablesStyle);

export default function BrokerOfficeListPage(props) {

  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isBrokerSearchLoading, setIsBrokerSearchLoading] = useState(false);  

  const { history } = props;


  const PAGE_TITLE = "Meglerkontorer";

  useEffect(() => {
    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });

  const classes = useStyles();

  const dispatch = useDispatch();

  dispatch({
  type: PUSH_TAB,
    payload: {
      id: createRandomTabId(),
      title: "Meglerkontorer",
      to: "/broker_offices"
    }
  });    


  const listingData = useSelector(state => {
    return state.brokerOffices.listingData || null;
  });


  const searchBrokerOffices = () => {
    dispatch({
      type: FETCH_BROKER_OFFICE_RESULTS_START,
      payload: {  }
    });

    let urlParams = {};

    if (listingData.brokerOfficeFilter){
      urlParams["broker_office_id"] = listingData.brokerOfficeFilter.id;
    }

    if (listingData.companyChainFilter){
      urlParams["company_chain_id"] = listingData.companyChainFilter.id;
    }

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_offices";

    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_BROKER_OFFICE_RESULTS_SUCCESS,
          payload: { data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_BROKER_OFFICE_RESULTS_ERROR,
          payload: { error }
        });
      })
  };



  const isLoading = listingData.loading;
  const hasData = listingData.entities == null ? false: true;
  const hasError = listingData.error==null ? false: true;
  const hasFilters = listingData.companyChainFilter!==null || listingData.brokerOfficeFilter!==null;

  if (isLoading==false && hasData==false && hasError==false && hasFilters==true) {
    searchBrokerOffices();
  }


  console.log("listingData",listingData);  


  const loadSuggestions = value => {
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_offices/autocomplete";

    const urlParams = {q: value};

    console.log("ajax query:" + url, urlParams);

    axios
      .get(url, {params: urlParams})
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsBrokerSearchLoading(false);

        const supportedResultTypes = [
          COMPANY_CHAIN_RESULT_TYPE, 
          BROKER_OFFICE_RESULT_TYPE, 
          BROKER_RESULT_TYPE
        ];
        setSuggestions(response.data.filter(suggestion=>supportedResultTypes.includes(suggestion.resultType)));        
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debouncedLoadSuggestions = useRef(debounce((value)=>{loadSuggestions(value)}, 250)).current;

  const onBrokerSearchInputChange = (event, { newValue }) => {
    setSearchText(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex}) => {
    
    console.log("Suggestion selected: " + suggestionValue, suggestions[suggestionIndex]);

    setSearchText("");

    const selectedObject = suggestions[suggestionIndex];
   
    if (selectedObject.resultType==BROKER_RESULT_TYPE) {
      updateBrokerOfficeFilter(selectedObject.brokerOffice);
    } else if (selectedObject.resultType==BROKER_OFFICE_RESULT_TYPE) {
      updateBrokerOfficeFilter(selectedObject.brokerOffice);
    } else if (selectedObject.resultType==COMPANY_CHAIN_RESULT_TYPE) {
      updateCompanyChainFilter(selectedObject.companyChain);
    }
  };

  const updateBrokerOfficeFilter = brokerOffice => {
    console.log("broker office filter",brokerOffice);

    dispatch({
      type: SET_BROKER_OFFICE_BROKER_OFFICE_FILTER,
      payload: brokerOffice
    });    
  }

  const updateCompanyChainFilter = companyChain => {
    console.log("company chain filter",companyChain);

    dispatch({
      type: SET_BROKER_OFFICE_COMPANY_CHAIN_FILTER,
      payload: companyChain
    });    
  }  

  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {listingData.error.error.message}</h1>;

  const renderDefault = () => (
    <React.Fragment>
      <ScrollLock path="/broker_offices" />
      <div className="Standard-Page-Content">
        <GridContainer>
          <GridItem md={1}></GridItem>
          <GridItem md={8}>
            <Link to="/broker_chains">
              <Button size="sm">Meglerkjede</Button>
            </Link>

            <Link to="/broker_offices">
              <Button color="primary" size="sm">
                Meglerkontorer
              </Button>
            </Link>

          </GridItem>
          <GridItem md={3}></GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{PAGE_TITLE} {listingData.entities!==null && <em> ({listingData.entities.length} resultater)</em>}</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem md={4}>

                    <div className="Autosuggest-Block">
                      <Autosuggest
                        onSuggestionSelected={onSuggestionSelected}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{value:searchText, onChange: onBrokerSearchInputChange}}
                        renderInputComponent={(autoInputProps)=>{
                          return (

                            <CustomInput
                              labelText="Søk kontor/megler"
                              formControlProps={{
                                fullWidth: false
                              }} 
                              inputProps={{...autoInputProps}}
                            />
                           )
                        }}            
                      />

                      {listingData.brokerOfficeFilter && (
                        <SearchTag title={listingData.brokerOfficeFilter.name} removeTag={()=>updateBrokerOfficeFilter(null)} />
                        )}

                      {listingData.companyChainFilter && (
                        <SearchTag title={listingData.companyChainFilter.name} removeTag={()=>updateCompanyChainFilter(null)} />
                        )}


                    </div>


                  </GridItem>

       
                  <GridItem md={4}>
                    <div style={{ textAlign: "center" }}>
                      {false && <Pagination
                        pages={[
                          { active: true, text: 1 },
                          { text: 2 },
                          { text: 3 },
                          { text: 4 },
                          { text: 5 },
                          { text: 6 }
                        ]}
                      />}
                    </div>
                  </GridItem>
                  <GridItem md={4}>
                    <div style={{ textAlign: "right" }}>
                        
                        <Button color="white" onClick={ e=>{
          
                          dispatch({
                            type: FETCH_BROKER_OFFICE_RESULTS_SUCCESS,
                            payload: { data: null }
                          });

                        }}>
                          <Refresh />
                        </Button>

                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>

              {listingData.entities!==null && <CardBody>
                <BrokerOfficeListTable

                  history={history}


                  tableHeaderColor="primary"
                  tableHead={[
                    "Meglerkontor",
                    "Kjede", 
                    "Sted",                   
                    "Status",
                    "Opprettet dato",
                    "Endret dato",
                    "Instillinger"
                  ]}
                  tableData={listingData.entities}
                />
              </CardBody>}

              {listingData.entities===null &&  <h3 style={{textAlign:"center",minHeight:"200px",color:"#CCCCCC"}}>No search filters applied.</h3>}




            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>
    );  

  



  if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else {
    return renderDefault();
  }

}
