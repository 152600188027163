import React from 'react';

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// app components
import PowerProductListTable from "./PowerProductListTable";

export default function PowerCompanyProductTab( {products, classes} ){
  return (
        
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Produkter</h4>
            </CardHeader>

            <CardBody>
              <PowerProductListTable
                tableHeaderColor="primary"
                tableData={products}
              />

            </CardBody>
          </Card>
          );
}