import { FETCH_POWER_ORDER_ITEM_START } from "actions/types";
import { FETCH_POWER_ORDER_ITEM_SUCCESS } from "actions/types";
import { FETCH_POWER_ORDER_ITEM_ERROR } from "actions/types";
import { FETCH_POWER_ORDER_RESULTS_START } from "actions/types";
import { FETCH_POWER_ORDER_RESULTS_SUCCESS } from "actions/types";
import { FETCH_POWER_ORDER_RESULTS_ERROR } from "actions/types";

import {UPDATE_POWER_ORDER_TASK} from "actions/types";
import {UPDATE_POWER_ORDER_BUYER} from "actions/types";
import {UPDATE_POWER_ORDER_SELLER} from "actions/types";
import {UPDATE_POWER_ORDER_PRODUCT} from "actions/types";
import {UPDATE_POWER_ORDER_BROKER} from "actions/types";
import {UPDATE_POWER_ORDER_ESTATE} from "actions/types";
import {UPDATE_POWER_ORDER_COMMENTS} from "actions/types";

import {ADD_NEW_POWER_ORDER} from "actions/types";
import {ADD_NEW_POWER_ORDER_COMMENT} from "actions/types";

import {UPDATE_POWER_ORDER_METER} from "actions/types";
import {UPDATE_POWER_ORDER_LOCKS} from "actions/types";
import {UPDATE_POWER_ORDER_ITEM_LOCK} from "actions/types";
import {ADD_POWER_ORDER_METER} from "actions/types";
import {DELETE_POWER_ORDER_METER} from "actions/types";

import {DISCARD_POWER_ORDER_ITEM} from "actions/types";
import {DISCARD_POWER_ORDER_RESULTS} from "actions/types";
import {RELOAD_POWER_ORDER_RESULTS} from "actions/types";
import {SET_POWER_ORDER_FILTERS} from "actions/types";

import {SET_POWER_ORDER_RESPONSIBLE_USER_MODE} from "actions/types";

import {SET_DUPLICATE_SEARCH_RESULTS} from "actions/types";
import {SET_DUPLICATE_CHECK_COMPLETED} from "actions/types";

import {SET_POWER_ORDER_ESTATE_CONFIG_MODE} from "actions/types";

import {DISCARD_EMAIL_ITEM} from "actions/types";


import { DELETE_NEW_ORDER_FROM_EMAIL } from "actions/types";


import { DRAG_AND_DROP_ATTACHMENT } from "actions/types";

import { REPLACE_TEMPORARY_ORDER_ID } from "actions/types";

import { SET_SELECTED_ORDER_IDS } from "actions/types";


import {APP_LOGOUT_SUCCESS} from "../actions/types";

// drop area types
import { EMAIL_ATTACHMENT_AREA } from "views/emails/components/constants.js";
import { ORDER_ATTACHMENT_AREA } from "views/emails/components/constants.js";

import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";
import PowerOrderSearchData from "classes/PowerOrder/PowerOrderSearchData";
import PowerOrderEmailAttachment from "classes/PowerOrder/PowerOrderEmailAttachment";

import {isTemporaryOrderId, isOrderFromEmail} from "functions/powerOrderFunctions";

const listingDataTemplate = {
  loading: false,
  error: null,
  
  results: null,
  currentPage: null,
  totalPages: null,
  totalResults: null,

  filters: {
    fromDate: null,
    toDate: null,
    dateRangeField: PowerOrderSearchData.TRANSFER_DATE_RANGE_FIELD,
    status: PowerOrderStatusType.UNTREATED,
    source: null,
    responsibleUser: null,
    textFilter: null
  },

  responsibleUserMode: false,
  selectedOrderIds: []
}

const workingDataItemTemplate = {
  loading: false,
  error: null,
  entity: null,
  duplicateSearchResults: null,
  duplicateCheckCompleted: false,
  estateConfigMode: false
};

const createInitialState = () => ({

  listingData: {
    ...listingDataTemplate
  },

  workingData: {
    
  }
});


export default function(state = createInitialState(), action) {
  let { workingData } = state;

  switch (action.type) {
    case FETCH_POWER_ORDER_ITEM_START: {
      console.log("Power order action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        loading: true
      };

      return {
        ...state,
        workingData
      };
    }

    case ADD_NEW_POWER_ORDER: {
      console.log("Power order action", action.type, action);
      
      const {entity} = action.payload;
      const id = entity.id;

      workingData[id] = {
        ...workingDataItemTemplate,
        entity
      };

      return {
        ...state,
        workingData
      };      
    }

    case FETCH_POWER_ORDER_ITEM_SUCCESS: {
      console.log("Power order action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        entity: action.payload.entity
      };

      return {
        ...state,
        workingData
      };
    }

    case FETCH_POWER_ORDER_ITEM_ERROR: {
      console.log("Power order action", action.type, action);

      let id = action.payload.id;
      workingData[id] = {
        ...workingDataItemTemplate,
        error: action.payload
      };

      return {
        ...state,
        workingData
      };
    }

    case DISCARD_POWER_ORDER_ITEM: {
      console.log("Power order action", action.type, action);

      let id = action.payload.id;
      delete workingData[id];

      return {
        ...state,
        workingData
      };
    }


    // when an email is discarded, we also need to discard all temporary emails(saved or not)
    case DISCARD_EMAIL_ITEM: {
      console.log("Power order action", action.type, action);

      const discardedEmailId = action.payload.id;

      for (let id in workingData) {

        const item = workingData[id];

        if (item.entity!==null) {
          if (item.entity.powerOrderEmail!==null && item.entity.powerOrderEmail.emailId==discardedEmailId) {
            delete workingData[id];
          }
        }
      }

      return {
        ...state,
        workingData
      };
    }


    case FETCH_POWER_ORDER_RESULTS_START: {
      console.log("Power order action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,
        loading: true
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_POWER_ORDER_RESULTS_SUCCESS: {
      console.log("Power order action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,

        results: action.payload.data.results,
        currentPage: action.payload.data.currentPage,
        totalPages: action.payload.data.totalPages,
        totalResults: action.payload.data.totalResults        
      };

      return {
        ...state,
        listingData
      };
    }

    case FETCH_POWER_ORDER_RESULTS_ERROR: {
      console.log("Power order action", action.type, action);

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters: state.listingData.filters,
        error: action.payload
      };

      return {
        ...state,
        listingData
      };
    }

    case DISCARD_POWER_ORDER_RESULTS: {
      console.log("Power order action", action.type, action);

      let listingData = {
        ...listingDataTemplate
      };

      return {
        ...state,
        listingData
      };
    }

    case RELOAD_POWER_ORDER_RESULTS: {
      console.log("Power order action", action.type, action);

      // when results are reloaded(after an order is saved)
      // the current page is restored

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        currentPage: state.listingData.currentPage,
        filters: state.listingData.filters
      }

      return {
        ...state,
        listingData
      };      
    }

    case SET_POWER_ORDER_FILTERS: {
      console.log("Power order action", action.type, action);

      const filters = {
        ...state.listingData.filters,
        ...action.payload
      }

      let listingData = {
        ...listingDataTemplate,
        responsibleUserMode: state.listingData.responsibleUserMode,
        filters
      };

      return {
        ...state,
        listingData
      };
    }   

    case SET_POWER_ORDER_RESPONSIBLE_USER_MODE: {
      console.log("Power order action", action.type, action);

      const responsibleUserMode = action.payload;

      let listingData = {
        ...state.listingData,
        responsibleUserMode
      };

      return {
        ...state,
        listingData
      };
    }

    case UPDATE_POWER_ORDER_TASK: {
      let task = action.payload;
      let orderId = task.orderId;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.task = task;
      workingData[orderId] = {...workingDataItem};


      return {
        ...state,
        workingData
      }
    }


    case UPDATE_POWER_ORDER_BUYER: {
      let buyer = action.payload;
      let orderId = buyer.orderId;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.buyer = buyer;
      workingData[orderId] = {...workingDataItem};


      return {
        ...state,
        workingData
      }
    }


    case UPDATE_POWER_ORDER_SELLER: {
      let seller = action.payload;
      let orderId = seller.orderId;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.seller = seller;
      workingData[orderId] = {...workingDataItem};


      return {
        ...state,
        workingData
      }
    }    

    case UPDATE_POWER_ORDER_PRODUCT: {
      console.log("Power order action", action.type, action);

      const {powerOrderProduct, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerOrderProduct = powerOrderProduct;
      workingData[orderId] = {...workingDataItem};


      return {
        ...state,
        workingData
      }
    }     

    case UPDATE_POWER_ORDER_ESTATE: {
      console.log("Power order action", action.type, action);
      
      const {powerOrderEstate, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerOrderEstate = powerOrderEstate;
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }  

    case SET_POWER_ORDER_ESTATE_CONFIG_MODE: {
      const {orderId, value} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.estateConfigMode = value;
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }

    case UPDATE_POWER_ORDER_BROKER: {
      console.log("Power order action", action.type, action);
      
      const {powerOrderBroker, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerOrderBroker = powerOrderBroker;
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }          

    case UPDATE_POWER_ORDER_METER: {
      console.log("Power order action", action.type, action);
      
      const {powerMeter, index, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerMeters[index] = powerMeter;
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }

    case UPDATE_POWER_ORDER_COMMENTS: {
      console.log("Power order action", action.type, action);
      
      const {comments, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.comments = comments;
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }

    case ADD_NEW_POWER_ORDER_COMMENT: {
      console.log("Power order action", action.type, action);
      
      const {entity} = action.payload;
      const orderId = entity.orderId;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.comments.push(entity);
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      };      
    }

    case DELETE_POWER_ORDER_METER: {
      console.log("Power order action", action.type, action);
      
      const {index, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerMeters.splice(index,1);
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }     

    case ADD_POWER_ORDER_METER: {
      //console.log("Power order action", action.type, action);
      
      const {powerMeter, orderId} = action.payload;
      const workingDataItem = workingData[orderId];

      workingDataItem.entity.powerMeters.push(powerMeter);
      workingData[orderId] = {...workingDataItem};

      return {
        ...state,
        workingData
      }
    }      

    case UPDATE_POWER_ORDER_LOCKS: {
      console.log("Power order action", action.type, action);

      const {locks} = action.payload;

      const lockIndex = {};

      for (let i=0;i<locks.length;i++){
        const lock = locks[i];
        lockIndex[ lock.orderId ] = lock;
      }


      let listingData = state.listingData;

      if (listingData.results !==null){
        for (let i=0;i<listingData.results.length;i++) {
          const powerOrder = listingData.results[i];

          if (lockIndex[powerOrder.id] !==undefined) {

            const lock = lockIndex[powerOrder.id];

            powerOrder.isLocked = true;
            powerOrder.lockedByUserId = lock.userId;
            powerOrder.lockedByName = lock.lockedByName;
            powerOrder.lockedAt = lock.createdAt;
          } else {
            powerOrder.isLocked = false;
            powerOrder.lockedByUserId = null;
            powerOrder.lockedByName = null;
            powerOrder.lockedAt = null;            
          }
        }  
      }

      return {
        ...state,
        listingData: {...listingData}
      }
    }

    case UPDATE_POWER_ORDER_ITEM_LOCK: {
      console.log("Power order action", action.type, action);

      const {id, lock} = action.payload;

      let listingData = state.listingData;

      if (listingData.results !==null){
        for (let i=0;i<listingData.results.length;i++) {
          
          const powerOrder = listingData.results[i];

          if (powerOrder.id==id){

            if (lock==null){
              powerOrder.isLocked = false;
              powerOrder.lockedByUserId = null;
              powerOrder.lockedByName = null;
              powerOrder.lockedAt = null;                      
            } else {
              powerOrder.isLocked = true;
              powerOrder.lockedByUserId = lock.userId;
              powerOrder.lockedByName = lock.lockedByName;
              powerOrder.lockedAt = lock.createdAt;              
            }


            break;
          }
        }  
      }

      return {
        ...state,
        listingData: {...listingData}
      }
    }


    case SET_DUPLICATE_SEARCH_RESULTS: {
      console.log("Power order action", action.type, action);

      const {id, duplicateSearchResults} = action.payload;
      const workingDataItem = workingData[id];

      workingDataItem.duplicateSearchResults=duplicateSearchResults;
      workingData[id] = {...workingDataItem};     

      return {
        ...state,
        workingData
      };
    }

    case SET_DUPLICATE_CHECK_COMPLETED: {
      console.log("Power order action", action.type, action);

      const {id, duplicateCheckCompleted} = action.payload;
      const workingDataItem = workingData[id];

      workingDataItem.duplicateCheckCompleted=duplicateCheckCompleted;
      workingData[id] = {...workingDataItem};     

      return {
        ...state,
        workingData
      };
    }    

    case REPLACE_TEMPORARY_ORDER_ID: {
      console.log("Power order action", action.type, action);

      const {temporaryOrderId, realOrderId} = action.payload;

      const workingDataItem = workingData[temporaryOrderId];

      workingData[realOrderId] = workingDataItem;
      delete workingData[temporaryOrderId];

      return {
        ...state,
        workingData
      };      
    }

    case SET_SELECTED_ORDER_IDS: {
      console.log("Power order action", action.type, action);

      const selectedOrderIds = action.payload;
      let listingData = state.listingData;

      listingData.selectedOrderIds = selectedOrderIds;

      return {
        ...state,
        listingData
      };   
    }

    case DRAG_AND_DROP_ATTACHMENT:
      return dragAndDropAttachmentReducer(state, action);
    case DELETE_NEW_ORDER_FROM_EMAIL:
      return deleteNewOrderFromEmailReducer(state, action);      

    case APP_LOGOUT_SUCCESS: {
      console.log("Power order action", action.type, action);

      return createInitialState();
    }    

    default:
      return state;
  }
}

/**
 * Common abbreviations in comments:
 *
 * EAA - email attachment area
 * NOA - new order area
 * DBOX - dropbox area
 * TOID - temporary order id
 *
*/

function dragAndDropAttachmentReducer(state, action) {
  const attachment = action.payload.attachment;
  const temporaryOrderId = action.payload.temporaryOrderId;
  const workingData = state.workingData;
  // eslint-disable-next-line no-unused-vars
  const workingDataItem = workingData[temporaryOrderId];

  switch (action.payload.dropArea) {

    case EMAIL_ATTACHMENT_AREA: {

      console.log(`[emailReducer] EMAIL_ATTACHMENT_AREA drop`, action);

      // remove the attachment from any order
      for (let orderId in workingData) {

        const workingDataItem = workingData[orderId];
        const isTemporary = isTemporaryOrderId(orderId);
        const isFromEmail = isOrderFromEmail(orderId);

        if (isTemporary && isFromEmail) {
          workingDataItem.entity.emailAttachments = workingDataItem.entity.emailAttachments.filter(item=>item.attachmentId!=attachment.id);
        }
      }  

      return {
        ...state,
        workingData
      }
    }

    case ORDER_ATTACHMENT_AREA: {

      console.log("[emailReducer] ORDER_ATTACHMENT_AREA drop",action);

      // remove the attachment from any order (when dragged from NOA)
      for (let orderId in workingData) {

        const workingDataItem = workingData[orderId];
        const isTemporary = isTemporaryOrderId(orderId);
        const isFromEmail = isOrderFromEmail(orderId);

        if (isTemporary && isFromEmail) {
          workingDataItem.entity.emailAttachments = workingDataItem.entity.emailAttachments.filter(item=>item.attachmentId!=attachment.id);
        }
      }  

      // add the attachment to the NOA order
      const workingDataItem = workingData[temporaryOrderId];
      const powerOrderEmailAttachment = new PowerOrderEmailAttachment(temporaryOrderId, attachment);    
      workingDataItem.entity.emailAttachments.push(powerOrderEmailAttachment);

      return {
        ...state,
        workingData
      }
    }

    default:
      break;
  }

  return state;
}

function deleteNewOrderFromEmailReducer(state, action) {
  const temporaryOrderId = action.payload.temporaryOrderId;
  const workingData = state.workingData;
  // const workingDataItem = workingData[temporaryOrderId];  
  
  delete workingData[temporaryOrderId];

  return {
    ...state,
    workingData
  };
}