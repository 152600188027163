export const buildPaginationData = (currentPage,totalPages,searchFunction)=>{
  
  const data = [];

  const minPage = currentPage-2;
  const maxPage = currentPage+2;

  if (totalPages>1) {
    data.push({text: "⇤", onClick:()=>searchFunction(1)});  
  }

  if (currentPage>1) {
    data.push({text: "←", onClick:()=>searchFunction(currentPage-1)});  
  } else {
    data.push({text: "←", disabled:true,onClick: null});  
  }

  for (let i=1;i<=totalPages;i++){

    if (i>=minPage && i<=maxPage) {
      if (i==currentPage) {
        data.push({text: i, active: true,onClick:()=>searchFunction(i)});
      } else {
        data.push({text: i, onClick:()=>searchFunction(i)});
      }        
    }
  }

  if (currentPage<totalPages) {
    data.push({text: "→", onClick:()=>searchFunction(currentPage+1)});  
  } else {
    data.push({text: "→", disabled:true, onClick: null});  
  }    

  if (totalPages>1) {
    data.push({text: "⇥", onClick:()=>searchFunction(totalPages)});  
  }    

  return data;
}