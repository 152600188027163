import React from "react";

import { useSelector } from "react-redux";


// material ui core
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ErrorIcon from "@material-ui/icons/Error";
import DoneIcon from "@material-ui/icons/Done";

import { useDrop } from "react-dnd";
import {EMAIL_ATTACHMENT} from "./constants";
import {ORDER_ATTACHMENT} from "./constants";
import OrderAttachment from "./OrderAttachment";

import PowerOrderStatusType from "classes/PowerOrder/PowerOrderStatusType";

export default function NewOrderArea(props) {
  const { temporaryOrderId, expanded, onDelete } = props;
  const { setPreviewAttachment } = props;
  
  const newPowerOrder = useSelector(state => {
    return state.powerOrders.workingData[temporaryOrderId].entity;
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [EMAIL_ATTACHMENT, ORDER_ATTACHMENT],
    drop: props.onDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });
  const isActive = canDrop && isOver;

  const { index, powerOrder } = props;

  const status = powerOrder.status;
  const readOnly = status==null ? false : true;

  let title = `Ordre ${index + 1} `;


  let titleClasses = ["Order-Area-Title"];
  let statusIcon = null;

  if (status==null) {
    title += `(Ikke lagret)`;
    titleClasses.push("Status-Unsaved");

  } else if (status==PowerOrderStatusType.READY_FOR_TRANSFER || status==PowerOrderStatusType.TRANSFER_COMPLETE) {
    title += `(${powerOrder.powerOrderStatusType.statusNameNo})`;
    titleClasses.push("Status-Saved");
    statusIcon = <DoneIcon style={{verticalAlign:"baseline"}} />;
  } else {
    title += `(${powerOrder.powerOrderStatusType.statusNameNo})`;
    titleClasses.push("Status-Saved");
    statusIcon = <ErrorIcon style={{verticalAlign:"baseline",color:"red"}} />;
  }

  let dropAreaClasses = ["Order-Attachment-Area"];

  if (isActive) {
    dropAreaClasses.push("Active");
  } else if (canDrop) {
    dropAreaClasses.push("CanDrop");
  }

  let dropRef = drop;
  if (readOnly) {
    dropRef = null;
  }

  return (
    <div ref={dropRef} className="New-Order-Area">
      <div className="Order-Area-Header">
        <div className={titleClasses.join(" ")}>{statusIcon} {title}</div>

        <div className="Order-Area-Buttons">
          {readOnly==false && <div className="Button" onClick={onDelete}><DeleteOutlineIcon /></div>}
          {expanded == true && <div className="Button"><RemoveIcon  /></div>}
          {expanded == false && <div className="Button"><AddIcon /></div>}
        </div>
      </div>

      {expanded && (
        <div className={dropAreaClasses.join(" ")}>
          {newPowerOrder.emailAttachments.map(({attachment}, index) => (
            <OrderAttachment key={index} attachment={attachment} readOnly={readOnly} onClick={e=>setPreviewAttachment(attachment)} />
          ))}
        </div>
      )}
    </div>
  );
}
