import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { FETCH_POWER_COMPANY_TERMS_START } from "actions/types";
import { FETCH_POWER_COMPANY_TERMS_SUCCESS } from "actions/types";
import { FETCH_POWER_COMPANY_TERMS_ERROR } from "actions/types";

import { UPDATE_POWER_COMPANY_TERMS } from "actions/types";

import PowerCompanyTermsForm from "./PowerCompanyTermsForm";
import{infoNotification,errorNotification, closeNotification} from "actions/notificationActions";
import { createRandomNotificationId } from "utils.js";


export default function PowerCompanyTermsTab({powerCompany}){

  const id = powerCompany.id;

  const dispatch = useDispatch();

  const termsTabDataItem = useSelector((state) => {
    return state.powerCompanies.termsTabData[id] || null;
  });

  console.log("Power company accounting tab data item:", termsTabDataItem);


  const fetchItem = (id) => {
    dispatch({
      type: FETCH_POWER_COMPANY_TERMS_START,
      payload: { id },
    });

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_company/" + id + "/terms";

    console.log(`GET ${url}`);

    axios
      .get(url)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_POWER_COMPANY_TERMS_SUCCESS,
          payload: { id, data: response.data }
        });
      })
      .catch((error) => {
        console.log(error);

        dispatch({
          type: FETCH_POWER_COMPANY_TERMS_ERROR,
          payload: { id, error }
        });
      });
  };

  const dispatchInfoNotification = (message) => {
    
    const notificationId = createRandomNotificationId();

    dispatch(infoNotification(message, notificationId));       

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 1500);       

  }

  const dispatchErrorNotification = (message, autoClose=true) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    if (autoClose){
      setTimeout(() => {
        dispatch(closeNotification(notificationId));
      }, 5000);          
    }
  }

  const save = terms => {
    
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_company/" + id + "/terms";

    console.log(`POST ${url}`);

    axios
      .post(url,terms)
      .then((response) => {
        // handle success
        console.log("AJAX RESPONSE", response);
        dispatchInfoNotification("Save successful");
      })
      .catch((error) => {
        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      });

  }

  const termsChangeHandler = e => {   
    dispatch({
      type: UPDATE_POWER_COMPANY_TERMS,
      payload: { id, terms: e.target.value }
    });
  }


  if (termsTabDataItem == null) {
    fetchItem(id);
  }  


  const renderLoading = () => <h1 style={{ marginTop: "200px" }}>Loading...</h1>;

  const renderError = () => <h1 style={{ marginTop: "200px" }}>Error: {termsTabDataItem.error.error.message}</h1>;

  const renderDefault = () => (<PowerCompanyTermsForm terms={termsTabDataItem.terms} termsChangeHandler={termsChangeHandler} save={save}  />);


  if (termsTabDataItem == null) {
    return renderLoading();
  } else if (termsTabDataItem.loading === true) {
    return renderLoading();
  } else if (termsTabDataItem.error !== null) {
    return renderError();
  } else {
    return renderDefault();
  }
}