import React from "react";

// app specific components
import EmailHeaderPanel from "./EmailHeaderPanel";


export default function OrderInfoArea(props) {
  let email = props.email;

  return (
    <div className="Order-Info-Area">
      <EmailHeaderPanel email={email} />

      <div className="Email-Body" dangerouslySetInnerHTML={{ __html: email.filteredHtmlContent }} />
    </div>
  );
}
