import React, { useState,useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import { debounce } from "debounce";

import Datetime from "react-datetime";
import moment from "moment";

// @material-ui/core assets and components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Assignment from "@material-ui/icons/Assignment";


// theme components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ScrollLock from "app_components/ScrollLock";

// app specific components
import { createRandomTabId, convertNullToString as _ } from "utils.js";
import { PUSH_TAB } from "actions/types";
import Constants from "../../constants";
import EstateListTable from "./components/EstateListTable";
import SearchTag from "app_components/SearchTag";


import { FETCH_ESTATE_RESULTS_START } from "actions/types";
import { FETCH_ESTATE_RESULTS_SUCCESS } from "actions/types";
import { FETCH_ESTATE_RESULTS_ERROR } from "actions/types";

import { SET_ESTATE_BROKER_OFFICE_FILTER } from "actions/types";
import { SET_ESTATE_COMPANY_CHAIN_FILTER } from "actions/types";
import { SET_ESTATE_TEXT_FILTER } from "actions/types";

// actions
import {setEstateFromDateFilter} from "actions/estateActions";
import {setEstateToDateFilter} from "actions/estateActions";

// app specific styling
import "app_assets/scss/Autosuggest.scss";
import regularTablesStyle from "app_assets/jss/views/regularTablesStyle.js";

// component specific
const COMPANY_CHAIN_RESULT_TYPE='company_chain';
const BROKER_OFFICE_RESULT_TYPE = 'broker_office';
const BROKER_RESULT_TYPE = 'broker';

function getSuggestionValue(suggestion) {
  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    return suggestion.brokerOffice.name;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    return suggestion.broker.name + " (" + suggestion.brokerOffice.name + ")";  
  } else if (suggestion.resultType==COMPANY_CHAIN_RESULT_TYPE) {
    return suggestion.companyChain.name;  
  }
}

function renderSuggestion(suggestion) {

  if (suggestion.resultType==BROKER_OFFICE_RESULT_TYPE) {
    let className = suggestion.brokerOffice.active==false ? "inactive" : "";
    return <div className={className}><strong>{suggestion.brokerOffice.name}</strong></div>;
  } else if (suggestion.resultType==BROKER_RESULT_TYPE) {
    let brokerClassName = suggestion.broker.active==false ? "inactive" : "";
    let officeClassName = suggestion.brokerOffice.active==false ? "inactive" : "";
   return (
    <div>
      <strong className={brokerClassName}>{suggestion.broker.name}</strong>
      <span className={officeClassName}> ({suggestion.brokerOffice.name})</span>
    </div>);  
  } else if (suggestion.resultType==COMPANY_CHAIN_RESULT_TYPE) {
   return (
    <div>
      <strong style={{fontSize:"larger"}}>{suggestion.companyChain.name}</strong>
    </div>);  
  }
}


const useStyles = makeStyles(regularTablesStyle);

export default function EstateListPage(props) {

  const [brokerSearchText, setBrokerSearchText] = useState("");
  const [missionSearchText, setMissionSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isBrokerSearchLoading, setIsBrokerSearchLoading] = useState(false);  


  const { history } = props;

  const PAGE_TITLE = "WebOT";

  useEffect(() => {
    document.title = PAGE_TITLE;

    return function() {
      document.title = Constants.DEFAULT_PAGE_TITLE;
    };
  });

  useEffect(()=>{
    dispatch({
      type: PUSH_TAB,
      payload: {
        id: createRandomTabId(),
        title: "Webot",
        to: "/estates"
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const classes = useStyles();


  const dispatch = useDispatch();





  const listingData = useSelector(state => {
    return state.estate.listingData || null;
  });


  const searchEstate = (page=1) => {
    dispatch({
      type: FETCH_ESTATE_RESULTS_START,
      payload: {  }
    });

    let urlParams = {};

    urlParams["page"] = page;

    if (listingData.brokerOfficeFilter){
      urlParams["broker_office_id"] = listingData.brokerOfficeFilter.id;
    }

    if (listingData.companyChainFilter){
      urlParams["company_chain_id"] = listingData.companyChainFilter.id;
    }

    if (listingData.textFilter) {
      urlParams["q"] = listingData.textFilter;
    }

    if (listingData.fromDateFilter) {
      urlParams["from_date"] = moment(listingData.fromDateFilter).format()
    }

    if (listingData.toDateFilter) {
      urlParams["to_date"] = moment(listingData.toDateFilter).format()
    }   

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estates";

    console.log(`GET ${url}`, urlParams);

    axios
      .get(url, {params: urlParams})
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        dispatch({
          type: FETCH_ESTATE_RESULTS_SUCCESS,
          payload: { data: response.data }
        });
      })
      .catch(error => {

        console.error("An error has occured during ajax request:",error);

        dispatch({
          type: FETCH_ESTATE_RESULTS_ERROR,
          payload: { error }
        });
      })
  };


  
  const isLoading = listingData.loading;
  const hasData = listingData.results == null ? false: true;
  const hasError = listingData.error==null ? false: true;
  const hasFilters = listingData.companyChainFilter!==null || listingData.brokerOfficeFilter!==null || listingData.textFilter!==null || listingData.fromDateFilter!==null || listingData.toDateFilter!==null;

  if (isLoading==false && hasData==false && hasError==false && hasFilters==true) {
    searchEstate();
  }
  

  console.log("listingData",listingData);



  const loadSuggestions = value => {
    let url = process.env.REACT_APP_API_ENDPOINT_URL + "/broker_offices/autocomplete";

    const urlParams = {q: value};

    console.log("ajax query:" + url, urlParams);

    axios
      .get(url, {params: urlParams})
      .then(response => {
        // handle success
        console.log("AJAX RESPONSE", response);

        setIsBrokerSearchLoading(false);

        const supportedResultTypes = [
          COMPANY_CHAIN_RESULT_TYPE, 
          BROKER_OFFICE_RESULT_TYPE, 
          BROKER_RESULT_TYPE
        ];
        setSuggestions(response.data.filter(suggestion=>supportedResultTypes.includes(suggestion.resultType)));        
      })
      .catch(error => {
        console.log(error);
      });
  };

  const debouncedLoadSuggestions = useRef(debounce((value)=>{loadSuggestions(value)}, 250)).current;

  const onBrokerSearchInputChange = (event, { newValue }) => {
    setBrokerSearchText(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedLoadSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex}) => {
    
    console.log("Suggestion selected: " + suggestionValue, suggestions[suggestionIndex]);

    setBrokerSearchText("");

    const selectedObject = suggestions[suggestionIndex];
   
    if (selectedObject.resultType==BROKER_RESULT_TYPE) {
      updateBrokerOfficeFilter(selectedObject.brokerOffice);
    } else if (selectedObject.resultType==BROKER_OFFICE_RESULT_TYPE) {
      updateBrokerOfficeFilter(selectedObject.brokerOffice);
    } else if (selectedObject.resultType==COMPANY_CHAIN_RESULT_TYPE) {
      updateCompanyChainFilter(selectedObject.companyChain);
    }
  };

  const updateBrokerOfficeFilter = brokerOffice => {
    console.log("broker office filter",brokerOffice);

    dispatch({
      type: SET_ESTATE_BROKER_OFFICE_FILTER,
      payload: brokerOffice
    });    
  }

  const updateCompanyChainFilter = companyChain => {
    console.log("company chain filter",companyChain);

    dispatch({
      type: SET_ESTATE_COMPANY_CHAIN_FILTER,
      payload: companyChain
    });    
  }  

  const updateTextFilter = textFilter => {
     console.log("Update text filter",textFilter);

      setMissionSearchText(textFilter);

      dispatch({
        type: SET_ESTATE_TEXT_FILTER,
        payload: textFilter
      });        
  }

  const onTextFilterBlur = e => {
    

    let textFilter = e.target.value;
    textFilter = textFilter.trim();

    if (textFilter!="") {
       updateTextFilter(textFilter);
    }
  }

  const textFilterEnterHandler = e => {
    
    let textFilter = e.target.value;
    textFilter = textFilter.trim();

    if(e.keyCode == 13){
         updateTextFilter(textFilter);
      }
      
  }  

  const fromDateChangeHandler = value => {
    if (typeof value == "object") {
      dispatch(setEstateFromDateFilter(value.toDate()));
    }
  }

  const toDateChangeHandler = value => {
    if (typeof value == "object") {
      dispatch(setEstateToDateFilter(value.toDate()));
    }
  }

  const buildPaginationData = (currentPage,totalPages)=>{
    
    const data = [];

    const minPage = currentPage-2;
    const maxPage = currentPage+2;

    if (totalPages>1) {
      data.push({text: "⇤", onClick:()=>searchEstate(1)});  
    }

    if (currentPage>1) {
      data.push({text: "←", onClick:()=>searchEstate(currentPage-1)});  
    } else {
      data.push({text: "←", disabled:true,onClick:null});  
    }

    for (let i=1;i<=totalPages;i++){

      if (i>=minPage && i<=maxPage) {
        if (i==currentPage) {
          data.push({active: true, text: i});
        } else {
          data.push({text: i, onClick:()=>searchEstate(i)});
        }        
      }
    }

    if (currentPage<totalPages) {
      data.push({text: "→", onClick:()=>searchEstate(currentPage+1)});  
    } else {
      data.push({text: "→", disabled:true, onClick: null});  
    }    

    if (totalPages>1) {
      data.push({text: "⇥", onClick:()=>searchEstate(totalPages)});  
    }    

    return data;
  }


  const renderLoading = () => <h1 className="header header-loading">Loading...</h1>;

  const renderError = () => <h1 className="header header-error">Error: {listingData.error.error.message}</h1>;

  const renderDefault = () => (

    <React.Fragment>
      <ScrollLock path="/estates" />
      <div className="Standard-Page-Content estateListPage">
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{PAGE_TITLE} {listingData.results!==null && <em> ({listingData.results.length} av {listingData.totalResults} resultater)</em>}</h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem md={4}>

                    <div className="Autosuggest-Block">
                      <Autosuggest
                        onSuggestionSelected={onSuggestionSelected}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{value:brokerSearchText, onChange: onBrokerSearchInputChange}}
                        renderInputComponent={(autoInputProps)=>{
                          return (

                            <CustomInput
                              labelText="Søk kontor/megler"
                              formControlProps={{
                                fullWidth: false
                              }} 
                              inputProps={{...autoInputProps}}
                            />
                           )
                        }}            
                      />

                      {listingData.brokerOfficeFilter && (
                        <SearchTag title={listingData.brokerOfficeFilter.name} removeTag={()=>updateBrokerOfficeFilter(null)} />
                        )}

                      {listingData.companyChainFilter && (
                        <SearchTag title={listingData.companyChainFilter.name} removeTag={()=>updateCompanyChainFilter(null)} />
                        )}

                    </div>

                     

                  </GridItem>


                  <GridItem md={4}></GridItem>


                  <GridItem md={2}>
                    <FormControl className="dateTime-wrapper" fullWidth>
                      <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={listingData.fromDateFilter} onChange={fromDateChangeHandler} inputProps={{ placeholder: "Fra",readOnly: true }} />
                    </FormControl>

                    {listingData.fromDateFilter && (
                      <SearchTag title={"Fra " + moment(listingData.fromDateFilter).format("DD MMMM YYYY (dddd)")} removeTag={()=>dispatch(setEstateFromDateFilter(null))} />
                      )}
                  </GridItem>

                  <GridItem md={2}>
                    <FormControl className="dateTime-wrapper" fullWidth>
                      <Datetime timeFormat={false} closeOnSelect={true} dateFormat="DD MMMM YYYY" value={listingData.toDateFilter} onChange={toDateChangeHandler} inputProps={{ placeholder: "Til",readOnly: true }} />
                    </FormControl>

                    {listingData.toDateFilter && (
                      <SearchTag title={"Til "+moment(listingData.toDateFilter).format("DD MMMM YYYY (dddd)")} removeTag={()=>dispatch(setEstateToDateFilter(null))} />
                      )}                    
                  </GridItem>



                  <GridItem md={4}>
                    <div>
                      <CustomInput
                        labelText="Søk oppdrag"
                        formControlProps={{
                          fullWidth: false
                        }}
                        inputProps={{onBlur:onTextFilterBlur, onKeyDown: textFilterEnterHandler, value: _(missionSearchText), onChange:e=>setMissionSearchText(e.target.value) }}
                      />
                    </div>

                    {listingData.textFilter && (
                      <SearchTag title={listingData.textFilter} removeTag={()=>updateTextFilter(null)} />
                    )}   
                  </GridItem>


                  <GridItem md={4}>
                    {listingData.results !== null && (
                      <div className="pagination-wrapper">
                        <div className="pagination-content">
                          Side {listingData.currentPage} av {listingData.totalPages}
                        </div>
                        <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages)} />
                      </div>
                    )}
                  </GridItem>

                  <GridItem md={4}></GridItem>


                </GridContainer>
              </CardBody>

              {listingData.results!==null && <CardBody>
                <EstateListTable
                  history={history}
                  tableHeaderColor="primary"
                  tableHead={["OppdragsNr", "Meglerkontor", "Meglerkjede", "Gate", "Sted", "Overtagelse dato"]}
                  tableData={listingData.results}
                />
              </CardBody>}

              {listingData.results!==null && listingData.totalPages>1 && (
                <CardBody>
                  <GridContainer className="pagination-bottomContainer">
                    <GridItem md={4}></GridItem>
                    <GridItem md={4}>

                      <div className="pagination-wrapper">
                        <Pagination pages={buildPaginationData(listingData.currentPage, listingData.totalPages)} />
                        <div className="pagination-content">
                          Side {listingData.currentPage} av {listingData.totalPages}
                        </div>                        
                      </div>
                      
                    </GridItem>
                    <GridItem md={4}></GridItem>
                  </GridContainer>
                </CardBody>
                )}

              {listingData.results===null &&  <h3 className="estateListPage-noFilter-header">No search filters applied.</h3>}


            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </React.Fragment>

    );


  if (listingData.loading == true) {
    return renderLoading();
  } else if (listingData.error !== null) {
    return renderError();    
  } else {
    return renderDefault();
  }

}
