import React from "react";

import Warning from "@material-ui/icons/Warning";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";
import thinLineBackground from "app_assets/img/thin_line_background.png";

export default function FilesSection(props) {

  const {title,files, warning} = props;

  console.log("files", files);

  return (
    <React.Fragment>

      <div className="Section-Header">
        <div className="Section-Title">{warning && <span style={{verticalAlign:"middle"}}><Warning color="error" /> </span>}{title}</div>
        <div className="Section-Spacer" style={{ backgroundImage: `url("${thinLineBackground}")` }}></div>
      </div>

      {files.map( (item, index) => {

        const fileUrl = process.env.REACT_APP_API_ENDPOINT_URL + `/protocol_file/${item.id}`;

        return (
          <div key={index} className="Summary-Settings-Area">
            <div className="Link-Container">
              <span>Last ned: </span>
              <a target="_blank" href={fileUrl}>{item.filename}</a>
            </div>
          </div>
          );


      } )}
         
    </React.Fragment>
  );
}
