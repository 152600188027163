import React from "react";
import { useSelector, useDispatch } from "react-redux";

import AddAlert from "@material-ui/icons/AddAlert";
import CustomSnackbar from "app_components/CustomSnackbar.js";

import HeaderMenu from "../common/HeaderMenu";

import { CLOSE_NOTIFICATION } from "actions/types";

const AdminLayout = function(props) {
  const notificationState = useSelector(state => state.notification);
  const dispatch = useDispatch();

  const { component, ...otherProps } = props;
  const element = React.createElement(component, otherProps);

  return (
    <React.Fragment>
      <div className="Top-Scroll-Anchor"></div>
      <HeaderMenu {...otherProps} />

      {notificationState.items.map((item, index) => (
        <CustomSnackbar
          key={item.id}
          index={index}
          place="tc"
          color={item.color}
          icon={AddAlert}
          message={item.message}
          open={true}
          closeNotification={e => {
            dispatch({
              type: CLOSE_NOTIFICATION,
              payload: {
                id: item.id
              }
            });
          }}
          close
        />
      ))}

      {element}
    </React.Fragment>
  );
};

export default AdminLayout;
