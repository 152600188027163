import React from "react";
import { Link } from "react-router-dom";

export default function EmailTabButton({ text, emailId, current }) {


  const classNames = ["Tab-Button", "Email"];

  if (current) {
    classNames.push("Current");
  }

  return (
    <Link to={`/email/${emailId}`} className={classNames.join(' ')} tabIndex={-1}>
      {text}
    </Link>
  );
}
