export default class TransactionType {
  static POWER_ORDER_TRANSACTION_TYPE = 1;
  static ADJUSTMENT_TRANSACTION_TYPE = 2;
  static FILE_TRANSACTION_TYPE = 3;
  static PORTAL_FILE_TRANSACTION_TYPE = 4;
  static ALARM_ORDER_TRANSACTION_TYPE = 5;
  static BROADBAND_ORDER_TRANSACTION_TYPE = 6;
  static PROTOCOL_BANKID_TRANSACTION_TYPE = 7;
  static BROADBAND_ORDER_2_TRANSACTION_TYPE = 8;
  static ALARM_ORDER_2_TRANSACTION_TYPE = 9;
  static PROTOCOL_BANKID_2_TRANSACTION_TYPE = 10;


  static getPayableTransactionTypes = () => {
    return [TransactionType.FILE_TRANSACTION_TYPE, TransactionType.PORTAL_FILE_TRANSACTION_TYPE];
  };
}
