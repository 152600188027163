import React, {useState} from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import {Link } from 'react-router-dom';

import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// app specific components
import { createRandomNotificationId, convertNullToString as _ } from "utils.js";

import{errorNotification,closeNotification} from "actions/notificationActions";

import {UPDATE_COMPANY_CHAIN_PRODUCT_VALUE} from "actions/types";
import {DELETE_COMPANY_CHAIN_PRODUCT_VALUE} from "actions/types";


import styles from "assets/jss/material-dashboard-pro-react/components/tableStyle";

import NotAvailableIcon from "app_assets/img/not_available_icon.png";
import { Height } from "@material-ui/icons";
//import RedStarIcon from "app_assets/img/redstar_icon.png";


const useStyles = makeStyles(styles);

const ProductValueForm = ({defaultValue, companyChainId, productId, close}) => {

  const [value,setValue] = useState(defaultValue);

  const dispatch = useDispatch();


  const dispatchErrorNotification = (message) => {

    const notificationId = createRandomNotificationId();

    dispatch(errorNotification(message,notificationId));            

    setTimeout(() => {
      dispatch(closeNotification(notificationId));
    }, 5000);   
  }

  const saveProductValue = () => {
    console.log("Save product value:", companyChainId, productId, value);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChainId + "/product/" + productId + "/product_value";

    console.log(`POST ${url}`);
    
    axios.post(url, {productValue: value})
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: UPDATE_COMPANY_CHAIN_PRODUCT_VALUE,
          payload: {
            companyChainId,
            productId,
            productValueEntity: response.data
          }
        });

        //dispatchSuccessNotification("Product value saved");

        close();

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  }

  const deleteProductValue = () => {
    console.log("Delete product value:", companyChainId, productId);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/company_chain/" + companyChainId + "/product/" + productId + "/product_value";

    console.log(`DELETE ${url}`);
    
    axios.delete(url)
      .then( response => {
        
        console.log("AJAX RESPONSE", response);
        
        dispatch({
          type: DELETE_COMPANY_CHAIN_PRODUCT_VALUE,
          payload: {
            companyChainId,
            productId
          }
        });

        //dispatchWarningNotification("Product value deleted");

        close();

      })
      .catch(error => {

        console.log(error);
        dispatchErrorNotification("An unexpected error has occured: " + error);
      })    
  }

  const inputEnterHandler = e => {

    if(e.keyCode == 13){
         if (value=="")
          deleteProductValue();
         else
          saveProductValue();
    }
  }    


  return (
    <GridContainer>
      <GridItem md={8}>
        <CustomInput
          labelText="Verdi"
          formControlProps={{
            fullWidth: false
          }}
          inputProps={{ name: "product_value", autoFocus: true, defaultValue: _(value), onKeyDown: inputEnterHandler, onChange:e=>{setValue(e.target.value)} }}
        />
      </GridItem>
      <GridItem className="companyChainAccountingTable-save" md={4}>
        <Button className="companyChainAccountingTable-save-btn" color="danger" onClick={e=>{saveProductValue()}}>
          Lagre
        </Button>
      </GridItem>
    </GridContainer>
  );
};

const expand = (itemId) => {

  setTimeout(() => {
    let el = document.getElementById(itemId);

    if (!el) return;

    let h = el.scrollHeight;
    el.style.maxHeight = h + 24 + "px"
  }, 100);
}

export default function CompanyChainAccountingTable(props) {
  const classes = useStyles();
  const { products, companyChain } = props;

  const tableCellClasses = classes.tableCell;

  const [currentItem,setCurrentItem] = useState(null);


  const tableHead = ["Strømleverandør", "Produkt", "Verdi", "Avvik"];

  return (
    <Card className="companyChainAccountingTable">

      <CardBody>
        <div className={classes.tableResponsive}>
          <Table className={classes.table}>
            {tableHead !== undefined ? (
              <TableHead>
                <TableRow className={classes.tableRow + " " + classes.tableRowHead}>
                  {tableHead.map((prop, key) => {
                    const tableCellClasses =
                      classes.tableHeadCell +
                      " " +
                      classes.tableCell +
                      " " +
                      cx({
                        [classes.tableHeadFontSize]: true
                      });
                    return (
                      <TableCell className={tableCellClasses} key={key}>
                        {prop}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {products.map((item, key) => {
                const tableRowClasses = cx({
                  [classes.tableRowBody]: true,
                  [classes.tableRowHover]: true,
                  [classes.tableStripedRow]: key % 2 === 0
                });

                let displayValue;
                let defaultValue  = null;

                if (item.productValues.length==0) {
                  displayValue = <em>Ingen verdi</em>;
                }
                else if (item.productValues.length==1) {
                  displayValue = item.productValues[0].productValue;
                  defaultValue = item.productValues[0].productValue;
                }
                else if (item.productValues.length>0) {
                  displayValue = <strong>Multiple values</strong>;
                }

                return (
                  <React.Fragment key={item.id}>
                    <TableRow
                      hover={true}
                      className={classes.tableRow + " " + tableRowClasses}
                      onClick={(e) => setCurrentItem(key) }>
                      <TableCell className={tableCellClasses}>{item.powerCompanyName}</TableCell>
                      <TableCell className={tableCellClasses}>{item.name}</TableCell>
                      <TableCell className={tableCellClasses}>{displayValue}</TableCell>
                      <TableCell className={tableCellClasses}>
                      
                        {item.brokerOfficeProductValues.length==0 && <img src={NotAvailableIcon} />}
                        
                        {item.brokerOfficeProductValues.length > 0 && (
                          <div className="companyChainAccountingTable-availableIcon">
                            <div className="companyChainAccountingTable-availableIcon-amount">
                              {item.brokerOfficeProductValues.length}
                            </div>
                          </div>
                        )}
                        

                      </TableCell>
                    </TableRow>
                    {currentItem == key && 
                    
                      <TableRow>
                        <TableCell className={tableCellClasses + ' expandable-wr'} colSpan="5">

                        <div id={item.id} className="expandable-content" onLoad={expand(item.id)}>
                        
                          <ProductValueForm defaultValue={defaultValue} companyChainId={companyChain.id} productId={item.id} close={e=>setCurrentItem(null)} />

                          {item.brokerOfficeProductValues.length > 0 && (
                            <table>
                              <tbody>

                                {item.brokerOfficeProductValues.map(item=>(
                                <tr key={item.id}>
                                  <td><Link className="companyChainAccountingTable-officeLink" to={"/broker_office/"+item.brokerOfficeId} href="#">{item.brokerOfficeName}:</Link></td>
                                  <td>{item.productValue}</td>
                                </tr>
                                ))}


                              </tbody>
                            </table>
                          )}

                          <div className="companyChainAccountingTable-info">
                            Press ENTER to save and go to the next product.
                          </div>                        
                        </div>
                      </TableCell>
                      </TableRow>
                    }
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}
