import React, { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";


// core resources
import LinearProgress from '@material-ui/core/LinearProgress';


// theme resources
import Button from "components/CustomButtons/Button.js";

// app specific resources
import { formatDateTime } from "utils.js";

import { createRandomNotificationId} from "utils.js";

import {UPDATE_POWER_ORDER_COMMENTS} from "actions/types";
import PowerOrderComment from "classes/PowerOrder/PowerOrderComment";
import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";


const CommentSection = (props) => {

  const dispatch = useDispatch();

  const [addCommentMode, setAddCommentMode] = useState(false);
  const [newCommentText, setNewCommentText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { orderId } = props;
  let comments = [...props.comments].reverse();

  const hasComments = comments.length > 0;
  const commentCount = comments.length;



  const addComment = (content) => {

    const newComment = new PowerOrderComment();
    newComment.orderId = orderId;
    newComment.content = content;


    console.log("Add comment data:", newComment);

    setIsLoading(true);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/power_order/" + orderId + "/comments";

        console.log(`PUT ${url}`);

        axios
          .put(url, newComment)
          .then( response => {
            // handle success
            console.log("AJAX RESPONSE", response);

            setAddCommentMode(false);
            setNewCommentText("");

            const freshComments = response.data;

            const notificationId = createRandomNotificationId();

            dispatch({
              type: PUSH_NOTIFICATION,
              payload: {
                id: notificationId,
                message: "Comment saved"
              }
            });            

            setTimeout(() => {
              dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
            }, 600);


            dispatch({
              type: UPDATE_POWER_ORDER_COMMENTS,
              payload: { orderId, comments: freshComments }
            });                 
          })
          .catch(error => {

            console.log(error);


            const notificationId = createRandomNotificationId();

            dispatch({
              type: PUSH_NOTIFICATION,
              payload: {
                id: notificationId,
                message: "An error occured:"+error,
                color:"danger"
              }
            });            

            setTimeout(() => {
              dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
            }, 2500);

          }).finally( ()=> setIsLoading(false));    
  };


  const MultiLineText = ({text}) => {
       return text.split("\n").map((line,key) => {
            return <React.Fragment key={key}>{line}<br /></React.Fragment>;
        });
  }

  return (
    <React.Fragment>
      {addCommentMode == false && (
        <Button size="sm" color="white" onClick={(e) => setAddCommentMode(true)}>
          Legg til ny kommentar
        </Button>
      )}

      {addCommentMode == true && (
        <React.Fragment>
          <textarea
            disabled={isLoading}
            style={{ width: "100%", resize: "vertical", height: "100pt" }}
            name="newComment"
            onChange={(e) => {
              setNewCommentText(e.target.value);
            }}></textarea>
          <div style={{ textAlign: "right" }}>

            {isLoading==true && <LinearProgress color="secondary" />}

            <Button disabled={isLoading} color="white" onClick={(e) => setAddCommentMode(false)}>
              Avbryt
            </Button>

            <Button color="primary" disabled={isLoading || (newCommentText.length>0 ? false : true)} style={{ marginLeft: "10pt" }} onClick={e=>addComment(newCommentText)}>
              Lagre kommentar
            </Button>


          </div>
        </React.Fragment>
      )}


      {hasComments == false && (
        <div className="Field-Preview-Label" style={{ textAlign: "left" }}>
          Ingen kommentarer.
        </div>
      )}
      {hasComments == true && (
        <div className="Section-Header" style={{ background: "none" }}>
          <div className="Section-Title">Kommentarer({commentCount})</div>
        </div>
      )}

      {hasComments == true &&
        comments.map((item) => (
          <div key={item.id} style={{ fontSize: "smaller" }}>
            {formatDateTime(item.createdAt)} - <strong>{item.createdByName}</strong> - <MultiLineText text={item.content} />
          </div>
        ))}
    </React.Fragment>
  );
};

export default CommentSection;
