import React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

// material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// theme components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

// action creators
import {closeTabTo} from "actions/tabActions";

// app specific styles and graphics
import "app_assets/scss/Estates.scss";

import { PUSH_NOTIFICATION, CLOSE_NOTIFICATION } from "actions/types";
import { createRandomNotificationId } from "utils.js";
import {errorNotification} from "actions/notificationActions";

import WebotCustomMessage from "./WebotCustomMessage";
import ProtocolItem from "./ProtocolItem";

import { DISCARD_ESTATE_ITEM } from "actions/types";

import Protocol from "classes/Protocol";
import SmsMessageLog from "classes/SmsMessageLog";


export default function WebotSection(props) {

  const dispatch = useDispatch();

  const { estate, history } = props;

  // -------- SMS SENDING UI ELEMENTS, HANDLERS -------- 

  const createDefaultList = () => {
    // add everyone in the list by default

    const defaultList = [];

    estate.brokers.map((broker,index)=>{
      defaultList.push({name:broker.name,mobile:broker.mobile,contact_type: SmsMessageLog.BROKER_CONTACT_TYPE});
    });

    estate.buyers.map((buyer,index)=>{
      defaultList.push({name:`${buyer.firstName} ${buyer.lastName}`,mobile:buyer.mobile,contact_type: SmsMessageLog.BUYER_CONTACT_TYPE});
    });

    estate.sellers.map((seller,index)=>{
       defaultList.push({name:`${seller.firstName} ${seller.lastName}`,mobile:seller.mobile,contact_type: SmsMessageLog.SELLER_CONTACT_TYPE});
    });

    return defaultList;
  }

  const getTakeoverSms = () =>{

    const protocol = estate.takeoverProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }


  const getMovingInServicesSms = () =>{

    const protocol = estate.movingInServicesProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  

    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `Hei, ved overtakelse av ${estate.address} er det blant annet viktig at du oppretter en strømavtale. `,
      "Bruk denne linken, så hjelper vi deg videre:",
      protocolUrl,
      "",
      "Vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }

  const getNewConstructionSms = () =>{

    const protocol = estate.newConstructionProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  

    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }

  const getForeclosureSms = () =>{

    const protocol = estate.foreclosureProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }

  const getCommercialTakeoverSms = () =>{

    const protocol = estate.commercialTakeoverProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }

  const getMovingInSms = () =>{

    const protocol = estate.movingInProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }


  const getMovingOutSms = () =>{

    const protocol = estate.movingOutProtocol;
    const protocolUrl = `https://digitalovertakelse.no/${protocol.ticketId}/${protocol.password}`;  
    const regards = estate.brokerOffice.displayName !== null ? estate.brokerOffice.displayName: estate.brokerOffice.name;

    let message = [
      `For overtakelse av ${estate.address}, bruk følgende link:`,
      protocolUrl,
      "",
      "Nb! Bruk kun én telefon for utfylling og signering av protokoll.",
      "",
      "Med vennlig hilsen",
      regards
    ].join('\n');

    return message;
  }


  const sendSmsGroup = (smsRecipientList, protocolType) => {

    if (protocolType==Protocol.TAKEOVER_TYPE){
      smsRecipientList.map(item=>item.message=getTakeoverSms());  
    } else if (protocolType==Protocol.MOVING_IN_SERVICES_TYPE){
      smsRecipientList.map(item=>item.message=getMovingInServicesSms());  
    } else if (protocolType==Protocol.NEW_CONSTRUCTION_TYPE){
      smsRecipientList.map(item=>item.message=getNewConstructionSms());  
    } else if (protocolType==Protocol.FORECLOSURE_TYPE){
      smsRecipientList.map(item=>item.message=getForeclosureSms());  
    } else if (protocolType==Protocol.COMMERCIAL_TAKEOVER_TYPE){
      smsRecipientList.map(item=>item.message=getCommercialTakeoverSms());  
    }  else if (protocolType==Protocol.MOVING_IN_TYPE){
      smsRecipientList.map(item=>item.message=getMovingInSms());  
    }  else if (protocolType==Protocol.MOVING_OUT_TYPE){
      smsRecipientList.map(item=>item.message=getMovingOutSms());  
    } else {
      console.error("Unsupported protocol type, the sms message can't be generated: " + protocolType);
    }

    console.log("Prepare to send messages: ",smsRecipientList);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estate/" + estate.id + "/send_sms?protocol_type=" + protocolType;

    console.log(`POST ${url}`);

    axios
      .post(url, smsRecipientList)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);


        dispatch({
          type: DISCARD_ESTATE_ITEM,
          payload: {
            id: estate.id
          }
        });   

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "SMS messages sent"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);   



      })
      .catch(error => {

        console.log(error);

        const notificationId = createRandomNotificationId();

        dispatch(errorNotification(`An error has occured while sending messages: ${error.response.data}, response code=${error.response.status}`, notificationId));         

      }).finally( ()=> {

      })


      dispatch(closeTabTo("/estate/" + estate.id));    

      // ... and go back to order list
      history.push("/estates");          

  }

  // popper control
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  let popperOpen1 = Boolean(anchorEl1);
  const popperId1 = popperOpen1 ? 'simple-popper' : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  let popperOpen2 = Boolean(anchorEl2);
  const popperId2 = popperOpen2 ? 'simple-popper' : undefined;

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  let popperOpen3 = Boolean(anchorEl3);
  const popperId3 = popperOpen3 ? 'simple-popper' : undefined;

  const [anchorEl4, setAnchorEl4] = React.useState(null);
  let popperOpen4 = Boolean(anchorEl4);
  const popperId4 = popperOpen4 ? 'simple-popper' : undefined;

  const [anchorEl5, setAnchorEl5] = React.useState(null);
  let popperOpen5 = Boolean(anchorEl5);
  const popperId5 = popperOpen5 ? 'simple-popper' : undefined;

  const [anchorEl6, setAnchorEl6] = React.useState(null);
  let popperOpen6 = Boolean(anchorEl6);
  const popperId6 = popperOpen6 ? 'simple-popper' : undefined;

  const [anchorEl7, setAnchorEl7] = React.useState(null);
  let popperOpen7 = Boolean(anchorEl7);
  const popperId7 = popperOpen7 ? 'simple-popper' : undefined;

  const handleOpenSendTakeover = e => {
    setAnchorEl1(anchorEl1 ? null : e.currentTarget);
  }

  const handleOpenSendMovingInServices = e => {
    setAnchorEl2(anchorEl2 ? null : e.currentTarget);
  }

  const handleOpenSendNewConstruction = e => {
    setAnchorEl3(anchorEl3 ? null : e.currentTarget);
  }

  const handleOpenSendForeclosure = e => {
    setAnchorEl4(anchorEl4 ? null : e.currentTarget);
  }

  const handleOpenSendCommercialTakeover = e => {
    setAnchorEl5(anchorEl5 ? null : e.currentTarget);
  }

  const handleOpenSendMovingIn = e => {
    setAnchorEl6(anchorEl6 ? null : e.currentTarget);
  }

  const handleOpenSendMovingOut = e => {
    setAnchorEl7(anchorEl7 ? null : e.currentTarget);
  }

  // --------------------------------------------------


  const activateProtocol = (protocolType) => {
    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/protocol/estate/" + estate.id + "?type=" + protocolType;

    const requestData = {active:true};

    console.log(`POST ${url}`);

    axios
      .post(url, requestData)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);


        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: "Protocol successfully activated"
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);   

        
        dispatch({
          type: DISCARD_ESTATE_ITEM,
          payload: {
            id: estate.id
          }
        });              

      })
      .catch(error => {

        console.log(error);
        dispatch(errorNotification(`Error: ${error.response.data}, response code=${error.response.status}`, createRandomNotificationId()));

      })
  }



  const sendCustomSms = (customSmsPhoneNr, customSmsMessage, protocolType) => {
    console.log("send custom sms",customSmsPhoneNr, customSmsMessage);

    const url = process.env.REACT_APP_API_ENDPOINT_URL + "/estate/" + estate.id + "/send_sms?protocol_type="+protocolType;

    console.log(`POST ${url}`, url);

    const payload = [{
          mobile: customSmsPhoneNr,
          name: "",
          message: customSmsMessage,
          contact_type: SmsMessageLog.UNKNOWN_CONTACT_TYPE
        }];

    console.log("Prepare to send custom sms payload",payload);

    axios
      .post(url, payload)
      .then( response => {
        // handle success
        console.log("AJAX RESPONSE", response);


        dispatch({
          type: DISCARD_ESTATE_ITEM,
          payload: {
            id: estate.id
          }
        });   

        const notificationId = createRandomNotificationId();

        dispatch({
          type: PUSH_NOTIFICATION,
          payload: {
            id: notificationId,
            message: `SMS message sent to ${customSmsPhoneNr}`
          }
        });            

        setTimeout(() => {
          dispatch({ type: CLOSE_NOTIFICATION, payload: { id: notificationId } });
        }, 2500);   

      })
      .catch(error => {

        console.log(error);

        const notificationId = createRandomNotificationId();

        const errorMessage = `An error has occured while sending the sms: ${error.response.data}, response code=${error.response.status}`;
        dispatch(errorNotification(errorMessage,notificationId));         

      }).finally( ()=> {
        
      })

      dispatch(closeTabTo("/estate/" + estate.id));    

      // ... and go back to order list
      history.push("/estates");    
  }


  // --------------------------------------------------


  const commonProtocolItemProps = {
    sendSmsGroup,
    activateProtocol,
    estate,
    createDefaultList
  };


//{hasActivatedProtocol ? null : "danger"}

let hasActivatedProtocol =
  estate.takeoverProtocol !== null ||
  estate.movingInServicesProtocol !== null ||
  estate.newConstructionProtocol !== null ||
  estate.foreclosureProtocol !== null ||
  estate.commercialTakeoverProtocol !== null ||
  estate.movingInProtocol !== null ||
  estate.movingOutProtocol !== null;

  const isProtocolSupported = protocolType => estate.supportedProtocolTypes.includes(protocolType);

  const getButtonColor = protocolType => {
    if (hasActivatedProtocol==true) {
      return null;
    }

    if (isProtocolSupported(protocolType)) {
      return "primary";
    }

    return "danger";
  }

  return (
    <React.Fragment>

      {estate.officeConfiguredForProtocol!==true && <div style={{marginTop:"20pt"}}></div>}
      {estate.officeConfiguredForProtocol!==true && <SnackbarContent message={<h5>Meglerkontoret er ikke konfigurert for web protokoll</h5>} icon={AddAlert} color="danger" />}

      <ProtocolItem
        protocol={estate.takeoverProtocol}
        protocolType={Protocol.TAKEOVER_TYPE}
        protocolName="Overtakelsesprotokoll"
        protocolSupported={isProtocolSupported(Protocol.TAKEOVER_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.TAKEOVER_TYPE)}
        showConfirmation={isProtocolSupported(Protocol.TAKEOVER_TYPE) ? false: true}
        popperId={popperId1}
        popperOpen={popperOpen1}
        anchorEl={anchorEl1}
        setAnchorEl={setAnchorEl1}
        handleOpenSendToClick={handleOpenSendTakeover}
      />

      <ProtocolItem
        protocol={estate.movingInServicesProtocol}
        protocolType={Protocol.MOVING_IN_SERVICES_TYPE}
        protocolName="Digitalt Strømskjema"
        protocolSupported={isProtocolSupported(Protocol.MOVING_IN_SERVICES_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.MOVING_IN_SERVICES_TYPE)}
        showConfirmation={true}
        popperId={popperId2}
        popperOpen={popperOpen2}
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        handleOpenSendToClick={handleOpenSendMovingInServices}
      />

      <ProtocolItem
        protocol={estate.newConstructionProtocol}
        protocolType={Protocol.NEW_CONSTRUCTION_TYPE}
        protocolName="Nybygg"
        protocolSupported={isProtocolSupported(Protocol.NEW_CONSTRUCTION_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.NEW_CONSTRUCTION_TYPE)}
        showConfirmation={true}
        popperId={popperId3}
        popperOpen={popperOpen3}
        anchorEl={anchorEl3}
        setAnchorEl={setAnchorEl3}
        handleOpenSendToClick={handleOpenSendNewConstruction}
      />

      <ProtocolItem
        protocol={estate.foreclosureProtocol}
        protocolType={Protocol.FORECLOSURE_TYPE}
        protocolName="Tvangssalg"
        protocolSupported={isProtocolSupported(Protocol.FORECLOSURE_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.FORECLOSURE_TYPE)}
        showConfirmation={true}
        popperId={popperId4}
        popperOpen={popperOpen4}
        anchorEl={anchorEl4}
        setAnchorEl={setAnchorEl4}
        handleOpenSendToClick={handleOpenSendForeclosure}
      />

      <ProtocolItem
        protocol={estate.commercialTakeoverProtocol}
        protocolType={Protocol.COMMERCIAL_TAKEOVER_TYPE}
        protocolName="Næring"
        protocolSupported={isProtocolSupported(Protocol.COMMERCIAL_TAKEOVER_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.COMMERCIAL_TAKEOVER_TYPE)}
        showConfirmation={true}
        popperId={popperId5}
        popperOpen={popperOpen5}
        anchorEl={anchorEl5}
        setAnchorEl={setAnchorEl5}
        handleOpenSendToClick={handleOpenSendCommercialTakeover}
      />

      <ProtocolItem
        protocol={estate.movingInProtocol}
        protocolType={Protocol.MOVING_IN_TYPE}
        protocolName="Innflytting"
        protocolSupported={isProtocolSupported(Protocol.MOVING_IN_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.MOVING_IN_TYPE)}
        showConfirmation={true}
        popperId={popperId6}
        popperOpen={popperOpen6}
        anchorEl={anchorEl6}
        setAnchorEl={setAnchorEl6}
        handleOpenSendToClick={handleOpenSendMovingIn}
      />

      <ProtocolItem
        protocol={estate.movingOutProtocol}
        protocolType={Protocol.MOVING_OUT_TYPE}
        protocolName="Utflytting"
        protocolSupported={isProtocolSupported(Protocol.MOVING_OUT_TYPE)}
        commonProps={commonProtocolItemProps}
        buttonColor={getButtonColor(Protocol.MOVING_OUT_TYPE)}
        showConfirmation={true}
        popperId={popperId7}
        popperOpen={popperOpen7}
        anchorEl={anchorEl7}
        setAnchorEl={setAnchorEl7}
        handleOpenSendToClick={handleOpenSendMovingOut}
      />


      <WebotCustomMessage
        estate={estate}
        sendCustomSms={sendCustomSms}
        enabled={hasActivatedProtocol}
        getTakeoverSms={getTakeoverSms}
        getMovingInServicesSms={getMovingInServicesSms}
        getNewConstructionSms={getNewConstructionSms}
        getForeclosureSms={getForeclosureSms}
        getCommercialTakeoverSms={getCommercialTakeoverSms}
        getMovingInSms={getMovingInSms}
        getMovingOutSms={getMovingOutSms}
      />

    </React.Fragment>
  );
}
